
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import LoadingScreen from '../loadScreen/Loadscreen';
import dayjs from 'dayjs';
import { DivConteudoAgendamento, DivMainPopupBuscaAgendamento, DivResultadoBusca } from './styledPopupBuscaAgendamento';
import Popup from 'reactjs-popup';
import PopupEditSchedule from '../popupEditSchedule/PopupEditSchedule';
export default function PopupBuscaAgendamento() {
    const [resultadoBusca, setResultadoBusca] = useState([]);
    const [openPopupEditAgendamento, setOpenPopupEditAgendamento] = useState(false);
    const [mostraResultado, setMostraResultado] = useState(false);
    const [dadosAgendamento, setDadosAgendamento] = useState({
        status: "",
        agenda: "",
        tipo: "",
        inicio: "",
        final: "",
        descricao: "",
        id: ""
    });
    const [loading, setLoading] = useState('none');
    const api = useApi();
    const stylePopup = {
        backdropFilter: 'blur(5px)',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
    };
    const fakeDados = [
        {
            "Id_do_Agendamento": 40809,
            "Id_do_Usuario": 42,
            "Inicio": "2022-03-09T15:00:00",
            "Final": "2022-03-09T16:00:00",
            "Descricao": "Willians 21970143475 Financeiro info@medx.med.br[Agendamento Online]",
            "Status": 1,
            "Id_da_Venda": null,
            "Tipo_Agendamento": "Financeiro",
            "Id_Cliente": 6869,
            "Historico_Edicao": "Feb 17 2022  6:47PM",
            "Nome": null
        },
        {
            "Id_do_Agendamento": 40810,
            "Id_do_Usuario": 42,
            "Inicio": "2022-03-09T15:00:00",
            "Final": "2022-03-09T16:00:00",
            "Descricao": "Willians 21970143475 Financeiro info@medx.med.br[Agendamento Online]",
            "Status": 2,
            "Id_da_Venda": null,
            "Tipo_Agendamento": "Financeiro",
            "Id_Cliente": 6869,
            "Historico_Edicao": null,
            "Nome": null
        },
        {
            "Id_do_Agendamento": 40811,
            "Id_do_Usuario": 42,
            "Inicio": "2022-03-09T15:00:00",
            "Final": "2022-03-09T16:00:00",
            "Descricao": "Willians 21970143475 Financeiro info@medx.med.br[Agendamento Online]",
            "Status": 2,
            "Id_da_Venda": null,
            "Tipo_Agendamento": "Financeiro",
            "Id_Cliente": 6869,
            "Historico_Edicao": null,
            "Nome": null
        },
        {
            "Id_do_Agendamento": 40815,
            "Id_do_Usuario": 42,
            "Inicio": "2022-03-02T15:00:00",
            "Final": "2022-03-02T16:00:00",
            "Descricao": "willians 21970143475 Financeiro info@medx.med.br[Agendamento Online]",
            "Status": 1,
            "Id_da_Venda": null,
            "Tipo_Agendamento": "Financeiro",
            "Id_Cliente": 6869,
            "Historico_Edicao": "Feb 18 2022 11:04AM",
            "Nome": null
        }
    ];

    function realizaBusca(event) {
        if (event.key == "Enter") {
            carregaResultadoBusca(event.target.value);
        }

    }

    async function carregaResultadoBusca(descricao) {
        try {
            setLoading('flex');
            const requestCarregaResultdoBusca = await api.get(`/agenda/GetAgendamentoByDesc?descricao=${descricao}`);
            if (requestCarregaResultdoBusca.status == 200) {
                setResultadoBusca(requestCarregaResultdoBusca.data);
                setMostraResultado(true);
                setLoading('none');
            }
        } catch (error) {
            console.error(error.request)
            if (error.request) {
                if (error.request.response) {
                    toast.error(error.request.response.replace(/"/g, ""));
                    setLoading('none');
                    return null;
                }
            } else {
                toast.error('Não foi possível realizar a busca');
                setLoading('none');
            }
        }
    }

    function selecionaAgendamento(dadosAgendamento) {
        setDadosAgendamento({
            status: dadosAgendamento.Status,
            agenda: dadosAgendamento.Id_do_Usuario,
            tipo: dadosAgendamento.Tipo_Agendamento,
            inicio: dadosAgendamento.Inicio,
            final: dadosAgendamento.Final,
            descricao: dadosAgendamento.Descricao,
            id: dadosAgendamento.Id_do_Agendamento
        })
        setOpenPopupEditAgendamento(true);
    }

    return <div style={{ padding: '16px' }}>
        <h1 style={{ textAlign: 'center' }}>Busca</h1>
        <input style={{ margin: '16px 0', minWidth: '300px' }} type="text" placeholder='Informe a descrição do agendamento.' onKeyPress={(event) => realizaBusca(event)} />
        <DivResultadoBusca mostraResultado={mostraResultado}>
            <h1 style={{ textAlign: 'center', margin: '0 0 12px 0' }} >Resultado da busca</h1>
            <DivMainPopupBuscaAgendamento>
                {
                    resultadoBusca.map(item => <DivConteudoAgendamento key={item.Id_do_Agendamento} onClick={() => selecionaAgendamento(item)}>
                        <span>Inicio: {dayjs(item.Inicio).format('DD/MM/YYYY HH:mm')}</span>
                        <p style={{ margin: '6px 0 0 0' }}>Descricao:{item.Descricao}</p>
                    </DivConteudoAgendamento>)
                }
            </DivMainPopupBuscaAgendamento>
        </DivResultadoBusca>
        <Popup open={openPopupEditAgendamento}
            contentStyle={stylePopup}
            closeOnDocumentClick={false}
            onClose={() => setOpenPopupEditAgendamento(false)}>
            <PopupEditSchedule close={setOpenPopupEditAgendamento}
                id={dadosAgendamento.id}
                getAgendamentos={() => console.log('atualiza')}
            />
        </Popup>
        <LoadingScreen display={loading} />
    </div>;
}
