import styled from 'styled-components';

export const DivMainSmsView = styled.div`
    width:fit-content;
    height:fit-content ;
    max-width:1080px;
    border-radius: 6px;
    padding: 20px;
    overflow:hidden;
    >h1{
        margin:0 0 12px 0;
    }
`;
export const DivViewSmsList = styled.div`
     width:fit-content;
    height:fit-content ;
    max-width:1080px;
    max-height:600px;
    overflow:hidden auto;
    padding:0 20px 0 0;
`
export const DivMainContent = styled.div`
    padding: 0 0px 12px 0;
    margin:0 0 30px 0;
    border: 1px solid lightgrey;
    border-top: 0;
    border-right: 0;
    border-left: 0
`