import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import {
    Button,
    DivBody,
    DivFooter,
    DivMain,
    DivSearch,
    DivTable,
    DivTitle,
} from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import XMLParser from 'react-xml-parser';
import { DivButtonsActionPopupBankInfoEdit, DivMainPopupBankInfoEdit, DivRakingBankInfoEdit } from './styledFinancasConciliacao';
export default function FinancasConciliacao() {
    const refInputFileOfx = useRef();
    const api = useApi();
    const [skip, setSkip] = useState(0);
    const [searchopen, setSearchopen] = useState(false);
    const [payments, setPayments] = useState([]);
    const [listRatingBank, setListRatingBank] = useState([]);
    const [loading, setLoading] = useState('none');
    const [countRow, setCountRow] = useState(0);
    const [openTransactionEdit, setOpenTransactionEdit] = useState(false);
    const [ratingBankInfo, setRatingBankInfo] = useState({
        IddoLancamento: "",
        Vencimento: "",
        DataPagamento: "",
        Documento: "",
        Historico: "",
        Credito: "",
        Debito: "",
        IddaClassificacao: ""
    })
    async function getPayments(take, skip) {
        try {
            setLoading('flex');
            const responseGetAllPayments = await api.get(`/boleto/GetAllPayments?Take=${take}&Skip=${skip}`);
            if (responseGetAllPayments.status === 200) {
                setPayments((oldvalue) => [...oldvalue, ...responseGetAllPayments.data]);
                setLoading('none');
            }
        } catch (error) {
            toast.error('Não foi possível carregar lista de lançamentos.');
            setLoading('none');
        }
    }
    async function searchPayments(string) {
        try {
            if (string === '') {
                setSearchopen(false);
                setSkip(50);
                getPayments(50, 0);
                return;
            }
            setLoading('flex');
            setSearchopen(true);
            const responseSearchPayments = await api.get(`/boleto/SearchPayments?busca=${string}`);
            if (responseSearchPayments.status === 200) {
                setPayments(responseSearchPayments.data);
                setLoading('none');
            } else {
                toast.error('Erro ao buscar lançamento.');
                setLoading('none');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error('Nenhum lançamento encontrado.');
                    setLoading('none');
                }
            } else {
                toast.error('Não foi possível fazer busca.');
                setLoading('none');
            }
        }
    }
    async function verifyAllOfxInfo(arrayOfxInfo) {
        try {
            setLoading('flex');
            const responseGetAllOfxInfo = await api.post(`/boleto/GetAllOfxInfo`, arrayOfxInfo);
            if (responseGetAllOfxInfo.status === 200) {
                toast.success('Conciliação bancária feita com sucesso.');
                await getPayments(50, 0);
                setSkip(0);
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível fazer a conciliação bancária.');
            setLoading('none');
        }
    }
    async function getBase64FromOfxFile(file) {
        return new Promise((resolve) => {
            function closeTags(text, tagName) {
                let newText = String();

                let position = text.indexOf(tagName);
                if (position !== -1) {
                    const textSeparator = text.split(tagName);

                    textSeparator.forEach((itemText, index) => {
                        if (index === 0) {
                            newText = itemText;
                        }
                        if (index > 0) {
                            newText =
                                newText + tagName + itemText.replace('<', `</${tagName.replace('<', '').replace('>', '')}> <`);
                        }
                    });
                }
                return newText;
            }
            let reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = function () {
                const conversionXml = `<?xml version="1.0" encoding="UTF-8" ?> ${reader.result
                    .replace('OFXHEADER:100', '')
                    .replace('DATA:OFXSGML', '')
                    .replace('VERSION:102', '')
                    .replace('SECURITY:NONE', '')
                    .replace('ENCODING:USASCII', '')
                    .replace('CHARSET:1252', '')
                    .replace('COMPRESSION:NONE', '')
                    .replace('OLDFILEUID:NONE', '')
                    .replace('NEWFILEUID:NONE', '')
                    .trim()}`;
                let xmlClosedTagCode = closeTags(conversionXml, '<CODE>');
                let xmlClosedTagSeverity = closeTags(xmlClosedTagCode, '<SEVERITY>');
                let xmlClosedTagDtServer = closeTags(xmlClosedTagSeverity, '<DTSERVER>');
                let xmlClosedTagLanguage = closeTags(xmlClosedTagDtServer, '<LANGUAGE>');
                let xmlClosedTagTrnuid = closeTags(xmlClosedTagLanguage, '<TRNUID>');
                let xmlClosedTagCurdef = closeTags(xmlClosedTagTrnuid, '<CURDEF>');
                let xmlClosedTagBANKID = closeTags(xmlClosedTagCurdef, '<BANKID>');
                let xmlClosedTagACCTID = closeTags(xmlClosedTagBANKID, '<ACCTID>');
                let xmlClosedTagACCTTYPE = closeTags(xmlClosedTagACCTID, '<ACCTTYPE>');
                let xmlClosedTagDTSTART = closeTags(xmlClosedTagACCTTYPE, '<DTSTART>');
                let xmlClosedTagDTEND = closeTags(xmlClosedTagDTSTART, '<DTEND>');
                let xmlClosedTagTRNTYPE = closeTags(xmlClosedTagDTEND, '<TRNTYPE>');
                let xmlClosedTagDTPOSTED = closeTags(xmlClosedTagTRNTYPE, '<DTPOSTED>');
                let xmlClosedTagTRNAMT = closeTags(xmlClosedTagDTPOSTED, '<TRNAMT>');
                let xmlClosedTagFITID = closeTags(xmlClosedTagTRNAMT, '<FITID>');
                let xmlClosedTagCHECKNUM = closeTags(xmlClosedTagFITID, '<CHECKNUM>');
                let xmlClosedTagMEMO = closeTags(xmlClosedTagCHECKNUM, '<MEMO>');
                let xmlClosedTagBALAMT = closeTags(xmlClosedTagMEMO, '<BALAMT>');
                let xmlClosedTagDTASOF = closeTags(xmlClosedTagBALAMT, '<DTASOF>');
                const xml = new XMLParser().parseFromString(xmlClosedTagDTASOF); // Assume xmlText contains the example XML
                const objTransaction = organizeOFX(xml);
                resolve(objTransaction);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                resolve([]);
            };
        }).catch((error) => console.log(error));
    }
    async function postAllOfx(file) {
        const allOfx = await getBase64FromOfxFile(file);
        await verifyAllOfxInfo(allOfx);
    }
    async function GetCountRowsPayments() {
        try {
            setLoading('flex');
            const responseGetCountRowsPayments = await api.get('/boleto/GetCountRowsPayments');
            if (responseGetCountRowsPayments.status === 200) {
                setCountRow(responseGetCountRowsPayments.data);
                setLoading('none');
            } else {
                toast.error('Erro ao carregar maximo de linhas.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar o maximo de linhas.');
            setLoading('none');
        }
    }
    async function GetAllRatingBank() {
        try {
            setLoading('flex')
            const responseGetAllRatingBank = await api.get(`/boleto/GetAllRatingBank`);
            if (responseGetAllRatingBank.status === 200) {
                setListRatingBank(responseGetAllRatingBank.data);
                setLoading('none')
            } else {
                toast.error("Erro ao carregar lista de classificações.")
                setLoading('none')
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de classificações.')
            setLoading('none')
        }
    }
    function organizeOFX(ofxText) {
        try {
            const BANKMSGSRSV1 = ofxText.children.find((item) => item.name === 'BANKMSGSRSV1');
            const STMTTRNRS = BANKMSGSRSV1.children.find((item) => item.name === 'STMTTRNRS');
            const STMTRS = STMTTRNRS.children.find((item) => item.name === 'STMTRS');
            const BANKTRANLIST = STMTRS.children.find((item) => item.name === 'BANKTRANLIST');
            const transactions = BANKTRANLIST.children;
            const objTransactions = {
                dateBegin: '',
                dateFinal: '',
                transactions: [],
            };
            transactions.forEach((transactionInfo) => {
                if (transactionInfo.name === 'DTSTART') {
                    objTransactions.dateBegin = transactionInfo.value;
                } else if (transactionInfo.name === 'DTEND') {
                    objTransactions.dateFinal = transactionInfo.value;
                } else {
                    let objTransactionInfo = {
                        TRNTYPE: '',
                        DTPOSTED: '',
                        TRNAMT: '',
                        FITID: '',
                        CHECKNUM: '',
                        MEMO: '',
                    };
                    transactionInfo.children.forEach((info) => {
                        if (info.name === 'TRNTYPE') {
                            objTransactionInfo.TRNTYPE = info.value;
                        } else if (info.name === 'DTPOSTED') {
                            objTransactionInfo.DTPOSTED = info.value;
                        } else if (info.name === 'TRNAMT') {
                            objTransactionInfo.TRNAMT = info.value;
                        } else if (info.name === 'FITID') {
                            objTransactionInfo.FITID = info.value;
                        } else if (info.name === 'CHECKNUM') {
                            objTransactionInfo.CHECKNUM = info.value;
                        } else if (info.name === 'MEMO') {
                            objTransactionInfo.MEMO = info.value;
                        } else {
                            toast.error(`Campo não criado: ${info.name}`);
                        }
                    });
                    objTransactions.transactions.push(objTransactionInfo);
                }
            });
            return objTransactions;
        } catch (error) {
            console.log(error);
            toast.error('Error ao organizar OFX Text.');
        }
    }
    function scroll(event) {
        if (!searchopen) {
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                if (skip >= countRow) {
                    return;
                }
                setSkip(skip + 50);
                getPayments(50, skip + 50);
            }
        } else {
            return;
        }
    }
    function takeRatingBank(RatingBankId) {
        const RatingBankName = listRatingBank.find(rating => rating.IdClassificacao === RatingBankId);
        if (RatingBankName) {
            return RatingBankName.Classificacao
        } else {
            return "Sem classificação"
        }
    }
    async function init() {
        try {
            await getPayments(50, skip);
            await GetCountRowsPayments();
            await GetAllRatingBank();
        } catch (error) {
            console.log(error)
            toast.error("Erro ao iniciar a página")
        }
    };
    async function saveEditInfo() {
        try {
            setLoading('flex')
            const responseSaveEditInfo = await api.post(`/boleto/saveeditinfo`, ratingBankInfo);
            if (responseSaveEditInfo.status === 200) {
                toast.success('Edição salva com sucesso.')
                setLoading('none')
            } else {
                toast.error('Não foi possível salvar com sucesso.')
                setLoading('none')
            }
        } catch (error) {
            console.log(error);
            toast.error('Error ao salvar edição do lançamento.')
            setLoading('none')
        }
    }
    function selectRatingBank(ratingBank) {
        setRatingBankInfo(ratingBank);
        setOpenTransactionEdit(true)
    }
    useEffect(() => {
        init();
        // eslint-disable-next-line 
    }, []);
    return (
        <DivMain>
            <DivTitle>
                <h1>Conciliação bancária</h1>
            </DivTitle>
            <DivBody>
                <DivSearch>
                    <input
                        type="text"
                        placeholder="Informe descrição, valor ou nº documento"
                        onKeyPress={(event) => (event.key === 'Enter' ? searchPayments(event.target.value) : '')}
                    />
                </DivSearch>
                <DivTable onScroll={(event) => scroll(event)}>
                    <table>
                        <thead>
                            <tr>
                                <th>Vencimento</th>
                                <th>Pagamento</th>
                                <th>Documento</th>
                                <th>Descrição</th>
                                <th>Crédito</th>
                                <th>Débito</th>
                                <th>Classificação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.length > 0
                                ? payments.map((payment) => {
                                    return (
                                        <tr key={payment.IddoLancamento} onClick={() => selectRatingBank(payment)}>
                                            <td>{dayjs(payment.Vencimento).format('DD/MM/YYYY')}</td>
                                            <td>{dayjs(payment.DataPagamento).format('DD/MM/YYYY')}</td>
                                            <td>{payment.Documento}</td>
                                            <td>{payment.Historico}</td>
                                            <td>{payment.Credito}</td>
                                            <td>{payment.Debito}</td>
                                            <td>{takeRatingBank(payment.IddaClassificacao)}</td>
                                        </tr>
                                    );
                                })
                                : <tr></tr>}
                        </tbody>
                    </table>
                </DivTable>
            </DivBody>
            <DivFooter>
                <Button onClick={() => refInputFileOfx.current.click()}>Inserir OFX</Button>
                <input
                    style={{ display: 'none' }}
                    ref={refInputFileOfx}
                    type="file"
                    onChange={(event) => postAllOfx(event.target.files[0])}
                    accept=".ofx"
                />
            </DivFooter>
            <LoadingScreen display={loading} />
            <PopupDefault open={openTransactionEdit} close={() => setOpenTransactionEdit(false)} children={
                <DivMainPopupBankInfoEdit>
                    <div style={{ marginBottom: '8px' }} >
                        <p><strong>Id do Lançamento:</strong> {ratingBankInfo.IddoLancamento}</p>
                    </div>
                    <DivRakingBankInfoEdit>
                        <p>Vencimento:</p>
                        <input type="date" disabled
                            value={dayjs(ratingBankInfo.Vencimento).format('YYYY-MM-DD')}
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, Vencimento: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p><nobr>Data Pagamento:</nobr></p>
                        <input type="date" disabled
                            value={dayjs(ratingBankInfo.DataPagamento).format('YYYY-MM-DD')}
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, DataPagamento: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p>Documento:</p>
                        <input type="text" value={ratingBankInfo.Documento} disabled
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, Documento: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p>Descrição:</p>
                        <input type="text" value={ratingBankInfo.Historico} disabled
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, Historico: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p>Crédito:</p>
                        <input type="text" value={ratingBankInfo.Credito} disabled
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, Credito: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p>Debito:</p>
                        <input type="text" value={ratingBankInfo.Debito} disabled
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, Debito: event.target.value })} />
                    </DivRakingBankInfoEdit>
                    <DivRakingBankInfoEdit>
                        <p>Classificação:</p>
                        <select
                            value={ratingBankInfo.IddaClassificacao}
                            onChange={(event) => setRatingBankInfo({ ...ratingBankInfo, IddaClassificacao: event.target.value })}
                        >
                            <option value="0">Sem classificação</option>
                            {
                                listRatingBank.map(ratingBank => <option value={ratingBank.IdClassificacao} key={ratingBank.IdClassificacao}>{ratingBank.Classificacao}</option>)
                            }
                        </select>
                    </DivRakingBankInfoEdit>
                    <DivButtonsActionPopupBankInfoEdit>
                        <Button onClick={() => setOpenTransactionEdit(false)}>Cancelar</Button>
                        <Button onClick={saveEditInfo}>Salvar</Button>
                    </DivButtonsActionPopupBankInfoEdit>
                </DivMainPopupBankInfoEdit>
            } />
        </DivMain>
    );
}
