import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import useApi from '~/service/api';
import { TextAreaRps } from '../financas/StyledFinancas';
import {
    DivButtons,
    DivContentNotaFiscal,
    DivInfoPopuEditInfoBoletos,
    DivMainFinancasNotaFiscal,
    DivMainPopuEditInfoBoletos,
    DivTitulo,
    TableNotasFiscais,
    DivSearchNota,
    DivMainPopupRpsNaoEmitidas,
} from './styledFinancasNotaFiscal';
import XMLParser from 'react-xml-parser';
import dayjs from 'dayjs';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button as ButtonMui, Box, TextField, Select, FormControl, InputLabel, MenuItem, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from 'styled-components';
import { formataCurrency } from '~/helpers/functions';
export default function FinancasNotaFiscal() {
    const api = useApi();
    const [nfeEmitidas, setNfeEmitidas] = useState([]);    
    const [openPopupEditInfo, setOpenPopupEditInfo] = useState(false);
    const [openPopupRps, setOpenPopupRps] = useState(false);
    const [openPopupRpsNaoEmitidas, setOpenPopupRpsNaoEmitidas] = useState(false);
    const [openPopupSelectData, setOpenPopupSelectData] = useState(false);
    const [openPopupInfoDataAlterada, setOpenPopupInfoDataAlterada] = useState(false);    
    const [dadosRps, setDadosRps] = useState({});
    const [boletoEdit, setBoletoEdit] = useState({});
    const [busca, setBusca] = useState('');
    // eslint-disable-next-line    
    const [loadScreen, setLoadScreen] = useState('none');
    const [filtroNotas, setFiltroNotas] = useState('emitidas');
    const [rpsSelecionada, setRpsSelecionada] = useState(null);
    const theme = useTheme();
    //#region Declaração das referencias (useRef)
    const refDataInicio = useRef();
    const refDataFinal = useRef();
    const refInputFile = useRef();
    // eslint-disable-next-line
    const refInputFileOfx = useRef();
    //#endregion
    
   
    async function BuscaNotasFiscais() {
        try {
            const inicio = refDataInicio.current.value;
            const final = refDataFinal.current.value;
            if (inicio === '') {
                toast.warning('Informe horario de inicio.');
                return;
            }
            if (final === '') {
                toast.warning('Informe horario de final.');
                return;
            }
            GeraNotaFiscal(inicio, final);
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar busca das notas fiscais.');
        }
    }
    
    async function GeraNotaFiscal(inicio, final) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`boleto/emitirnotas?inicio=${inicio}&final=${final}`);              
            if (data.status === 200) {
                setOpenPopupSelectData(false);
                setDadosRps(data.data);
                setLoadScreen('none');
                if (data.data.todasRps === 'Error') {
                    setOpenPopupRpsNaoEmitidas(true);
                    setLoadScreen('none');
                    toast.warning('Erro ao gerar algumas rps verifique os motivos.');
                    return;
                }
                if (data.data.listaDatasAlteradas.length > 0) {
                    //setOpenPopupInfoDataAlterada(true);
                }
                if (window.location.href.includes('localhost')) {
                    let url = data.data.todasRps.split('/');
                    let name = url[url.length - 1];
                    window.open(
                        // eslint-disable-next-line
                        'C:\\Users\\willi\\source\\Workspaces\\Sistema_API\\Sistema_API\\TempFiles' + '\\' + name,
                        '_blank',
                    );
                } else {
                    window.open(data.data.todasRps, '_blank');
                }
                toast.success('Notas geradas com sucesso.');
                setLoadScreen('none');
            } else {
                toast.error('Erro ao gerar notas fiscais.');
                setLoadScreen('none');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setLoadScreen('none');
                    toast.error(error.response.data);
                    return;
                }
            }
            setLoadScreen('none');
            console.log(error);
            toast.error('Não foi possível gerar as notas fiscais.');
        }
    }
    
    function carregaListaNotasFiscais(nome,filtro,skip,take){
        return new Promise(async (resolve,reject)=>{
            try{

                const responseGetAllNotasEmitidas = await api.get(`boleto/getNotasFiscais2?nome=${nome}&filtro=${filtro}&skip=${skip}&take=${take}`);
                if (responseGetAllNotasEmitidas.status === 200) {                                   
                    resolve(responseGetAllNotasEmitidas.data);
                } else {
                    throw('Erro ao carregar notas emitidas.')                    
                }

            }catch(error){
                console.error(error);
                reject();
            }
        })
    }

    async function CarregaNotasFiscaisEmitidas(nome,filtro,skip,take) {
        try {
            setNfeEmitidas([])
            setLoadScreen('flex');            
            setNfeEmitidas(await carregaListaNotasFiscais(nome,filtro,skip,take));
            setLoadScreen('none');
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar lista de notas emitidas.');
            setLoadScreen('none');
        }
    }
    function organizeOFX(ofxText) {
        try {
            const BANKMSGSRSV1 = ofxText.children.find((item) => item.name === 'BANKMSGSRSV1');
            const STMTTRNRS = BANKMSGSRSV1.children.find((item) => item.name === 'STMTTRNRS');
            const STMTRS = STMTTRNRS.children.find((item) => item.name === 'STMTRS');
            const BANKTRANLIST = STMTRS.children.find((item) => item.name === 'BANKTRANLIST');
            const transactions = BANKTRANLIST.children;
            const objTransactions = {
                dateBegin: '',
                dateFinal: '',
                transactions: [],
            };
            transactions.forEach((transactionInfo) => {
                if (transactionInfo.name === 'DTSTART') {
                    objTransactions.dateBegin = transactionInfo.value;
                } else if (transactionInfo.name === 'DTEND') {
                    objTransactions.dateFinal = transactionInfo.value;
                } else {
                    let objTransactionInfo = {
                        TRNTYPE: '',
                        DTPOSTED: '',
                        TRNAMT: '',
                        FITID: '',
                        CHECKNUM: '',
                        MEMO: '',
                    };
                    transactionInfo.children.forEach((info) => {
                        if (info.name === 'TRNTYPE') {
                            objTransactionInfo.TRNTYPE = info.value;
                        } else if (info.name === 'DTPOSTED') {
                            objTransactionInfo.DTPOSTED = info.value;
                        } else if (info.name === 'TRNAMT') {
                            objTransactionInfo.TRNAMT = info.value;
                        } else if (info.name === 'FITID') {
                            objTransactionInfo.FITID = info.value;
                        } else if (info.name === 'CHECKNUM') {
                            objTransactionInfo.CHECKNUM = info.value;
                        } else if (info.name === 'MEMO') {
                            objTransactionInfo.MEMO = info.value;
                        } else {
                            toast.error(`Campo não criado: ${info.name}`);
                        }
                    });
                    objTransactions.transactions.push(objTransactionInfo);
                }
            });
            return objTransactions;
        } catch (error) {
            console.log(error);
            toast.error('Error ao organizar OFX Text.');
        }
    }
    // eslint-disable-next-line
    function addRegister(array, obj) {
        const TRNTYPE = obj.children.find((item) => item.name === 'TRNTYPE');
        const STMTTRN = obj.children.find((item) => item.name === 'STMTTRN');
        if (STMTTRN.children.length === 2) {
            array.push(TRNTYPE.value);
            addRegister(array, STMTTRN);
        } else {
            console.log(STMTTRN);
        }
    }
    function getBase64FromXmlFile(file) {
        let reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function () {
            const xml = new XMLParser().parseFromString(reader.result); // Assume xmlText contains the example XML
            organizaXml(xml);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    // eslint-disable-next-line
    function getBase64FromOfxFile(file) {
        try {
            function closeTags(text, tagName) {
                // eslint-disable-next-line
                let newText = new String();

                let position = text.indexOf(tagName);
                if (position !== -1) {
                    const textSeparator = text.split(tagName);

                    textSeparator.forEach((itemText, index) => {
                        if (index === 0) {
                            newText = itemText;
                        }
                        if (index > 0) {
                            newText =
                                newText + tagName + itemText.replace('<', `</${tagName.replace('<', '').replace('>', '')}> <`);
                        }
                    });
                }
                return newText;
            }
            let reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = function () {
                const conversionXml = `<?xml version="1.0" encoding="UTF-8" ?> ${reader.result
                    .replace('OFXHEADER:100', '')
                    .replace('DATA:OFXSGML', '')
                    .replace('VERSION:102', '')
                    .replace('SECURITY:NONE', '')
                    .replace('ENCODING:USASCII', '')
                    .replace('CHARSET:1252', '')
                    .replace('COMPRESSION:NONE', '')
                    .replace('OLDFILEUID:NONE', '')
                    .replace('NEWFILEUID:NONE', '')
                    .trim()}`;
                let xmlClosedTagCode = closeTags(conversionXml, '<CODE>');
                let xmlClosedTagSeverity = closeTags(xmlClosedTagCode, '<SEVERITY>');
                let xmlClosedTagDtServer = closeTags(xmlClosedTagSeverity, '<DTSERVER>');
                let xmlClosedTagLanguage = closeTags(xmlClosedTagDtServer, '<LANGUAGE>');
                let xmlClosedTagTrnuid = closeTags(xmlClosedTagLanguage, '<TRNUID>');
                let xmlClosedTagCurdef = closeTags(xmlClosedTagTrnuid, '<CURDEF>');
                let xmlClosedTagBANKID = closeTags(xmlClosedTagCurdef, '<BANKID>');
                let xmlClosedTagACCTID = closeTags(xmlClosedTagBANKID, '<ACCTID>');
                let xmlClosedTagACCTTYPE = closeTags(xmlClosedTagACCTID, '<ACCTTYPE>');
                let xmlClosedTagDTSTART = closeTags(xmlClosedTagACCTTYPE, '<DTSTART>');
                let xmlClosedTagDTEND = closeTags(xmlClosedTagDTSTART, '<DTEND>');
                let xmlClosedTagTRNTYPE = closeTags(xmlClosedTagDTEND, '<TRNTYPE>');
                let xmlClosedTagDTPOSTED = closeTags(xmlClosedTagTRNTYPE, '<DTPOSTED>');
                let xmlClosedTagTRNAMT = closeTags(xmlClosedTagDTPOSTED, '<TRNAMT>');
                let xmlClosedTagFITID = closeTags(xmlClosedTagTRNAMT, '<FITID>');
                let xmlClosedTagCHECKNUM = closeTags(xmlClosedTagFITID, '<CHECKNUM>');
                let xmlClosedTagMEMO = closeTags(xmlClosedTagCHECKNUM, '<MEMO>');
                let xmlClosedTagBALAMT = closeTags(xmlClosedTagMEMO, '<BALAMT>');
                let xmlClosedTagDTASOF = closeTags(xmlClosedTagBALAMT, '<DTASOF>');
                const xml = new XMLParser().parseFromString(xmlClosedTagDTASOF); // Assume xmlText contains the example XML
                const objTransaction = organizeOFX(xml);
                console.log(objTransaction);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        } catch (error) {
            console.log(error);
        }
    }
    function organizaXml(Array) {
        debugger;
        const arrayXml = [];
        if (Array.name === 'ConsultarNfseResposta') {
            //verificar com CARLOS se esse campo sempre vem com 1 filho;
            const ListaNfse = Array.children;
            const CompNfse = ListaNfse[0].children;
            // eslint-disable-next-line
            CompNfse.map((nfeInfo, index) => {
                const Nfse = nfeInfo.children[0];
                const InfNfse = Nfse.children[0];
                const objInfoNfe = {};
                // eslint-disable-next-line
                InfNfse.children.map((infNfse) => {
                    if (infNfse.name === 'CodigoVerificacao') {
                        objInfoNfe[infNfse.name] = infNfse.value;
                    }
                    if (infNfse.name === 'PrestadorServico') {
                        // eslint-disable-next-line
                        infNfse.children.map((identiPrestador) => {
                            if ((identiPrestador.name = 'IdentificacaoPrestador')) {
                                // eslint-disable-next-line
                                identiPrestador.children.map((cnpj) => {
                                    if (cnpj.name === 'Cnpj') {
                                        objInfoNfe['CCM'] = cnpj.value;
                                    }
                                });
                            }
                        });
                    }
                    if (infNfse.name === 'Numero') {
                        objInfoNfe[infNfse.name] = infNfse.value;
                    }
                    if (infNfse.name === 'IdentificacaoRps') {
                        // eslint-disable-next-line
                        infNfse.children.map((rpsinfo) => {
                            if (rpsinfo.name === 'Numero') {
                                objInfoNfe['RPS'] = rpsinfo.value;
                            }
                        });
                    }
                    if (infNfse.name === 'DataEmissao') {
                        objInfoNfe[infNfse.name] = infNfse.value;
                    }
                    // eslint-disable-next-line
                    return;
                });
                arrayXml.push(objInfoNfe);
            });
            EnviaXmlOrganizado(arrayXml);
        } else {
            toast.error('Arquivo inválido.');
            return null;
        }
    }
    async function EnviaXmlOrganizado(xml) {
        try {
            setLoadScreen('flex');
            debugger;
            const responseUpdateStatusRps = await api.post(`boleto/UpdateStatusRpsNotasEmitidas`, xml);
            if (responseUpdateStatusRps.status === 200) {
                toast.success('XMl enviado com sucesso.');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível enviar XML');
            setLoadScreen('none');
        }
    }
    function SelectBoletoEdit(boleto) {
        setBoletoEdit(boleto);
        setOpenPopupEditInfo(true);
    }
    // eslint-disable-next-line
    async function SaveEdit() {
        try {
            setLoadScreen('flex');
            const responseUpdateInfoBoleto = await api.post('/boleto/UpdateInfoBoleto', {...boletoEdit,Id:boletoEdit.IdBoleto,Id_do_Cliente:boletoEdit.IdCliente});
            if (responseUpdateInfoBoleto.status === 200) {
                toast.success('Edição salva com sucesso');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            setLoadScreen('none');
            toast.error('Não foi possível salvar edição dos dados.');
        }
    }
    
    async function searchNota(event) {
        const value = event.target.value;
        setBusca(value);
        if (event.key === 'Enter') {
            await CarregaNotasFiscaisEmitidas(value,filtroNotas,0,200);
        }
    }

    async function filtraNotas(filtroString){
        await CarregaNotasFiscaisEmitidas(busca,filtroString,0,200);
        setFiltroNotas(filtroString);
    }

    function removeRpsCorrigida(idBoleto) {
        
        setDadosRps( oldValue =>{
            const rpsFiltradas = oldValue.rpsNaoGeradas.filter(rpsInfo => rpsInfo.Id != idBoleto);
            debugger
            return {...oldValue, rpsNaoGeradas: rpsFiltradas}
            
        });
    }

    async function saveInfoEdit() {
        try {
            setLoadScreen('flex');
            const responseUpdateInfoBoleto = await api.post(`/boleto/UpdateInfoBoleto`,  {...boletoEdit,Id:boletoEdit.IdBoleto,Id_do_Cliente:boletoEdit.IdCliente});
            if (responseUpdateInfoBoleto.status === 200) {
                removeRpsCorrigida(boletoEdit.Id);
                setOpenPopupEditInfo(false)
                toast.success('Edição salva com sucesso.');
                setLoadScreen('none');
            } else {
                setLoadScreen('none');
                toast.error('Error ao salvar alterações.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível salvar alteração.');
            setLoadScreen('none');
        }
    }

    async function salvarEditNota(){
        try{
            setLoadScreen('flex');
            const responseUpdateRpsInfo = await api.post('/boleto/updateRpsInfo',rpsSelecionada);
            if(responseUpdateRpsInfo.status === 200){
                toast.success('Alterações salvas com sucesso.');
                setRpsSelecionada(null);
                await CarregaNotasFiscaisEmitidas(busca,filtroNotas,0,200);
                setLoadScreen('none');
            }else{
                toast.error('Erro ao salvar alterações das informações da RPS.');
                setLoadScreen('none');
            }
        }catch(error){
            console.error(error);
            toast.error('Erro ao salvar alterações das informações da RPS.');
            setLoadScreen('none');
        }
    }

    useEffect(() => {
        console.log(rpsSelecionada);
    },[rpsSelecionada])

    useEffect(() => {
        CarregaNotasFiscaisEmitidas(busca,filtroNotas,0,200);
        // eslint-disable-next-line
    }, []);

    return (
        <DivMainFinancasNotaFiscal>
            <DivTitulo>
                <h1>Notas Fiscais</h1>
            </DivTitulo>
            <Box height={40} margin={'0 0 0 16px'} display={'flex'}>
                <TextField label='busca' focused variant='outlined' 
                    slotProps={{htmlInput:{style:{':hover':{boxShadow:'none'}}}}}
                    sx={{flex:0.8,mr:2,':focus':{boxShadow:'none'}}} size='small'  placeholder='Informe o nome do cliente.' 
                    onKeyUp={(event) => searchNota(event)}/> 
                <FormControl sx={{flex:0.2}} size='small'>
                    <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filtroNotas}
                        label="Filtro"
                        onChange={({target})=>filtraNotas(target.value)}
                    >
                        <MenuItem value={'todos'}>Todos</MenuItem>
                        <MenuItem value={'emitidas'}>Emitidas</MenuItem>
                        <MenuItem value={'naoEmitidas'}>Não emitidas</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <DivContentNotaFiscal>
                <TableNotasFiscais>
                    <thead>
                        <tr>                            
                            <th>N° NF-e</th>
                            <th>Cliente</th>
                            <th>N° RPS</th>
                            <th>Id Boleto</th>
                            <th>Data Emissão</th>
                            <th>Data Pagamento</th>
                            <th>Link NF-e</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nfeEmitidas&&nfeEmitidas.map((nfe) => {                         
                            return (
                            <tr style={nfe.Cancelado ? {color:'red'}:{}} key={nfe.Id} onClick={()=>setRpsSelecionada({...nfe,RpsData:dayjs(nfe.RpsData).format('YYYY-MM-DD'),
                                DataPagamento:dayjs(nfe.DataPagamento).format('YYYY-MM-DD')})}>
                                <td>{nfe.Nfe}</td>
                                <td>{nfe.Nome}</td>
                                <td>{nfe.Rps}</td>
                                <td>{nfe.IdBoleto}</td>
                                <td>{dayjs(nfe.RpsData).format('DD/MM/YYYY')}</td>
                                <td>{dayjs(nfe.DataPagamento).format('DD/MM/YYYY')}</td>
                                {
                                    nfe.Codigo?
                                    <td>
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        title={`https://notacarioca.rio.gov.br/contribuinte/notaprint.aspx?nf=${nfe.Nfe}&inscricao=10178622&verificacao=${nfe.Codigo}&returnurl=..%2FDocumentos%2Fverificacao.aspx`}
                                        href={`https://notacarioca.rio.gov.br/contribuinte/notaprint.aspx?nf=${nfe.Nfe}&inscricao=10178622&verificacao=${nfe.Codigo}&returnurl=..%2FDocumentos%2Fverificacao.aspx`}
                                    >
                                        Link
                                    </a>
                                    </td> :
                                    <td>Sem link do documento</td>
                                }
                            </tr>
                        )})}
                    </tbody>
                </TableNotasFiscais>
            </DivContentNotaFiscal>
            {/* <Box display={'flex'}>
                <span>{`<`}</span>
                <div>pages</div>
                <span>{`>`}</span>
            </Box> */}
            <DivButtons>
                <Button style={{ height: '40px' }} onClick={() =>  setOpenPopupSelectData(true)}>
                    Gerar Notas
                </Button>
                {/* <Button onClick={() => refInputFile.current.click()}>Ler arquivo nota carioca</Button> */}
                <Button style={{ height: '40px' }} onClick={() => refInputFile.current.click()}>
                    Ler arquivo nota carioca
                </Button>
                <input
                    style={{ display: 'none' }}
                    ref={refInputFile}
                    type="file"
                    onChange={(event) => getBase64FromXmlFile(event.target.files[0])}
                    accept=".xml"
                />
            </DivButtons>
            <LoadingScreen display={loadScreen} />
            {/* POUP QUE MOSTRA A IMPRESSÃO DO TEXTO XML */}
            <PopupDefault
                children={
                    <div style={{ width: '800px', height: '600px', padding: '12px' }}>
                        <div style={{ height: '40px', textAlign: 'center' }}>
                            <h1 style={{ fontSize: '24px' }}>Texto do XML gerado</h1>
                        </div>
                        <TextAreaRps value={dadosRps.todasRps}></TextAreaRps>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '40px',
                                marginTop: '8px',
                            }}
                        ></div>
                    </div>
                }
                open={openPopupRps}
                close={() => setOpenPopupRps(false)}
            />
            {/* POUP QUE MOSTRA AS RPS NÃO EMITIDAS*/}
            <Dialog open={openPopupRpsNaoEmitidas} onClose={() => setOpenPopupRpsNaoEmitidas(false)}>
                <DialogTitle>RPS com erro</DialogTitle>
                <DialogContent>
                    <DivMainPopupRpsNaoEmitidas>
                        {dadosRps.rpsNaoGeradas
                            ? dadosRps.rpsNaoGeradas.map((item) => (
                                  <Box
                                      sx={{ paddingBottom: '12px', borderBottom: '1px solid #fefefe', transition:'.5s' ,marginBottom: '8px', cursor:"pointer",
                                        ":hover":{transform:"scale(1.08)"} }}
                                      onClick={() => SelectBoletoEdit(item)}
                                  >
                                      <div style={{ display: 'flex' }}>
                                          <p>Id da nota: </p>
                                          <div style={{ marginLeft: '12px' }}>{item.Id}</div>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                          <p>Id do boleto: </p>
                                          <div style={{ marginLeft: '12px' }}>{item.IdBoleto}</div>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                          <p>Motivo: </p>
                                          <div style={{ marginLeft: '12px' }}>{item.motivo}</div>
                                      </div>
                                  </Box>
                              ))
                            : ''}
                    </DivMainPopupRpsNaoEmitidas>
                </DialogContent>
                <DialogActions>
                    <ButtonMui sx={{flex:1}} variant="contained" onClick={() => setOpenPopupRpsNaoEmitidas(false)} >Fechar</ButtonMui>
                </DialogActions>
            </Dialog>

            {/* POUP QUE MOSTRA A SELEÇÃO DAS DATAS*/}
            <Dialog open={openPopupSelectData} onClose={() => setOpenPopupSelectData(false)} PaperProps={{sx:{background:theme.colors.background}}}>
                <DialogTitle>Selecione o período</DialogTitle>
                <DialogContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2}>
                            <p>De:</p>
                            <input
                                style={{ marginLeft: '12px' }}
                                type="date"
                                max="9999-12-31"
                                min="1900-01-01"
                                ref={refDataInicio}
                            />
                    </Box>                    
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <p>Até:</p>
                            <input
                                style={{ marginLeft: '12px' }}
                                type="date"
                                max="9999-12-31"
                                min="1900-01-01"
                                ref={refDataFinal}
                            />
                    </Box>                    
                </DialogContent>
                <DialogActions>
                    <ButtonMui sx={{flex:1}} variant="outlined" onClick={() => setOpenPopupSelectData(false)} >Fechar</ButtonMui>
                    <ButtonMui sx={{flex:1}} variant="contained" onClick={BuscaNotasFiscais} >Buscar</ButtonMui>
                </DialogActions>
            </Dialog>           
            {/* POUP QUE MOSTRA OS REGISTROS COM DATAS ALTERADAS*/}
            <PopupDefault
                children={
                    <div style={{ padding: '20px' }}>
                        <div style={{ margin: '0 0 12px 0' }}>
                            <h1>Boletos com datas de pagamento maior que 10 dias.</h1>
                        </div>
                        {dadosRps.listaDatasAlteradas
                            ? dadosRps.listaDatasAlteradas.map((info) => {
                                  return (
                                      <div style={{ display: 'flex', margin: '0 0 12px 0' }}>
                                          <span>Id do Boleto: {info.Id}</span>
                                      </div>
                                  );
                              })
                            : ''}
                    </div>
                }
                open={openPopupInfoDataAlterada}
                close={() => setOpenPopupInfoDataAlterada(false)}
            />
            {/* POPUP PARA EDITAR INFORMAÇÕES INCORRETAS */}
            <Dialog open={openPopupEditInfo} onClose={() => setOpenPopupEditInfo(false)} >
                <DialogTitle>Edição dados cadastrais do cliente <span onClick={()=>navigator.clipboard.writeText(`IdNota: ${boletoEdit.Id}
IdBoleto: ${boletoEdit.IdBoleto}
Cliente: ${boletoEdit.Nome}
Motivo: `)}>clique</span></DialogTitle>
                <DialogContent>
                <DivMainPopuEditInfoBoletos>
                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Id Nota :</nobr>
                                    </p>
                                    <input value={boletoEdit.Id} readOnly />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Id boleto :</nobr>
                                    </p>
                                    <input value={boletoEdit.IdBoleto} readOnly />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Nome Cliente :</nobr>
                                    </p>
                                    <input value={boletoEdit.Nome} readOnly />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>CNPJ :</nobr>
                                    </p>
                                    <input value={boletoEdit.CGC} readOnly />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Endereco :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Endereco}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Endereco: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Número :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Numero}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Numero: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Complemento :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Complemento}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Complemento: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Estado :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Estado}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Estado: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Bairro :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Bairro}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Bairro: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Cidade :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Cidade}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, Cidade: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>CEP :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.CEP}
                                        onChange={(event) => setBoletoEdit({ ...boletoEdit, CEP: event.target.value })}
                                    />
                                </DivInfoPopuEditInfoBoletos>
                                <DivInfoPopuEditInfoBoletos>
                                    <p>
                                        <nobr>Codigo do Municipio :</nobr>
                                    </p>
                                    <input
                                        value={boletoEdit.Codigo_do_Municipio}
                                        onChange={(event) =>
                                            setBoletoEdit({ ...boletoEdit, Codigo_do_Municipio: event.target.value })
                                        }
                                    />
                                </DivInfoPopuEditInfoBoletos>                                                        
                        </DivMainPopuEditInfoBoletos>
                </DialogContent>
                <DialogActions>
                    <ButtonMui sx={{flex:1}} variant='outlined' onClick={() => setOpenPopupEditInfo(false)}>Fechar</ButtonMui>
                    <ButtonMui sx={{flex:1}} variant='contained' onClick={saveInfoEdit} >Salvar Edição</ButtonMui>
                </DialogActions>
            </Dialog>
            {rpsSelecionada != null&&<Dialog open={true} onClose={()=>setRpsSelecionada(null)}>
                <DialogTitle>Detalhes RPS</DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <Typography>Nome: {rpsSelecionada.Nome}</Typography>
                        <Typography>Data pagamento: {dayjs(rpsSelecionada?.DataPagamento).format('DD/MM/YYYY')}</Typography>
                        <Typography>Valor: {formataCurrency.format(rpsSelecionada.Valor)}</Typography>
                        <TextField focused size='small' label='NFE' variant='outlined' value={rpsSelecionada.Nfe} onChange={({target})=>setRpsSelecionada({...rpsSelecionada,Nfe:target.value})} />
                        <TextField focused size='small' label='RPS' variant='outlined' value={rpsSelecionada.Rps} onChange={({target})=>setRpsSelecionada({...rpsSelecionada,Rps:target.value})} />
                        <TextField focused size='small' label='Rps Data' type='date' variant='outlined' value={rpsSelecionada.RpsData}  slotProps={{htmlInput:{max:'9999-12-31',min:'1900-01-01'}}}
                        onChange={({target})=>setRpsSelecionada({...rpsSelecionada,RpsData:target.value})}/>
                        <FormControlLabel control={
                            <Checkbox 
                                checked={rpsSelecionada.Cancelado}
                                onClick={()=>setRpsSelecionada({...rpsSelecionada,Cancelado:!rpsSelecionada.Cancelado})} />
                        } label="Cancelado" 
                        />

                    </Box>
                </DialogContent>
                <DialogActions  >
                        <ButtonMui onClick={()=>setRpsSelecionada(null)} sx={{flex:1,mr:1}}>Cancelar</ButtonMui>
                        <ButtonMui onClick={salvarEditNota} variant='contained' sx={{flex:1}}>Salvar</ButtonMui>
                </DialogActions>
            </Dialog>               }
        </DivMainFinancasNotaFiscal>
    );
}
