import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { Button, DivTitle, Input, Title } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import useApi from '~/service/api';
import { DivBotao } from '../alunos/styledGammyxAlunos';
import { ContainerAluno, ContainerDadosAlunoInfos, ContainerPopupAddJornada, ContainerTitulo } from './styledAluno';
export default function GammyxAluno() {
    const params = useParams();
    const api = useApi();
    const theme = useTheme()
    const [loading, setLoading] = useState(false);
    const [openPopupAddJornada, setOpenPopupAddJornada] = useState(false)
    const [dadosAluno, setDadosAluno] = useState(null);
    const [listaJornadasAluno, setListaJornadasAluno] = useState([]);
    const [listaJornadas, setListaJornadas] = useState([]);
    const [jornadaSelecionada, setJornadaSelecionada] = useState("");
    useEffect(() => {
        if (params.alunoId)
            carregaInfoAluno(params.alunoId)
        else
            toast.error('Id do Aluno não encontrado.')
    }, [])
    async function carregaInfoAluno(idAluno) {
        try {
            setLoading(true)
            const responseCarregaDadosAluno = await api.get(`/gammyx/GetInfoAlunoById?AlunoId=${idAluno}`);
            if (responseCarregaDadosAluno.status == 200) {
                let { DadosAluno, ListaJornadas } = responseCarregaDadosAluno.data;
                setDadosAluno(DadosAluno);
                setListaJornadasAluno(ListaJornadas)
                setLoading(false)
            } else {
                toast.error('Erro ao carregar informações do aluno.')
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível tentar carregar informações do aluno.')
            setLoading(false)
        }
    }
    async function openSelecaoJornada() {
        try {
            setLoading(true)
            const responseCarregaListaJornadas = await api.get(`/gammyx/GetJornadasResumo`);
            if (responseCarregaListaJornadas.status == 200) {
                if (listaJornadasAluno.length) {
                    let arrayJornadas = [];
                    responseCarregaListaJornadas.data.forEach(jornada => {
                        const findJornada = listaJornadasAluno.find(jornadaAluno => jornadaAluno.Id == jornada.Id);
                        if (!findJornada)
                            arrayJornadas.push(jornada);
                    })
                    setListaJornadas(arrayJornadas);
                } else {
                    setListaJornadas(responseCarregaListaJornadas.data);
                }
                setOpenPopupAddJornada(true);
                setLoading(false);
            } else {
                toast.error('Error ao carregar lista de jornadas.')
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            toast.error('Error ao tentar carregar lista de jornadas.')
            setLoading(false)
        }
    }
    async function salvaNovaJornada() {
        try {
            if (!params.alunoId || jornadaSelecionada == "") {
                toast.warning('Verifique a seleção da jornada, por favor.');
                return;
            }
            const responseSalvaNovaJornada = await api.post(`/gammyx/InsertNovaJornadaAluno`, { IdAluno: params.alunoId, IdJornada: jornadaSelecionada });
            if (responseSalvaNovaJornada.status == 200) {
                toast.success('Jornada salva com sucesso.');
                setOpenPopupAddJornada(false);
            } else {
                toast.error('Erro ao salvar nova jornada.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Error ao tentar salvar nova jornada.')
        }
    }
    return <ContainerAluno>
        <div>
            <Input type="text" placeholder='Pesquise pelo nome ou o email do aluno.' />
        </div>
        {dadosAluno && <>
            <div>
                <ContainerTitulo>
                    <Title>Dados Cadastrais</Title>
                    <Button>Editar dados cadastrais</Button>
                </ContainerTitulo>
                <ContainerDadosAlunoInfos>
                    <div>
                        <span>Nome : {dadosAluno.Nome}</span>
                        <span>CGC : {dadosAluno.Cpf ?? ""}</span>
                        <span>Email : {dadosAluno.Email}</span>
                        <span>Nascimento : {dayjs(dadosAluno.Nascimento).format('DD/MM/YYYY')}</span>
                        <span>Sexo : {dadosAluno.Sexo ?? ""}</span>
                    </div>
                    <div>
                        <span>Cep : {dadosAluno.Cep ?? ""}</span>
                        <span>Endereço : {dadosAluno.Endereco ?? ""} {dadosAluno.Numero ?? ""}</span>
                        <span>Complemento : {dadosAluno.Complemento ?? ""}</span>
                        <span>Bairro : {dadosAluno.Bairro ?? ""}</span>
                        <span>Cidade : {dadosAluno.Cidade ?? ""}</span>
                        <span>Estado : {dadosAluno.Estado ?? ""}</span>
                    </div>
                </ContainerDadosAlunoInfos>
            </div>
            <div>
                <ContainerTitulo>
                    <Title>Lista de Jornadas</Title>
                    <Button onClick={() => openSelecaoJornada()}>Adicionar Jornada</Button>
                </ContainerTitulo>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Jornada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaJornadasAluno.length ? listaJornadasAluno.map(jornada => <tr><td>{jornada.Nome}</td></tr>) : ''
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>}
        {loading && <LoadingScreen display={'flex'} />}
        {openPopupAddJornada && <PopupDefault open={true}
            close={() => setOpenPopupAddJornada(false)}
            children={<ContainerPopupAddJornada>
                <DivTitle>
                    <Title>Adicionar nova jornada</Title>
                </DivTitle>
                <select value={jornadaSelecionada} onChange={({ target }) => setJornadaSelecionada(target.value)}>
                    <option value="">Selecione a jornada</option>
                    {
                        listaJornadas.length ? listaJornadas.map(jornada => <option value={jornada.Id}>{jornada.Nome}</option>)
                            : ''
                    }
                </select>
                <DivBotao>
                    <Button style={{ flex: 1, background: theme.colors.colorDelete }} onClick={() => setOpenPopupAddJornada(false)} >Cancelar</Button>
                    <Button style={{ flex: 1, background: theme.colors.colorSave }} onClick={() => salvaNovaJornada()}>Cadastrar</Button>
                </DivBotao>
            </ContainerPopupAddJornada>} />}
    </ContainerAluno>;
}