import React, { useState, useEffect,useRef } from 'react';
import { DivMainBackups } from '../backups/styledBackups';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { Busca, ContainerBusca, ContainerList, Table } from '../clientes/stylesClientes';
import dayjs from 'dayjs';
import { MdSearch } from 'react-icons/md';
import {
    DivCampo,
    DivMainEditParticipante,
    DivMainParticipantesNaoConfirmados,
    DivOpcaoFiltro,
    TrEventos,
} from './eventosStyled';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { Button } from '~/components/commonComponents/commonComponents';
import { IconMdContentCopy } from '~/components/boletoEdit/StyledBoletoEdit';
import { useTheme } from 'styled-components';
import Popup from 'reactjs-popup';
export default function Eventos() {
    const theme = useTheme();
    const api = useApi();
    const [loading, setLoading] = useState('none');
    const [listaParticipantes, setListaParticipantes] = useState([]);
    const [listaParticipantesNaoConfirmados, setListaParticipantesNaoConfirmados] = useState([]);
    const [openEditParticipante, setOpenEditParticipante] = useState(false);
    const [pacienteEditInfo, setPacienteEditInfo] = useState(null);
    const [openParticipadosFiltradosStatus, setOpenParticipadosFiltradosStatus] = useState(false);
    const [filtroEvento, setFiltroEvento] = useState([]);
    const [listaFiltered, setListaFiltered] = useState([]);
    const [listaEventos, setListaEventos] = useState([]);
    const refInputBusca = useRef();
    const refSelectBusca = useRef();

    async function getGetParticipantesEventos(busca,evento) {
        setLoading('flex');
        try {
            const resultGetParticipantesEventos = await api.get(`clientesfollowup/GetParticipantesEventos?nome=${busca}&eventoId=${evento}`);
            if (resultGetParticipantesEventos.status === 200) {
                setListaParticipantes(resultGetParticipantesEventos.data);
                setLoading('none');
                return resultGetParticipantesEventos.data;
            } else {
                toast.error('Erro ao carregar lista de participantes.');
                setLoading('none');
                return [];
            }
        } catch (error) {
            toast.error('Não foi possível carrega a lista de participantes.');
            setLoading('none');
            return [];
        }
    };
    async function getParticipantesNaoConfirmados() {
        setLoading('flex');
        try {
            const resultGetPacientesNaoConfirmados = await api.get('clientesfollowup/GetParticipantesNaoConfirmados');
            if (resultGetPacientesNaoConfirmados.status == 200) {
                setListaParticipantesNaoConfirmados(resultGetPacientesNaoConfirmados.data);
                setOpenParticipadosFiltradosStatus(true);
            }
        } catch (error) {
            toast.error('Não foi possível carregar lista de pacientes não confirmados.');
        }
        setLoading('none');
    };
    async function saveParticipantesEdit(participanteEdit) {
        setLoading('flex');
        try {
            const resultSaveParticipantesEdit = await api.post(`/clientesfollowup/SaveParticipantesEdit`, participanteEdit);
            if (resultSaveParticipantesEdit.status === 200) {
                setOpenEditParticipante(false);
                toast.success('Dados salvos com sucesso.');
            } else {
                toast.error('Erro ao salvar dados.');
            }
        } catch (error) {
            toast.error('Não foi possível salvar edição.');
        }
        setLoading('none');
    };
    async function carregaListaEventos(){
        setLoading(`flex`);
        try{
            const response = await api.get(`Eventos/GetAllEventos`);
            setListaEventos(response.data);            
        }catch(error){
            console.error(error);
            toast.error('Erro ao carregar lista de eventos.');
        }
        setLoading(`none`);
    };    
    function editParticipante(participanteInfo) {
        try {
            setOpenEditParticipante(true);
            setPacienteEditInfo(participanteInfo);
            console.log(participanteInfo);
        } catch (error) {
            console.log(error);
        }
    };
    function copyInfo(info) {
        try {
            navigator.clipboard.writeText(info);
            toast.success('Copiado com sucesso');
        } catch (error) {
            toast.error('error ao copiar');
        }
    };
    function criaListaFiltroEvento(paricipantesEventos) {
        try {
            const filtros = [];
            paricipantesEventos.forEach((info) => {
                const findFiltro = filtros.find((filtro) => filtro.Evento == info.Evento);
                if (!findFiltro) {
                    filtros.push({ IdEvento: info.Id_Evento, Evento: info.Evento, Ativo: false });
                }
            });
            filtros.unshift({ Evento: 'Todos' });
            setFiltroEvento(filtros);
        } catch (error) {}
    };
    function filtraListaParcipantes(filtroInfo) {
        try {
            if (String(filtroInfo.Evento).toLowerCase() == 'todos') {
                setListaFiltered([]);
                return;
            }
            if (String(filtroInfo.Evento).toLowerCase() == 'naoconfirmado') {
                setListaFiltered(listaParticipantes.filter((participante) => participante.Confirmado === false));
                return;
            }
            setListaFiltered(listaParticipantes.filter((participante) => participante.Id_Evento === filtroInfo.IdEvento));
        } catch (error) {
            toast.error('Não foi possível filtrar.');
        }
    };
    async function init() {
        setLoading('flex');
        try {
            const participantesEventos = await getGetParticipantesEventos('','');
            await carregaListaEventos()
            criaListaFiltroEvento(participantesEventos);
        } catch (error) {
            toast.error('erro ao incializar componente.');
        }
        setLoading('none');
    };
    const TbodyConteudo = function ({ array }) {
        return array.map((participante, index) => {
            return (
                <TrEventos
                    key={`z${index}a${participante.Id_Cliente}`}
                    onDoubleClick={() => editParticipante(participante)}
                    cancelado={participante.Cancelado}
                >
                    <td>
                        <span>{participante.Nome}</span>
                    </td>
                    <td>
                        <span>{participante.Evento}</span>
                    </td>
                    <td>
                        <span>{participante.Email}</span>
                    </td>
                    <td>
                        {' '}
                        <a
                            target="_blank"
                            rel="noopener"
                            href={`https://api.whatsapp.com/send?phone=55${participante.Celular}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            {participante.Celular}
                        </a>
                        <span></span>
                    </td>
                    <td>
                        <span>{dayjs(participante.CreationDate).format('DD/MM/YYYY')}</span>
                    </td>
                    <td
                        onClick={() => {
                            if (participante.Chave) copyInfo(`http://apoio.medx.med.br/ecommerce.aspx?id=${participante.Chave}`);
                            else toast.warning('Boleto sem chave.');
                        }}
                    >
                        <span>{participante.Id_Boleto}</span>
                    </td>
                    <td>
                        <input
                            style={{ width: '12px', height: '12px' }}
                            type="checkbox"
                            checked={participante.Confirmado}
                            readOnly
                        />
                    </td>
                    <td>
                        <input
                            style={{ width: '12px', height: '12px' }}
                            type="checkbox"
                            checked={participante.Cancelado}
                            readOnly
                        />
                    </td>
                </TrEventos>
            );
        });
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <DivMainBackups>
            <ContainerBusca>
                <div style={{ position: 'absolute', top: '10px', left: '5px' }}>
                    <MdSearch fontSize="20px" />
                </div>
                <Busca
                    type="text"
                    placeholder="Busque o cliente pelo nome, email ou softId."
                    onKeyPress={(event) => (event.key === 'Enter' ? getGetParticipantesEventos(event.currentTarget.value,refSelectBusca?.current?.value ?? '') : '')}
                    ref={refInputBusca}
                />
                <div style={{marginLeft:'8px'}}>
                    <select  ref={refSelectBusca} onChange={(event)=> getGetParticipantesEventos(refInputBusca?.current?.value ?? '',event.currentTarget.value)}>
                        <option value={''}></option>
                        {
                            listaEventos.map(evento => <option value={evento.Id_Evento}>{evento.NomeEvento}</option>)
                        }
                    </select>
                </div>
            </ContainerBusca>
            <ContainerList>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th style={{ cursor: 'pointer' }}>
                                <Popup
                                    trigger={<div>Evento</div>}
                                    position="bottom left"
                                    on="click"
                                    closeOnDocumentClick
                                    mouseLeaveDelay={300}
                                    mouseEnterDelay={0}
                                    contentStyle={{ padding: '0px', border: 'none' }}
                                    arrow={false}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            background: '#181818',
                                            border: '1px solid #fff',
                                            padding: '8px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {filtroEvento
                                            ? filtroEvento.map((eventoInfo) => {
                                                  return (
                                                      <DivOpcaoFiltro onClick={() => filtraListaParcipantes(eventoInfo)}>
                                                          {eventoInfo.Evento}
                                                          {eventoInfo.Ativo ? 'Selecionado' : ''}
                                                      </DivOpcaoFiltro>
                                                  );
                                              })
                                            : ''}
                                    </div>
                                </Popup>
                            </th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Dt Inscrição</th>
                            <th>Id Boleto</th>
                            <th style={{ cursor: 'pointer' }} onClick={() => filtraListaParcipantes({ Evento: 'NaoConfirmado' })}>
                                Status
                            </th>
                            <th>Cancelado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaFiltered.length > 0 ? (
                            <TbodyConteudo array={listaFiltered} />
                        ) : (
                            <TbodyConteudo array={listaParticipantes} />
                        )}
                    </tbody>
                </Table>
            </ContainerList>
            <LoadingScreen display={loading} />
            <PopupDefault
                open={openEditParticipante}
                close={() => setOpenEditParticipante(false)}
                children={
                    pacienteEditInfo ? (
                        <DivMainEditParticipante>
                            <h3>{pacienteEditInfo.Nome}</h3>
                            <div style={{ marginTop: '16px' }}>
                                <DivCampo style={{ marginBottom: '12px' }}>
                                    <span>Evento :</span>
                                    <div style={{ marginLeft: '10px' }}>
                                        <strong>{pacienteEditInfo.Evento}</strong>
                                    </div>
                                </DivCampo>
                                <DivCampo>
                                    <span>Email : </span>
                                    <input
                                        value={pacienteEditInfo.Email}
                                        onChange={({ target }) =>
                                            setPacienteEditInfo({ ...pacienteEditInfo, Email: target.value })
                                        }
                                        readOnly
                                    />
                                </DivCampo>
                                <DivCampo>
                                    <span>Celular : </span>
                                    <input
                                        value={pacienteEditInfo.Celular}
                                        onChange={({ target }) =>
                                            setPacienteEditInfo({ ...pacienteEditInfo, Celular: target.value })
                                        }
                                        readOnly
                                    />
                                </DivCampo>
                                <DivCampo>
                                    <span>Data Cadastro: </span>
                                    <input
                                        type="date"
                                        value={dayjs(pacienteEditInfo.CreationDate).format('YYYY-MM-DD')}
                                        readOnly
                                    />
                                </DivCampo>
                                <DivCampo>
                                    <span>Inscrição :</span>
                                    <div>
                                        <input
                                            style={{ width: '20px', height: '20px' }}
                                            type="checkbox"
                                            checked={pacienteEditInfo.Confirmado}
                                            onClick={() =>
                                                setPacienteEditInfo({
                                                    ...pacienteEditInfo,
                                                    Confirmado: !pacienteEditInfo.Confirmado,
                                                })
                                            }
                                        />
                                    </div>
                                </DivCampo>
                                <DivCampo>
                                    <span>Chave do boleto:</span>

                                    <input readOnly value={pacienteEditInfo.Chave} />
                                    <IconMdContentCopy
                                        title="copia link do pagamento"
                                        onClick={() =>
                                            copyInfo(`http://apoio.medx.med.br/ecommerce.aspx?id=${pacienteEditInfo.Chave}`)
                                        }
                                    />
                                </DivCampo>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => setOpenEditParticipante(false)}>Fechar</Button>
                                <Button
                                    style={{ background: theme.colors.colorSave }}
                                    onClick={() => saveParticipantesEdit(pacienteEditInfo)}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </DivMainEditParticipante>
                    ) : (
                        ''
                    )
                }
            />
            <PopupDefault
                open={openParticipadosFiltradosStatus}
                close={() => setOpenParticipadosFiltradosStatus(false)}
                children={
                    <DivMainParticipantesNaoConfirmados>
                        <ContainerBusca>
                            <div style={{ position: 'absolute', top: '10px', left: '5px' }}>
                                <MdSearch fontSize="20px" />
                            </div>
                            <Busca
                                type="text"
                                placeholder="Busque o cliente pelo nome, email ou softId."
                                onKeyPress={(event) =>
                                    event.key === 'Enter' ? getGetParticipantesEventos(event.currentTarget.value) : ''
                                }
                            />
                        </ContainerBusca>
                        <ContainerList style={{ maxHeight: '600px' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Evento</th>
                                        <th>Email</th>
                                        <th>Telefone</th>
                                        <th>Dt Inscrição</th>
                                        <th>Id Boleto</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaParticipantesNaoConfirmados.map((participante, index) => {
                                        return (
                                            <TrEventos key={`z${index}a${participante.Id_Cliente}`}>
                                                <td>
                                                    <span>{participante.Nome}</span>
                                                </td>
                                                <td>
                                                    <span>{participante.Evento}</span>
                                                </td>
                                                <td>
                                                    <span>{participante.Email}</span>
                                                </td>
                                                <td>
                                                    {' '}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener"
                                                        href={`https://api.whatsapp.com/send?phone=55${participante.Celular}`}
                                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                                    >
                                                        {participante.Celular}
                                                    </a>
                                                    <span></span>
                                                </td>
                                                <td>
                                                    <span>{dayjs(participante.CreationDate).format('DD/MM/YYYY')}</span>
                                                </td>
                                                <td
                                                    onClick={() => {
                                                        if (participante.Chave)
                                                            copyInfo(
                                                                `http://apoio.medx.med.br/ecommerce.aspx?id=${participante.Chave}`,
                                                            );
                                                        else toast.warning('Boleto sem chave.');
                                                    }}
                                                >
                                                    <span>{participante.Id_Boleto}</span>
                                                </td>
                                                <td>
                                                    <input
                                                        style={{ width: '12px', height: '12px' }}
                                                        type="checkbox"
                                                        checked={participante.Confirmado}
                                                        readOnly
                                                    />
                                                </td>
                                            </TrEventos>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </ContainerList>
                    </DivMainParticipantesNaoConfirmados>
                }
            />
        </DivMainBackups>
    );
}
