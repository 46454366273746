import { Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DivMain } from '~/components/commonComponents/commonComponents';
import UsuariosHipoGpt from './components/usuarios';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3,height:'100%' }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

export default function HipoGpt() {
    const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return <DivMain id='divMainHipoGpt'>
    <Box sx={{ width: '100%',height:'100%' }} id="teste" >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{height:'50px'}}>
          <Tab label="Usuários" {...a11yProps(0)} />
          <Tab label="Assistentes" {...a11yProps(1)}/>          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} style={{height:'calc(100% - 50px)'}}>
        <UsuariosHipoGpt />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} style={{height:'calc(100% - 50px)'}}>
        <Box>
            <Typography>Em manutenção</Typography>
        </Box>
      </CustomTabPanel>     
    </Box>
  </DivMain>;
}
