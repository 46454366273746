import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import { Button } from '../commonComponents/commonComponents';
import {
    DivButtons,
    DivContainerPermissionUser,
    DivListaPermissoes,
    DivMainPopupEditUser,
    DivNome,
    DivPermissoes,
    DivTitulo,
} from './styledPopupEditUser';
import LoadingScreen from '../loadScreen/Loadscreen';
import { useTheme } from 'styled-components';
export default function PopupEditUser({ userInfos, close, reload }) {
    const theme = useTheme();
    const api = useApi();
    const [dados, setDados] = useState(userInfos);
    const [loading, setLoading] = useState('none');
    async function salvaEditUser() {
        try {
            setLoading('flex');
            const responseUpdateUser = await api.post('/user/UpdateUser', dados);
            if (responseUpdateUser.status === 200) {
                toast.success('Usuário salvo com sucesso.');
                setLoading('none');
                close();
            }
        } catch (error) {
            console.log(error);
            toast.error('Erro ao salvar edição de usuário.');
            setLoading('none');
        }
    }
    useEffect(() => {
        return () => {
            reload();
        };
    }, []);

    return (
        <DivMainPopupEditUser>
            <DivTitulo>
                <h1>Usuário</h1>
            </DivTitulo>
            <div>
                <DivNome>
                    <p>Nome:</p>
                    <input
                        type={'text'}
                        value={dados.CurrentUser}
                        onChange={(event) => setDados({ ...dados, CurrentUser: event.target.value })}
                    />
                </DivNome>
                <div>
                    <p style={{ textAlign: 'center', margin: '12px 0', fontSize: '24px' }}>Permissões</p>
                    <DivListaPermissoes>
                        <DivContainerPermissionUser>
                            <DivPermissoes>
                                <p>Clientes</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Clientes}
                                    onClick={() => setDados({ ...dados, Clientes: !dados.Clientes })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Contatos em risco</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.ContratosRisco}
                                    onClick={() => setDados({ ...dados, ContratosRisco: !dados.ContratosRisco })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Agenda</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Agenda}
                                    onClick={() => setDados({ ...dados, Agenda: !dados.Agenda })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Finanças</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Financas}
                                    onClick={() => setDados({ ...dados, Financas: !dados.Financas })}
                                />
                            </DivPermissoes>
                        </DivContainerPermissionUser>
                        <DivContainerPermissionUser>
                            <DivPermissoes>
                                <p>Social</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Social}
                                    onClick={() => setDados({ ...dados, Social: !dados.Social })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Prescreva</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Prescreva}
                                    onClick={() => setDados({ ...dados, Prescreva: !dados.Prescreva })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Docflix</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Docflix}
                                    onClick={() => setDados({ ...dados, Docflix: !dados.Docflix })}
                                />
                            </DivPermissoes>
                        </DivContainerPermissionUser>
                        <DivContainerPermissionUser>
                            <DivPermissoes>
                                <p>Evento Medx</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.EventoMedx}
                                    onClick={() => setDados({ ...dados, EventoMedx: !dados.EventoMedx })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Backups</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Backups}
                                    onClick={() => setDados({ ...dados, Backups: !dados.Backups })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Usuarios</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Usuarios}
                                    onClick={() => setDados({ ...dados, Usuarios: !dados.Usuarios })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>Gammyx</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.Gammyx}
                                    onClick={() => setDados({ ...dados, Gammyx: !dados.Gammyx })}
                                />
                            </DivPermissoes>
                            <DivPermissoes>
                                <p>R1Med</p>
                                <input
                                    type={'checkbox'}
                                    checked={dados.R1Med}
                                    onClick={() => setDados({ ...dados, R1Med: !dados.R1Med })}
                                />
                            </DivPermissoes>
                        </DivContainerPermissionUser>
                    </DivListaPermissoes>
                </div>
                <DivButtons>
                    <Button style={{ background: theme.colors.colorDelete }} onClick={close}>Cancelar</Button>
                    <Button style={{ background: theme.colors.colorSave }} onClick={salvaEditUser}>
                        Salvar
                    </Button>
                </DivButtons>
            </div>
            <LoadingScreen display={loading} />
        </DivMainPopupEditUser>
    );
}
