import React from 'react';
import { Link } from 'react-router-dom'
import { Container, DivOpcao, DivOpcoes, LinkCustom } from './styeldMenuGammyx';
export default function GammyxInicial({ path }) {
    const opcoes = ["Alunos", "Vendas", "Cursos", "Autores", "Dashboard"]
    let pathLocal = path ?? "";
    return <Container>
        <DivOpcoes>
            {
                opcoes.map(opcao =>
                    <LinkCustom key={opcao} to={`/app/gammyx/${opcao.toLowerCase()}`}>
                        <DivOpcao
                            onMouseEnter={() => pathLocal = ""}
                            onMouseLeave={() => pathLocal = path}
                            selecionado={pathLocal?.toLowerCase().trim() == opcao.toLowerCase().trim()} >
                            {opcao}
                        </DivOpcao>
                    </LinkCustom>
                )
            }
        </DivOpcoes>
    </Container>;
}