import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding:16px;
  height:100%;
  width:100%;
`;
