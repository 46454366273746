import styled from 'styled-components';

export const DivMainNovaFatura = styled.div`
    position: relative;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden auto;
    min-width:600px;
    @media (max-height : 724px ) and (min-height: 0px) {        
        height: 550px;
    }
    @media (max-height: 700px) and (min-height: 725px) {        
        height: 650px;
    }
    @media (max-height: 800px) and (min-height: 701px){        
        height: 700px;
    }
`;

export const DivListClientes = styled.div`
    display: ${(props) => (props.hide ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    background: #0d0d0d;
    top: 40px;
    border-radius: 6px;
    cursor: default;
    max-height: 200px;
    overflow: auto;
    z-index: 99;
    diV {
        margin: 8px 0;
        padding: 12px;

        :hover {
            background: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const Select = styled.select`
    :hover {
        border-color: rgba(176, 206, 255, 1);
        box-shadow: 0px 0px 2px 1px;
    }
`;
export const DivTitleBoleto = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 16px 0;
    align-items: center;
`;
export const DivListaReferenteA = styled.div`
    display: ${(props) => (props.esconde ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    background: #0d0d0d;
    top: 40px;
    border-radius: 6px;
    cursor: default;
    max-height: 200px;
    overflow: auto;
    z-index:99;
    diV {
        margin: 8px 0;
        padding: 12px;

        :hover {
            background: ${(props) => props.theme.colors.primary};
        }
    }
`;
export const ContainerPopupRevisaoValores = styled.div`
    padding: 0px 60px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    h1 {
        text-align: center;
    }
`;
export const TextoConfirmacaoNovaVenda = styled.p`
    font-size: 18px;
    margin: 12px 0;
`;
export const DivBotoes = styled.div`
    display: flex;
    flex: 1;
    > button {
        flex: 1;
    }
`;
