import styled from 'styled-components';

export const DivMainPopupBankInfoEdit = styled.div`
    padding:20px;
    width:600px;
`

export const DivRakingBankInfoEdit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:8px;
    p{
        margin-right:8px;
        font-weight:600;     
        width:200px;   
    }
    /* input[type="date"]{
        width:200px;
    } */
`;

export const DivButtonsActionPopupBankInfoEdit = styled.div`
    display:flex;
    margin-top:12px;
    justify-content:center;
`