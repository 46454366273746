import styled from 'styled-components';

export const DivMainUsuarios = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    align-items: center;
    padding: 10px;

    h1 {
        margin: 16px 0;
    }

    input {
        flex: 1;
    }
`;

export const DivTable = styled.div`
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    table {
        width: 100%;
        tr {
            td {
                padding: 20px;
            }
        }
        tbody {
            height: 100%;
            overflow: auto;
        }
    }
`;
