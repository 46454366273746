import styled from 'styled-components';
import { lighten } from 'polished';
import { TableRow } from '@mui/material'
export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    height: 100%;
    overflow: hidden;
`;
export const Title = styled.h1`
    text-align: center;
    font-size: 30px;
`;

export const Article = styled.article`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding-top: 20px;
    overflow: hidden;
`;

export const ContainerBusca = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

export const Busca = styled.input`
    padding-left: 30px;
    cursor:text;
`;

export const ContainerList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-radius: 7px;
    padding: 10px;
    padding-top: 0px;
    flex: 1;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
`;

export const Table = styled.table`
    text-align: left;
    position: relative;
    border-collapse: collapse;
    @media (max-width: 900px) {
        display: none;
    }
    th {
        font-size: 14px;
    }

    td {
        padding: 15px 0.25rem;
        font-size: 14px;
    }

    tr th {
        background-color: ${(p) => p.theme.colors.background};
        padding: 20px 0.25rem;
        padding-top: 20px;
        border-bottom: 1px solid ${(p) => p.theme.colors.borderColor};
    }

    tbody > tr {
        transition-duration: 0.3s;
    }

    tbody > tr:hover {
        background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
        cursor: default;
        /* transform: scale(1.02); */
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

        > span {
            color: #fff;
        }
    }

    th {
        background: white;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
`;
export const DivMobile = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 901px) {
        display: none;
    }
`;
export const ScoreAtividade = styled.span`
    color: ${(p) => (p.score >= 5 ? '#009600' : p.score > 2 ? '#e7d817' : '#c11212')};
    font-weight: 800;
`;

export const DivInfoAtividade = styled.div`
    color: white;
    background: #000;
    border-radius: 6px;
    padding: 12px;
    font-size: 12px;
`;
export const DivButtonNovoPaciente = styled.div`
    margin: 12px 0 0 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const DivPopupNovoCliente = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const DivTituloPopupNovoCliente = styled.div`
    display: flex;
    flex: 0.1;
    justify-content: center;
    margin: 8px 0;
`;
export const FormNovoCliente = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden auto;
    padding: 12px;
`;
export const DivBodyDadosCliente = styled.div`
    display: flex;
`;
export const DivDadosContato = styled.div`
    margin-right: 24px;
    flex: 1;
`;
export const DivDadosEndereco = styled.div`
    flex: 1;
`;
export const DivCampo = styled.div`
    display: flex;
    flex-direction: column;
    > input {
        margin: 5px 0;
    }
`;
export const SpanNomeCampos = styled.span`
    font-weight: 600;
    margin: 8px 0;
`;
export const TableRowMui = styled(TableRow)`
    :hover{
        color: #1463a6 !important;;
        background:#1463a6;
    }
`
