import styled from 'styled-components';

export const DivMainPopupEditSolicitacaoBackup = styled.div`
    padding: 20px;
    h1 {
        text-align: center;
    }
`;
export const DivInfosPopupEditSolicitacaoBackup = styled.div`
    display: flex;
    flex-direction: column;
`;
export const DivButtons = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 12px 0 0 0;
`;
export const DivConteudoInfo = styled.div`
    margin: 12px 0;
    display: flex;
    align-items: center;
`;
