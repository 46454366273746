
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MenuGammyx from '~/components/menuGammyx/menuGammyx'
import { Button, Input, Title } from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import useApi from '~/service/api';
import { Container, CamposForm, DivBotao, DivBusca, DivTabela, FormNovoAluno, SpanCampoForm, DivTituloForm, DivConteudoForm } from './styledGammyxAlunos';
import { useTheme } from 'styled-components'
import LoadingScreen from '~/components/loadScreen/Loadscreen';
export default function Gammyx() {
    const api = useApi();
    const theme = useTheme();
    const history = useHistory();
    const [alunos, setAlunos] = useState([]);
    const [openPopupNovoAluno, setOpenPopupNovoAluno] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dadosNovoAluno, setDadosNovoAluno] = useState({
        Nome: "",
        Email: "",
        Celular: ""
    })
    useEffect(() => {
        GetAlunos('')
    }, [])
    async function GetAlunos(busca) {
        try {
            setLoading(true);
            const responseGetAlunos = await api.get(`/gammyx/GetAlunosGammyx?busca=${busca}`);
            if (responseGetAlunos.status == 200) {
                setAlunos(responseGetAlunos.data)
                setLoading(false);
            } else {
                toast.error('Erro ao carregar lista de alunos.')
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            if (error?.response?.status == 404) {
                toast.warning('Nenhum aluno foi encontrado.');
                setLoading(false);
                return;
            }
            toast.error('Erro ao carregar lista de alunos.')
            setLoading(false);
        }
    }
    async function cadastrarNovoAluno() {
        try {
            setLoading(true);
            const responseCadastrarNovoAluno = await api.post(`/gammyx/InsertNovoAluno`, dadosNovoAluno);
            if (responseCadastrarNovoAluno.status == 200) {
                setOpenPopupNovoAluno(false);
                toast.success('Cadastro realizado com sucesso.');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao cadastrar novo aluno.')
            setLoading(false);
        }
    }
    return <Container>
        <MenuGammyx />
        <DivBusca>
            <Input placeholder='Busque pelo nome ou email do aluno.'
                onKeyDownCapture={({ key, target, currentTarget }) => key == 'Enter' ? GetAlunos(currentTarget.value) : ''}
            />
        </DivBusca>
        <DivTabela>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Data Cadastro</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        alunos.length ? alunos.map(aluno => (
                            <tr key={aluno.Id} onDoubleClick={() => { history.push({ pathname: `/app/gammyx/aluno/${aluno.Id}`, state: aluno.Id }) }}>
                                <td>{aluno.Nome || "Sem nome"}</td>
                                <td>{aluno.Email || "Sem email"}</td>
                                <td>{dayjs(aluno.DataCriacao).format('DD/MM/YYYY')}</td>
                            </tr>
                        )) : ''
                    }
                </tbody>
            </table>
        </DivTabela>
        <DivBotao>
            <Button onClick={() => setOpenPopupNovoAluno(true)}>Novo Aluno</Button>
        </DivBotao>
        <PopupDefault
            open={openPopupNovoAluno}
            close={() => setOpenPopupNovoAluno(false)}
            children={<FormNovoAluno onSubmit={(event) => { event.preventDefault(); cadastrarNovoAluno() }}>
                <DivTituloForm>
                    <Title>Novo Aluno</Title>
                </DivTituloForm>
                <DivConteudoForm>
                    <CamposForm>
                        <SpanCampoForm>Nome:</SpanCampoForm>
                        <Input type="text" required value={dadosNovoAluno.Nome}
                            onChange={({ target }) => setDadosNovoAluno({ ...dadosNovoAluno, Nome: target.value })}
                        />
                    </CamposForm>
                    <CamposForm>
                        <SpanCampoForm>Email:</SpanCampoForm>
                        <Input type="email" required value={dadosNovoAluno.Email}
                            onChange={({ target }) => setDadosNovoAluno({ ...dadosNovoAluno, Email: target.value })}
                        />
                    </CamposForm>
                    <CamposForm>
                        <SpanCampoForm>Celular:</SpanCampoForm>
                        <Input type="tel" required value={dadosNovoAluno.Celular}
                            onChange={({ target }) => setDadosNovoAluno({ ...dadosNovoAluno, Celular: target.value.replace(/[A-Z]/g, '').replace(/[a-z]/g, '') })}
                        />
                    </CamposForm>
                    <DivBotao>
                        <Button style={{ flex: 1, background: theme.colors.colorDelete }} onClick={() => setOpenPopupNovoAluno(false)} >Cancelar</Button>
                        <Button style={{ flex: 1, background: theme.colors.colorSave }}>Cadastrar</Button>
                    </DivBotao>
                </DivConteudoForm>
            </FormNovoAluno>}
        />
        {loading && <LoadingScreen display={'flex'} />}
    </Container>;
}

