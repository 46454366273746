import React, { useRef, useState } from 'react';
import { Button, Title } from '../commonComponents/commonComponents';
import { DivPopupEditClient, SpanNameField, DivInfoPopup, DivDataForm, TextAreaObservacaoCliente, DivContentInfo } from './styledPopupEditFicha';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import { IconAiOutlineCloseCircle } from '../boletoEdit/StyledBoletoEdit';
import LoadingScreen from '../loadScreen/Loadscreen';
import { useTheme } from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
function PopupEditFicha({ clientInfo, IdCliente, setClientInfo, close }) {
    const theme = useTheme();
    const api = useApi();
    const refClienteInfo = useRef({});
    const [loading, setLoading] = useState('none');
    const [listaEstados] = useState([{value:"", estado:"Selecione o estado"},{value:"AC", estado:"Acre"},
    {value:"AL", estado:"Alagoas"},
    {value:"AP", estado:"Amapá"},
    {value:"AM", estado:"Amazonas"},
    {value:"BA", estado:"Bahia"},
    {value:"CE", estado:"Ceará"},
    {value:"DF", estado:"Distrito Federal"},
    {value:"ES", estado:"Espírito Santo"},
    {value:"GO", estado:"Goiás"},
    {value:"MA", estado:"Maranhão"},
    {value:"MT", estado:"Mato Grosso"},
    {value:"MS", estado:"Mato Grosso do Sul"},
    {value:"MG", estado:"Minas Gerais"},
    {value:"PA", estado:"Pará"},
    {value:"PB", estado:"Paraíba"},
    {value:"PR", estado:"Paraná"},
    {value:"PE", estado:"Pernambuco"},
    {value:"PI", estado:"Piauí"},
    {value:"RJ", estado:"Rio de Janeiro"},
    {value:"RN", estado:"Rio Grande do Norte"},
    {value:"RS", estado:"Rio Grande do Sul"},
    {value:"RO", estado:"Rondônia"},
    {value:"RR", estado:"Roraima"},
    {value:"SC", estado:"Santa Catarina"},
    {value:"SP", estado:"São Paulo"},
    {value:"SE", estado:"Sergipe"},
    {value:"TO", estado:"Tocantins"}]);
    const [listaEspecialidades] = useState([{label:'Residência Médica',value:'Residência Médica'},
    {label:'Acupuntura',value:'Acupuntura'},
    {label:'Alergia e Imunologia',value:'Alergia e Imunologia'},
    {label:'Anestesiologista',value:'Anestesiologista'},
    {label:'Angiologia',value:'Angiologia'},
    {label:'Cardiologia',value:'Cardiologia'},
    {label:'Cirurgia Cardiovascular',value:'Cirurgia Cardiovascular'},
    {label:'Cirurgia da Mão',value:'Cirurgia da Mão'},
    {label:'Cirurgia de Cabeça e Pescoço',value:'Cirurgia de Cabeça e Pescoço'},
    {label:'Cirurgia do Aparelho Digestivo',value:'Cirurgia do Aparelho Digestivo'},
    {label:'Cirurgia Geral',value:'Cirurgia Geral'},
    {label:'Cirurgia Oncológica',value:'Cirurgia Oncológica'},
    {label:'Cirurgia Pediátrica',value:'Cirurgia Pediátrica'},
    {label:'Cirurgia Plástica',value:'Cirurgia Plástica'},
    {label:'Cirurgia Torácica',value:'Cirurgia Torácica'},
    {label:'Cirurgia Vascular',value:'Cirurgia Vascular'},
    {label:'Clínica Médica',value:'Clínica Médica'},
    {label:'Coloproctologia',value:'Coloproctologia'},
    {label:'Dermatologia',value:'Dermatologia'},
    {label:'Endocrinologia e Metabologia',value:'Endocrinologia e Metabologia'},
    {label:'Endoscopia',value:'Endoscopia'},
    {label:'Gastroenterologia',value:'Gastroenterologia'},
    {label:'Genética Médica',value:'Genética Médica'},
    {label:'Geriatria',value:'Geriatria'},
    {label:'Ginecologia e Obstetrícia',value:'Ginecologia e Obstetrícia'},
    {label:'Hematologia e Hemoterapia',value:'Hematologia e Hemoterapia'},
    {label:'Homeopatia',value:'Homeopatia'},
    {label:'Infectologia',value:'Infectologia'},
    {label:'Mastologia',value:'Mastologia'},
    {label:'Medicina de Emergência',value:'Medicina de Emergência'},
    {label:'Medicina de Família e Comunidade',value:'Medicina de Família e Comunidade'},
    {label:'Medicina do Trabalho',value:'Medicina do Trabalho'},
    {label:'Medicina de Tráfego',value:'Medicina de Tráfego'},
    {label:'Medicina Esportiva',value:'Medicina Esportiva'},
    {label:'Medicina Física e Reabilitação',value:'Medicina Física e Reabilitação'},
    {label:'Medicina Intensiva',value:'Medicina Intensiva'},
    {label:'Medicina Legal e Perícia Médica',value:'Medicina Legal e Perícia Médica'},
    {label:'Medicina Nuclear',value:'Medicina Nuclear'},
    {label:'Medicina Preventiva e Social',value:'Medicina Preventiva e Social'},
    {label:'Nefrologia',value:'Nefrologia'},
    {label:'Neurocirurgia',value:'Neurocirurgia'},
    {label:'Neurologia',value:'Neurologia'},
    {label:'Nutrologia',value:'Nutrologia'},
    {label:'Oftalmologia',value:'Oftalmologia'},
    {label:'Oncologia Clínica',value:'Oncologia Clínica'},
    {label:'Ortopedia e Traumatologia',value:'Ortopedia e Traumatologia'},
    {label:'Otorrinolaringologia',value:'Otorrinolaringologia'},
    {label:'Especialista em Patologia',value:'Especialista em Patologia'},
    {label:'Patologia Clínica/Medicina Laboratorial',value:'Patologia Clínica/Medicina Laboratorial'},
    {label:'Especialista em Pediatria',value:'Especialista em Pediatria'},
    {label:'Pneumologia',value:'Pneumologia'},
    {label:'Psiquiatria',value:'Psiquiatria'},
    {label:'Radiologia e Diagnóstico por Imagem',value:'Radiologia e Diagnóstico por Imagem'},
    {label:'Radioterapia',value:'Radioterapia'},
    {label:'Reumatologia',value:'Reumatologia'},
    {label:'Urologia',value:'Urologia'}])
    const setOpenPopupEditClient = close;
    async function updateCliente() {
        try {
            //console.log(refClienteInfo);
            setLoading('flex');
            let objUpdateClienteInfo = {};
            objUpdateClienteInfo['Codigo_do_Cliente'] = IdCliente;
            const newObj = Object.assign(objUpdateClienteInfo, refClienteInfo.current);
            const data = await api.post(`cliente/UpdateClientInfoById`, newObj);
            if (data.status === 200 && data.data === 'Success') {
                toast.success('Salvo com sucesso');
                setClientInfo((oldValue) => {
                    let newValue = Object.assign(oldValue, newObj);
                    return newValue;
                });
                setOpenPopupEditClient(false);
                setLoading('none');
            } else {
                setLoading('none');
                toast.warning('Não foi possível salvar as alterações');
            }
        } catch (error) {
            setLoading('none');
            toast.error('Não foi possível salvar as alterações');
        }
    }
    
    function trocaValorEspecialidade(value){       
        refClienteInfo.current['Especialidade'] = value;
    }

    return (
        <DivPopupEditClient>
            <div style={{ display: 'inherit', justifyContent: 'center', margin: '8px 0 16px 0'}}>
                <Title>Informações cadastrais</Title>
            </div>
            <DivDataForm>
                <DivInfoPopup>
                    <TextField sx={{mb:3}} variant='outlined' label={'Nome do cliente'} required name='Nome'
                        size='small'
                        focused                        
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Nome}}
                        onChange={(event)=> refClienteInfo.current['Nome'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Nome'] = event?.value)}                                          
                    />
                    <TextField sx={{mb:3}} variant='outlined' label={'Email de Assinante'} required name='Email de Assinante'
                        size='small'
                        focused                        
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Email}}
                        onChange={(event)=>  refClienteInfo.current['Email'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Email'] = event?.value)}                        
                    />
                    <TextField sx={{mb:3}} variant='outlined' label={'Email de ADM'} required name='Email de ADM'
                        size='small'
                        focused                        
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Email_Administrativo}}
                        onChange={(event)=>  refClienteInfo.current['Email_Administrativo'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Email_Administrativo'] = event?.value)}                        
                    />                                                        
                    <TextField sx={{mb:3}} variant='outlined' label={'Atividade'} name='Atividade'
                        size='small'
                        focused                        
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Indice_de_Atividade}}
                        disabled                                             
                    />                   
                    <TextField sx={{mb:3}} variant='outlined' label={'Instagram'} required name='Instagram'
                        size='small'
                        focused                        
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Instagram}}
                        onChange={(event)=>  refClienteInfo.current['Instagram'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Instagram'] = event?.value)}                        
                    />
                    <FormControl sx={{mb:3}} fullWidth focused>
                        <InputLabel id="especialidade">Especialidade</InputLabel>
                        <Select
                            size='small'
                            labelId="especialidade"
                            id="especialidade"
                            label="Especialidade"
                            onChange={(event) => {
                                trocaValorEspecialidade(event?.target.value)
                            }}
                            SelectDisplayProps={{style:{color:theme.colors.text}}}
                            defaultValue={clientInfo.Especialidade}
                            ref={(event) => (refClienteInfo.current['Especialidade'] = event?.value)}
                        >
                            <MenuItem value="">Selecione a opção</MenuItem>
                            {
                                listaEspecialidades.map(especialidade =>(
                                    <MenuItem key={especialidade.label} value={especialidade.value}>{especialidade.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>              
                </DivInfoPopup>
                <DivInfoPopup>
                    <div style={{display:"flex"}}>
                        <TextField sx={{mb:3,mr:2}} variant='outlined' label={'DDD'} required name='DDD'
                            size='small'
                            focused                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text,maxWidth:'60px'},maxLength:2}}
                            InputProps={{defaultValue:clientInfo.DDD}}
                            onChange={(event)=>  refClienteInfo.current['DDD'] = event?.currentTarget.value}
                            inputRef={(event) => (refClienteInfo.current['DDD'] = event?.value)}                        
                        />
                        <TextField sx={{mb:3}} variant='outlined' label={'Telefone'} required name='Telefone'
                            size='small'
                            focused                                                    
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text,flex:1}}}
                            InputProps={{defaultValue:clientInfo.Telefone}}
                            onChange={(event)=>  refClienteInfo.current['Telefone'] = event?.currentTarget.value}
                            inputRef={(event) => (refClienteInfo.current['Telefone'] = event?.value)}                        
                        />                       
                    </div>
                    <div>
                        <TextField sx={{mb:3,mr:2}} variant='outlined' label={'DDD2'} required name='DDD2'
                            size='small'
                            focused                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text,maxWidth:'60px'},maxLength:2}}
                            InputProps={{defaultValue:clientInfo.DDD2}}
                            onChange={(event)=>  refClienteInfo.current['DDD2'] = event?.currentTarget.value}
                            inputRef={(event) => (refClienteInfo.current['DDD2'] = event?.value)}                        
                        />                        
                        <TextField sx={{mb:3}} variant='outlined' label={'Celular'} required name='Celular'
                            size='small'
                            focused                                                    
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text,flex:1}}}
                            InputProps={{defaultValue:clientInfo.Celular}}
                            onChange={(event)=>  refClienteInfo.current['Celular'] = event?.currentTarget.value}
                            inputRef={(event) => (refClienteInfo.current['Celular'] = event?.value)}                        
                        />                           
                    </div>
                    <TextField sx={{mb:3}} variant='outlined' label={'Cidade'} required name='Cidade'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Cidade}}
                        onChange={(event)=>  refClienteInfo.current['Cidade'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Cidade'] = event?.value)}                        
                    />
                    <TextField sx={{mb:3}} variant='outlined' label={'CGC'} required name='CGC'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.CGC}}
                        onChange={(event)=>  refClienteInfo.current['CGC'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['CGC'] = event?.value)}            
                    />                    
                    <FormControl fullWidth focused>
                        <InputLabel id="canalCadastro">Canal Cadastro</InputLabel>
                        <Select
                            size='small'
                            labelId="canalCadastro"
                            id="canalCadastro"    
                            SelectDisplayProps={{style:{color:theme.colors.text}}}                        
                            label="Canal Cadastro"
                            onChange={(event) => {                                
                                refClienteInfo.current['CanalCadastro'] = event?.target.value;
                            }}
                            defaultValue={clientInfo.CanalCadastro}                                              
                            ref={(event) => (refClienteInfo.current['CanalCadastro'] = event?.value)}
                        >
                            <MenuItem value="">Selecione a opção</MenuItem>
                            <MenuItem value="Congresso ABRAN" label="Congresso ABRAN">
                                {' '}
                                Congresso ABRAN{' '}
                            </MenuItem>
                            <MenuItem value="Congresso da SBD (Dermatologia)" label="Congresso da SBD (Dermatologia)">
                                {' '}
                                Congresso da SBD (Dermatologia){' '}
                            </MenuItem>
                            <MenuItem value="Congresso da SBMEE (Esportiva)" label="Congresso da SBMEE (Esportiva)">
                                {' '}
                                Congresso da SBMEE (Esportiva){' '}
                            </MenuItem>
                            <MenuItem value="Congresso Longevidade Saudável" label="Congresso Longevidade Saudável">
                                {' '}
                                Congresso Longevidade Saudável{' '}
                            </MenuItem>
                            <MenuItem value="Congresso Médico" label="Congresso Médico">
                                {' '}
                                Congresso Médico{' '}
                            </MenuItem>
                            <MenuItem value="Corporativo" label="Corporativo">
                                {' '}
                                Corporativo{' '}
                            </MenuItem>
                            <MenuItem
                                value="Curso de Hipertrofia Dr Gustavo Medeiros"
                                label="Curso de Hipertrofia Dr Gustavo Medeiros"
                            >
                                {' '}
                                Curso de Hipertrofia Dr Gustavo Medeiros{' '}
                            </MenuItem>
                            <MenuItem value="Curso de Longevidade Dr Ítalo Rachid" label="Curso de Longevidade Dr Ítalo Rachid">
                                {' '}
                                Curso de Longevidade Dr Ítalo Rachid{' '}
                            </MenuItem>
                            <MenuItem value="Curso Dr Ronald Canejo" label="Curso Dr Ronald Canejo">
                                {' '}
                                Curso Dr Ronald Canejo{' '}
                            </MenuItem>
                            <MenuItem value="Curso Mkt/Gestão Larissa Mokarzel" label="Curso Mkt/Gestão Larissa Mokarzel">
                                {' '}
                                Curso Mkt/Gestão Larissa Mokarzel{' '}
                            </MenuItem>
                            <MenuItem value="Curso Nutricionista Renata Starling" label="Curso Nutricionista Renata Starling">
                                {' '}
                                Curso Nutricionista Renata Starling{' '}
                            </MenuItem>
                            <MenuItem value="Curso Nutrologia Dr Carlos Werutsky" label="Curso Nutrologia Dr Carlos Werutsky">
                                {' '}
                                Curso Nutrologia Dr Carlos Werutsky{' '}
                            </MenuItem>
                            <MenuItem value="Curso Nutrologia Dra Vivian Suen" label="Curso Nutrologia Dra Vivian Suen">
                                {' '}
                                Curso Nutrologia Dra Vivian Suen{' '}
                            </MenuItem>
                            <MenuItem value="Curso Obesidade Dr Carlos J C Lopes" label="Curso Obesidade Dr Carlos J C Lopes">
                                {' '}
                                Curso Obesidade Dr Carlos J C Lopes{' '}
                            </MenuItem>
                            <MenuItem value="Curso/Pós Dr Gabriel Almeida" label="Curso/Pós Dr Gabriel Almeida">
                                {' '}
                                Curso/Pós Dr Gabriel Almeida{' '}
                            </MenuItem>
                            <MenuItem value="Email" label="Email">
                                {' '}
                                Email{' '}
                            </MenuItem>
                            <MenuItem value="Eventos e Cursos MEDX" label="Eventos e Cursos MEDX">
                                {' '}
                                Eventos e Cursos MEDX{' '}
                            </MenuItem>
                            <MenuItem value="Facebook" label="Facebook">
                                {' '}
                                Facebook{' '}
                            </MenuItem>
                            <MenuItem value="Google/Outros Buscadores" label="Google/Outros Buscadores">
                                {' '}
                                Google/Outros Buscadores{' '}
                            </MenuItem>
                            <MenuItem value="Indicação de um colega" label="Indicação de um colega">
                                {' '}
                                Indicação de um colega{' '}
                            </MenuItem>
                            <MenuItem value="Instragram" label="Instragram">
                                {' '}
                                Instragram{' '}
                            </MenuItem>
                            <MenuItem value="ISMD" label="ISMD">
                                {' '}
                                ISMD{' '}
                            </MenuItem>
                            <MenuItem value="Jornada Cirurgia Plástica" label="Jornada Cirurgia Plástica">
                                {' '}
                                Jornada Cirurgia Plástica{' '}
                            </MenuItem>
                            <MenuItem value="Keter Consultoria" label="Keter Consultoria">
                                {' '}
                                Keter Consultoria{' '}
                            </MenuItem>
                            <MenuItem value="Linkedin" label="Linkedin">
                                {' '}
                                Linkedin{' '}
                            </MenuItem>
                            <MenuItem value="Não Sei" label="Não Sei">
                                {' '}
                                Não Sei{' '}
                            </MenuItem>
                            <MenuItem value="Outros" label="Outros">
                                {' '}
                                Outros{' '}
                            </MenuItem>
                            <MenuItem value="Pós-Graduação Albert Einstein" label="Pós-Graduação Albert Einstein">
                                {' '}
                                Pós-Graduação Albert Einstein{' '}
                            </MenuItem>
                            <MenuItem value="Pós-Graduação BWS " label="Pós-Graduação BWS ">
                                {' '}
                                Pós-Graduação BWS{' '}
                            </MenuItem>
                            <MenuItem value="Pós-Graduação Clínica Jurado SP" label="Pós-Graduação Clínica Jurado SP">
                                {' '}
                                Pós-Graduação Clínica Jurado SP{' '}
                            </MenuItem>
                            <MenuItem value="Pós-Graduação HZM" label="Pós-Graduação HZM">
                                {' '}
                                Pós-Graduação HZM{' '}
                            </MenuItem>
                            <MenuItem value="Pós-Graduação Longevidade Saudável" label="Pós-Graduação Longevidade Saudável">
                                {' '}
                                Pós-Graduação Longevidade Saudável{' '}
                            </MenuItem>
                            <MenuItem value="Programa GENES" label="Programa GENES">
                                {' '}
                                Programa GENES{' '}
                            </MenuItem>
                            <MenuItem value="Rio Health Nutrition" label="Rio Health Nutrition">
                                {' '}
                                Rio Health Nutrition{' '}
                            </MenuItem>
                            <MenuItem
                                value="SEND (Seminário de Endocrinologia, Nutrologia e Diabetes)"
                                label="SEND (Seminário de Endocrinologia, Nutrologia e Diabetes)"
                            >
                                {' '}
                                SEND (Seminário de Endocrinologia, Nutrologia e Diabetes){' '}
                            </MenuItem>
                            <MenuItem
                                value="SIDOM (Simpósio de Diabetes, Obesidade e Metabolismo)"
                                label="SIDOM (Simpósio de Diabetes, Obesidade e Metabolismo)"
                            >
                                {' '}
                                SIDOM (Simpósio de Diabetes, Obesidade e Metabolismo){' '}
                            </MenuItem>
                            <MenuItem value="Simpósio Ex-alunos Ivo Pitangy" label="Simpósio Ex-alunos Ivo Pitangy">
                                {' '}
                                Simpósio Ex-alunos Ivo Pitangy{' '}
                            </MenuItem>
                            <MenuItem value="Visita Representante Comercial" label="Visita Representante Comercial">
                                {' '}
                                Visita Representante Comercial{' '}
                            </MenuItem>
                            <MenuItem value="Webinar MEDX" label="Webinar MEDX">
                                {' '}
                                Webinar MEDX{' '}
                            </MenuItem>
                            <MenuItem
                                value="Workshop de Mídias Sociais para Médicos Dr Carlos Lopes"
                                label="Workshop de Mídias Sociais para Médicos Dr Carlos Lopes"
                            >
                                {' '}
                                Workshop de Mídias Sociais para Médicos Dr Carlos Lopes{' '}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </DivInfoPopup>
                <DivInfoPopup>
                    <TextField sx={{mb:3}} variant='outlined' label={'Endereço'} required name='Endereco'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Endereco}}
                        onChange={(event)=>  refClienteInfo.current['Endereco'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Endereco'] = event?.value)}                        
                    />
                    <TextField sx={{mb:3}} variant='outlined' label={'Nº'} required name='Nº'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Numero}}
                        onChange={(event)=>  refClienteInfo.current['Numero'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Numero'] = event?.value)}                        
                    />                  
                    <TextField sx={{mb:3}} variant='outlined' label={'Complemento'} required name='Complemento'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Complemento}}
                        onChange={(event)=>  refClienteInfo.current['Complemento'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Complemento'] = event?.value)}                        
                    />
                    <TextField sx={{mb:3}} variant='outlined' label={'Bairro'} required name='Bairro'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.Bairro}}
                        onChange={(event)=>  refClienteInfo.current['Bairro'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['Bairro'] = event?.value)}                        
                    />
                    <FormControl fullWidth sx={{mb:3}} focused>
                        <InputLabel id="estado">Estado</InputLabel>
                        <Select                            
                            size='small'
                            labelId="estado"
                            id="estado"                            
                            label="estado"
                            SelectDisplayProps={{style:{color:theme.colors.text}}}
                            onChange={(event) => {                                
                                refClienteInfo.current['Estado'] = event?.target.value;
                            }}
                            defaultValue={clientInfo.Estado}                                                     
                            ref={(event) => (refClienteInfo.current['Estado'] = event?.value)}
                        >
                            {
                                listaEstados.map(estado =>(
                                <MenuItem value={estado.value}>{estado.estado}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>                                       
                    <TextField sx={{mb:3}} variant='outlined' label={'CEP'} required name='CEP'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                        InputProps={{defaultValue:clientInfo.CEP}}
                        onChange={(event)=>  refClienteInfo.current['CEP'] = event?.currentTarget.value}
                        inputRef={(event) => (refClienteInfo.current['CEP'] = event?.value)}                        
                    />                    
                </DivInfoPopup>
            </DivDataForm>
            <TextField sx={{mb:3}} variant='outlined' label={'Observação'} required name='Observacoes'
                size='small'
                focused
                multiline
                rows={3}
                inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                InputProps={{defaultValue:clientInfo.Observacoes}}
                onChange={(event)=>  refClienteInfo.current['Observacoes'] = event?.currentTarget.value}
                inputRef={(event) => (refClienteInfo.current['Observacoes'] = event?.value)}                        
            />           
            <div style={{ display: 'flex',width:'100%', margin: '0 auto', justifyContent: 'space-around' }}>
                <Button onClick={() => setOpenPopupEditClient(false)} style={{ background: theme.colors.colorDelete,flex:1 }} >Cancelar</Button>
                <Button onClick={updateCliente} style={{ background: theme.colors.colorSave,flex:1 }}>Salvar</Button>
            </div>
            <LoadingScreen display={loading} />
        </DivPopupEditClient>
    );
}

export default PopupEditFicha;
