import React, { useEffect, useState,useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import { Container, LoginContainer } from './stylesLogin';
import { Button } from '~/components/commonComponents/commonComponents';
import useApi from '~/service/api';
import { setToken } from '~/service/auth';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { Box, TextField } from '@mui/material'
import { useTheme } from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha';
import logo from '~/assets/images/logo-pequeno-transparente.png'
const IS_LOCAL = window.location.href.includes('localhost');

function Login() {
    let history = useHistory();
    let location = useLocation();
    const [tipoServidor, setTipoServidor] = useState('servidor');

    const api = useApi();
    const theme = useTheme();
    const recaptchaRef = useRef();

    useEffect(() => {
        sessionStorage.setItem('tipoServidor', tipoServidor);
    }, [tipoServidor]);

    const [openLoaderScreen, setOpenLoaderScreen] = useState('none');
    const [user, setUser] = useState({ Usuario: localStorage.getItem('@sistemaInterno-user') || '', Senha: '' });

    async function login(event) {
        event.preventDefault();  
        try {
            setOpenLoaderScreen('flex');
             let tokenCaptcha = await getTokenCaptcha();    
            let { data: token } = await api.post('/auth/Login', {...user,Captcha:tokenCaptcha});
            // let { data: token } = await api.post('/auth/Login', user);
            if (token !== 'Usuario ou senha incorreta') {
                setToken(token);
                setOpenLoaderScreen('none');
                return acessarSistema();
            }
            setOpenLoaderScreen('none');
            toast.error('Erro ao tentar logar!');
            // let token = '0123456789';
        } catch (error) {
            let mensagemErro = error?.response?.data?.Message || error.message;
            toast.error(mensagemErro || 'Erro ao tentar logar!');
            setOpenLoaderScreen('none');
            console.log(error, error.response);
        }
    }

    function setUsuario(User) {
        localStorage.setItem('@sistemaInterno-user', User);
        setUser({ ...user, Usuario: User });
    }

    function getTokenCaptcha(){
        return new Promise((resolve, reject) => {
            recaptchaRef.current.executeAsync().then(value => {
                //console.log("executeAsync promise - Captcha value:", value);
                resolve(value);
              });
        })
    }

    function acessarSistema() {
        let pagina = location?.state?.from;
        if (pagina) return history.push(pagina);
        history.push('/app/clientes');
    }
    function asyncScript(event){
        console.log("script load")
        console.log(event);
    }
    function changeRecaptch(event){
        console.log("changeEvent");
        console.log(event);
    }
    return (
        <Container>
            <LoginContainer>
                <div>
                    <img src={logo} alt="" />
                    <Box component={"form"} onSubmit={login}>
                            <TextField
                                size='small'
                                label="Usuario"
                                value={user.Usuario}
                                placeholder="Digite aqui seu usuário"
                                onChange={(e) => setUsuario(e.target.value.trim())}
                                required
                                fullWidth
                                slotProps={
                                    {inputLabelProps: {style: {fontSize: '18px'}},htmlInput:{ background: theme.colors.backgroundInput, color: theme.colors.text }
                                }}                                                                
                                focused
                            />
                        
                            <TextField
                                label="Senha"
                                size='small'
                                placeholder="Digite aqui sua senha"
                                onChange={(e) => setUser({ ...user, Senha: e.target.value.trim() })}
                                type="Password"
                                required
                                fullWidth
                                slotProps={
                                    {inputLabelProps: {style: {fontSize: '18px'}},htmlInput:{ background: theme.colors.backgroundInput, color: theme.colors.text }
                                }}  
                                focused
                            />
                            <Button
                                style={{ width: '100%', textAlign: 'center', justifyContent: 'center', marginBottom: '10px' }}
                                backgroundColorProps="#1564a6"
                                hoverBackgroundColorProps="#154a77"
                            >
                                Acessar
                            </Button>                        
                        {IS_LOCAL && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ paddingBottom: '10px' }}>Usar Api:</span>
                                <div style={{ paddingLeft: '15px' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={tipoServidor}
                                        onChange={(event) => setTipoServidor(event.target.value)}
                                    >
                                        <FormControlLabel value="local" control={<Radio />} label="Local" />
                                        <FormControlLabel value="servidor" control={<Radio />} label="Servidor" />
                                    </RadioGroup>
                                </div>
                            </div>
                        )}
                    </Box>
                </div>
            </LoginContainer>
            <LoadingScreen display={openLoaderScreen} />
            <ReCAPTCHA
                ref={recaptchaRef}
                style={{ display: "inline-block" }}
                theme="dark"
                size="invisible"            
                sitekey={"6LehhcoaAAAAAM88Uc2_-GeZ9N-p_KKGe3PGrsvh"}
                onChange={changeRecaptch}
                asyncScriptOnLoad={asyncScript}
          />
        </Container>
    );
}

export default Login;
