import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'tui-date-picker';
import 'tui-date-picker/dist/tui-date-picker.css';
import dayjs from 'dayjs';

export default function DataPickerComponent({ getAgendamentos, refCal }) {
    const cal = useRef();

    DatePicker.localeTexts['customKey'] = {
        titles: {
            // days
            DD: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            // daysShort
            D: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            // months
            MMMM: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
            ],
            // monthsShort
            MMM: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        },
        titleFormat: 'MMM yyyy',
        todayFormat: 'D, MMMM dd, yyyy',
        date: 'Date',
        time: 'Time',
    };

    const [datapicker, setDatapicker] = useState();

    function clickOnDate(event) {
        console.log(event);
    }

    useEffect(() => {
        setDatapicker(
            new DatePicker('#wrapper', {
                date: new Date(),
                input: {
                    element: 'datepicker-input',
                    format: 'yyyy-MM-dd',
                },
                language: 'customKey',
                autoClose: true,
            }),
        );
    }, []);

    useEffect(() => {
        if (datapicker) {
            datapicker.on('change', () => {
                getAgendamentos(
                    dayjs(datapicker.getDate()).format('YYYY-MM-DDT00:00'),
                    dayjs(datapicker.getDate()).format('YYYY-MM-DDT23:59'),
                );
                refCal[0].current.calendarInst.setDate(datapicker.getDate());
                refCal[1].current.calendarInst.setDate(datapicker.getDate());
                refCal[2].current.calendarInst.setDate(datapicker.getDate());
            });
        }
    }, [datapicker]);

    return (
        <div id="wrapper">
            <input type="text" id="datepicker-input" onClick={(event) => clickOnDate(event)} />
        </div>
    );
}
