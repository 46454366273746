import React from 'react';
import { DivHigh, DivMain, DivMedium, DivMini } from './StyledSignalIcon';

// import { Container } from './styles';

function SignalIcon({ score }) {
    return <DivMain>
        <DivMini score={score} />
        <DivMedium score={score} />
        <DivHigh score={score} />
    </DivMain>;
}

export default SignalIcon;