import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-Direction: column;
    justify-Content: center;
    align-Items: center;
    height: 100%;
    gap: 20px;


    >h3{
        font-size: 40px;
    }
    >h1{
        font-size: 50px;
    }
    >a{
        color: ${ p => p.theme.colors.primary };
        font-size: 20px;
    }
`;
