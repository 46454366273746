import React from 'react';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import { DivMain } from '../cliente/ClienteStyled';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import LoadingScreen from '~/components/loadScreen/Loadscreen';


export default function Cancelamentos() {
    const api = useApi();
    const [listaCancelamentos, setListaCancelamentos] = React.useState([]);
    const [listaCancelamentosFiltrados, setListaCancelamentosFiltrados] = React.useState([])
    const [cancelamentoSelecionado, setCancelamentoSelecionado] = React.useState(null);
    const [loading, setLoading] = React.useState('none');
    
    React.useEffect(()=>{
        init()
    },[])

    async function getListaCancelamentos(){
        setLoading('flex')
        try {
            const resultListaCancelamentos = await api.get('/cliente/GetCancelamentos');
            const {status,data} = resultListaCancelamentos;
            if(status == 200){
                setListaCancelamentos(data)                
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de cancelamentos.')
        }
        setLoading('none')
    }

    async function init(){
        try {
            await getListaCancelamentos()
        } catch (error) {
            console.error(error);
            toast.error('Erro na inicialização da pagina');
        }
    }

    function selecionaCancelamento(cancelamentoInfo){
        setCancelamentoSelecionado(cancelamentoInfo)
    }

    async function updateStatusCancelamento(){
        setLoading('flex')
        try {
            const resultUpdateCancelamento = await api.post('/cliente/UpdateCancelamento',cancelamentoSelecionado);
            const {status,data} = resultUpdateCancelamento;
            if(status == 200){
                setCancelamentoSelecionado(null)
                toast.success('Cancelamento atualizado com sucesso.')
                await init();
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível atualizar pedido de cancelamento.')
        }
        setLoading('none')
    }

    function buscaCliente(string){
        try {
            const cancelamentosFiltered = listaCancelamentos.filter(cancelamentoInfo =>{

                const verificaNome = cancelamentoInfo.nome.toLowerCase().includes(string.toLowerCase());
                const verificaEmail = cancelamentoInfo.email.toLowerCase().includes(string.toLowerCase());                
                return (verificaNome || verificaEmail)
            })            
            if(cancelamentosFiltered.length){                
                setListaCancelamentosFiltrados(cancelamentosFiltered);
            }else{
                toast.warning('Nenhum cliente foi encontrado.')
            }
        } catch (error) {
            console.error(error)
            toast.error('Erro ao tentar buscar pelo cliente.')
        }finally{

        }
    }

    const TabelaMemo = React.memo(()=>{
        let arraySelecionado = listaCancelamentos;
        if(listaCancelamentosFiltrados.length){
            arraySelecionado = listaCancelamentosFiltrados
        }
        return<TableContainer>
             <Table>
        <TableHead>
            <TableRow>                        
                <TableCell align="left">nome</TableCell>
                <TableCell align="left">status</TableCell>
                <TableCell align="left">email</TableCell>
                <TableCell align="left">celular</TableCell>
                <TableCell align="left">motivos</TableCell>
                <TableCell align="left">observações</TableCell>
                <TableCell align="left">data</TableCell>                             
            </TableRow>
        </TableHead>               
        <TableBody>{
            arraySelecionado.map((cancelamento) => (
            <TableRow
                key={cancelamento.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer' }}
                onClick={()=>selecionaCancelamento(cancelamento)}
            >
                <TableCell component="th" scope="row">
                    {cancelamento.nome}
                </TableCell>
                <TableCell align="left">{cancelamento.status}</TableCell>
                <TableCell align="left">{cancelamento.email}</TableCell>
                <TableCell align="left">{cancelamento.celular}</TableCell>
                <TableCell align="left">{cancelamento.motivos}</TableCell>
                <TableCell align="left">{cancelamento.observacoes}</TableCell>
                <TableCell align="left">{dayjs(cancelamento.dataPedido).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
            ))}
        </TableBody>                 
    </Table>
        </TableContainer>
    },[listaCancelamentosFiltrados,listaCancelamentos])

  return <DivMain>
    <Box display={'flex'} flexDirection={'column'}>
        <Typography variant='h1' fontSize={'2em'} textAlign={'center'}>Cancelamentos</Typography>
        <Divider sx={{mt:2,mb:2}}></Divider>
        <TextField variant='outlined' placeholder='Busque pelo nome do cliente ou pelo email.' onChange={({target})=>buscaCliente(target.value)}/>
        <TabelaMemo/>
        
        {cancelamentoSelecionado && <Dialog            
            open={true}
            onClose={()=>setCancelamentoSelecionado(null)}
            aria-describedby="Cancelamento selecionado"
      >
        <DialogTitle sx={{textAlign:"center"}}>{cancelamentoSelecionado.nome}</DialogTitle>
        <DialogContent sx={{overflow:'hidden auto'}}>
            <Box display={'flex'} flexDirection={'column'} minWidth={400}>
                <FormControl sx={{mt:1,mb:2}}>
                    <InputLabel id="statusCancelamento">Status</InputLabel>
                    <Select
                        labelId="statusCancelamento"
                        id="selectStatusCancelamento"
                        value={cancelamentoSelecionado.status}
                        label="Status"
                        onChange={({target})=>setCancelamentoSelecionado({...cancelamentoSelecionado,status:target.value})}
                        size='small'
                    >
                        <MenuItem value={'pendente'}>Pendente</MenuItem>
                        <MenuItem value={'concluido'}>Concluído</MenuItem>
                        <MenuItem value={'cancelado'}>Cancelado</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label={'Email'}
                    value={cancelamentoSelecionado.email}
                    variant='outlined'
                    multiline
                    rows={1} 
                    InputProps={{sx:{boxShadow:'none'}}}
                    sx={{mb:2}}
                    disabled
                    focused
                />
                <TextField
                    label={'Celular'}
                    value={cancelamentoSelecionado.celular}
                    variant='outlined'
                    multiline
                    rows={1}           
                    sx={{mb:2}}
                    disabled
                    focused
                />
                <TextField
                    label={'Motivos'}
                    value={cancelamentoSelecionado.motivos}
                    variant='outlined'
                    multiline
                    rows={2}
                    inputProps={{sx:{boxShadow:'none',':hover,:focus':{boxShadow:'none'}}}}
                    sx={{mb:2}}
                    disabled
                    focused
                />
                <TextField
                    label={'Mais detalhes'}
                    value={cancelamentoSelecionado?.OutroMotivosString}
                    variant='outlined'
                    multiline
                    rows={2}
                    inputProps={{sx:{boxShadow:'none',':hover,:focus':{boxShadow:'none'}}}}
                    sx={{mb:2}}
                    focused
                    disabled
                />
                <TextField
                    label={'Outro sistema'}
                    value={cancelamentoSelecionado?.OutroSistema ?? ''}
                    variant='outlined'
                    multiline
                    rows={2}
                    inputProps={{sx:{boxShadow:'none',':hover,:focus':{boxShadow:'none'}}}}
                    sx={{mb:2}}
                    disabled
                    focused
                />
                <TextField
                    label={'Observações'}
                    value={cancelamentoSelecionado.observacoes ?? ''}
                    variant='outlined'
                    multiline
                    rows={4}
                    inputProps={{sx:{boxShadow:'none',':hover,:focus':{boxShadow:'none'}}}}
                    disabled
                    focused
                />
            </Box>          
        </DialogContent>
        <DialogActions>
          <Button sx={{flex:1}} variant='outline' onClick={()=>setCancelamentoSelecionado(null)}>Cancelar</Button>
          <Button sx={{flex:1}} variant='contained' onClick={updateStatusCancelamento}>Salvar</Button>
        </DialogActions>
      </Dialog> }   
    </Box>
    <LoadingScreen display={loading} />
  </DivMain>;
}
