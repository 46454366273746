import React, { useState, useEffect } from 'react';
import useApi from '~/service/api';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
    ContainerDivCardGridInfo,
    DivFilterSearch,
    DivInfoClient,
    DivMainPopupEditClientInfo,
    DivTable,
} from '../financas/StyledFinancas';
import { Button } from '~/components/commonComponents/commonComponents';
import PopupEditVideo from '~/components/popupEditVideo/PopupEditVideo';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { DivActions, SpanNameField } from './styledDocfilx';
import { useTheme } from 'styled-components';
export default function Docflix() {
    const theme = useTheme();
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState({
        Aniversario: '',
        Confirmado: '',
        Email: '',
        Genero: '',
        Id: '',
        Nome: '',
        Plano: '',
        URLFoto: '',
        Validade: '',
    });
    // eslint-disable-next-line 
    const [videos, setVideos] = useState([]);
    const [autores, setAutores] = useState([]);
    const [loadScreen, setLoadScreen] = useState('none');
    const [openPopupEdit, setOpenPopupEdit] = useState(false);
    // eslint-disable-next-line 
    const [selected, setSelected] = useState([true, false]);
    const [videoSelected, setVideoSelected] = useState({});
    const [openPopupEditVideo, setOpenPopupEditVideo] = useState(false);
    const api = useApi();


    function editClient(client) {
        setCliente(client);
        setOpenPopupEdit(true);
    }

    async function getClientesDocflix(busca) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/docflix/GetDocflixAssinantes?busca=${busca}`);
            if (data.status === 200) {
                setClientes(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de clientes');
            setLoadScreen('none');
        }
    }

    async function getVideos(busca) {
        try {
            const data = await api.get(`/docflix/GetDocflixVideos?busca=${busca}`);
            if (data.status === 200) {
                setVideos(data.data);
            } else {
                toast.error('Não foi possível carregar os vídeos');
            }
        } catch (error) {
            console.log(error);
            toast.error('Error ao carregar os vídeos.');
        }
    }

    async function getAutores(busca) {
        try {
            const autoresRequest = await api.get(`/docflix/GetDocflixAutor?busca=${busca}`);
            if (autoresRequest.status === 200) {
                setAutores(autoresRequest.data);
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar autores');
        }
    }

    async function salvarDadosClient() {
        try {
            setLoadScreen('flex');
            const dados = {
                Nome: cliente.Nome,
                Email: cliente.Email,
                Id: cliente.Id,
                Plano: cliente.Plano,
                Validade: dayjs(cliente.Validade).format('YYYY-MM-DD'),
                Genero: cliente.Genero || 'H',
                Aniversario: dayjs(cliente.Aniversario).format('YYYY-MM-DD'),
            };
            const data = await api.post(`/docflix/UpdateDocflixAssinantes`, dados);
            if (data.data === 'Success') {
                toast.success('Dados salvos com sucesso.');
                setOpenPopupEdit(false);
                setLoadScreen('none');
            } else {
                toast.error('Não foi possível salvar os dados.');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível salvar os dados do cliente.');
            setLoadScreen('none');
        }
    }

    function loadInfoVideos() {
        getVideos('');
        getAutores('');
    }

    useEffect(() => {
        getClientesDocflix('');
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (selected[1]) {
            loadInfoVideos();
        }
        // eslint-disable-next-line 
    }, [selected]);

    return (
        <ContainerDivCardGridInfo>
            <div style={{ display: 'flex', justifyContent: 'center', height: '40px', marginTop: '20px' }}>
                <h1>Docflix</h1>
            </div>
            <DivFilterSearch style={{ height: '40px' }}>
                <input
                    type="text"
                    placeholder="Informe o nome ou o email cadastrado."
                    onKeyPress={(event) => (event.key === 'Enter' ? getClientesDocflix(event.currentTarget.value) : '')}
                />
            </DivFilterSearch>
            <DivTable style={{ height: 'calc(100% - 80px - 60px)' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Plano</th>
                            <th>Validade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clientes.map(cliente => {
                                return <tr onClick={() => editClient(cliente)}>
                                    <td>{cliente.Nome}</td>
                                    <td>{cliente.Email}</td>
                                    <td>{cliente.Plano}</td>
                                    <td>{dayjs(cliente.Validade).format('DD/MM/YYYY')}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </DivTable>
            <PopupDefault open={openPopupEdit} close={() => setOpenPopupEdit(false)}
                children={<DivMainPopupEditClientInfo>
                    <DivInfoClient style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 0 15px 0' }}>
                        <span>Id</span>
                        <input disabled type="text" value={cliente.Id} style={{ margin: '0px 0 0 10px' }} />
                    </DivInfoClient>
                    <div style={{ display: 'flex', width: '550px', flexDirection: 'column' }}>
                        <div>
                            <DivInfoClient>
                                <SpanNameField>Nome</SpanNameField>
                                <input
                                    type="text"
                                    defaultValue={cliente.Nome}
                                    onChange={(event) => setCliente({ ...cliente, Nome: event.target.value })}
                                />
                            </DivInfoClient>
                            <DivInfoClient>
                                <SpanNameField>Email</SpanNameField>
                                <input
                                    type="text"
                                    defaultValue={cliente.Email}
                                    onChange={(event) => setCliente({ ...cliente, Email: event.target.value })}
                                />
                            </DivInfoClient>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <DivInfoClient>
                                    <SpanNameField>Sexo</SpanNameField>
                                    <select
                                        defaultValue={cliente.Genero}
                                        onChange={(event) => setCliente({ ...cliente, Genero: event.target.value })}
                                    >
                                        <option value="H">Homem</option>
                                        <option value="M">Mulher</option>
                                    </select>
                                </DivInfoClient>
                                <DivInfoClient>
                                    <SpanNameField>Aniversário</SpanNameField>
                                    <input
                                        type="date"
                                        maxLength="1"
                                        defaultValue={dayjs(cliente.Aniversario).format('YYYY-MM-DD')}
                                        onChange={(event) => setCliente({ ...cliente, Aniversario: event.currentTarget.value })}
                                    />
                                </DivInfoClient>
                            </div>
                            <div>
                                <DivInfoClient>
                                    <SpanNameField>Plano</SpanNameField>
                                    <select
                                        defaultValue={cliente.Plano ?? ''}
                                        onChange={(event) => setCliente({ ...cliente, Plano: event.target.value })}
                                    >
                                        <option value="FREE">FREE</option>
                                        <option value="PREMIUM">PREMIUM</option>
                                    </select>
                                </DivInfoClient>
                                <DivInfoClient>
                                    <SpanNameField>Validade</SpanNameField>
                                    <input
                                        type="date"
                                        maxLength="1"
                                        defaultValue={dayjs(cliente.Validade).format('YYYY-MM-DD')}
                                        onChange={(event) => setCliente({ ...cliente, Validade: event.currentTarget.value })}
                                    />
                                </DivInfoClient>
                            </div>
                        </div>
                    </div>
                    <div>
                        <DivActions>
                            <Button style={{ background: theme.colors.colorDelete }} onClick={() => setOpenPopupEdit(false)}>
                                Fechar
                            </Button>
                            <Button style={{ background: theme.colors.colorSave }} onClick={salvarDadosClient}>
                                Salvar
                            </Button>
                        </DivActions>
                    </div>
                </DivMainPopupEditClientInfo>
                } />


            <PopupDefault open={openPopupEditVideo} close={() => setOpenPopupEditVideo(false)}
                children={<PopupEditVideo
                    videoSelected={videoSelected}
                    setVideoSelected={setVideoSelected}
                    autores={autores}
                    close={setOpenPopupEditVideo}
                    reload={loadInfoVideos}
                />}
            />


            <LoadingScreen display={loadScreen} />
        </ContainerDivCardGridInfo>
    );
}
