import React from 'react';
import Popup from 'reactjs-popup';
import { SpanNameField } from '~/pages/cliente/ClienteStyled';
import { DivMainDescription } from './StyledDescriptionAtividades';

export default function DescriptionAtividades({ info_vendas, clientInfo }) {
    const venda_ativa = info_vendas.filter((item) => item.Status?.toLowerCase() !== 'suspenso');
    return (
        <Popup
            trigger={
                <div style={{ cursor: 'default' }}>
                    <SpanNameField>Atividade : </SpanNameField>
                    <span>{clientInfo.Indice_de_Atividade}</span>
                </div>
            }
            on={'hover'}
            arrow={false}
            contentStyle={{ marginTop: '10px' }}
        >
            <DivMainDescription>
                <div>
                    <span>Agenda</span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivAgenda} readOnly />
                </div>

                <div>
                    <span>Contatos </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivContatos} readOnly />
                </div>
                <div>
                    <span>Faturamento </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivFaturamento} readOnly />
                </div>
                <div>
                    <span>Lançamentos </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivLancamentos} readOnly />
                </div>
                <div>
                    <span>Marketing </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivMarketing} readOnly />
                </div>
                <div>
                    <span>Prontuario </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivProntuario} readOnly />
                </div>
                <div>
                    <span>SMS </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivSMS} readOnly />
                </div>
                <div>
                    <span>Pesquisa de qualidade </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivPesquisa_de_qualidade} readOnly />
                </div>
                <div>
                    <span> Usuarios </span>
                    <input type="checkbox" checked={venda_ativa[0]?.AtivUsuarios} readOnly />
                </div>
                <div>
                    <span>Migração </span>
                    <input type="checkbox" checked={venda_ativa[0]?.MedxMigracao} readOnly />
                </div>
            </DivMainDescription>
        </Popup>
    );
}
