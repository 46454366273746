import React, { useEffect,useRef,useState } from "react";
import { DivMainLoginUnificado } from "./loginUnificadoStyled";
import { DivTituloPagina } from "~/pages/eventos/notificacoes/styledNotificacoes";
import TabelaModelo from "~/components/tabelaModelo/tabelaModelo";
import { toast } from "react-toastify";
import { Button, Input } from "~/components/commonComponents/commonComponents";
import useApi from "~/service/api";
import LoadingScreen from "~/components/loadScreen/Loadscreen";
import dayjs from "dayjs";
import PopupDefault from "~/components/popupDefault/PopupDefault";
import FormLoginUnificado from "./formLoginUnificado";
export function LoginUnificado(){

    const header=["Id","DbId","UserName","Email","Validado","Dt Expira"];
    const inputBuscaRef = useRef();
    const [loading, setLoading] = useState('none');
    const [listaLoginUnificado, setListaLoginUnificado] = useState([]);
    const [openPopupEditaLoginUnificado, setOpenPopupEditaLoginUnificado] = useState(false);
    const [dadosLoginUnificado, setDadosLoginUnificado] = useState(null);
    const api = useApi();

    
    
    useEffect(()=>{
        init("")
    },[])
    
    function retornaValor(key, ObjCompleto) {
        if(String(key).toLowerCase() == "dt expira"){
            return dayjs(ObjCompleto["DtExpira"]).format("DD/MM/YYYY");
        }
        if(String(key).toLowerCase() == "validado"){
            if(ObjCompleto[key]){
                return "Sim"
            }else{
                return "Não"
            }
        }
        return ObjCompleto[key];
                
    };
    
    async function getLoginsUnificado(busca){
        setLoading('flex')
        try {
            const {status,data} = await api.get(`/Cliente/GetLoginUnificado?busca=${busca}`);
            if(status == 200){
                setLoading('none')
               return data;
            }
        } catch (error) {
            setLoading('none')
            console.error(JSON.stringify(error,null,3));
            if(error.response.status == 404)
                toast.warning("Nenhum resultado encontrado.");
            else{
                toast.error('Não foi possível carregar lista de login unificado;');
            }
            return []
        }
        
    }

    async function init(busca){
        setListaLoginUnificado(await getLoginsUnificado(busca))
    }

    async function handlerKeyPress(event){
        if(event.key == "Enter"){
            await init(event.currentTarget.value)
        }
    }

    function closePopup(){
        setOpenPopupEditaLoginUnificado(false);
        setDadosLoginUnificado(null)
    }

    function selecionaLoginUnificado(loginUnificado){
        setDadosLoginUnificado(loginUnificado);
        setOpenPopupEditaLoginUnificado(true)
    }

    return <DivMainLoginUnificado>
        <DivTituloPagina >
            <h1>Login Unificado</h1>
        </DivTituloPagina>
        <div style={{display:"flex"}}>
            <Input type="text" placeholder="Informe um email ou softwareid" onKeyDown={(event)=>handlerKeyPress(event)} ref={inputBuscaRef} />
            <Button style={{marginLeft:'16px'}} onClick={()=> init(inputBuscaRef.current.value)}>Buscar</Button>
        </div>

        <div style={{flex:1,overflow:'hidden auto'}}>
            <TabelaModelo
                header={header}
                fnRetornaValor={retornaValor}
                clientes={listaLoginUnificado}
                callbackDoubleClick={(value)=>selecionaLoginUnificado(value)}
            />
        </div>
        <PopupDefault
            open={openPopupEditaLoginUnificado}
            close={()=>closePopup()}
            children={<FormLoginUnificado dadosLoginUnificado={dadosLoginUnificado} callbackCancelar={()=>closePopup()}/>}
        />
        <LoadingScreen display={loading} />
    </DivMainLoginUnificado>
}