import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, DivMain, DivTable, Input, Title } from '~/components/commonComponents/commonComponents';
import { Busca, ContainerBusca } from '../clientes/stylesClientes';
import useApi from '~/service/api';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import dayjs from 'dayjs';
import { DivContentSelectUser, DivFooter, DivInfo, DivObservacao, DivSelect, DivSelectTitle } from './styledFollowUp';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { TextareaAutosize } from '@mui/material';

export default function FollowUp() {
    const api = useApi();
    const [loading, setLoading] = useState('none');
    const [atendimentos, setAtendimentos] = useState([]);
    const [usersInfo, setUsersInfo] = useState([]);
    const [newAtendimento, setNewAtendimento] = useState({
        IdResponsavel: '',
        IdCriador: '',
        IdCliente: '',
        SoftwareId: '',
        Tipo: '',
        DataCriacao: '',
        Prazo: dayjs(new Date()).format('YYYY-MM-DD'),
        Titulo: '',
        Status: '',
        Observacao: '',
        PlataformaContato: '',
        NomeCliente: '',
        TelefoneCliente: '',
        EmailCliente: '',
        Discussao: '',
        Urgencia: '',
    });
    const [tipoNome, setTipoNome] = useState('Selecione tipo de atendimento')
    const [responsavelNome, setResponsavelNome] = useState('Selecione o responsável');
    const [status, setStatus] = useState('Selecione o status');
    const [urgencia, setUrgencia] = useState('Selecione a urgência');
    const [platContato, setPlatContato] = useState('Selecione a plataforma de contato')
    const [listClients, setListClients] = useState([]);
    const [tiposAtendimento, setTiposAtendimento] = useState([]);
    const [openPopupNewAtendimento, setOpenPopupNewAtendimento] = useState(false);
    const [openSelectType, setOpenSelectType] = useState(false);
    const [openSelectResponsavel, setOpenSelectResponsavel] = useState(false);
    const [openSelectStatus, setOpenSelectStatus] = useState(false);
    const [openSelectUrgencia, setOpenSelectUrgencia] = useState(false);
    const [openSelectPlatContato, setOpenSelectPlatContato] = useState(false)
    async function getFollowUp(title) {
        try {
            setLoading('flex');
            const responseGetFollowUp = await api.get(`/FollowUp/GetFollowUp?busca=${title}`);
            if (responseGetFollowUp.status === 200) {
                setAtendimentos(responseGetFollowUp.data);
            } else {
                toast.error('Erro ao carregar lista de atendimentos.')
            }
            setLoading('none')
        } catch (error) {
            setLoading('none')
            toast.error('Não foi possível carregar lista de atendimentos.')
        }
    }
    async function getAllUsers() {
        try {
            setLoading('flex');
            const responseGetAllUsers = await api.get('/user/GetAllUsers');
            if (responseGetAllUsers.status === 200) {
                setUsersInfo(responseGetAllUsers.data);
                setLoading('none');
            } else {
                setLoading('none');
                toast.error('Erro ao carregar lista de usuários.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carrega lista de usuários.');
            setLoading('none');
        }
    }
    async function getAllClients(nameOrEmail) {
        try {
            setLoading('flex')
            const responseGetAllClients = await api.get(`/cliente/GetListClients?search=${nameOrEmail}`);
            if (responseGetAllClients.status === 200) {
                setListClients(responseGetAllClients.data);
            } else {
                toast.error('Erro ao carregar lista de clientes.')
            }
            setLoading('none')
        } catch (error) {
            setLoading('none')
            toast.error('Não foi possível carregar lista de clientes.')
        }
    }
    async function init() {
        await getAllUsers();
        await getFollowUp('');

    }
    async function openCreateNewAtendimento() {
        await getAllClients('');
        await getAllTiposAtendimento();
        setOpenPopupNewAtendimento(true);
    }
    async function getAllTiposAtendimento() {
        try {
            setLoading('flex');
            const responseGetTipo = await api.get('/FollowUp/GetTipo');
            if (responseGetTipo.status === 200) {
                setTiposAtendimento(responseGetTipo.data);
            } else {
                toast.error('Não foi possível carregar tipos de atendimento.')
            }
            setLoading('none')
        } catch (error) {
            setLoading('none')
            toast.error('Erro ao carregar tipos de atendimento.')
        }
    }
    async function saveNewAtendimento() {
        console.log(newAtendimento);
    }
    function getUserName(idUser) {
        try {
            const findUserInfo = usersInfo.find(el => el.IdUsuario === idUser);
            if (findUserInfo) {
                return findUserInfo.CurrentUser;
            } else {
                return "Usuário não localizado."
            }
        } catch (error) {
            toast.error('Não foi possível carregar nome do usuário.');
            return 'error';
        }
    }
    function selectTipo(tipo) {
        try {
            setTipoNome(tipo.Nome_Tipo_Suporte)
            setNewAtendimento({ ...newAtendimento, Tipo: tipo.ID_Tipo_Suporte });
            setOpenSelectType(false)
        } catch (error) {
            toast.error('Erro ao selecionar opção de atendimento.')
        }
    }
    function selectResponsavelName(user) {
        try {
            setResponsavelNome(user.CurrentUser);
            setNewAtendimento({ ...newAtendimento, IdResponsavel: user.IdUsuario })
            setOpenSelectResponsavel(false);
        } catch (error) {
            toast.error('Não foi possível selecionar o responsável.')
        }
    }
    function organizeResponsavel(arrayResponsavel) {
        try {

        } catch (error) {
            toast.error('Não foi possível organizar lista de responsáveis.')
        }
    }
    function selectStatus(status) {
        try {
            setNewAtendimento({ ...newAtendimento, Status: status });
            setStatus(status);
            setOpenSelectStatus(false);
        } catch (error) {
            toast.error('Erro ao selecionar status.')
        }
    }
    function selectUrgencia(urgencia) {
        try {
            setNewAtendimento({ ...newAtendimento, Urgencia: urgencia });
            setUrgencia(urgencia);
            setOpenSelectUrgencia(false)
        } catch (error) {
            toast.error('Não foi possível selecionar urgência')
        }
    }
    function selectPlatContato(platContato) {
        try {
            setNewAtendimento({ ...newAtendimento, PlataformaContato: platContato });
            setPlatContato(platContato);
            setOpenSelectPlatContato(false);
        } catch (error) {
            toast.error('Não foi possível selecionar plataforma contato.')
        }
    }
    useEffect(() => {
        init();
    }, [])

    return (
        <DivMain>
            <ContainerBusca style={{ height: '40px' }}>
                <div style={{ position: 'absolute', top: '10px', left: '5px' }}>
                    <MdSearch fontSize="20px" />
                </div>
                <Busca
                    type="text"
                    placeholder="Busque pelo nome da solicitação."
                    onBlur={(event) => {
                        return event.currentTarget.value === '' ? '' : '';
                    }}
                    onKeyPress={(event) => (event.key === 'Enter' ? getFollowUp(event.target.value) : '')}
                />
            </ContainerBusca>
            <DivTable id="tabela" style={{ height: 'calc(100% - 100px)' }}>
                <table style={{ fontSize: '12px' }}>
                    <thead>
                        <tr style={{ height: '30px', fontSize: '14px' }}>
                            <th>Cliente</th>
                            <th>Solicitação</th>
                            <th>Data Criação</th>
                            <th>Prazo</th>
                            <th>Status</th>
                            <th>Responsável</th>
                            <th>Criador</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            atendimentos.map(atendimentoInfo => {
                                return <tr>
                                    <td>{atendimentoInfo.NomeCliente}</td>
                                    <td>{atendimentoInfo.Titulo}</td>
                                    <td>{dayjs(atendimentoInfo.DataCriacao).format('DD/MM/YYYY')}</td>
                                    <td>{dayjs(atendimentoInfo.Prazo).format('DD/MM/YYYY')}</td>
                                    <td>{atendimentoInfo.Status}</td>
                                    <td>{getUserName(atendimentoInfo.IdResponsavel)}</td>
                                    <td>{getUserName(atendimentoInfo.IdCriador)}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </DivTable>
            <DivFooter>
                <Button onClick={openCreateNewAtendimento}>Criar Atendimento</Button>
            </DivFooter>
            <PopupDefault
                open={openPopupNewAtendimento}
                close={() => setOpenPopupNewAtendimento(false)}
                children={<div>
                    <Title style={{ textAlign: 'center' }}>Novo Atendimento</Title>
                    <div style={{ padding: '12px' }}>
                        <DivInfo>
                            <span>Título:</span>
                            <Input type='text'
                                value={newAtendimento.Titulo}
                                onChange={({ target }) => setNewAtendimento({ ...newAtendimento, Titulo: target.value })} />
                        </DivInfo>
                        <DivInfo>
                            <span>Cliente:</span>
                            <Input type="text"
                                value={newAtendimento.NomeCliente}
                                onChange={({ target }) => setNewAtendimento({ ...newAtendimento, NomeCliente: target.value })}
                                onKeyDown={({ key, target }) => key === 'Enter' ? getAllClients(target.value) : ''}
                            />
                        </DivInfo>
                        <div style={{ display: 'flex', margin: '0 0 12px 0' }}>
                            <div style={{ flex: '1' }}>
                                <DivInfo>
                                    <span>SoftwareId:</span>
                                    <Input type="text"
                                        value={newAtendimento.SoftwareId}
                                        onChange={({ target }) => setNewAtendimento({ ...newAtendimento, SoftwareId: target.value })}
                                    />
                                </DivInfo>
                                <DivInfo>
                                    <span>Telefone:</span>
                                    <Input type="tel"
                                        value={newAtendimento.TelefoneCliente}
                                        onChange={({ target }) => setNewAtendimento({ ...newAtendimento, TelefoneCliente: target.value.replace(/[a-z]/g, '') })}
                                    />
                                </DivInfo>
                                <DivInfo>
                                    <span>Email:</span>
                                    <Input type="email"
                                        value={newAtendimento.EmailCliente}
                                        onChange={({ target }) => setNewAtendimento({ ...newAtendimento, EmailCliente: target.value })}
                                    />
                                </DivInfo>
                                <DivInfo>
                                    <span>Urgencia:</span>
                                    <DivSelect>
                                        <DivSelectTitle onClick={() => setOpenSelectUrgencia(true)}>{urgencia}</DivSelectTitle>
                                        <DivContentSelectUser show={openSelectUrgencia} onMouseLeave={() => setOpenSelectUrgencia(false)}>
                                            {
                                                ['Normal', 'Média', 'Urgente'].map(urgencia => {
                                                    return <DivSelect onClick={() => selectUrgencia(urgencia)}>{urgencia}</DivSelect>
                                                })
                                            }
                                        </DivContentSelectUser>
                                    </DivSelect>
                                </DivInfo>
                                <DivInfo>
                                    <span>Plataforma Contato:</span>
                                    <DivSelect>
                                        <DivSelectTitle onClick={() => setOpenSelectPlatContato(true)}>{platContato}</DivSelectTitle>
                                        <DivContentSelectUser show={openSelectPlatContato} onMouseLeave={() => setOpenSelectPlatContato(false)}>
                                            {
                                                ['WhatsApp', 'Ladesk', 'Ligação', 'Email'].map(platContato => {
                                                    return <DivSelect onClick={() => selectPlatContato(platContato)}>{platContato}</DivSelect>
                                                })
                                            }
                                        </DivContentSelectUser>
                                    </DivSelect>
                                </DivInfo>
                            </div>
                            <div style={{ flex: '1', margin: '0 0 0 12px' }}>
                                <DivInfo>
                                    <span>Tipo:</span>
                                    <DivSelect>
                                        <DivSelectTitle onClick={() => setOpenSelectType(true)}>{tipoNome}</DivSelectTitle>
                                        <DivContentSelectUser show={openSelectType} onMouseLeave={() => setOpenSelectType(false)}>
                                            {
                                                tiposAtendimento.map(tipo => {
                                                    return <DivSelect onClick={() => selectTipo(tipo)}>{tipo.Nome_Tipo_Suporte}</DivSelect>
                                                })
                                            }
                                        </DivContentSelectUser>
                                    </DivSelect>
                                </DivInfo>
                                <DivInfo>
                                    <span>Responsável:</span>
                                    <DivSelect>
                                        <DivSelectTitle onClick={() => setOpenSelectResponsavel(true)}>{responsavelNome}</DivSelectTitle>
                                        <DivContentSelectUser show={openSelectResponsavel} onMouseLeave={() => setOpenSelectResponsavel(false)}>
                                            {
                                                usersInfo.sort((a, b) => a.CurrentUser.localeCompare(b.CurrentUser)).map(user => {
                                                    return <DivSelect onClick={() => selectResponsavelName(user)}>{user.CurrentUser}</DivSelect>
                                                })
                                            }
                                        </DivContentSelectUser>
                                    </DivSelect>
                                </DivInfo>
                                <DivInfo>
                                    <span>Data Criação:</span>
                                    <Input type="date"
                                        min='2022-01-01' max='9999-01-01'
                                        value={dayjs(new Date()).format('YYYY-MM-DD')}
                                        disabled
                                    />
                                </DivInfo>
                                <DivInfo>
                                    <span>Status:</span>
                                    <DivSelect>
                                        <DivSelectTitle onClick={() => setOpenSelectStatus(true)}>{status}</DivSelectTitle>
                                        <DivContentSelectUser show={openSelectStatus} onMouseLeave={() => setOpenSelectStatus(false)}>
                                            {
                                                ['Em aberto', 'Em execução', 'Finalizado'].map(status => {
                                                    return <DivSelect onClick={() => selectStatus(status)}>{status}</DivSelect>
                                                })
                                            }
                                        </DivContentSelectUser>
                                    </DivSelect>
                                </DivInfo>
                                <DivInfo>
                                    <span>Prazo:</span>
                                    <Input type="date"
                                        min='2022-01-01' max='9999-01-01'
                                        value={newAtendimento.Prazo}
                                        onChange={({ target }) => setNewAtendimento({ ...newAtendimento, Prazo: target.value })}
                                    />
                                </DivInfo>
                            </div>
                        </div>
                        <span style={{ cursor: 'default' }}>Observação</span>
                        <DivObservacao>
                            <TextareaAutosize aria-label='Observação' minRows={4} maxRows={4} style={{ width: '100%', padding: '8px 12px', fontSize: '16px' }}
                                value={newAtendimento.Observacao} onChange={({ target }) => setNewAtendimento({ ...newAtendimento, Observacao: target.value })} />
                        </DivObservacao>
                        <DivFooter style={{ justifyContent: 'end' }}>
                            <Button style={{ background: '#115213' }} onClick={saveNewAtendimento}>Salvar</Button>
                        </DivFooter>
                    </div>
                </div>}
            />
            <LoadingScreen display={loading} />
        </DivMain>
    );
}
