import styled from 'styled-components';

export const DivSelectionCustom = styled.div`
    transition: 0.5s;
    :hover {
        transform: scale(0.9);
    }
`;

export const DivSelectAgendaName = styled.div`
    padding: 10px 5px;
    border: 1px solid #717171;
    border-radius: 6px;
    cursor: pointer;
    background: #545454;
    color: #ffffffff;
`;

export const DivOptionsSelectAgendaName = styled.div`
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1d1d1d;
    border-radius: 6px;
    height: calc(fit-content + 50px);
    border: 1px solid #3a3a3a;
    overflow: hidden;
    div {
        :last-child {
            border-bottom: 0;
        }
        border-bottom: 1px solid #3a3a3a;
        width: 100%;
        padding: 12px 0;
        text-align: center;
        cursor: pointer;
        height: fit-content;
        :hover {
            background: #0f4a7c;
            color: #fff;
        }
    }
`;
