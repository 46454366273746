import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getToken, removeToken } from '~/service/auth';
const LINK_SERVER = 'https://brsistemainterno.azurewebsites.net/api';

// const LINK_SERVER = 'https://sistemainterno-sistemainternoteste2.azurewebsites.net/api';

function getUrlServer(isLocal) {
    if (!isLocal) return LINK_SERVER;

    if (sessionStorage.getItem('tipoServidor') == 'local') {
        return 'http://localhost:3002/api';
    } else {
        return LINK_SERVER;
    }
}

function useApi(param) {
    const history = useHistory();
    const [api] = useState(() => {
        const api = axios.create({
            baseURL: getUrlServer(window.location.href.includes('localhost')),

            // headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json',
            //     'Access-Control-Expose-Headers': 'Access-Control-*',
            //     'Access-Control-Allow-Headers': 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
            //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
            //     'Access-Control-Allow-Origin': '*',
            // },
        });

        api.interceptors.request.use(async (config) => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        api.interceptors.response.use(
            async function (config) {
                config.headers = 'Access-Control-Allow-Origin, new[] { ' * ' }';
                return config;
            },
            function (error) {
                // Do something with request error
                if(param?.skip){
                    return error
                }else{
                    if (error.response) {
                        if (error.response.status === 403) {
                            removeToken();
                        }
                        if (error.response.status === 401 || String(error.message).toLowerCase() == 'network error') {
                            toast.error('Você não tem permissão');
                            history.push('/');
                        }
                    }
                    if (String(error.message).toLowerCase() == 'network error' && !error.response) {
                        toast.error('Você não tem permissão');
                        history.push('/');
                    }
                    return Promise.reject(error);
                }
            },
        );

        return api;
    });

    return api;
}

export default useApi;
