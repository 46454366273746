import React,{useState,useEffect, useRef} from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

export default function PopupNovoTrial({close}) {

    const [dadosTrial, setDadosTrial] = useState({
        nome:'',
        email:'',
        celular:'',
        comoConheceu:'Selecione um opção'
    });
    const [display, setDisplay] = useState('none')
    const [listaCanais, setListaCanais] = useState([{
        id: -1,
        Canal: 'Selecione um opção',
        Descricao: 'Selecione um opção',
    }])
    const recaptchaRef = useRef();


    useEffect(()=>{
        init()
    },[])
    useEffect(()=>{
        console.log(listaCanais);
    },[listaCanais])

    async function cadastraTrial(){
        setDisplay('flex')
        try {
            const valueCaptcha = await recaptchaRef.current.executeAsync();            
            const objTrial = {
                nome: dadosTrial.nome,
                plano: 'Individual',
                celular: dadosTrial.celular,
                email: dadosTrial.email,
                comoconheceu: dadosTrial.comoConheceu,
                vigencia: 30,
                PartnerId: null,
                ip: 1,
                responsecaptcha:valueCaptcha,
                sistemaInterno:true
            }
            const responseAxiosRaw = await axios.post('https://fn.medx.med.br/api/CreateLicense',objTrial);            
            if(responseAxiosRaw.status == 200){
                toast.success('Trial criado com sucesso.');
                close()
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar cadastrar trial')
        }
        setDisplay('none')

    }


    async function handleSubmit(event){
        event.preventDefault();
        if(dadosTrial.comoConheceu == 'Selecione um opção'){
            toast.warning('Por favor selecione uma opção de "Como conheceu"')
            return;
        }
        await cadastraTrial()
    }

    async function init(){
        try {
            const responseGetCanais = await axios.get('https://medx65-v65teste.azurewebsites.net/api/adm/getchannels');
            const {data} = responseGetCanais;
            const newArray = listaCanais.concat(data);
            setListaCanais(newArray)
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível cadastrar novo trial.')
        }
    }

    function changeRecaptch(event){
        console.log(event);
    }


  return <Box component={'form'} display={'flex'} flexDirection={'column'} minWidth={600} p={2} overflow={'hidden auto'} onSubmit={handleSubmit}>
            <TextField 
                label={'Nome'} 
                required 
                size={'small'} 
                fullWidth 
                sx={{mb:2}}
                placeholder='Informe o nome do cliente'
                value={dadosTrial.nome}
                onChange={(event) => {
                    setDadosTrial({ ...dadosTrial, nome: event.target.value });
                }}
            />
            <TextField 
                label={'Email'} 
                type='email'
                required 
                size={'small'} 
                sx={{mb:2}}
                value={dadosTrial.email}
                placeholder='Inform o email do cliente'
                onChange={(event) => {
                    setDadosTrial({ ...dadosTrial, email: event.target.value });
                }}
                inputProps={{
                    maxLength: 100,                    
                }}
            />
            <TextField 
                label={'Celular'} 
                required  
                size={'small'} 
                sx={{mb:2}} 
                title="ex: 21988887777"
                placeholder="Celular com DDD ex: 21984438518"
                inputProps={{
                    maxLength: 20,
                    'x-autocompletetype': 'tel',
                    pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                    autoComplete: 'tel',
                }}
                value={dadosTrial.celular}
                onBlur={(event) => {
                    setDadosTrial({ ...dadosTrial, celular: String(event.target.value).replace(/\s/g, '').replace(/\D/g, '') });
                }}
                onChange={(event) => {
                    setDadosTrial({ ...dadosTrial, celular: String(event.target.value).replace(/\s/g, '').replace(/\D/g, '') });
                }}
            />
            <FormControl required size="small" sx={{ mb: 2 }}>
                <InputLabel>Como conheceu</InputLabel>
                <Select
                    label={'Como conheceu'}
                    onChange={(event) => {
                        setDadosTrial({ ...dadosTrial, comoConheceu: event.target.value });
                    }}
                    value={dadosTrial.comoConheceu}
                >
                    {listaCanais.map((canal) => (
                        <MenuItem key={canal.id} value={canal.Canal}>{canal.Descricao}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box display={'flex'}>
                <Button sx={{flex:1}} type={'button'} onClick={()=>close()}>Cancelar</Button>
                <Button sx={{flex:1}} variant='contained' type='submit'>Criar</Button>
            </Box>
            <LoadingScreen display={display}/>
            <ReCAPTCHA
                ref={recaptchaRef}
                style={{ display: "inline-block" }}
                theme="dark"
                size="invisible"            
                sitekey={"6LehhcoaAAAAAM88Uc2_-GeZ9N-p_KKGe3PGrsvh"}                           
          />
        </Box>;
}