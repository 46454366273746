import React, { useContext } from 'react';
import Popup from 'reactjs-popup';
import { IconAiOutlineCloseCircle } from '../boletoEdit/StyledBoletoEdit';
import { Container } from './styledPopupDefault';
import { ThemeContext } from 'styled-components';
// import
const stylePopupContent = {
    width: ' 100%',
    height: ' 100%',
    display: ' flex',
    justifyContent: ' center',
    alignItems: ' center',
    backdropFilter: ' blur(4px)',
};

export default function PopupDefault({ open, close, children, largura, altura, zIndex, esc }) {
    const themeContext = useContext(ThemeContext);
    return (
        <Popup
            closeOnDocumentClick={false}
            onClose={close}
            open={open}
            contentStyle={stylePopupContent}
            overlayStyle={{ zIndex: zIndex ?? 999 }}
            closeOnEscape={esc ?? true}
        >
            <Container altura={altura} largura={largura}>
                {children}
                <IconAiOutlineCloseCircle onClick={close} color={themeContext.title === 'light' ? '#333' : '#fff'} />
            </Container>
        </Popup>
    );
}
