import styled from 'styled-components';

export const DivMainPopupListaMotivos = styled.div`
    padding: 12px;
    h1 {
        text-align: center;
    }
`;
export const DivTitulo = styled.div`
    margin: 0 0 12px 0;
`;
export const DivInfos = styled.div`
    margin: 12px 0;
    input {
        min-width: 300px;
    }
`;
export const PMotivos = styled.p`
    margin: 8px 0;
`;
