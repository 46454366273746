import styled from 'styled-components';

export const ContainerAluno = styled.div`
    display:flex;
    flex-direction:column;
    padding:20px;
`;
export const ContainerTitulo = styled.div`
    display:flex;
    align-items:center;
    >h1{
        margin:0 8px 0 0;
    }
`
export const ContainerDadosAlunoInfos = styled.div`
    display:flex;
    >div{
        display:flex;
        flex-direction:column;
        flex:1;
        >span{
            margin:8px 0;
        }
    }
`
export const ContainerPopupAddJornada = styled.div`
    display:flex;
    flex-direction:column;
    padding:0 20px 20px 20px;
    min-width:400px;
`

