import React from 'react';

// import { Container } from './styles';

function Dashboard() {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    );
}

export default Dashboard;
