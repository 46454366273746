import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Title } from '~/components/commonComponents/commonComponents';
import useApi from '~/service/api';
import { ButtonDelete, ButtonSave, Container, DivAlunoInfoContainer, DivAulaInfo, DivConteudoMaisDetalhes, DivConteudoMaisDetlhes, DivDetalhesResumidosCurso, DivJornadasDetalhes, DivPrecoAlias, DivPrincipalEdicaoCurso, DivTitulo, DivTituloCollapseMaisDetalhes, DivVoltar, IconMdExpandLess, IconMdExpandMore, IconMdKeyboardBackspace, JornadaContainer, MaisDetalhes, SpanTituloMaisDetalhes } from './styledGammyxCurso';
import MenuGammyx from '~/components/menuGammyx/menuGammyx'
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { DivCampoPopup } from '../cursos/styledGammyxCursos';
import { DivButton } from '~/components/boletoEdit/StyledBoletoEdit';
export default function GammyxCurso() {
    const api = useApi();
    const history = useHistory();
    const [cursoInfo, setCursoInfo] = useState({
        id: "",
        nome: "",
        lancamento: "1900-01-01",
        descricao: "",
        urlImagem: "",
        autorId: "",
        valor: "",
        alias: ""
    });
    const [aulaInfo, setAulaInfo] = useState({
        id: "",
        tipo: "",
        titulo: "",
        descricao: "",
        urlConteudo: "",
        urlImagemNoJogo: "",
        lancamento: "",
        posicaoX: "",
        posicaoY: "",
        posicaoJogadorX: "",
        posicaoJogadorY: "",
        idQualAulaLibera: "",
        idJornada: "",
        idAutor: "",
        dataCriacao: "",
        ordem: "",
        duracaoVideo: ""
    })
    const [listaAutores, setListaAutores] = useState([])
    const [mostraLoad, setMostraLoad] = useState(false);
    const [show, setShow] = useState([]);
    const [showDetalhesAulas, setShowDetalhesAulas] = useState([]);
    const [showDetalhesAlunos, setShowDetalhesAlunos] = useState([]);
    const [controllersPopups, setControllersPopups] = useState({
        editaCurso: false,
        insereAula: false
    })
    useEffect(() => {
        init();
    }, []);

    async function init() {
        await carregaCursoDetalhe(history.location.state.id)
        await carregaAutores();
    }
    async function carregaAutores() {
        setMostraLoad(true);
        try {
            const responseGetAutoresResumo = await api.get(`/gammyx/GetAutoresResumo`);
            if (responseGetAutoresResumo.status == 200) {
                setListaAutores(responseGetAutoresResumo.data);
            } else {
                toast.error('Erro ao carregar lista de autores.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de autores.')
        }
        setMostraLoad(false);
    }
    async function carregaCursoDetalhe(idCurso) {
        setMostraLoad(true)
        try {
            const responseGetCursoDetalhes = await api.get(`/gammyx/GetCursoDetalhes?cursoid=${idCurso}`);
            if (responseGetCursoDetalhes.status == 200) {
                let showOptions = responseGetCursoDetalhes.data.jornadasDetalhes.map(dados => false);
                setShow(showOptions);
                setShowDetalhesAulas(showOptions);
                setShowDetalhesAlunos(showOptions);
                setCursoInfo(responseGetCursoDetalhes.data);
            } else {
                toast.error('Erro ao carrega detalhes do curso.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar detalhes do curso. ')
        }
        setMostraLoad(false)
    }
    async function editarCurso() {
        setMostraLoad(true)
        try {
            const objEditaCurso = {
                id: cursoInfo.id,
                nome: cursoInfo.nome,
                lancamento: cursoInfo.lancamento,
                descricao: cursoInfo.descricao,
                urlImagem: cursoInfo.urlImagem,
                autorId: cursoInfo.autorId,
                valor: cursoInfo.valor,
                alias: cursoInfo.alias,
            }
            const responseEditaCurso = await api.put(`/gammyx/UpdateCurso`, objEditaCurso);
            if (responseEditaCurso.status == 200) {
                toast.success('Edição feita com sucesso. 👍🏽');
                setControllersPopups({ ...controllersPopups, editaCurso: false })
            } else {
                toast.error('Erro ao realizar edição. 🙁')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível editar o curso. 🙁')
        }
        setMostraLoad(false)
    }
    async function cadastrarNovaAula() {
        setMostraLoad(true)
        try {
            if (aulaInfo.id != "") {
                const responseUpdateAula = await api.put(`/gammyx/UpdateAula`, aulaInfo);
                if (responseUpdateAula.status == 200) {
                    toast.success('Aula atualizada com sucesso.');
                    fecharPopupInserirAula();
                    await carregaCursoDetalhe(history.location.state.id)
                } else {
                    toast.error('Erro ao atualizar aula.');
                }
            } else {
                let objNovaAula = {
                    tipo: aulaInfo.tipo,
                    titulo: aulaInfo.titulo,
                    descricao: aulaInfo.descricao,
                    urlConteudo: aulaInfo.urlConteudo,
                    urlImagemNoJogo: aulaInfo.urlImagemNoJogo,
                    lancamento: aulaInfo.lancamento,
                    posicaoX: aulaInfo.posicaoX,
                    posicaoY: aulaInfo.posicaoY,
                    posicaoJogadorX: aulaInfo.posicaoJogadorX,
                    posicaoJogadorY: aulaInfo.posicaoJogadorY,
                    idQualAulaLibera: aulaInfo.idQualAulaLibera,
                    idJornada: aulaInfo.idJornada,
                    idAutor: aulaInfo.idAutor,
                    dataCriacao: aulaInfo.dataCriacao,
                    ordem: aulaInfo.ordem,
                    duracaoVideo: aulaInfo.duracaoVideo
                }
                const responseCriarAula = await api.post(`/gammyx/InsertAula`, objNovaAula);
                if (responseCriarAula.status == 200) {
                    toast.success('Aula criada com sucesso.');
                    fecharPopupInserirAula();
                    await carregaCursoDetalhe(history.location.state.id)
                } else {
                    toast.error('Erro ao criar aula.');
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível cadastrar ou criar aula nova.');
        }
        setMostraLoad(false)
    }
    function mostraNomeAutor(autorId) {
        if (autorId) {
            const findAutor = listaAutores.find(autor => autor.id == autorId);
            return findAutor.nome;
        }
        return '';
    }
    function mostraDetalhes(indexElemento, array, fnSetNovoArray) {
        array[indexElemento] = !array[indexElemento];
        let newArray = array.map(item => item);
        fnSetNovoArray(newArray)
    }
    function fecharPopupInserirAula() {
        setAulaInfo({
            id: "",
            tipo: "",
            titulo: "",
            descricao: "",
            urlConteudo: "",
            urlImagemNoJogo: "",
            lancamento: "",
            posicaoX: "",
            posicaoY: "",
            posicaoJogadorX: "",
            posicaoJogadorY: "",
            idQualAulaLibera: "",
            idJornada: "",
            idAutor: "",
            dataCriacao: "",
            ordem: "",
            duracaoVideo: ""
        })
        setControllersPopups({ ...controllersPopups, insereAula: false })
    }
    function editarAula(aula) {
        setAulaInfo(aula);
        setControllersPopups({ ...controllersPopups, insereAula: true })
    }
    function adicionarAula(idJornada) {
        console.log(idJornada);
        setAulaInfo({ ...aulaInfo, idJornada: idJornada });
        setControllersPopups({ ...controllersPopups, insereAula: true });
    }
    return <Container>
        <MenuGammyx />
        <DivVoltar>
            <div onClick={() => history.goBack()}><IconMdKeyboardBackspace /> <span>voltar para lista de cursos</span></div>
        </DivVoltar>
        <DivTitulo>
            <Title>{cursoInfo.nome}</Title>
            <Button onClick={() => setControllersPopups({ ...controllersPopups, editaCurso: true })}>Editar informações do curso</Button>
        </DivTitulo>
        <DivDetalhesResumidosCurso>
            <div>
                <span>Data Lançamento : {dayjs(cursoInfo.lancamento).format("DD/MM/YYYY")}</span>
            </div>
            <div>
                <span>Autor : {listaAutores.length ? mostraNomeAutor(cursoInfo.autorId) : ''}</span>
            </div>
        </DivDetalhesResumidosCurso>
        <h3 style={{ margin: '0 0 16px 0' }}>Lista de jornadas do curso</h3>
        <DivJornadasDetalhes>
            {
                cursoInfo?.jornadasDetalhes?.length ?
                    cursoInfo.jornadasDetalhes.map((jornada, index) => (
                        <>
                            <JornadaContainer>
                                <div>{jornada.nome}</div>
                                <div>Quantidade de alunos: {jornada.alunosJornada?.length ?? 0}</div>
                                <div>Quantidade de aulas: {jornada.aulasJornada?.length ?? 0}</div>
                                <div>
                                    <span onClick={() => mostraDetalhes(index, show, setShow)}>
                                        {show[index] ? 'Menos' : 'Mais'} detalhes{show[index] ? <IconMdExpandLess /> : <IconMdExpandMore />}
                                    </span>
                                </div>
                            </JornadaContainer>
                            <MaisDetalhes mostrar={show[index]}>
                                <DivConteudoMaisDetalhes>
                                    <DivTituloCollapseMaisDetalhes>
                                        <SpanTituloMaisDetalhes>Aulas</SpanTituloMaisDetalhes>
                                        <div>
                                            <span onClick={() => mostraDetalhes(index, showDetalhesAulas, setShowDetalhesAulas)}>
                                                {showDetalhesAulas[index] ? 'Menos' : 'Mais'} detalhes{showDetalhesAulas[index] ? <IconMdExpandLess /> : <IconMdExpandMore />}
                                            </span>
                                        </div>
                                    </DivTituloCollapseMaisDetalhes>
                                    <DivConteudoMaisDetlhes mostrar={showDetalhesAulas[index]}>
                                        {
                                            jornada?.aulasJornada?.length ?
                                                jornada.aulasJornada.map(aula => (
                                                    <DivAulaInfo key={aula.id}>
                                                        <div>
                                                            <span>{aula.titulo}</span>
                                                        </div>
                                                        <div>
                                                            <Button onClick={() => editarAula(aula)}>Editar info</Button>
                                                        </div>
                                                    </DivAulaInfo>
                                                ))
                                                :
                                                ''
                                        }
                                    </DivConteudoMaisDetlhes>
                                    <DivTituloCollapseMaisDetalhes>
                                        <SpanTituloMaisDetalhes>Alunos</SpanTituloMaisDetalhes>
                                        <div>
                                            <span onClick={() => mostraDetalhes(index, showDetalhesAlunos, setShowDetalhesAlunos)}>
                                                {showDetalhesAlunos[index] ? 'Menos' : 'Mais'} detalhes{showDetalhesAlunos[index] ? <IconMdExpandLess /> : <IconMdExpandMore />}
                                            </span>
                                        </div>
                                    </DivTituloCollapseMaisDetalhes>
                                    <DivConteudoMaisDetlhes mostrar={showDetalhesAlunos[index]}>
                                        {
                                            jornada?.alunosJornada?.length ?
                                                jornada.alunosJornada.map(aluno => (
                                                    <DivAlunoInfoContainer key={aluno.Id}>
                                                        <div>
                                                            <span>{aluno.Nome}</span>
                                                        </div>
                                                        <div>
                                                            <span>{aluno.Celular}</span>
                                                        </div>
                                                        <div>
                                                            <span>{aluno.Email}</span>
                                                        </div>
                                                    </DivAlunoInfoContainer>
                                                ))
                                                :
                                                ''
                                        }
                                    </DivConteudoMaisDetlhes>
                                </DivConteudoMaisDetalhes>
                                <div style={{ display: 'flex' }}>
                                    <Button style={{ flex: 1 }} onClick={() => adicionarAula(jornada.id)}>
                                        Adicionar aulas
                                    </Button>
                                </div>
                            </MaisDetalhes>
                        </>
                    ))
                    : ""
            }
        </DivJornadasDetalhes>
        {mostraLoad && <LoadingScreen display={'flex'} />}
        <PopupDefault
            open={controllersPopups.editaCurso}
            close={() => setControllersPopups({ ...controllersPopups, editaCurso: false })}
            esc={false}
            children={<DivPrincipalEdicaoCurso>
                <h1>Edição do curso</h1>
                <div>
                    <DivCampoPopup>
                        <span>Nome:</span>
                        <input
                            type="text"
                            value={cursoInfo.nome}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, nome: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Data de Lançamento:</span>
                        <input
                            type="date"
                            max={'9999-01-01'}
                            min={'1900-01-01'}
                            value={dayjs(cursoInfo.lancamento).format('YYYY-MM-DD')}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, lancamento: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Link da Imagem:</span>
                        <input
                            type="text"
                            value={cursoInfo.urlImagem}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, urlImagem: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Preço:</span>
                        <input
                            type="number"
                            step={0.1}
                            value={cursoInfo.valor}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, valor: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Alias:</span>
                        <input
                            type="text"
                            value={cursoInfo.alias}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, alias: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Autor:</span>
                        <select
                            value={cursoInfo.autorId}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, autorId: target.value })}
                        >
                            {
                                listaAutores?.length ?
                                    listaAutores.map(autor => <option value={autor.id}>{autor.nome}</option>)
                                    :
                                    ''
                            }
                        </select>
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Descrição:</span>
                        <textarea
                            style={{ flex: 1, margin: '8px 0px 8px -2px', fontSize: '16px', resize: 'none' }}
                            value={cursoInfo.descricao}
                            rows={8}
                            onChange={({ target }) => setCursoInfo({ ...cursoInfo, descricao: target.value })}
                        />
                    </DivCampoPopup>
                </div>
                <DivButton style={{ margin: '16px 0 0 0' }}>
                    <ButtonDelete className='deletar' onClick={() => setControllersPopups({ ...controllersPopups, editaCurso: false })}>Cancelar</ButtonDelete>
                    <ButtonSave className='salvar' onClick={() => editarCurso()} >Salvar</ButtonSave>
                </DivButton>
            </DivPrincipalEdicaoCurso>}
        />
        <PopupDefault
            open={controllersPopups.insereAula}
            close={fecharPopupInserirAula}
            esc={false}
            children={<DivPrincipalEdicaoCurso>
                <h1>Nova aula</h1>
                <div>
                    <DivCampoPopup>
                        <span>Título:</span>
                        <input
                            type="text"
                            value={aulaInfo.titulo}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, titulo: target.value })}
                        />
                    </DivCampoPopup>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ flex: '1', marginRight: '16px' }}>
                            <span>Data de Lançamento:</span>
                            <input
                                type="date"
                                max={'9999-01-01'}
                                min={'1900-01-01'}
                                value={dayjs(aulaInfo.lancamento).format('YYYY-MM-DD')}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, lancamento: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup style={{ flex: '1', marginRight: '16px' }}>
                            <span>Ordem:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.ordem}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, ordem: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup style={{ flex: '1' }}>
                            <span>Tipo:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.tipo}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, tipo: target.value })}
                            />
                        </DivCampoPopup>
                    </div>
                    <DivCampoPopup>
                        <span>Link do conteúdo:</span>
                        <input
                            type="text"
                            value={aulaInfo.urlConteudo}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, urlConteudo: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Link da imagem no jogo:</span>
                        <input
                            type="text"
                            value={aulaInfo.urlImagemNoJogo}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, urlImagemNoJogo: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Id da proxima aula:</span>
                        <input
                            type="text"
                            value={aulaInfo.idQualAulaLibera}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, idQualAulaLibera: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Autor:</span>
                        <select
                            value={aulaInfo.idAutor}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, idAutor: target.value })}
                        >
                            <option value={""}>Selecione o autor</option>
                            {
                                listaAutores?.length ?
                                    listaAutores.map(autor => <option value={autor.id}>{autor.nome}</option>)
                                    :
                                    ''
                            }
                        </select>
                    </DivCampoPopup>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ flex: '1', marginRight: '16px' }}>
                            <span>Posição X:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.posicaoX}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, posicaoX: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup style={{ flex: '1', marginRight: '16px' }}>
                            <span>Posição Y:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.posicaoY}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, posicaoY: target.value })}
                            />
                        </DivCampoPopup>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ flex: '1', marginRight: '16px' }}>
                            <span>Posição Jogador X:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.posicaoJogadorX}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, posicaoJogadorX: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup style={{ flex: '1' }}>
                            <span>Posição Jogador Y:</span>
                            <input
                                type="number"
                                min={'0'}
                                value={aulaInfo.posicaoJogadorY}
                                onChange={({ target }) => setAulaInfo({ ...aulaInfo, posicaoJogadorY: target.value })}
                            />
                        </DivCampoPopup>
                    </div>
                    <DivCampoPopup>
                        <span>Descrição:</span>
                        <textarea
                            style={{ flex: 1, margin: '8px 0px 8px -2px', fontSize: '16px', resize: 'none', padding: '16px' }}
                            value={aulaInfo.descricao}
                            rows={6}
                            onChange={({ target }) => setAulaInfo({ ...aulaInfo, descricao: target.value })}
                        />
                    </DivCampoPopup>
                </div>
                <DivButton style={{ margin: '16px 0 0 0' }}>
                    <ButtonDelete className='deletar' onClick={fecharPopupInserirAula}>Cancelar</ButtonDelete>
                    <ButtonSave className='salvar' onClick={() => cadastrarNovaAula()} >Salvar</ButtonSave>
                </DivButton>
            </DivPrincipalEdicaoCurso>}
        />
    </Container>;
}