import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding:16px;
  height:100%;
  width:100%;
`;
export const DivBusca = styled.div`
    display:flex;
    >input{
        margin: 5px 0;
    }
`
export const DivTabela = styled.div`
    cursor:default;
    display:flex;
    overflow:hidden auto;
    width:100%;
    height:100%;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: content;
    table {
        width: 100%;
        thead {
            height: 60px;
            tr {
                background: ${(p) => p.theme.colors.background};
                position: sticky;
                top: 0;
                th {
                    text-align: start;
                    vertical-align: middle;
                    min-width: 80px;
                }
            }
        };
        tbody {
            tr {
                height: 30px;
                vertical-align: middle;
                transition: 0.5s;
                td {
                    vertical-align: middle;
                }
                :hover {
                    background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`
export const DivBotao = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin:12px 0;
`
export const FormNovoAluno = styled.form`
    display:flex;
    flex-direction:column;
    padding:0 20px 20px 20px;
   
`
export const DivTituloForm = styled.div`
    text-align:center;
    margin:0 0 24px 0;
`
export const DivConteudoForm = styled.div`

`
export const CamposForm = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    min-width:400px;
    >input{
        min-width:400px;
    }
`
export const SpanCampoForm = styled.span`
    margin:0 12px 0 0 ;
    min-width:60px;
`