import {useRef, useState,useEffect, memo} from 'react'
import { Autocomplete, FormControl, InputLabel, TextField,Select as SelectMui, MenuItem, Dialog, DialogTitle, DialogContent, Button, DialogActions, Box, FormGroup, FormControlLabel, Checkbox, OutlinedInput, InputAdornment, Popover, Typography, Grid,List, ListItem, ListItemText, FormHelperText, ListItemButton } from "@mui/material";
import { BoxMain } from './styledFormNovoBoleto';
import LoadingScreen from '../loadScreen/Loadscreen';
import useApi from '~/service/api';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

 

const InputForm = (props)=>{
    
    return <TextField {...props} size="small" focused inputProps={{...props.inputProps,style:{boxShadow:'none'}}}  />

}

export default function FormNovoBoleto({open,fnClose,infoCliente,atualizar}){
    const refFormBoleto = useRef();
    const api = useApi();
    const theme = useTheme();
    const [dadosBoleto, setDadosBoleto] = useState({
        nomeCliente:'',
        Codigo_do_Cliente:0,
        referente:'',
        referente_a:'',
        valor:'',
        emissao:dayjs(new Date()).format('YYYY-MM-DD'),
        vencimento:'1900-01-01',
        classificacao:0,
        Envia_Boleto:-1
    });
    const [loading, setLoading] = useState('none');    
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElReferenteA, setAnchorElReferenteA] = useState(null);
    const [anchorElReferenteA1, setAnchorElReferenteA1] = useState(null);

    const [listaCliente, setListaCliente] = useState([]);
    const [errorClassificacao, setErrorClassificacao] = useState(false);
    const [openConfirmBoleto, setOpenConfirmBoleto] = useState(false);
    const [listaReferenteA, setListaReferenteA] = useState([]);
    const [listaReferenteA1, setListaReferenteA1] = useState([]);
    
    const openPopover = Boolean(anchorEl);
   
    useEffect(()=>{
        init();
        if(infoCliente){
            setDadosBoleto({...dadosBoleto,
                nomeCliente:infoCliente.Nome,
                Codigo_do_Cliente:infoCliente.Codigo_do_Cliente
            })
        }
    },[])

    const formataCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    async function procuraCliente(name) {
        setLoading('flex');
        try {
            const data = await api.get(`/cliente/GetClientById?Id=${name}`);
            if (data.status === 200) {                
                setListaCliente(data.data);
            }
        } catch (error) {
            if (error.response.status == 404) {
                toast.warning('Nenhum paciente foi encontrado.');
            } else {
                console.error(error);
                toast.error('Não foi possível buscar pelo cliente');
            }
        }
        setLoading('none');
    }
    
    async function buscaCliente(event){
        try {            
            if(event.key == "Enter"){
                event.preventDefault();
                await procuraCliente(event.target.value)
                setAnchorEl(event.target);          
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar realizar busca pelo cliente.')
        }
    }

    function selecionaCliente(dadosCliente){
        setDadosBoleto({...dadosBoleto,Codigo_do_Cliente:dadosCliente.Codigo_do_Cliente,nomeCliente:dadosCliente.Nome});
        
        setListaCliente([]);
        setAnchorEl(null)
    }

    function submitHandle(event){
        event.preventDefault()
        if(!dadosBoleto.classificacao){
            setErrorClassificacao(true)
            return;
        }          
        setOpenConfirmBoleto(true); 
    }

    function classificacaoHandleSubmit(event){
        setDadosBoleto({ ...dadosBoleto, classificacao: event.target.value })
        if(errorClassificacao){
            setErrorClassificacao(false)
        }
    }

    async function cadastrarNovoBoleto(){
        setLoading('flex');
        try {            
            const objDadosNovoBoleto = {
                Id_do_Cliente: dadosBoleto.Codigo_do_Cliente,
                Referente_a: dadosBoleto.referente,
                Referente_a_1:dadosBoleto.referente_a,
                Emissao: dadosBoleto.emissao,
                Vencimento: dadosBoleto.vencimento,
                Classificacao: dadosBoleto.classificacao,
                Valor: dadosBoleto.valor,
                Envia_Boleto: dadosBoleto.Envia_Boleto,
            }
            await api.post('/boleto/CadastrarNovoBoleto',objDadosNovoBoleto)
            setOpenConfirmBoleto(false);
            fnClose();
            atualizar()
            toast.success('Boleto criado com sucesso.');
        } catch (error) {
            console.error(error);
            toast.error('Error ao tentar cadastrar novo boleto.')            
        }
        setLoading('none')
    }

    function cancelarCadastroNovoBoleto(){
        setOpenConfirmBoleto(false)
    }

    function handleClickCheckbox(){
        if(dadosBoleto.Envia_Boleto == -1){
            setDadosBoleto({...dadosBoleto,Envia_Boleto:0});
            return
        }
        if(dadosBoleto.Envia_Boleto == 0){
            setDadosBoleto({...dadosBoleto,Envia_Boleto:-1});
            return
        }
        setDadosBoleto({...dadosBoleto,Envia_Boleto:-1});
            
    }

    async function carregaReferenteA() {
        setLoading('flex');
        try {
            const resultCarregaReferenteA = await api.get(`/boleto/CarregaReferenteA`);
            if (resultCarregaReferenteA.status == 200) {
                setListaReferenteA(resultCarregaReferenteA.data);
            } else {
                toast.error('Erro ao carregar lista referente_A');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de referente_A');
        }
        setLoading('none');
    }

    async function carregaReferenteA1() {
        setLoading('flex');
        try {
            const resultCarregaReferenteA1 = await api.get(`/boleto/CarregaReferenteA1`);
            if (resultCarregaReferenteA1.status == 200) {
                setListaReferenteA1(resultCarregaReferenteA1.data);
            } else {
                toast.error('Erro ao carregar lista referente_A');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de referente_A');
        }
        setLoading('none');
    }

    async function init(){
        try {
            setLoading('flex')
            await Promise.all([carregaReferenteA(),carregaReferenteA1()])
            setLoading('none')
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar incializar componente')
        }
    }
return ( 
    <Dialog
        open={open}        
        PaperProps={{sx:{background:theme.colors.background,minWidth:800,maxWidth:'unset'}}}
    >
        <DialogTitle textAlign={'center'}>Novo Boleto</DialogTitle>
        <DialogContent>
            <BoxMain component={'form'} p={2} sx={{width:'calc(100% - 32px)'}} ref={refFormBoleto} onSubmit={submitHandle}>
                <InputForm label={'Cliente'}
                    required  
                    sx={{mb:2}}
                    value={dadosBoleto.nomeCliente}
                    placeholder='Informe o nome do cliente.'                    
                    onKeyDown={(event)=>buscaCliente(event)}
                    onChange = {(event) => setDadosBoleto({...dadosBoleto,nomeCliente:event.target.value})}
                    disabled={infoCliente ? true : false}
                />               
                <Popover
                    id={'listaClientes'}
                    open={openPopover}
                    anchorEl={anchorEl}
                    //onClose={()=>setAnchorEl(null)}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}                   
                >
                    <List dense={false}>
                        {
                            listaCliente.map(cliente =>(
                                <ListItem onClick={()=>selecionaCliente(cliente)} >
                                    <ListItemText
                                        primary={cliente.Nome}
                                        secondary={cliente.Email}                                     
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                </Popover>                
                <Box display={'flex'} width={'100%'}>                
                    <InputForm label={'Referente'} 
                        required
                        value={dadosBoleto.referente}
                        onClick={({currentTarget})=>setAnchorElReferenteA(currentTarget)}
                        onChange={({target})=>setDadosBoleto({...dadosBoleto,referente:target.value})}
                        sx={{mb:2,flex:1,mr:1}}
                        placeholder='Coloque uma breve descrição'
                    />
                    <InputForm label={'Referente_a'}  
                        required
                        value={dadosBoleto.referente_a} 
                        onClick={({currentTarget})=>setAnchorElReferenteA1(currentTarget)}
                        onChange={({target})=>setDadosBoleto({...dadosBoleto,referente_a:target.value})}
                        sx={{mb:2,flex:1}}
                        placeholder='Continue a breve descrição'
                    />
                </Box>
                <InputForm label={'Emissão'}
                    required
                    value={dadosBoleto.emissao}                     
                    sx={{mb:2}}
                    type={'date'}
                    disabled
                />
                <InputForm label={'Vencimento'}
                    required
                    value={dadosBoleto.vencimento}
                    onChange={({target})=>setDadosBoleto({...dadosBoleto,vencimento:target.value})}
                    inputProps={{ min:dadosBoleto.emissao, max:'2999-01-01'}}
                    sx={{mb:2}}
                    type={'date'}
                />
                <FormGroup sx={{mb:2}}>
                    <FormControlLabel 
                        control={<Checkbox defaultChecked={dadosBoleto.Envia_Boleto == -1 ? true : false} onClick={handleClickCheckbox}/>} 
                        label="Envia boleto" 
                    />
                </FormGroup>
                <TextField
                    type="number"
                    size="small"
                    focused
                    variant="outlined"
                    label={'Valor'}
                    required
                    sx={{ width: '100%',mb:2 }}
                    value={dadosBoleto.valor}
                    inputProps={{ min: 0,step:0.01 }}
                    onChange={({target}) => setDadosBoleto({...dadosBoleto,valor:target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                />
                <FormControl fullWidth focused sx={{mb:2}} required error={errorClassificacao}>
                    <InputLabel id="classificacao">Classificação</InputLabel>
                    <SelectMui                                
                        size='small'
                        labelId="classificacao"
                        id="classificacao"                            
                        label="Classificação"
                        required
                        value={dadosBoleto.classificacao}
                        onChange={classificacaoHandleSubmit}                        
                        defaultValue={0}
                        SelectDisplayProps={{style:{color:theme.colors.text}}}
                        inputProps={{required:true}}
                        prop={{required:true}}
                    >   
                        <MenuItem></MenuItem>
                        <MenuItem value="1">Ativação</MenuItem>
                        <MenuItem value="2">Renovação</MenuItem>
                        <MenuItem value="3">Consultoria</MenuItem>
                        <MenuItem value="4">SMS</MenuItem>
                        <MenuItem value="5">Medx social</MenuItem>
                        <MenuItem value="6">Prescreva</MenuItem>
                        <MenuItem value="7">Eventos/Cursos</MenuItem>
                        <MenuItem value="8">Docflix</MenuItem>                    
                        <MenuItem value="9">Evolução Inteligente</MenuItem>                    
                        <MenuItem value="10">IA</MenuItem>                    
                        <MenuItem value="11">Whatsapp</MenuItem>                    
                    </SelectMui>
                    {errorClassificacao && <FormHelperText>Campo obrigatório</FormHelperText>}
                </FormControl>  
                <Box display={'flex'}>
                <Button sx={{flex:1,mr:1}} type='button' onClick={fnClose} >Cancelar</Button>
                <Button sx={{flex:1}} variant='contained' type='submit'>Cadastrar</Button>
                </Box>
            </BoxMain>  
        </DialogContent>                
        <Dialog open={openConfirmBoleto}>
            <DialogContent>
                <Typography>Confirma a criação do boleto no valor de {formataCurrency.format(String(dadosBoleto.valor).replace(/,/g, '.'))} ?</Typography>
            </DialogContent>
            <DialogActions>
                <Button sx={{flex:1}} onClick={cancelarCadastroNovoBoleto}>Cancelar</Button>
                <Button sx={{flex:1}} variant='contained' onClick={cadastrarNovoBoleto}>Confirmar</Button>
            </DialogActions>
        </Dialog>
        <Popover
            id={'listaReferenteA'}
            // open={anchorEl!= null}
            open = {anchorElReferenteA != null}
            onClose={()=>setAnchorElReferenteA(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            anchorEl={anchorElReferenteA}            
        >
            <Box p={1}>
                <TextField fullWidth variant='filled' size='small' value={dadosBoleto.referente} onChange={({target})=>setDadosBoleto({...dadosBoleto,referente:target.value})} />
                <List>
                    {
                        listaReferenteA.slice(0,100)
                            .filter(x=> String(x.ReferenteA).toLowerCase().includes(String(dadosBoleto.referente).toLowerCase()))
                            .map(referenteAInfo => (
                            <ListItemButton onClick={()=>{setDadosBoleto({...dadosBoleto,referente:referenteAInfo.ReferenteA});setAnchorElReferenteA(null)}}>
                                <ListItemText>{referenteAInfo.ReferenteA}</ListItemText>
                            </ListItemButton>
                            )
                        )
                    }                    
                </List>
            </Box>
        </Popover>
        <Popover
            id={'listaReferenteA1'}            
            open = {anchorElReferenteA1 != null}
            onClose={()=>setAnchorElReferenteA1(null)}
            anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
            transformOrigin={{vertical: 'top',horizontal: 'left'}}
            anchorEl={anchorElReferenteA1}                                            
        >
            <Box p={1}>
                <TextField fullWidth variant='filled' size='small' 
                    onChange={({target})=>setDadosBoleto({...dadosBoleto,referente_a:target.value})} 
                />
                <List>
                    {
                        listaReferenteA1.slice(0, 100)
                            .filter(x=> String(x.ReferenteA).toLowerCase().includes(String(dadosBoleto.referente_a).toLowerCase()))
                            .map(referenteAInfo => (
                            <ListItemButton onClick={()=>{
                                setDadosBoleto({...dadosBoleto,referente_a:referenteAInfo.ReferenteA});
                                setAnchorElReferenteA1(null);
                                }
                            }>
                                <ListItemText>{referenteAInfo.ReferenteA}</ListItemText>
                            </ListItemButton>
                            )
                        )
                    }                    
                </List>
            </Box>
        </Popover>
        <LoadingScreen display={loading} />
    </Dialog>
)}