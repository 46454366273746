import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DivButton,
    DivContentElements,
    DivLeftBoletoEdit,
    DivRightBoletoEdit,
    IconAiOutlineCloseCircle,
} from '../boletoEdit/StyledBoletoEdit';
import { Button, Title } from '../commonComponents/commonComponents';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '../loadScreen/Loadscreen';
import { DivListClientes, DivMainNovaFatura, DivTitleBoleto, Select } from './StyledNovaFaturaPacerlamento';

export default function NovaFatura({ close, clientInfo }) {
    const [loadScreen, setLoadScreen] = useState('none');
    const [boletoInfo, setBoletoInfo] = useState({
        Id_do_Cliente: '0',
        Classificacao: 0,
        Emissao: dayjs(new Date()).format('YYYY-MM-DD'),
        Envia_Boleto: -1,
        Vencimento: '1900-01-01',
        Referente_a: '',
        Referente_a_1: '',
        Valor: '0',
        parcelas: '1',
    });
    const [valorEntrada, setValorEntrada] = useState(0);
    const [searchCliente, setSearchCliente] = useState([]);
    const [hideDisplay, setHideDisplay] = useState(true);
    const [isDesconto, setIsDesconto] = useState(false);
    const maxWidthInput = { maxWidth: '170px' };
    const api = useApi();
    const refParcelas = useRef(0);
    const refInputName = useRef();
    const refValorEntrada = useRef(0);
    const refSelectPlano = useRef(1);
    const refValor = useRef(0);

    async function createFatura() {
        try {
            if (boletoInfo.Classificacao == 0) {
                toast.info('Preencha o campo classificação, por favor.');
                return null;
            }
            if (boletoInfo.Id_do_Cliente == 0) {
                toast.info('Seleciona um cliente, por favor.');
                return null;
            }
            if (boletoInfo.Vencimento == '1900-01-01') {
                toast.info('Informe a data de vencimento por favor.');
                return null;
            }
            if (boletoInfo.Valor == '0') {
                toast.info('Informe o valor por favor.');
                return null;
            }
            if (boletoInfo.Referente_a == '' || boletoInfo.Referente_a_1 == '') {
                toast.info('Verifique os campos de descrição do boleto, por favor.');
                return null;
            }
            if (refParcelas.current.value == 0) {
                toast.info('Informe a quantidade de parcelas, por favor.');
                return null;
            }
            if (refParcelas.current.value == 6 && refValorEntrada.current.value <= 0) {
                toast.warning('Não é possível realizar a criação da fatura.');
                return null;
            }
            setLoadScreen('flex');
            if (refParcelas.current.value == 6 && refValorEntrada.current.value > 0) {
                const novoBoleto = { ...boletoInfo, Valor: refValorEntrada.current.value };
                const parcelasRestantes = {
                    ...boletoInfo,
                    Vencimento: dayjs(boletoInfo.Vencimento).add(1, 'month').format('YYYY-MM-DD'),
                    Valor: boletoInfo.Valor - refValorEntrada.current.value,
                    parcelas: refParcelas.current.value - 1,
                };
                const entrada = await api.post(`/boleto/InsertBoleto`, novoBoleto);
                const parcelamento = await api.post(`/boleto/InsertParcelamento`, parcelasRestantes);
                if (entrada.status == 200 && parcelamento.status == 200) {
                    toast.success('Faturas criadas com sucesso.');
                    setLoadScreen('none');
                    close(false);
                    return;
                }
                if (entrada.status != 200) {
                    toast.error('Não foi possível criar boleto com valor de entrada');
                    setLoadScreen('none');
                    return;
                }
                if (parcelamento.status != 200) {
                    toast.error('Não foi possível criar boletos do parcelamento');
                    setLoadScreen('none');
                    return;
                }
            }
            if (refParcelas.current.value > 1) {
                const data = await api.post(`/boleto/InsertParcelamento`, boletoInfo);
                if (data.status == 200) {
                    setLoadScreen('none');
                    close(false);
                    toast.success('Fatura criada com sucesso.');
                } else {
                    toast.error('Não foi possível criar fatura.');
                    setLoadScreen('none');
                }
            }
            if (refParcelas.current.value == 1) {
                delete boletoInfo.parcelas;
                const data = await api.post(`/boleto/InsertBoleto`, boletoInfo);
                if (data.status == 200) {
                    toast.success('Fatura criada com sucesso.');
                    setLoadScreen('none');
                    close(false);
                } else {
                    toast.error('Não foi possível criar fatura.');
                    setLoadScreen('none');
                }
                return null;
            }
        } catch (error) {
            setLoadScreen('none');
            console.log(error);
            toast.error('Não foi possível criar a fatura');
        }
    }
    async function findCliente(name) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/cliente/GetClientById?Id=${name}`);
            if (data.status === 200) {
                setLoadScreen('none');
                setHideDisplay(false);
                setSearchCliente(data.data);
            }
        } catch (error) {
            setLoadScreen('none');
            console.log(error);
            toast.error('Não foi possível buscar pelo cliente');
        }
    }
    async function getNameFromClientScreen(clientInfo) {
        try {
            const { Nome, Codigo_do_Cliente } = clientInfo;
            refInputName.current.value = Nome;
            setBoletoInfo({ ...boletoInfo, Id_do_Cliente: Codigo_do_Cliente });
        } catch (error) {
            setLoadScreen('none');
            toast.error('Não foi possível carregar o nome dos pacientes.');
        }
    }
    function selectName(item) {
        refInputName.current.value = item.Nome;
        setBoletoInfo({ ...boletoInfo, Id_do_Cliente: item.Codigo_do_Cliente });
        setHideDisplay(true);
        setSearchCliente([]);
    }

    function verificaPesquisa() {
        if (searchCliente.length > 0) {
            setHideDisplay(false);
        }
    }

    function carregaValorPlano(value) {
        try {
            if (value == 1) {
                setBoletoInfo({ ...boletoInfo, Valor: 4500 });
                refValor.current.value = 4500;
                refSelectPlano.current.value = 1;
                return;
            } else if (value == 2) {
                setBoletoInfo({ ...boletoInfo, Valor: 3000 });
                refValor.current.value = 3000;
                refSelectPlano.current.value = 2;
                return;
            } else if (value == 3) {
                setBoletoInfo({ ...boletoInfo, Valor: 2500 });
                refValor.current.value = 2500;
                refSelectPlano.current.value = 3;
                return;
            } else if (value == 4) {
                setBoletoInfo({ ...boletoInfo, Valor: 1200 });
                refValor.current.value = 1200;
                refSelectPlano.current.value = 4;
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }

    function alteraValorEntrada(value) {
        try {
            refValorEntrada.current.value = value;
            setValorEntrada(value);
        } catch (error) {
            console.log(error);
        }
    }

    function descontoValor() {
        try {
            if (refValor.current.value == 0 && isDesconto) {
                toast.info('Selecione o plano ou informe um valor.');
                setIsDesconto(false);
                return;
            }
            if (isDesconto == true) {
                refValor.current.value = refValor.current.value - (refValor.current.value * 10) / 100;
                setBoletoInfo({ ...boletoInfo, Valor: refValor.current.value, parcelas: 1 });
                refParcelas.current.value = 1;
            } else {
                carregaValorPlano(refSelectPlano.current.value);
                refParcelas.current.value = 1;
            }
        } catch (error) {
            console.log(error);
        }
    }

    function carregaValorParcelas(numeroParcelas) {
        try {
            if (numeroParcelas == 6) {
                refValorEntrada.current.value = 1000;
            } else {
                refValorEntrada.current.value = 0;
            }
            setBoletoInfo({
                ...boletoInfo,
                parcelas: numeroParcelas,
            });
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar valor das parcelas.');
        }
    }

    useEffect(() => {
        if (clientInfo) {
            getNameFromClientScreen(clientInfo);
        }
        descontoValor();
    }, [isDesconto]);

    return (
        <DivMainNovaFatura>
            <DivTitleBoleto>
                <Title>Novo Boleto</Title>
            </DivTitleBoleto>
            <div style={{ width: '100%' }}>
                <DivContentElements style={{ position: 'relative', height: '40px' }}>
                    <span>Cliente:</span>
                    <input
                        ref={refInputName}
                        type="text"
                        placeholder="Informe o nome do cliente"
                        onKeyPress={(event) => (event.key === 'Enter' ? findCliente(event.currentTarget.value) : '')}
                        onClick={verificaPesquisa}
                    />
                    <DivListClientes hide={hideDisplay} onMouseLeave={() => setHideDisplay(true)}>
                        {searchCliente.map((item) => (
                            <div onClick={() => selectName(item)}>{item.Nome}</div>
                        ))}
                    </DivListClientes>
                </DivContentElements>
                <DivContentElements>
                    <span>Plano:</span>
                    <Select
                        ref={refSelectPlano}
                        style={{ height: '30px', cursor: 'pointer' }}
                        onChange={(event) => carregaValorPlano(event.target.value)}
                    >
                        <option> </option>
                        <option value="1">FULL</option>
                        <option value="2">SMALL</option>
                        <option value="3">INDIVIDUAL</option>
                        <option value="4">NUTRICIONISTA</option>
                    </Select>
                </DivContentElements>
                <DivContentElements>
                    <div>
                        <span>Pagamento à vista 10% de desconto.</span>
                    </div>
                    <div style={{ width: '18px', alignItems: 'center', display: 'flex' }}>
                        <input
                            type="checkbox"
                            defaultValue={isDesconto}
                            checked={isDesconto ? true : false}
                            disabled={refParcelas.current.value > 1 ? true : false}
                            style={{ margin: '0', cursor: 'pointer' }}
                            onChange={() => setIsDesconto(!isDesconto)}
                        />
                    </div>
                </DivContentElements>
                <div style={{ display: 'flex' }}>
                    <DivLeftBoletoEdit>
                        <DivContentElements>
                            <span>Referente_a:</span>
                            <input
                                style={{ minWidth: '300px' }}
                                type="text"
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Referente_a: event.target.value })}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Valor Total:</span>
                            <input
                                ref={refValor}
                                style={maxWidthInput}
                                defaultValue={boletoInfo?.Valor}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Valor: event.target.value })}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Valor da Primeira Parcela:</span>
                            <input
                                style={maxWidthInput}
                                defaultValue={0}
                                ref={refValorEntrada}
                                disabled={boletoInfo.parcelas == '6' ? false : true}
                                onChange={(event) => alteraValorEntrada(event.target.value)}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Emissão :</span>
                            <input
                                style={maxWidthInput}
                                type="date"
                                defaultValue={dayjs(new Date()).format('YYYY-MM-DD')}
                                onChange={(event) =>
                                    setBoletoInfo({
                                        ...boletoInfo,
                                        Emissao: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                    })
                                }
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>RPS</span>
                            <input
                                style={maxWidthInput}
                                type="text"
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, RPS: event.target.value })}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>NFE</span>
                            <input
                                style={maxWidthInput}
                                type="text"
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, NFE: event.target.value })}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Classificação</span>
                            <Select
                                value={boletoInfo?.Classificacao}
                                style={{ height: '30px', cursor: 'pointer' }}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Classificacao: event.target.value })}
                            >
                                <option> </option>
                                <option value="1">Ativação</option>
                                <option value="2">Renovação</option>
                                <option value="3">Consultoria</option>
                                <option value="4">SMS</option>
                                <option value="5">Medx social</option>
                                <option value="6">Prescreva</option>
                                <option value="7">Eventos/Cursos</option>
                                <option value="8">Docflix</option>
                            </Select>
                        </DivContentElements>
                    </DivLeftBoletoEdit>
                    <DivRightBoletoEdit>
                        <DivContentElements>
                            <span>Referente_a_1:</span>
                            <input
                                style={{ minWidth: '300px' }}
                                type="text"
                                defaultValue={boletoInfo?.Referente_a_1}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Referente_a_1: event.target.value })}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Nº de Parcelas:</span>
                            <input
                                style={maxWidthInput}
                                ref={refParcelas}
                                type="number"
                                max="6"
                                min="1"
                                defaultValue="1"
                                disabled={isDesconto ? true : false}
                                onChange={(event) => carregaValorParcelas(event.target.value)}
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Vencimento:</span>
                            <input
                                style={maxWidthInput}
                                type="date"
                                defaultValue={boletoInfo.Vencimento}
                                onChange={(event) =>
                                    setBoletoInfo({
                                        ...boletoInfo,
                                        Vencimento: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                    })
                                }
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>RPS Data</span>
                            <input
                                style={maxWidthInput}
                                type="date"
                                defaultValue={null}
                                onChange={(event) =>
                                    setBoletoInfo({
                                        ...boletoInfo,
                                        RPS_Data: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                    })
                                }
                            />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Plano Mensal</span>
                            <input style={maxWidthInput} type="text" />
                        </DivContentElements>
                        <DivContentElements>
                            <span>Enviar Boleto</span>
                            <input
                                style={{ width: '18px', cursor: 'pointer' }}
                                type="checkbox"
                                checked={boletoInfo?.Envia_Boleto == -1 ? true : false}
                                onClick={(event) => {
                                    setBoletoInfo({ ...boletoInfo, Envia_Boleto: event.target.checked ? -1 : 0 });
                                }}
                                onChange={(event) =>
                                    setBoletoInfo({ ...boletoInfo, Envia_Boleto: event.target.checked ? -1 : 0 })
                                }
                            />
                        </DivContentElements>
                    </DivRightBoletoEdit>
                </div>
            </div>
            <div style={{ cursor: 'default' }}>
                <div style={{ margin: '0 0 10px 0' }}>
                    <span>Resumo da fatura:</span>
                </div>
                <div style={{ margin: '0 0 10px 0' }}>
                    <span>
                        Valor Total: {boletoInfo.Valor}
                        {refValorEntrada.current.value != 0 && refParcelas.current.value == 6 ? (
                            <span>
                                <br /> Entrada de: {refValorEntrada.current.value} +{' '}
                            </span>
                        ) : (
                            <br />
                        )}
                        {refParcelas.current.value > 1
                            ? refParcelas.current.value == 6
                                ? ` ${refParcelas.current.value - 1} parcelas de ${
                                      (boletoInfo.Valor - refValorEntrada.current.value) / (refParcelas.current.value - 1)
                                  }`
                                : ` ${refParcelas.current.value} parcelas de ${
                                      (boletoInfo.Valor - refValorEntrada.current.value) / refParcelas.current.value
                                  }`
                            : ''}
                    </span>
                </div>
            </div>
            <DivButton>
                <Button style={{ background: '#115213' }} onClick={() => createFatura()}>
                    Salvar
                </Button>
            </DivButton>
            <LoadingScreen display={loadScreen} />
        </DivMainNovaFatura>
    );
}
