import styled from 'styled-components';

export const DivMainPopupEditUser = styled.div`
    padding: 20px;
`;
export const DivTitulo = styled.div`
    height: 35px;
    text-align: center;
    margin: 0 0 12px 0;
`;

export const DivNome = styled.div`
    display: flex;
    align-items: center;
    input {
        margin: 0 0 0 12px;
    }
`;
export const DivPermissoes = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        width: 25px;
        margin: 0 0 0 12px;
    }
`;
export const DivContainerPermissionUser = styled.div`
    width: 200px;
    margin: 0 12px 0 0;
`;
export const DivButtons = styled.div`
    margin: 12px 0 0 0;
    display: flex;
    justify-content: space-evenly;
`;
export const DivListaPermissoes = styled.div`
    display:flex;
    @media screen and (max-width:800px) {
        flex-direction:column;
    }
`