import React, { useState, useEffect, useRef } from 'react';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import { DivMainEditVideo } from './StyledPopupEditVideo';
import { Button } from '../commonComponents/commonComponents';
import { toast } from 'react-toastify';
import LoadingScreen from '../loadScreen/Loadscreen';
// import { Container } from './styles';

export default function PopupEditVideo({ videoSelected, setVideoSelected, autores, close, reload }) {
    const api = useApi();
    const refInputPublicado = useRef();
    const refInputFree = useRef();
    const [videoEditInfo, setVideoEditInfo] = useState({});
    const [loadScreen, setLoadScreen] = useState('none');
    async function saveEditVideo(videoInfo) {
        try {
            setLoadScreen('flex');
            const objVideoEdit = {
                Titulo: videoInfo.Titulo,
                URL: videoInfo.URL,
                Foto: videoInfo.Foto || '',
                Descricao: videoInfo.Descricao,
                DataLancamento: videoInfo.DataLancamento,
                Publicado: videoInfo.Publicado ? '1' : '0',
                Expira: videoInfo.Expira,
                Colecao: videoInfo.Colecao,
                Duracao: videoInfo.Duracao,
                AutorId: videoInfo.AutorId,
                Free: videoInfo.Free ? '1' : '0',
                Standard: videoInfo.Standard ? '1' : '0',
                Premium: videoInfo.Premium ? '1' : '0',
                VideoId: videoInfo.VideoId,
            };

            const data = await api.post(`/docflix/UpdateVideo`, objVideoEdit);
            if (data.status === 200 && !data.data.error) {
                close(false);
                toast.success('Informações salvas com sucesso');
                reload();
                setLoadScreen('none');
            } else {
                toast.warning('Não foi possível salvar as informações');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível salvar as alterações');
        }
    }

    function alteraValorPublicado(event) {
        refInputPublicado.current.checked = !event.target.checked;
        event.target.checked = !event.target.checked;
        // event.target.value = true;
        setVideoEditInfo({ ...videoEditInfo, Publicado: !videoEditInfo.Publicado });
    }

    function alteraValorFree(event) {
        refInputFree.current.checked = !event.target.checked;
        event.target.checked = !event.target.checked;
        // event.target.value = true;
        setVideoEditInfo({ ...videoEditInfo, Free: !videoEditInfo.Free });
    }

    useEffect(() => {
        setVideoEditInfo(videoSelected);
    }, []);

    return (
        <DivMainEditVideo style={{ background: '#000' }}>
            <div>
                <span>Id: {videoEditInfo.VideoId}</span>
            </div>
            <div>
                <span>Título :</span>
                <input
                    type="text"
                    defaultValue={videoEditInfo.Titulo}
                    onChange={(event) => setVideoEditInfo({ ...videoEditInfo, Titulo: event.currentTarget.value })}
                />
            </div>
            <div>
                <span>Descrição :</span>
                <input
                    type="text"
                    defaultValue={videoEditInfo.Descricao}
                    onChange={(event) => setVideoEditInfo({ ...videoEditInfo, Descricao: event.currentTarget.value })}
                />
            </div>
            <div>
                <span>Url:</span>
                <input type="text" defaultValue={videoEditInfo.URL} />
            </div>
            <div>
                <span>Autor: </span>
                <select defaultValue={videoEditInfo.AutorId}>
                    {autores.map((item) => {
                        return <option value={item.AutorId}>{item.Autor}</option>;
                    })}
                </select>
            </div>
            <div>
                <span>Categorias:</span>
                <div>
                    {videoEditInfo.Categorias?.map((item) => (
                        <span>{item}</span>
                    ))}
                </div>
                <div>
                    <Button>Adicionar Categoria</Button>
                </div>
            </div>
            <div>
                <span>Data de Lançamento:</span>
                <input type="date" defaultValue={dayjs(videoEditInfo.DataLancamento).format('YYYY-MM-DD')} />
            </div>
            <div>
                <span>Data de expiração:</span>
                <input type="date" defaultValue={dayjs(videoEditInfo.Expira).format('YYYY-MM-DD')} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Publicado: </span>
                <div style={{ width: '20px', margin: '0 8px' }}>
                    <input
                        type="checkbox"
                        defaultChecked={videoEditInfo.Publicado}
                        ref={refInputPublicado}
                        onClick={(event) => alteraValorPublicado(event)}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Free: </span>
                <div style={{ width: '20px', margin: '0 8px' }}>
                    <input
                        type="checkbox"
                        defaultChecked={videoEditInfo.Free}
                        ref={refInputFree}
                        onClick={(event) => alteraValorFree(event)}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Button onClick={() => close(false)}>Cancelar</Button>
                <Button onClick={() => saveEditVideo(videoEditInfo)}>Salvar</Button>
            </div>
            <LoadingScreen display={loadScreen} />
        </DivMainEditVideo>
    );
}
