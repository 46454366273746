import { PsychologyOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Fab, TextField, Typography } from '@mui/material';
import React from 'react';
import useApi from '~/service/api';
import { MensagemPergunta } from './styledAssistenteInteligente';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function AssistenteInteligente() {
    const [showAssistente, setShowAssistente] = React.useState(false);
    const [listaConversa, setListaConversa] = React.useState([]);
    const [loadingAssistente, setLoadingAssistente] = React.useState(false)
    const api = useApi();
    function copyInfo(info) {
        try {
            navigator.clipboard.writeText(info);
            toast.success('Copiado com sucesso');
        } catch (error) {
            toast.error('error ao copiar');
        }
    };

    async function perguntaAssistente(pergunta){
        try {
            const responsePerguntaAssistente = await api.post('/openai/RunAssistant',{pergunta});
            const {status,data} = responsePerguntaAssistente;
            if(status == 200){
                toast.success('Pergunta feita com sucesso.')
                return data
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível se conectar com o assintente.')
        }
    }

    async function fazerPergunta(input,pergunta){
        setLoadingAssistente(true)
        try {
            input.value = ''
            setListaConversa(oldValue => [...oldValue, {pergunta:true,texto:pergunta}]);
            const resposta = await perguntaAssistente(pergunta);            
            setListaConversa(oldValue => [...oldValue, {pergunta:false,texto:resposta}]);            
        } catch (error) {
            
        }
        setLoadingAssistente(false)
    }

    return(
        <Box position={'absolute'} right={32} bottom={24} width={400}>
            <Fab sx={{right:32,bottom:24,position:'absolute' }} onClick={()=>setShowAssistente(!showAssistente)} color="primary" aria-label="add">
            <PsychologyOutlined/>
            </Fab>
            <Box position={'absolute'} display={showAssistente ? 'flex' : 'none'} bottom={84} left={0} height={600} width={'100%'}
                flexDirection={'column'}                 
                sx={{background:'#0e4574'}}  padding={2} borderRadius={1}
            >
                <Box flex={1} height={'calc(100% - 48px)'}>
                    {
                        listaConversa.length ? 
                            <ul style={{overflow:'hidden auto',height:'100%'}}>
                                {listaConversa.map(mensagem => 
                                <MensagemPergunta pergunta={mensagem.pergunta}>
                                    <strong>{mensagem.pergunta?'Você' : 'Bot'}:</strong>
                                    <br/>
                                    <br/>
                                    {mensagem.texto}
                                    {mensagem.pergunta? '':<>
                                        <br/><br/>
                                        <ContentCopyIcon fontSize={'13px'} sx={{ml:2,cursor:'pointer'}} onClick={()=>copyInfo(mensagem.texto)} titleAccess='Copiar resposta'/></> 
                                    }
                                </MensagemPergunta>)}
                            </ul>
                        :
                            <Box>
                                <Typography>
                                Seja bem-vindo ao nosso sistema de atendimento! Estou aqui para ajudar você em todas as suas questões sobre a utilização do MEDX. Como posso auxiliá-lo hoje?
                                </Typography>
                            </Box>
                    }
                </Box>
                <TextField  size='small' InputProps={{sx:{fontSize:12}}} fullWidth sx={{mt:1}}
                    onKeyDown={({key,target})=> key == 'Enter' ? fazerPergunta(target,target.value): ''}
                />
                <Box width={'100%'} height={'100%'} display={loadingAssistente?'flex':'none'} 
                    justifyContent={'center'} alignItems={'center'} 
                    zIndex={10} position={'absolute'} top={0} left={0}
                    sx={{background:'#000000aa'}}
                >
                    <CircularProgress color="secondary" />

                </Box>
            </Box>                
        </Box>
    );
}