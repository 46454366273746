import styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';
import { FaGreaterThan, FaLessThan, FaArrowCircleDown } from 'react-icons/fa';

export const DivControllViewSchedule = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 0 0;
    height: 40px;
    @media screen and (max-width: 767px){
        flex-direction:column;
        height:auto;
        align-items:flex-start;
    }
`;
export const DescricaoCampo = styled.span`
    margin: 0 0 0 12px;
    @media screen and (max-width: 767px){
        margin-bottom:8px;
    }
`
export const DivSelecaoAgenda = styled.div`
    margin-left:12px;
    @media screen and (max-width: 767px) {
        margin-bottom:8px;
    }
`
export const IconBiSearch = styled(BiSearch)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 10px;
`;
export const DivSelectDaySchedule = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 12px 12px;
    height: 30px;
`;
export const IconFaLessThan = styled(FaLessThan)`
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    transition: 0.5s;
    :hover {
        transform: scale(1.08);
    }
`;
export const IconFaGreaterThan = styled(FaGreaterThan)`
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 0 10px;
    transition: 0.5s;
    :hover {
        transform: scale(1.08);
    }
`;
export const IconFaArrowCircleDown = styled(FaArrowCircleDown)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 10px;
`;
export const DivToday = styled.div`
    cursor: pointer;
    transition: 0.5s;
    :hover {
        transform: scale(1.08);
    }
`;
export const DivAgendas = styled.div`
    display: flex;
    height: calc(100% - 110px);
    overflow: hidden ;
    @media screen and (max-width:767px) {
        overflow:hidden auto;
    }
`