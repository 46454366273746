import React,{useState,useEffect} from 'react';
import { TableContainer, Paper, Table as TableMui, TableHead, TableRow, TableCell, TableBody, createTheme, ThemeProvider } from '@mui/material';
import { useTheme } from 'styled-components';
export default function TabelaModelo({ header, clientes, callbackDoubleClick, fnRetornaValor, callbackClick, cancelado, callbackHeader }) {
    const theme = useTheme();
    const [dadosClientes, setDadosClietnes] = useState([])

    const themeMui = createTheme({
        components: {
            MuiTableRow: {
            styleOverrides: {
                root: {                    
                '&:hover': {
                    backgroundColor: '#1463a6', // Cor quando a linha é hover
                },
                },
            },
            },
        },
    });

    useEffect(()=>{
        if(clientes)
            setDadosClietnes(clientes);
    })
    function ordemCrescenteRps() {
        let arrayOrganizado = clientes.sort((a, b) => {return (b.RPS + a.RPS)})             
        setDadosClietnes(arrayOrganizado);
        
    } 
    return <Paper sx={{ width: '100%', overflow: 'hidden', display: 'flex', flex: 1, mt: 2, background: theme.colors.background, color: theme.colors.text }}>
            <TableContainer sx={{ border: `1px solid ${theme.colors.borderColor}` }}>
                <TableMui stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow>
                            {header.map((column) => (
                                <TableCell 
                                    onClick={callbackHeader?.nome == column ? ()=>ordemCrescenteRps() : ()=>{return}}
                                    style={{ 
                                        background: theme.colors.background, 
                                        color: theme.colors.text, 
                                        borderBottom: `1px solid ${theme.colors.borderColor}`,
                                        cursor:callbackHeader?.nome == column ? 'pointer' : 'unset',
                                    }}
                                    key={column}
                                >
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dadosClientes.map((cliente, index) => {
                            return (
                                <ThemeProvider theme={themeMui}>
                                    <TableRow role="checkbox" tabIndex={-1} key={index}
                                        onDoubleClick={() => callbackDoubleClick ? callbackDoubleClick(cliente) : ''}
                                        onClick={() => callbackClick ? callbackClick(cliente) : ''}
                                        sx={cliente.Cancelado ?{fontStyle:'italic'}:{}}
                                        cancelado={cliente.Cancelado ?? false}
                                    >
                                        {
                                            header.map((info, index) => (
                                                <TableCell 
                                                    sx={{color: cliente.Cancelado?'red!important' : theme.colors.text, borderBottom: '0' }}
                                                    key={index}>
                                                    {fnRetornaValor(info, cliente)}
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </ThemeProvider>
                            );
                        })}
                    </TableBody>
                </TableMui>
            </TableContainer>
        </Paper>    
}