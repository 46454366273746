import styled from 'styled-components';

export const DivCardEditCliente = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 30px 16px 16px 16px;
    border-radius: 6px;
    position: relative;
    div {
        display: flex;
        width: 100%;
        margin: 0 0 16px 0;
        align-items: center;
        input {
            width: auto;
            flex:1;
        }

        span {
            margin: 0 12px 0 0;
        }
    }
`;
