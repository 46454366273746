import React, { useEffect, useState, useRef, useMemo, memo } from 'react';
import { useLocation, useHistory, useParams, NavLink } from 'react-router-dom';
import useApi from '~/service/api';
import { useTheme } from 'styled-components';
import NovaFaturaParcelamento from '~/components/novaFaturaPacerlamento/NovaFaturaPacerlamento';
import FormClientEdit from '~/components/popupEditFicha/PopupEditFicha';
import {
    DivBotoes,
    DivDadosPessoais,
    DivFatura,
    DivFaturasContratos,
    DivFaturasMobile,
    DivHeaderDatabase,
    DivInfo,
    DivInfoContrato,
    DivInfoContratoMobile,
    DivInfoPopupViewDataBase,
    DivListClientSearch,
    DivMainInfoFaturasMobile,
    DivMainPopupViewDataBase,
    DivMainSearch,
    DivMainSmsView,
    DivInfoTitle,
    DivMobileContratos,
    SpanNameField,
    TableInfoVendas,
    TdDescription,
    DivUsers,
    DivTableUsers,
    ThFixed,
    TdFixed,
    TdButton,
    ThButton,
    DivInfoContent,
    DivInfoIcon,
    SpanInfoDados,
} from './ClienteStyled';
import { Input, Button, Title, DivFooter, DivBody, DivMain, DivTitle } from '~/components/commonComponents/commonComponents';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import DescriptionAtividades from '~/components/descriptionAtividades/DescriptionAtividades';
import BoletoEdit from '~/components/boletoEdit/BoletoEdit';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import PopupEditContrato from '~/components/popupEditContrato/popupEditContrato';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import PopupViewSmsList from '~/components/popupViewSmsList/PopupViewSmsList';
import PopupUsuarios from '~/components/popupUsuarios/PopupUsuarios';
import FormGerarBackup from '~/components/formGerarBackup/FormGerarBackup';
import { IconAiOutlineMail, IconAiOutlineWhatsApp } from '../financasFaturasPendentes/StyledFinancasBoletosAtrasados';
import NovaVenda from '~/components/novaVenda/NovaVenda';
import FormularioFatura from '~/components/formularioFatura/formularioFatura';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button as ButtonMui, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormNovoBoleto from '~/components/formNovoBoleto/formNovoBoleto';
import { formataCurrency } from '~/helpers/functions';
function Cliente() {
    //#region Declaração dos States
    const [clientInfo, setClientInfo] = useState({});
    const [vendasInfo, setVendasInfo] = useState([]);
    const [clientBoletos, setClientBoletos] = useState([]);
    const [loading, setLoading] = useState('none');
    const [clientListSearch, setClientListSearch] = useState([]);
    const [search, setSearch] = useState('');
    const [dadosContrato, setDadosContrato] = useState([]);
    const [boletoId, setBoletoId] = useState(0);
    const [database, setDatabase] = useState({});
    const [sms, setSms] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [software, setSoftware] = useState('');
    const [linkAcessoMedx, setLinkAcessoMedx] = useState('')
    const [boletoBloqueio, setBoletoBloqueio] = useState([]);
    const [permissoesSelecionadas, setPermissoesSelecionadas] = useState(null)
    const [arquivoAnexo, setArquivoAnexo] = useState(null);
    const [listaArquivoAnexo, setListaArquivoAnexo] = useState([]);
    const [listDealsEncontrados, setListDealsEncontrados] = useState([]);

    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupSearch, setOpenPopupSearch] = useState(false);
    const [openPopupEditClient, setOpenPopupEditClient] = useState(false);
    const [openPopupViewDatabase, setOpenPopupViewDatabase] = useState(false);
    const [openPopupSmsView, setOpenPopupSmsView] = useState(false);
    const [openPopupDadosContrato, setOpenPopupDadosContrato] = useState(false);
    const [openPopupUsuarios, setOpenPopupUsuarios] = useState(false);
    const [openFormGerarBackup, setOpenFormGerarBackup] = useState(false);
    const [openPopupCreateFatura, setOpenPopupCreateFatura] = useState(false);
    const [openPopupWarningIsTrial, setOpenPopupWarningIsTrial] = useState(false);
    const [openPopupNovaVenda, setOpenPopupNovaVenda] = useState(false);
    const [openPopupLoginMedx, setOpenPopupLoginMedx] = useState(false);
    const [openPopupBoletoBloqueio, setOpenPopupBoletoBloqueio] = useState(false);
    const [openPopupPermissoes, setOpenPopupPermissoes] = useState(false);
    const [openPopupPreviewAnexo, setOpenPopupPreviewAnexo] = useState(false);
    const [openPopupNovoBoleto, setOpenPopupNovoBoleto ] = useState(false);
    //#endregion
    const api = useApi();
    const location = useLocation();
    const history = useHistory();
    const { id: IdCliente } = useParams('id');
    const refInputSearch = useRef();
    const refInputFile = useRef();
    const theme = useTheme();
    const styledPopupSearch = {
        margin: '40px 0 0 0',
        maxHeight: '400px',
        overflow: 'auto',
        background: '#000',
        backdropFilter: 'blur(4px)',
    };
    function getClienteInfoById(IdCliente) {
        return new Promise(async (resolve) => {
            try {
                const cliente = await api.get(`/cliente/GetClientById?id=${IdCliente}`);
                resolve(cliente.data[0]);
            } catch (error) {
                if (error.message?.toLowerCase() == 'network error') {
                    history.push('/');
                }
                console.error(error);
                resolve('error');
            }
        });
    };
    function getListaVendasByIdCliente(IdCliente) {
        return new Promise(async (resolve) => {
            try {
                const vendas = await api.get(`/vendas/GetListVendas?id=${IdCliente}`);
                resolve(vendas.data);
            } catch (error) {
                console.error(error);
                resolve('error');
            }
        });
    };
    function getAllBoletos(IdCliente) {
        return new Promise(async (resolve) => {
            try {
                const boletos = await api.get(`/boleto/GetAllBoletosByClienteId?id=${IdCliente}`);
                resolve(boletos.data);
            } catch (error) {
                console.error(error);
                resolve('error');
            }
        });
    };
    function dataPassou15Dias(data) {
        // Obtém a data atual
        var dataAtual = new Date();
      
        // Adiciona 15 dias à data atual
        var dataLimite = new Date(dataAtual);
        dataLimite.setDate(dataLimite.getDate() - 15);
      
        // Converte a string da data fornecida para um objeto Date
        var dataFornecida = new Date(data);
      
        // Compara as datas
        return dataFornecida <= dataLimite;
    };
    async function getCliente() {
        try {
            const cliente = await getClienteInfoById(IdCliente);
            if (cliente == 'error') {
                toast.error('Erro ao carregar info dos detalhes.');
            } else {
                setClientInfo(cliente);
            }
            const vendas = await getListaVendasByIdCliente(IdCliente);
            if (vendas == 'error') {
                toast.error('Erro ao carregar lista de softwareid do cliente.');
            } else {
                const listaVendasOrganizadas = vendas.map(venda =>{
                    if(String(venda.Status).toLowerCase() == 'full' ){
                        venda.Status = 'Enterprise'
                    }else if(String(venda.Status).toLowerCase() == 'individual' ){
                        venda.Status = 'Starter'
                    }else if(String(venda.Status).toLowerCase() == 'small' ){
                        venda.Status = 'Business'
                    }
                    return venda
                } );
                
                setVendasInfo(listaVendasOrganizadas);
            }
            await carregaBoletos()
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível realizar o carregamento das informações');
        }
        // setClientInfo(dadosCliente[0]);
    };
    async function carregaBoletos(){
        try {
            const boletos = await getAllBoletos(IdCliente);
            if (boletos == 'error') {
                throw('Erro ao carregar lista de boletos do cliente.');
            } else {
                let arrayBoletosBloqueio = [];
                boletos.forEach(boleto =>{
                    if(dataPassou15Dias(boleto.Vencimento)){
                        if(!boleto.Pago && !boleto.Cancelado){
                            arrayBoletosBloqueio.push(boleto)                            
                        }
                    }
                    
                })
                setBoletoBloqueio(arrayBoletosBloqueio);
                setClientBoletos(boletos);
            }
        } catch (error) {
            console.error(error);
            toast.error("Não foi possível carregar lista de boletos.")
        }
    }
    async function getDadosContratos(softwareId) {
        try {
            setLoading('flex');
            const data = await api.get(`/cliente/GetDadosContrato?softwId=${softwareId}`);
            if (data.status === 200) {
                setDadosContrato(data.data);
                setOpenPopupDadosContrato(true);
                setLoading('none');
            } else {
                toast.warning('Erro ao carregar dados do contrato.');
                setLoading('none');
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível realizar a ação.');
            setLoading('none');
        }
    };
    async function searchCliente(Nome_Email) {
        try {
            setLoading('flex');
            if (Nome_Email === search) {
                setLoading('none');
                setOpenPopupSearch(true);
                return;
            }
            const data = await api.get(`/cliente/GetListClients?search=${Nome_Email}&filter=todos`);
            if (data.status === 200) {
                if (data.data.length == 0) {
                    setLoading('none');
                    toast.warning('Não foi encontrado nenhum cliente.');
                    return;
                }                
                const listaRenomeandoPlanos = data.data.map(resultSearch =>{
                    if(String(resultSearch.Status).toLowerCase() == 'full' ){
                        resultSearch.Status = 'Enterprise'
                    }else if(String(resultSearch.Status).toLowerCase() == 'individual' ){
                        resultSearch.Status = 'Starter'
                    }else if(String(resultSearch.Status).toLowerCase() == 'small' ){
                        resultSearch.Status = 'Business'
                    }
                    return resultSearch
                } );
                setClientListSearch(listaRenomeandoPlanos);
                setSearch(Nome_Email);
                setLoading('none');
                setOpenPopupSearch(true);
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível realizar a busca pelo cliente.');
            setLoading('none');
        }
    };
    async function getSms() {
        try {
            setLoading('flex');
            const ativo = vendasInfo.filter((item) => item.Status.toLocaleLowerCase() != 'suspenso');
            const softwid = ativo[0].SoftwareId;
            const data = await api.get(`/cliente/GetSmsClient?softwId=${softwid}`);
            if (data.status === 200) {
                setSms(data.data);
                setOpenPopupSmsView(true);
                setLoading('none');
            } else {
                toast.warning('Erro ao carrega lista de sms');
                setLoading('none');
            }
        } catch (error) {
            toast.error('Erro ao carregar lista de sms');
            setLoading('none');
        }
    }
    async function getDatabase() {
        try {
            setLoading('flex');
            const ativo = vendasInfo.filter((item) => item.Status.toLocaleLowerCase() != 'suspenso');
            const softwid = ativo[0].SoftwareId;
            const data = await api.get(`/home/GetAcessAccountClient?softwid=${softwid}`);
            if (data.status === 200) {
                setDatabase(data.data[0]);
                setOpenPopupViewDatabase(true);
                setLoading('none');
            } else {
                toast.warning('Não foi possível realizar requisição');
                setLoading('none');
            }
        } catch (error) {
            toast.error('Não foi possível realizar requisição');
            setLoading('none');
        }
    };
    async function getUsersAccount(softwid) {
        try {
            setLoading('flex');
            const responseGetUsuarios = await api.get(`/cliente/getUsuarios?softwareId=${softwid}`);
            if (responseGetUsuarios.status == 200) {
                setListUser(responseGetUsuarios.data);
                setLoading('none');
            } else {
                toast.error('Erro ao carregar os usuários.');
                setLoading('none');
            }
        } catch (error) {
            toast.error('Não foi possível carregar a lista de usuários.');
            setLoading('none');
        }
    };
    function verificaBusca() {
        if (search !== '') {
            setOpenPopupSearch(true);
        }
    };
    function selectClient(event, obj_cliente) {
        event.preventDefault();
        setOpenPopupSearch(false);
        history.push(`/app/cliente/${obj_cliente.Codigo_do_Cliente}`);
    };
    function copyInfo(info) {
        try {
            navigator.clipboard.writeText(info);
            toast.success('Copiado com sucesso');
        } catch (error) {
            toast.error('error ao copiar');
        }
    };
    function limpaBusca() {
        refInputSearch.current.value = '';
        setOpenPopupSearch(false);
    };
    function carregaSoftwareId() {
        try {
            const ativo = vendasInfo.filter((item) => item.Status.toLocaleLowerCase() != 'suspenso');
            const softwid = ativo[0].SoftwareId;
            return softwid;
        } catch (error) {
            console.error(error);
            toast.error();
            return '';
        }
    };
    function openingPopup(id) {
        if (
            JSON.parse(sessionStorage.getItem('currentUser')).Financas ||
            JSON.parse(sessionStorage.getItem('currentUser')).CurrentUser.toLowerCase().includes('yuri')
        ) {
            setBoletoId(id);
            setOpenPopup(true);
        } else return '';
    };
    async function criandoNovaVenda() {
        setLoading('flex');
        try {
            try {                
                 const responseAlteraPipePosVenda = await api.get(`/vendas/GetDealIdByEmail?email=${clientInfo.Email}`);
                 if (responseAlteraPipePosVenda.status == 200) {
                     setOpenPopupWarningIsTrial(false);
                    if(responseAlteraPipePosVenda.data.length > 0){
                        setListDealsEncontrados(responseAlteraPipePosVenda.data);
                    }
                    else{
                        toast.warning('Cliente sem deals encontrados.');
                        setOpenPopupNovaVenda(true);
                    }                     
                }
            } catch (error) {
                toast.error(error?.response?.data?.Message || 'Erro ao alterar pipe.');
            }                        
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.Message || 'Erro ao abrir popup de nova venda.');
        }
        setLoading('none');
    };
    function verificaCpfAbreAviso() {
        try {
            if (!clientInfo.CGC || clientInfo.CGC === '') {
                toast.warning('Cliente sem cpf cadastrado.');
                return;
            }
            setOpenPopupWarningIsTrial(true);
        } catch (error) {
            toast.error('Não foi possível verificar CPF/CNPJ');
        }
    };
    function fecharPopupAcessoMedx(){
        setOpenPopupLoginMedx(false);
        setLinkAcessoMedx("")
    };
    async function loadUsers() {
        const softwareId = carregaSoftwareId();
        await getUsersAccount(softwareId);
    };
    async function acessaMedx(dadosUsuario){
        try {
            if(!dadosUsuario.Ativo){
                toast.warning('Usuário desativado.');
                return
            }
            setLoading('flex');
            const objLoginMedx = {
                Username:"MEDXMASTER",
                DbId: carregaSoftwareId(),
                Ip:"1.1.1.1",
                Mobile:0,
                UserId:dadosUsuario.Id_do_Usuario
            }            
            const responseLoginMedx = await api.post(`/auth/LoginUsuarioMedx`,objLoginMedx);            
            if(responseLoginMedx.status == 200){
                setLinkAcessoMedx(`https://v65.medx.med.br/login_unificado/loginUnificadoRedirect.html?token=${responseLoginMedx.data}`);
                setOpenPopupLoginMedx(true)
                window.open(`https://v65.medx.med.br/login_unificado/loginUnificadoRedirect.html?token=${responseLoginMedx.data}`);
                toast.success(`Usuário logado.`)
            }
            setLoading('none');
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível fazer o login com o usuário selecionado.')
            setLoading('none');
        }
    };
    async function liberaBoleto(boletoInfo){
        setLoading('flex');
        try {
            const responseLiberaBoleto = await api.post(`/boleto/LiberaConta?idBoleto=${boletoInfo.Id}`);
            if(responseLiberaBoleto.status == 200){
                await getCliente(IdCliente)
                setOpenPopupBoletoBloqueio(false);
                setBoletoBloqueio([]);
                toast.success("Conta liberada com sucesso.")
            }else{
                toast.error(responseLiberaBoleto)
            }
        } catch (error) {
            debugger
            toast.error(error.response.data.Message ?? 'Não foi possível liberar conta.');
            console.error(error);
        }
        setLoading('none');
    };
    async function reenviarLinkSenha(userInfo){
        if(!userInfo.Email || userInfo.Email == ""){
            return toast.warning('Usuário sem email cadastrado.');
        }
        setLoading('flex')
        try {
            if(userInfo.Email != ""){
                const responseReenviaLink = await api.post(`/cliente/ReenviarLinkRedefinirSenha?email=${userInfo.Email}&dbid=${carregaSoftwareId()}`);
                if(responseReenviaLink.status == 200){
                    toast.success("Email enviado com sucesso.")
                }
            }else{
                toast.warning("Usuário sem email cadastrado.")
            }    
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar enviar link para redefinição de senha.')
        }
        setLoading('none')
    };
    function visualizarPermissoes(user){
        setOpenPopupPermissoes(true);
        setPermissoesSelecionadas(user)
    };
    function fecharVisualizar(){
        setOpenPopupPermissoes(false);
        setPermissoesSelecionadas(null)
    };
    async function enviaLinkLoginUnificado(userInfo){
        if(!userInfo.Email || userInfo.Email == ""){
            return toast.warning('Usuário sem email cadastrado.');
        }
        if(userInfo.Login_Unificado){
            return toast.warning('Usuário já possui login unificado confirmado.');
        }
        setLoading('flex');
        try {
            
            const objPost = {
                DbId:carregaSoftwareId(),
                UserId:userInfo.Id_do_Usuario,
                UserName:userInfo.Usuario,
                Email:userInfo.Email,
            }            
            const responseEnvioLinkLoginUnificado = await api.post(`/cliente/AtualizaOuCriaLoginUnificado`,objPost)
            if(responseEnvioLinkLoginUnificado.status == 200){
                toast.success('Link de confirmação enviado com sucesso.')
            }else{
                toast.error('Erro ao tentar enviar link de confirmação de email.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível enviar link de login unificado.')
        }
        setLoading('none');
    }
    async function selecionaArquivo(event){
        setLoading('flex')
        try {
            let objArquivo = await getInfoArquivo(event);
            if(String(objArquivo).toLowerCase().includes('arquivo maior')){
                toast.warning('Arquivo excedeu limite (10MB)')
            }
            else{
                await addArquivo(objArquivo)
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possivel carregar informações do arquivo');
        }
        setLoading('none')
    }

    function getInfoArquivo(event){
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            
            reader.onload = function (e) {
                let obj = {
                    file_base64:e.target.result.split(/,/)[1],
                    filename:event.target.files[0].name,
                    filetype:event.target.files[0].type,
                    filesize:event.target.files[0].size,
                    
                }
                if(Math.round((obj.filesize/1024)/1024).toFixed(2) > 10){
                    resolve('Arquivo maior que 10MB')
                }
                resolve(obj)                
            };
            reader.readAsDataURL(event.target.files[0]);
            
        })
    }

    async function addArquivo(objArquivo){
        if(objArquivo){
            const responseAddArquivo = await api.post(`/cliente/AdicionarAnexoCliente`,{softwareId:carregaSoftwareId(),arquivos:objArquivo});
            if(responseAddArquivo.status == 200){
                toast.success('Arquivo salvo com sucesso.');
                await abrePopupAnexos()
            }else{
                toast.error('Não foi possível salvar arquivo.')
            }
        }else{
            toast.warning('Selecione um arquivo.')
        }
    }

    async function getAnexos(){
        setLoading('flex');
        try {
            let softwareId = carregaSoftwareId();
            if(!softwareId){
                setLoading('none');
                toast.warning('Cliente com nenhuma conta ativa.')
                return null
            }
            const responseGetAnexos = await api.get('/cliente/getanexos?softwareId='+carregaSoftwareId());
            if(responseGetAnexos.status == 200){
                setLoading('none');
                return responseGetAnexos.data;
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar anexos do cliente.')
        }
        setLoading('none');
    }

    async function abrePopupAnexos(){
        try {
            const anexos = await getAnexos();
            if(anexos){
                setListaArquivoAnexo(anexos)
                setOpenPopupPreviewAnexo(true)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function getUrl(arquivo){
        setLoading('flex')
        try {
            const responseUrlArquivo = await api.get(`/cliente/GetUrlArquivo?caminhoArquivo=${arquivo}&softwareId=${carregaSoftwareId()}`)
            if(responseUrlArquivo.status == 200){
                window.open(responseUrlArquivo.data,'_blank')
            }else{
                toast.error('Erro ao tentar acessar arquivo.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar url do arquivo.')
        }
        setLoading('none')
    }

     async function alteraPosVendaV2(dealInfo){
        setLoading('flex')
        try {
            const responseAlteraPipeParaPosVenda = await api.post(`/vendas/AlteraPipeParaPosVenda`,{
                idDealUpdate:dealInfo.id,
                title:dealInfo.title,
                valorDeal:dealInfo.value,
                person_id:dealInfo.person_id.value,
            });
            if(responseAlteraPipeParaPosVenda.status == 200){
                setOpenPopupWarningIsTrial(false);
                setListDealsEncontrados([]);
                toast.success('Pipe alterado para pós venda com sucesso.');
                setOpenPopupNovaVenda(true);
            }
        } catch (error) {
            toast.error('Erro ao alterar pipe para pós venda.');
        }
        setLoading('none')
     }

    const PopupNovoBoleto = memo(()=>{
        return <FormNovoBoleto open={openPopupNovoBoleto} fnClose={()=>{setOpenPopupNovoBoleto(false)}} infoCliente={clientInfo} atualizar={carregaBoletos} />
    },[clientInfo])

    useEffect(() => {
        getCliente();
    }, [location.pathname]);
    useEffect(() => {
        if (vendasInfo.length > 0) {
            loadUsers();
        }
    }, [vendasInfo]);

    return (
        <DivMain>
            <DivTitle style={{ position: 'relative' }}>
                <Input
                    list="clientes"
                    ref={refInputSearch}
                    type="text"
                    style={{ height: '40px', position: 'relative' }}
                    placeholder="Busque o cliente pelo nome, email ou softId."
                    onKeyPress={(event) => (event.key === 'Enter' ? searchCliente(event.currentTarget.value) : '')}
                    onClick={verificaBusca}
                    onKeyDown={(event) => (event.key === 'Escape' ? limpaBusca() : '')}
                />
                <DivListClientSearch onMouseLeave={() => setOpenPopupSearch(false)} hide={openPopupSearch}>
                    {clientListSearch.map((item) => (
                        <DivMainSearch key={item.SoftwareId} onClick={(event) => selectClient(event, item)}>
                            <span>{item.Nome} </span>
                            <span>{item.Email}</span>
                            <span>{item.Status}</span>
                        </DivMainSearch>
                    ))}
                </DivListClientSearch>
            </DivTitle>
            <DivBody>
                <DivInfoTitle>
                    <Title>Dados cadastrais</Title>
                    <Button onClick={() => setOpenPopupEditClient(true)}>Editar dados cadastrais</Button>                    
                </DivInfoTitle>
                <DivDadosPessoais id="dados_pessoais">
                    <DivInfo>
                        <DivInfoContent onClick={() => copyInfo(clientInfo.Nome)}>
                            <SpanNameField>Nome do Cliente : </SpanNameField>
                            <SpanInfoDados>{clientInfo.Nome}</SpanInfoDados>
                        </DivInfoContent>
                        <DivInfoContent onClick={() => copyInfo(clientInfo.Email)}>
                            <SpanNameField>Email de Assinante : </SpanNameField>
                            <DivInfoIcon>
                                <span style={{ marginLeft: '8px' }}>{clientInfo.Email}</span>{' '}
                                <a
                                    target="_blank"
                                    rel="noopener"
                                    href={`mailto:${clientInfo.Email}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <IconAiOutlineMail
                                        style={{ width: '20px', height: '20px', marginLeft: '8px', cursor: 'pointer' }}
                                    />
                                </a>
                            </DivInfoIcon>
                        </DivInfoContent>                        
                        <DivInfoContent style={{ cursor: 'pointer' }}>
                            <SpanNameField>Email de ADM : </SpanNameField>
                            <SpanInfoDados>{clientInfo.Email_Administrativo}</SpanInfoDados>
                        </DivInfoContent>
                        <DivInfoContent style={{ cursor: 'pointer' }}>
                            <SpanNameField> Celular : </SpanNameField>
                            <DivInfoIcon>
                                <SpanInfoDados  onClick={()=>copyInfo(clientInfo.Celular)}>{` (${clientInfo.DDD2 || ''}) ${clientInfo.Celular || ''}`}</SpanInfoDados>
                                <a
                                    target="_blank"
                                    rel="noopener"
                                    href={`https://api.whatsapp.com/send?phone=55${clientInfo.Celular}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <IconAiOutlineWhatsApp
                                        style={{ width: '20px', height: '20px', marginLeft: '8px', cursor: 'pointer' }}
                                    />
                                </a>
                            </DivInfoIcon>
                        </DivInfoContent>
                    </DivInfo>
                    <DivInfo>
                        <div>
                            <SpanNameField>Telefone : </SpanNameField>
                            <span>{`${clientInfo.DDD || ''} ${clientInfo.Telefone || ''}`}</span>
                        </div>
                        <DescriptionAtividades info_vendas={vendasInfo} clientInfo={clientInfo} />
                        <div>
                            <SpanNameField>Cidade : </SpanNameField>
                            <span>{clientInfo.Cidade}</span>
                        </div>
                        <div>
                            <SpanNameField>CGC : </SpanNameField>
                            <span>{clientInfo.CGC}</span>
                        </div>
                        <div>
                            <SpanNameField>Observações : </SpanNameField>
                            <span>{clientInfo.Observacoes}</span>
                        </div>
                    </DivInfo>
                </DivDadosPessoais>
                <DivFaturasContratos id="contratos e faturas">
                    <div style={{ margin: '0 24px 0 0', width: 'fit-content' }} id="contratos">
                        <div id="contrato_ativo">
                            <div style={{ margin: '0 0 16px 0' }}>
                                <Title>Contrato(s) Ativo(s)</Title>
                            </div>
                            <TableInfoVendas>
                                <thead>
                                    <tr>
                                        <td>SoftwId</td>
                                        <td>Data Contratação</td>
                                        <td>Plano válido até</td>
                                        <td>Tipo do plano</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendasInfo
                                        .sort((a, b) => a.Data > b.Data)
                                        // .filter((item) => item?.Status?.toLowerCase() !== 'suspenso')
                                        .map((item) => (
                                            <tr key={item.Id_da_Venda} onClick={() => getDadosContratos(item.SoftwareId)}>
                                                <td>{item.SoftwareId}</td>
                                                <td>{dayjs(item.Data).format('DD/MM/YYYY')}</td>
                                                <td>{dayjs(item.Pago_ate).format('DD/MM/YYYY')}</td>
                                                <td>{item.Status}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </TableInfoVendas>
                            <DivMobileContratos>
                                {vendasInfo
                                    .sort((a, b) => a.Data > b.Data)
                                    // .filter((item) => item?.Status?.toLowerCase() !== 'suspenso')
                                    .map((item) => (
                                        <div
                                            key={item.Id_da_Venda}
                                            style={{
                                                margin: '0 0 12px 0',
                                                background: '#010305',
                                                padding: '12px',
                                                borderRadius: '6px',
                                            }}
                                        >
                                            <DivInfoContratoMobile>
                                                <span>SoftwareId:</span>
                                                {item.SoftwareId}
                                            </DivInfoContratoMobile>
                                            <DivInfoContratoMobile>
                                                <span>Data Contratação:</span> {dayjs(item.Data).format('DD/MM/YYYY')}
                                            </DivInfoContratoMobile>
                                            <DivInfoContratoMobile>
                                                <span>Pago Até:</span> {dayjs(item.Pago_ate).format('DD/MM/YYYY')}
                                            </DivInfoContratoMobile>
                                            <DivInfoContratoMobile>
                                                <span>Status:</span> {item.Status}
                                            </DivInfoContratoMobile>
                                        </div>
                                    ))}
                            </DivMobileContratos>
                        </div>
                    </div>
                    <DivFatura id="faturas">
                        <div style={{display:'flex' ,margin: '0 0 16px 0',alignItems:'center'}}>
                            <Title>Boleto(s)</Title>
                            {
                                boletoBloqueio.length > 0 && <Button style={{marginLeft:'12px'}} onClick={()=>setOpenPopupBoletoBloqueio(true)} >Liberar conta</Button>
                            }
                           <Button style={{marginLeft:'12px'}} onClick={()=>setOpenPopupNovoBoleto(true)}>Novo Boleto</Button>
                        </div>
                        <TableInfoVendas>
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>Vencimento</td>
                                    <td>Valor</td>
                                    <td>Descrição</td>
                                    <td>
                                        <nobr>Data de Envio</nobr>
                                    </td>
                                    <td>Status</td>
                                    <td>Link Boleto</td>
                                    <td>Link Cartão</td>
                                </tr>
                            </thead>
                            <tbody>
                                {clientBoletos.map((item) => (
                                    <tr
                                        key={item.Id}
                                        onDoubleClick={() => openingPopup(item.Id)}
                                        style={item.Cancelado ? { textDecorationLine: 'line-through' } : {}}
                                    >
                                        <td>{item.Id}</td>
                                        <td>{dayjs(item.Vencimento).format('DD/MM/YYYY')}</td>
                                        <td>
                                            <nobr>{`R$ ${parseFloat(item.Valor).toFixed(2)}`}</nobr>
                                        </td>

                                        <TdDescription
                                            title={`${item.Referente_a} ${item.Referente_a_1}`}
                                        >{`${item.Referente_a} ${item.Referente_a_1}`}</TdDescription>
                                        <td>
                                            {item.Envio_em !== null ? dayjs(item.Envio_em).format('DD/MM/YYYY') : 'NÃO ENVIADO'}
                                        </td>
                                        <td>{item.Pago > 0 ? 'PAGO' : 'PENDENTE'}</td>
                                        <td onClick={() => copyInfo(`http://apoio.medx.med.br/boleto.aspx?id=${item.Chave}`)}>
                                            LINK
                                        </td>
                                        <td onClick={() => copyInfo(`http://apoio.medx.med.br/ecommerce.aspx?id=${item.Chave}`)}>
                                            LINK
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableInfoVendas>
                        <DivFaturasMobile>
                            {clientBoletos.map((item) => (
                                <DivMainInfoFaturasMobile
                                    key={item.Id}
                                    onClick={() => openingPopup(item.Id)}
                                    style={item.Cancelado ? { textDecorationLine: 'line-through' } : {}}
                                >
                                    <div>
                                        {' '}
                                        <span>Id:</span> {item.Id}
                                    </div>
                                    <div>
                                        {' '}
                                        <span>Vencimento:</span> {dayjs(item.Vencimento).format('DD/MM/YYYY')}
                                    </div>
                                    <div>
                                        {' '}
                                        <span>Valor:</span>
                                        <nobr>{`R$ ${parseFloat(item.Valor).toFixed(2)}`}</nobr>
                                    </div>

                                    <div title={`${item.Referente_a} ${item.Referente_a_1}`} style={{ textOverflow: 'ellipsis' }}>
                                        <span>Descrição:</span>
                                        <span>
                                            <nobr>{`${item.Referente_a} ${item.Referente_a_1}`}</nobr>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <nobr>
                                                {item.Envio_em !== null
                                                    ? dayjs(item.Envio_em).format('DD/MM/YYYY')
                                                    : 'NÃO ENVIADO'}
                                            </nobr>
                                        </span>
                                    </div>
                                    <div>{item.Valor === item.Pago || item.Pago > item.Valor ? 'PAGO' : 'PENDENTE'}</div>
                                </DivMainInfoFaturasMobile>
                            ))}
                        </DivFaturasMobile>
                    </DivFatura>
                </DivFaturasContratos>
                <DivUsers id="Usuarios">
                    <Title>Usuário(s)</Title>                    
                    <DivTableUsers>
                        <table>
                            <thead>
                                <tr>
                                    <ThFixed id="thfixed">Usuário</ThFixed>
                                    <th>Email</th>                          
                                    <th>Ativo</th>                          
                                    <th>Unificado</th>                          
                                    <ThButton></ThButton>
                                </tr>
                            </thead>
                            <tbody>
                                {listUser
                                    ? listUser.map((user) => (
                                        <tr key={user.Id_do_Usuario}>
                                            <TdFixed>{user.Usuario}</TdFixed>
                                            <td>
                                                <Typography variant='body1'>{user.Email}</Typography>
                                            </td>                                            
                                            <td>
                                                <input type="checkbox" defaultChecked={user.Ativo} readOnly disabled style={{width:'20px'}} />
                                            </td>                                            
                                            <td>
                                                <input type="checkbox" defaultChecked={Boolean(user.Login_Unificado)} readOnly disabled style={{width:'20px'}} />
                                            </td>                                            
                                            <TdButton>
                                                <Box display={'flex'}>
                                                    <Button style={{margin:'0 8px 0 0'}} onClick={() => acessaMedx(user)}>Acessar</Button>
                                                    <Button style={{margin:'0 8px 0 0'}} onClick={() => visualizarPermissoes(user)}>Permissões</Button>
                                                    <Button style={{margin:'0 8px 0 0'}} onClick={() => reenviarLinkSenha(user)}>Esqueceu a senha</Button>
                                                    <Button style={{margin:'0 8px 0 0'}} onClick={() => enviaLinkLoginUnificado(user)}>Login Unificado</Button>
                                                </Box>
                                            </TdButton>                                            
                                        </tr>
                                    ))
                                    : ''}
                            </tbody>
                        </table>
                    </DivTableUsers>
                </DivUsers>
            </DivBody>
            <DivFooter>
                {/* <Button onClick={() => getDatabase(vendasInfo)}>Visualizar Database</Button> */}
                <Button onClick={getSms}>Visualizar Envios</Button>
                <Button onClick={() => setOpenFormGerarBackup(true)}>Gerar Backup</Button>
                <Button onClick={async () =>await abrePopupAnexos()}>Anexo</Button>
                    <input onChange={(event)=>selecionaArquivo(event)}
                        ref={refInputFile} type='file' 
                        accept='.jpg, .jpeg, .png, .xdoc, .doc, .docx, .pdf, .JPEG, .JPG, .png, .PDF' style={{display:'none'}}
                    />
                <Button onClick={verificaCpfAbreAviso}>Nova Venda</Button>
            </DivFooter>
            <PopupDefault
                open={openPopup}
                close={() => setOpenPopup(false)}
                children={<BoletoEdit clientBoletos={clientBoletos} id={boletoId} close={setOpenPopup} reload={getCliente} />}
            />

            <PopupDefault
                open={openPopupEditClient}
                close={() => setOpenPopupEditClient(false)}
                children={
                    <FormClientEdit
                        close={() => setOpenPopupEditClient(false)}
                        clientInfo={clientInfo}
                        IdCliente={IdCliente}
                        setClientInfo={setClientInfo}
                    />
                }
            />
            <PopupDefault
                open={openPopupViewDatabase}
                close={() => setOpenPopupViewDatabase(false)}
                children={
                    <DivMainPopupViewDataBase>
                        <DivHeaderDatabase>
                            <p>Clique nos campos para copiar a informação</p>
                        </DivHeaderDatabase>
                        <DivInfoPopupViewDataBase
                            onClick={() => {
                                copyInfo(database.Database);
                            }}
                        >
                            <span style={{ margin: '0 8px 0 0' }}>Database:</span>
                            <span>{database.Database}</span>
                        </DivInfoPopupViewDataBase>
                        <DivInfoPopupViewDataBase
                            onClick={() => {
                                copyInfo(database.Server);
                            }}
                        >
                            <span style={{ margin: '0 8px 0 0' }}>Server:</span>
                            <span>{database.Server}</span>
                        </DivInfoPopupViewDataBase>
                        <DivInfoPopupViewDataBase
                            onClick={() => {
                                copyInfo(`Medizin_${database.SoftwareId}`);
                            }}
                        >
                            <span style={{ margin: '0 8px 0 0' }}>SoftwId:</span>
                            <span>{database.SoftwareId}</span>
                        </DivInfoPopupViewDataBase>
                        <DivInfoPopupViewDataBase
                            onClick={() => {
                                copyInfo(database.Webpwd);
                            }}
                        >
                            <span style={{ margin: '0 8px 0 0' }}>Senha:</span>
                            <span>{database.Webpwd}</span>
                        </DivInfoPopupViewDataBase>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '20px 0px 0px 0px',
                            }}
                        >
                            <Button onClick={() => setOpenPopupViewDatabase(false)}>Fechar</Button>
                        </div>
                    </DivMainPopupViewDataBase>
                }
            />
            <PopupDefault
                open={openPopupSmsView}
                close={() => setOpenPopupSmsView(false)}
                children={<PopupViewSmsList sms={sms} />}
            />
            <PopupDefault
                open={openPopupDadosContrato}
                close={() => setOpenPopupDadosContrato(false)}
                children={<PopupEditContrato dadosContrato={dadosContrato[0]} closePopup={setOpenPopupDadosContrato} />}
            />
            <PopupDefault
                open={openFormGerarBackup}
                close={() => setOpenFormGerarBackup(false)}
                children={<FormGerarBackup IdCliente={IdCliente} closePopup={setOpenFormGerarBackup} />}
            />
            <PopupDefault
                largura={'80%'}
                altura={'80%'}
                open={openPopupUsuarios}
                close={() => setOpenPopupUsuarios(false)}
                children={<PopupUsuarios softwareId={carregaSoftwareId} />}
            />
            <PopupDefault
                open={openPopupCreateFatura}
                close={() => setOpenPopupCreateFatura(false)}
                children={<NovaFaturaParcelamento close={() => setOpenPopupCreateFatura(false)} clientInfo={clientInfo} />}
            />
            <PopupDefault
                open={openPopupWarningIsTrial}
                close={() => setOpenPopupWarningIsTrial(false)}
                children={
                    <div style={{ maxWidth: '400px', padding: '12px' }}>
                        <Title style={{ textAlign: 'center' }}>Aviso Pipe</Title>
                        <div>
                            <p style={{ lineHeight: '26px', marginTop: '12px', textAlign: 'justify' }}>
                                Ao prosseguir com a essa opção será feito, a alteração no pipedrive do cliente{' '}
                                <strog>
                                    <u>{clientInfo.Nome}</u>
                                </strog>{' '}
                                cadastrado com o email{' '}
                                <strong>
                                    <u>{clientInfo.Email}</u>
                                </strong>
                                . <br /> Deseja prosseguir ?
                            </p>
                            <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ background: theme.colors.colorDelete }}
                                    onClick={() => setOpenPopupWarningIsTrial(false)}
                                >
                                    Não
                                </Button>
                                <Button style={{ background: theme.colors.colorSave }} onClick={criandoNovaVenda}>
                                    Sim
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            />
            <PopupDefault
                open={openPopupNovaVenda}
                close={() => setOpenPopupNovaVenda(false)}
                children={
                    <FormularioFatura
                        listaSoftwareId={vendasInfo || []}
                        titulo={'Nova Venda'}
                        clientInfo={clientInfo}
                        close={() => setOpenPopupNovaVenda(false)}
                    />
                }
            />
            <PopupDefault
                open={openPopupLoginMedx}
                close={fecharPopupAcessoMedx}
                children={
                    <Box padding={2}>
                        <Typography variant='h6' children={"Acesso aberto em outra aba, caso não tenha funcionado, utilize o link abaixo:"}/>
                        <Typography mt={2} variant='body1' children={linkAcessoMedx}/>
                        <Box display={`flex`} mt={2}>
                            <Button style={{marginRight:'8px',background:theme.colors.colorDelete,flex:1}} onClick={fecharPopupAcessoMedx}>Fechar</Button>
                            <Button style={{background:theme.colors.colorSave,flex:1}} onClick={()=>copyInfo(linkAcessoMedx)}>Copiar</Button>
                        </Box>
                    </Box>
                }
            />
             <PopupDefault
                open={openPopupBoletoBloqueio}
                close={()=>setOpenPopupBoletoBloqueio(false)}
                children={
                    <Box padding={2}>
                        
                        {
                            boletoBloqueio.length == 1 ?
                            (
                                <Box display={'flex'} flexDirection={'column'} padding={2}>
                                    <Typography variant='body1' sx={{mb:2}}>Deseja alterar a data de vencimento do boleto {boletoBloqueio.length && boletoBloqueio[0].Id} ?</Typography>
                                    {
                                        boletoBloqueio.map(boletoInfo =>(
                                            <Box key={boletoInfo.Id}>
                                                <Box display={'flex'}>
                                                    <Typography variant='body1' >Data Vencimento:</Typography>
                                                    <Typography variant='body1' sx={{ml:2}}>{dayjs(boletoInfo.Vencimento).format('DD/MM/YYYY')}</Typography>
                                                </Box>
                                                <Box display={'flex'}>
                                                    <Typography variant='body1' >Valor:</Typography>
                                                    <Typography variant='body1' sx={{ml:2}}>{`R$ ${parseFloat(boletoInfo.Valor).toFixed(2)}`}</Typography>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>

                            )
                        :
                            (
                                <Typography variant='body1' children={'Cliente com mais de um boleto atrasado, por favor entrar em contato com o setor financeiro para avaliar a situação.'} />
                            )
                        }
                        <Box display={`flex`} mt={2}>
                            <Button style={{marginRight:'8px',background:theme.colors.colorDelete,flex:1}} onClick={()=>setOpenPopupBoletoBloqueio(false)}>
                                Fechar
                            </Button>
                            { boletoBloqueio.length == 1 && 
                                <Button style={{background:theme.colors.colorSave,flex:1}} onClick={()=>liberaBoleto(boletoBloqueio[0])} >
                                    Liberar
                                </Button>
                            }
                        </Box>
                    </Box>
                }
            />
            <PopupDefault
                open={openPopupPermissoes}
                close={()=>fecharVisualizar()}
                children={
                    <Box padding={2}>
                        <Box padding={2}>
                            <Typography variant='h5' textAlign={'center'}>Permissoes {permissoesSelecionadas?.Usuario??''}</Typography>                            
                        </Box>
                        <Box display={'flex'} flexWrap={'wrap'} maxWidth={'800px'}>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'} >
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Pacientes??false} readOnly disabled />
                                <Typography>Pacientes</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Prontuario??false} readOnly disabled />
                                <Typography>Prontuário</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Agenda??false} readOnly disabled />
                                <Typography>Agenda</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Gerencia??false} readOnly disabled />
                                <Typography>Gerencia</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Estatistica??false} readOnly disabled />
                                <Typography>Estatistica</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Usuarios??false} readOnly disabled />
                                <Typography>Usuários</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Configuracao??false} readOnly disabled />
                                <Typography>Configurações</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Prontuario_Exibe??false} readOnly disabled />
                                <Typography>Prontuário Exibe</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Prontuario_Escreve??false} readOnly disabled />
                                <Typography>Prontuário Escreve</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Atendimentos??false} readOnly disabled />
                                <Typography>Atendimentos</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Financeiro??false} readOnly disabled />
                                <Typography>Financeiro</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Dashboard??false} readOnly disabled />
                                <Typography>Dashboard</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Qualidade??false} readOnly disabled />
                                <Typography>Pesq Qualidade</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Portal??false} readOnly disabled />
                                <Typography>Portal Apoio</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Faturamento_Editar??false} readOnly disabled />
                                <Typography>Editar Fatura</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={'center'} marginRight={'8px'} marginBottom={'8px'}>
                                <input type="checkbox" style={{width:'25px',marginRight:'8px'}} defaultChecked={permissoesSelecionadas?.Permissao_Relatorio_Financeiro??false} readOnly disabled />
                                <Typography>Relatórios Financeiros</Typography>
                            </Box>
                        </Box>
                    </Box>
                }
            />
            <PopupDefault
                open={openPopupPreviewAnexo}
                close={()=>setOpenPopupPreviewAnexo(false)}
                children={
                    <Box padding={2} display={'flex'} flexDirection={'column'}>
                        <Title>Arquivos</Title>
                        <Box mt={2} mb={2} pr={2} maxHeight={'400px'} overflow={'hidden auto'} >
                            {
                                listaArquivoAnexo.map(arquivo => (
                                    <Box minWidth={'300px'} 
                                        key={arquivo.caminhoArquivo} 
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        sx={{cursor:'pointer'}}
                                    >
                                        <Typography onClick={()=>getUrl(arquivo.caminhoArquivo)} flex={1} borderRadius={'4px'} pt={1} pl={1} pb={1}  border={'1px solid #626263'} children={arquivo.caminhoArquivo}/> 
                                        <DeleteIcon sx={{cursor:'pointer',ml:1}} />
                                    </Box>
                                ))
                            }
                        </Box>
                        <Button style={{flex:1}} onClick={()=>refInputFile.current.click()}>Adicionar Anexo</Button>       
                    </Box>                    
                }
            />
           <PopupNovoBoleto/>
            <Dialog open={listDealsEncontrados.length > 0} onClose={()=>setListDealsEncontrados([])}>
                <DialogContent>
                    <Typography variant='h5' textAlign={'center'} children={'Deals encontrados'} mb={2} />
                    {
                        listDealsEncontrados.map(deal =>(
                            <>
                                <Box key={deal.id} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mb={2}>
                                    <Typography variant='h6' children={deal.title} />
                                    <Typography variant='h6' children={`Valor: ${formataCurrency.format(deal.value)}`} />
                                    <Typography variant='h6' children={`Nome Stage: ${deal.stage_name}`} />
                                    <Typography variant='h6' children={`Status: ${deal.status}`} />
                                    <Button onClick={()=>alteraPosVendaV2(deal)}>Selecionar</Button>
                                </Box>
                                <Divider/>
                            </>
                        ))
                    }
                </DialogContent>                
            </Dialog>
            <LoadingScreen display={loading} />
        </DivMain>
    );
}

export default Cliente;