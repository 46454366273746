import styled from 'styled-components';

export const DivPopupEditClient = styled.div`
    display: flex;
    flex-direction: column;
    //background:#000;
    padding: 16px;
    border-radius: 6px;
    width: fit-content;
    position: relative;
    @media (max-width: 767px) {
        width:100%;
        height:100%;
        overflow: hidden auto;
    }
    @media (max-height:650px) {
        max-height:600px;
        overflow:hidden auto;
    }
`;
export const DivInfoPopup = styled.div`
    margin-right:16px;
    display:flex;
    flex-direction:column;
    div {
        /* display: flex;
        align-items: center; */
        //margin: 20px;
        @media screen and (max-width:767px){
            flex-direction:column;
            align-items:flex-start;
        }
    }
    input {
        /* margin: 0 0 0 8px; */
        @media screen and (max-width:767px){
            margin:8px 0 8px 0;
        }
    }
    @media screen and (max-width:767px){
        flex-direction:column;
    }
`;
export const SpanNameField = styled.span`
    font-weight: 600;
`;
export const DivDataForm = styled.div`
    display: inherit;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
export const TextAreaObservacaoCliente = styled.textarea`
    margin: 12px 0;
    min-height: 100px;
    width: 100%;
    resize: none;
    padding: 12px;
    font-size: 16px;
`;
export const DivContentInfo = styled.div`
    
`