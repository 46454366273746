import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import { Button } from '../commonComponents/commonComponents';
import { DivMainItensForm, InputCampoFormBackup, SelectFormBackup, TextAreaFormBackup } from './StyledFormGerarBackup';
import LoadingScreen from '../loadScreen/Loadscreen';
import { useTheme } from 'styled-components';
export default function FormGerarBackup({ IdCliente, closePopup }) {
    const theme = useTheme();
    const [dadosFormulario, setDadosFormulario] = useState({
        Email: '',
        Celular: '',
        SoftwareId: '',
        Motivo: 0,
        Observacoes: '',
    });
    const [motivos, setMotivos] = useState([]);
    const [loading, setLoading] = useState('none');
    const api = useApi();

    async function getMotivosBackup() {
        try {
            const data = await api.get('/backup/GetMotivos');
            if (data.status == 200) {
                setMotivos(data.data);
            } else {
                toast.error('Não carregou motivos backups.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar motivos do backup.');
        }
    }

    async function buscaDadosCliente(email) {
        try {
            const cliente = await api.get(`/backup/busca?email=${email}`);
            if (cliente.status == 200) {
                console.log(cliente.data);
            } else {
                toast.error('Não foi possível encontrar nenhum paciente.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar dados do Cliente');
        }
    }

    async function getDadosCliente(idCliente) {
        try {
            if (idCliente == '') return null;
            setLoading('flex');
            await getMotivosBackup();
            const dadosCliente = await api.get(`/backup/GetListaSoftwareId?idCliente=${idCliente}`);
            if (dadosCliente.status == 400) throw 'Status da Requisição 400';
            if (dadosCliente.status == 200) {
                setDadosFormulario({
                    ...dadosFormulario,
                    SoftwareId: dadosCliente.data[0].Softwareid,
                    Celular: dadosCliente.data[0].Celular,
                    Email: dadosCliente.data[0].Email,
                    Chave: gerarChave(dadosCliente.data[0].CPF),
                });
                setLoading('none');
            } else {
                closePopup(false);
                setLoading('none');
                toast.error('Não encontrou dados do cliente');
            }
        } catch (error) {
            closePopup(false);
            setLoading('none');
            console.log(error);
            toast.error('Não foi possível obter dados do cliente.');
        }
    }

    async function saveSolicitacaoBackup() {
        try {
            //#region Verificar campos obrigatórios
            if (dadosFormulario.Motivo == 0) {
                toast.info('Selecione o motivo, por favor.');
                return null;
            }
            if (dadosFormulario.SoftwareId == '') {
                toast.info('Informe o softwareid, por favor.');
                return null;
            }
            if (dadosFormulario.Celular == '') {
                toast.info('Informe o celular, por favor.');
                return null;
            }
            if (dadosFormulario.Email == '') {
                toast.info('Informe o email, por favor.');
                return null;
            }
            //#endregion
            const saveSolicitacao = await api.post(`/backup/SaveSolicitacao`, dadosFormulario);
            if (saveSolicitacao.status == 200) {
                closePopup(false);
                toast.success('Solicitação salva com sucesso.');
            } else {
                toast.error('Erro ao gerar solicitação.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível solicitar backup.');
        }
    }

    function buscaPaciente(event) {
        if (event.key == 'Enter') {
            console.log(event.target.value);
        }
    }
    function gerarChave(cpf) {
        try {
            const chave = cpf.substring(cpf.length - 4, cpf.length);
            return chave;
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível gerar chave');
            return null;
        }
    }
    useEffect(() => {
        if (IdCliente != '') {
            getDadosCliente(IdCliente);
        }
    }, []);

    return (
        <div style={{ padding: '12px', minWidth: '720px' }}>
            <div>
                <h1>Formulário para gerar backup</h1>
            </div>
            <DivMainItensForm>
                <div>Email de assinante:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o email do assinante"
                    onKeyPress={(event) => buscaPaciente(event)}
                    value={dadosFormulario.Email}
                    onChange={(event) => setDadosFormulario({ ...dadosFormulario, Email: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>SoftwareId:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o softwareId do assinante"
                    value={dadosFormulario.SoftwareId}
                    onChange={(event) => setDadosFormulario({ ...dadosFormulario, SoftwareId: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>Celular:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o celular do assinante"
                    value={dadosFormulario.Celular}
                    onChange={(event) => setDadosFormulario({ ...dadosFormulario, Celular: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>Motivo:</div>
                <SelectFormBackup
                    value={dadosFormulario.Motivo}
                    onChange={(event) => setDadosFormulario({ ...dadosFormulario, Motivo: event.target.value })}
                >
                    <option value={0}>Selecione a opção</option>
                    {motivos.map((item) => {
                        return <option value={item.IdMotivo}>{item.Motivo}</option>;
                    })}
                </SelectFormBackup>
            </DivMainItensForm>
            <DivMainItensForm style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <div>Observações:</div>
                <TextAreaFormBackup
                    value={dadosFormulario.Observacoes}
                    onChange={(event) => setDadosFormulario({ ...dadosFormulario, Observacoes: event.target.value })}
                />
            </DivMainItensForm>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={() => closePopup(false)} style={{ background: theme.colors.colorDelete }}>Cancelar</Button>
                <Button onClick={() => saveSolicitacaoBackup()} style={{ background: theme.colors.colorSave }}>Gerar</Button>
            </div>
            <LoadingScreen display={loading} />
        </div>
    );
}
