import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import PopupEditUser from '~/components/popupEditUser/popupEditUser';
import useApi from '~/service/api';
import {
    DivButtonsUsuario,
    DivInputUsuario,
    DivMainUsuarios,
    DivTabelaUsuarios,
    DivTitulo,
    TableUsuarios,
} from './styledUsuariosSistemaInterno';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
export default function UsuariosSistemaInterno() {
    const [usuarios, setUsuarios] = useState([]);
    const [openPopupEditUser, setOpenPopupEditUser] = useState(false);
    const [loading, setLoading] = useState('none');
    const [userSelected, setUserSelected] = useState({});
    const api = useApi();

    async function carregaAllUsers() {
        try {
            setLoading('flex');
            const responseGetAllUsers = await api.get('/user/GetAllUsers');
            if (responseGetAllUsers.status === 200) {
                setUsuarios(responseGetAllUsers.data);
                setLoading('none');
            } else {
                setLoading('none');
                toast.error('Erro ao carregar lista de usuários.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carrega lista de usuários.');
            setLoading('none');
        }
    }

    function openEditUser(user) {
        setUserSelected(user);
        setOpenPopupEditUser(true);
    }

    useEffect(() => {
        carregaAllUsers();
        // eslint-disable-next-line 
    }, []);

    return (
        <DivMainUsuarios>
            <DivTitulo>
                <h1>Usuários</h1>
            </DivTitulo>
            <DivTabelaUsuarios>
                <TableUsuarios>
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Clientes</th>
                            <th>Agenda</th>
                            <th>Finanças</th>
                            <th>Social</th>
                            <th>Prescreva</th>
                            <th>DocFlix</th>
                            <th>EventoMedx</th>
                            <th>Backups</th>
                            <th>Usuários</th>
                            <th>ContratosRisco</th>
                            <th>Gammyx</th>
                            <th>R1Med</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((user) => {
                            return (
                                <tr key={user.IdUsuario} onClick={() => openEditUser(user)}>
                                    <td>{user.CurrentUser}</td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Clientes} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Agenda} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Financas} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Social} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Prescreva} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Docflix} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.EventoMedx} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Backups} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Usuarios} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.ContratosRisco} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.Gammyx} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                    <td>
                                        <DivInputUsuario>
                                            <input type="checkbox" checked={user.R1Med} readOnly />
                                        </DivInputUsuario>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableUsuarios>
            </DivTabelaUsuarios>
            <DivButtonsUsuario>
                <Button>Criar novo usuário</Button>
            </DivButtonsUsuario>
            <PopupDefault
                open={openPopupEditUser}
                close={() => setOpenPopupEditUser(false)}
                children={
                    <PopupEditUser userInfos={userSelected} close={() => setOpenPopupEditUser(false)} reload={carregaAllUsers} />
                }
            />
            <LoadingScreen display={loading} />
        </DivMainUsuarios>
    );
}
