import styled from 'styled-components';

export const DivMainNotificacoes = styled.div`
    height:100%;
    display:flex;
    flex-direction:column;
`
export const DivTituloPagina = styled.div`
    height:60px;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const DivCampoFormulario = styled.div`
    display:flex;
    align-items:center;
    >span{
        width:fit-content;
        white-space:nowrap;
        margin-right:10px;
    }
`