import { lighten } from 'polished';
import styled from 'styled-components';

export const DivMainBackups = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 16px 16px 16px;
    position: relative;
`;

export const DivBuscaBackups = styled.div`
    height: 40px;
    margin: 12px 0;
    display: flex;
`;

export const DivTabelaBackups = styled.div`
    height: calc(100% - 95px - 52px);
    overflow-y: auto;
    overflow-x: hidden;
    border:1px solid ${p => p.theme.colors.borderColor};
    border-radius:6px;
    padding:0 10px 10px 10px;
`;

export const TableBackups = styled.table`
    border-spacing: 1px;
    thead {
        height:40px;
        tr {
            border-bottom:2px solid ${p => p.theme.colors.borderColor};
            background:${p => p.theme.colors.background};
            position: sticky;
            top: 0;
            th {
                padding: 10px;
                vertical-align: middle;
            }
        }
    }
    tbody {
        tr {
            transition: 0.5s;
            :hover{
                background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
                cursor: default;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            }
            td {
                width: 200px;
                word-break: break-word;
                text-align: center;
                vertical-align: middle;
                padding: 10px;
            }
        }
    }
`;

export const Observacao = styled.p`
    /* text-align: start;
    max-width: 24ch;
    max-height: 80px;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden; */
    text-align: start;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
export const DivTitulo = styled.div`
    text-align: center;
    height: 35px;
`;
export const DivRodape = styled.div`
    height: 40px;
    display: flex;
    margin: 12px 0 0 0;
    justify-content: center;
`;
