import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { DivOptionsSelectAgendaName, DivSelectAgendaName, DivSelectionCustom } from './StyledSelecCustom';

export default function OwnerScheduleSelect({ array_options, initial_option, setValue, field }) {

    const [agendaName, setAgendaName] = useState(initial_option);
    const [openSelectCustom, setOpenSelectCustom] = useState(false);
    function changeName(name) {
        setAgendaName(name);
        setOpenSelectCustom(false);
        setValue(name, field);
    }
    return (
        <DivSelectionCustom>
            <Popup
                trigger={<DivSelectAgendaName>{agendaName}</DivSelectAgendaName>}
                position="bottom center"
                on={'click'}
                open={openSelectCustom}
                arrowStyle={{ color: 'lightgray' }}
                onOpen={() => setOpenSelectCustom(true)}
            >
                <DivOptionsSelectAgendaName>
                    {array_options.map((item, index) => {
                        return (
                            <div
                                key={item}
                                onClick={() => {
                                    changeName(item);
                                    setOpenSelectCustom(false);
                                }}
                            >
                                {item}
                            </div>
                        );
                    })}
                </DivOptionsSelectAgendaName>
            </Popup>
        </DivSelectionCustom>
    );
}
