import React, { useState, useRef, useEffect } from 'react';
import { Button, Title } from '../commonComponents/commonComponents';
import dayjs from 'dayjs';
import {
    DivButton,
    DivContentElements,
    DivIdBoleto,
    DivLeftBoletoEdit,
    DivRightBoletoEdit,
    SpanCancelado,
    IconAiOutlineCloseCircle,
    DivMainEditBoleto,
    DivMainBoletoContent,
    DivListClientes,
    IconMdContentCopy,
    DivMainPopupParcelarBoleto,
} from './StyledBoletoEdit';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '../loadScreen/Loadscreen';
import Popup from 'reactjs-popup';
import { useTheme } from 'styled-components';
import PopupDefault from '../popupDefault/PopupDefault';
import { FormControl, InputLabel, TextField, Select as SelectMui, MenuItem, FormControlLabel, Checkbox, Box, Button as ButtonMui, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
export default function BoletoEdit({ clientBoletos, close, id, reload }) {    
    const theme = useTheme();
    const [updateInfoBoleto, setUpdateInfoBoleto] = useState();
    const maxWidthInput = { maxWidth: '170px' };
    const api = useApi();
    const [loadScreen, setLoadScreen] = useState('none');
    const [popupCancelBoleto, setPopupCancelBoleto] = useState(false);
    const [popupRestauraBoleto, setPopupRestauraBoleto] = useState(false);
    const [listClientsSearch, setListClientsSearch] = useState([]);
    const [hideDisplay, setHideDisplay] = useState(true);
    const [parcelar, setParcelar] = useState(false);
    const [numParcela, setNumParcela] = useState(1);
    const [openPopupAvisoParcela, setOpenPopupAvisoParcela] = useState(false);
    const [openPopupParcelamentoBoleto, setOpenPopupParcelamentoBoleto] = useState(false);
    async function salvar(boleto_info) {
        try {            
            if(boleto_info.Pago > 0 && (boleto_info.TID == '' || !boleto_info.TID)){
                toast.warning('O campo TID é obrigatório')
                return;
            }else{
                await salvarEdicaoBoleto(boleto_info);
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro, incapaz de processar.');
        }
    }

    async function salvarEdicaoBoleto(boleto_info) {
        try {
            setLoadScreen('flex');
            const data = await api.post(`/boleto/UpdateTicketById`, {
                ...boleto_info,
                Valor: String(boleto_info.Valor).replace(/,/g, '.'),
            });
            if (data.status === 200 && data.data.toLowerCase() === 'success') {
                toast.success('Salvo com sucesso.');
                setLoadScreen('none');
                reload();
                close(false);
            }
        } catch (error) {
            setLoadScreen('none');
            console.log(error);
            toast.error('Não foi possível salvar a edição do boleto');
        }
    }

    async function parcelarBoleto(boleto_info, numParcela) {
        try {
            setLoadScreen('flex');
            const responseParcelarBoleto = await api.post('/boleto/ParcelarBoleto', {
                ...boleto_info,
                Valor: String(boleto_info.Valor).replace(/,/g, '.'),
                parcelas: numParcela,
            });
            if (responseParcelarBoleto.status == 200) {
                toast.success('Boleto parcelado com suceso.');
                setOpenPopupParcelamentoBoleto(false);
                setOpenPopupAvisoParcela(false);
                reload();
                close(false);
                setLoadScreen('none');
            } else {
                setLoadScreen('none');
                toast.error('Erro ao tentar parcelar o boleto.');
            }
        } catch (error) {
            setLoadScreen('none');
            console.error(error);
            toast.error('Não foi possível fazer o parcelamento do boleto.');
        }
    }

    function loadData(data) {
        try {
            return dayjs(data).format('YYYY-MM-DD');                         
        } catch (error) {
            console.log(error);
            return '2021-01-01';
        }
    }

    async function getCliente(event) {
        if (event.key === 'Enter') {
            try {
                setLoadScreen('flex');
                const responseGetClientById = await api.get(`/cliente/GetClientById?Id=${event.target.value}`);
                if (responseGetClientById.status === 200) {
                    setListClientsSearch(responseGetClientById.data);
                    setHideDisplay(false);
                    setLoadScreen('none');
                }
            } catch (error) {
                toast.error('Erro ao buscar ');
                setLoadScreen('none');
            }
        } else {
            return null;
        }
    }
    function copyInfo(info) {
        try {
            navigator.clipboard.writeText(info);
            toast.success('Copiado com sucesso');
        } catch (error) {
            toast.error('error ao copiar');
        }
    }    
    useEffect(() => {
        const dadosBoleto = clientBoletos.find((item) => item.Id === id);
        console.log(dadosBoleto)     
        //setUpdateInfoBoleto(dadosBoleto);
        setUpdateInfoBoleto({
            ArqRemessa: dadosBoleto.ArqRemessa,
            Cancelado: dadosBoleto.Cancelado,
            Chave: dadosBoleto.Chave,
            Classificacao: dadosBoleto.Classificacao,
            CorpoNF: dadosBoleto.CorpoNF,
            Data_Pagamento: loadData(dadosBoleto.Data_Pagamento),
            Emissao: loadData(dadosBoleto.Emissao),
            Envia_Boleto: dadosBoleto.Envia_Boleto,
            Envio_em: loadData(dadosBoleto.Envio_em),
            ExportaOFX: dadosBoleto.ExportaOFX,
            Id: dadosBoleto.Id,
            Id_da_Venda: dadosBoleto.Id_da_Venda,
            Id_do_Cliente: dadosBoleto.Id_do_Cliente,
            NFE: dadosBoleto.NFE,
            Nome: dadosBoleto.Nome,
            Obs: dadosBoleto.Obs,
            Pago: dadosBoleto.Pago,
            Plano_Mensal: dadosBoleto.Plano_Mensal,
            Produtividade: dadosBoleto.Produtividade,
            Produtividade_Paga: dadosBoleto.Produtividade_Paga,
            RPS: dadosBoleto.RPS,
            RPS_Data: loadData(dadosBoleto.RPS_Data),
            Referente_a: dadosBoleto.Referente_a,
            Referente_a_1: dadosBoleto.Referente_a_1,
            Resposta: dadosBoleto.Resposta,
            SMS: dadosBoleto.SMS,
            Split: dadosBoleto.Split,
            TID: dadosBoleto.TID,
            Valor: dadosBoleto.Valor,
            Vencimento: loadData(dadosBoleto.Vencimento),
            envia_cartao: dadosBoleto.envia_cartao,
            parcelas: dadosBoleto.parcelas,
            payment_status: dadosBoleto.payment_status,
        });
    }, []);

    return (
        <DivMainEditBoleto>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0 16px 0', alignItems: 'center' }}>
                <Title>Boleto</Title>
                {updateInfoBoleto?.Cancelado ? <SpanCancelado>Cancelado</SpanCancelado> : ''}
            </div>
            <div style={{ width: '100%' }}>
                <DivIdBoleto>
                    <div> {`Id do boleto: ${updateInfoBoleto?.Id}`} </div>
                    <div>{`Id Venda: ${updateInfoBoleto?.Id_da_Venda}`} </div>
                    <div>
                        {`Chave: ${updateInfoBoleto?.Chave ? updateInfoBoleto.Chave : '-'}`}{' '}
                        <IconMdContentCopy
                            title="copia link do pagamento"
                            onClick={() => copyInfo(`http://apoio.medx.med.br/ecommerce/checkout.html?localizador=${updateInfoBoleto.Chave}`)}
                        />
                    </div>
                </DivIdBoleto>
                <div style={{ position: 'relative' }}>
                    <TextField sx={{mb:3}} variant='outlined' label={'Nome'} required fullWidth name='Nome'
                        size='small'
                        focused
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}                        
                        value={updateInfoBoleto?.Nome}
                        InputProps={{defaultValue:updateInfoBoleto?.Nome}}
                        onKeyDown={ (event) => getCliente(event)}
                        onChange={(event) => setUpdateInfoBoleto({ ...updateInfoBoleto, Nome: event.target.value })}
                    />                    
                    <DivListClientes hide={hideDisplay} onMouseLeave={() => setHideDisplay(true)}>
                        {listClientsSearch.map((client) => {
                            return (
                                <div
                                    key={client.Codigo_do_Cliente}
                                    onClick={() => {
                                        setUpdateInfoBoleto({
                                            ...updateInfoBoleto,
                                            Id_do_Cliente: client.Codigo_do_Cliente,
                                            Nome: client.Nome,
                                        });
                                        setHideDisplay(true);
                                    }}
                                >
                                    {client.Nome}
                                </div>
                            );
                        })}
                    </DivListClientes>
                </div>
                <DivMainBoletoContent>
                    <DivLeftBoletoEdit>
                        <DivContentElements>
                            <TextField sx={{mb:3}} variant='outlined' label={'Referente_a'} required fullWidth name='Referente_a'
                                size='small'
                                focused
                                inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                                value={updateInfoBoleto?.Referente_a}
                                InputProps={{defaultValue:updateInfoBoleto?.Referente_a}}
                                onChange={(event) =>
                                    setUpdateInfoBoleto({ ...updateInfoBoleto, Referente_a: event.target.value })
                                }
                            />                            
                        </DivContentElements>
                        <TextField variant='outlined' label={'Valor'} required name='Valor'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='number'                                
                            placeholder='Valor'                                                                                 
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "0.01", min:'0'}}                                                        
                            value={updateInfoBoleto?.Valor}
                            onChange={(event) => setUpdateInfoBoleto({ ...updateInfoBoleto, Valor: event.target.value })}
                        />
                        <TextField variant='outlined' label={'Valor Pago'} required name='Valor Pago'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='number'                                
                            placeholder='Valor Pago'                                                                                 
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "0.01", min:'0'}}                                                        
                            value={updateInfoBoleto?.Pago}
                            onChange={(event) => setUpdateInfoBoleto({ ...updateInfoBoleto, Pago: event.target.value })}
                        />
                        <TextField variant='outlined' label={'Emissão'} required name='Emissão'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Valor Total'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            value={updateInfoBoleto?.Emissao ?? ''}
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    Emissao: event.target.value,
                                })
                            }
                        />
                        <TextField variant='outlined' label={'RPS'} required name='RPS'
                            size='small'
                            sx={{mb:2}}
                            focused
                            type='number'                                
                            placeholder='RPS'                                                                                
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "1", min:0}}
                            inputMode='decimal'
                            defaultValue={updateInfoBoleto?.RPS ?? 0}
                            onChange={(event) => setUpdateInfoBoleto({ ...updateInfoBoleto, RPS: event.target.value })}
                        />
                        <TextField variant='outlined' label={'NFE'} required name='NFE'
                            size='small'
                            sx={{mb:2}}
                            focused
                            type='number'                                
                            placeholder='NFE'                                                                                
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "1", min:0}}                            
                            value={updateInfoBoleto?.NFE?? 0}
                            onChange={(event) => setUpdateInfoBoleto({ ...updateInfoBoleto, NFE: event.target.value })}
                        />
                        <FormControl fullWidth focused sx={{mb:2}}>
                            <InputLabel id="classificacao">Classificação</InputLabel>
                            <SelectMui                                
                                size='small'
                                labelId="classificacao"
                                id="classificacao"                            
                                label="Classificação"                                
                                value={updateInfoBoleto?.Classificacao?? 0}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}                                
                                onChange={(event) =>
                                    setUpdateInfoBoleto({ ...updateInfoBoleto, Classificacao: event.target.value })
                                }                                                                                                                                       
                            >   
                                <MenuItem value="0"></MenuItem>
                                <MenuItem value="1">Ativação</MenuItem>
                                <MenuItem value="2">Renovação</MenuItem>
                                <MenuItem value="3">Consultoria</MenuItem>
                                <MenuItem value="4">SMS</MenuItem>
                                <MenuItem value="5">Medx social</MenuItem>
                                <MenuItem value="6">Prescreva</MenuItem>
                                <MenuItem value="7">Eventos/Cursos</MenuItem>
                                <MenuItem value="8">Docflix</MenuItem>                                
                                <MenuItem value="9">Evolução Inteligente</MenuItem>                                
                                <MenuItem value="10">IA</MenuItem>                                
                                <MenuItem value="11">Whatsapp</MenuItem>                                
                            </SelectMui>
                        </FormControl>                       
                    </DivLeftBoletoEdit>
                    <DivRightBoletoEdit>
                        <TextField sx={{mb:3}} variant='outlined' label={'Referente_a_1'} required fullWidth name='Referente_a_1'
                            size='small'
                            focused
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                            value={updateInfoBoleto?.Referente_a_1}
                            InputProps={{defaultValue:updateInfoBoleto?.Referente_a_1}}
                            onChange={(event) =>
                                setUpdateInfoBoleto({ ...updateInfoBoleto, Referente_a_1: event.target.value })
                            }
                        />
                        <TextField variant='outlined' label={'Vencimento'} required name='Vencimento'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Vencimento'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}                            
                            value={updateInfoBoleto?.Vencimento}
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    Vencimento:event.target.value,
                                })
                            }
                        />
                        <TextField variant='outlined' label={'Data do Pagamento'} required name='Data do Pagamento'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Data do Pagamento'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            defaultValue={updateInfoBoleto?.Data_Pagamento ?? ''}
                            value={updateInfoBoleto?.Data_Pagamento ?? ''}                         
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    Data_Pagamento: event.target.value,
                                })
                            }
                        />
                        <TextField variant='outlined' label={'Data de Envio'} required name='Data de Envio'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Data de Envio'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            value={updateInfoBoleto?.Envio_em ?? ''}                            
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    Envio_em: event.target.value,
                                })
                            }
                        />                       
                        <TextField variant='outlined' label={'RPS Data'} required name='RPS Data'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='RPS Data'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            value={updateInfoBoleto?.RPS_Data ?? ''}                           
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    RPS_Data: event.target.value,
                                })
                            }
                        />
                        <FormControlLabel 
                            label="Enviar Boleto"
                            sx={{marginBottom:'14px'}}
                            defaultChecked={false} 
                            control={
                                <Checkbox  checked={updateInfoBoleto?.Envia_Boleto == 1 ? true : false} />
                            }
                            onChange={(event) => {                                
                                setUpdateInfoBoleto({ ...updateInfoBoleto, Envia_Boleto: event.target.checked ? '1' : '-1' });
                            }}

                        />
                        <TextField variant='outlined' label={'TID'} required name='TID'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='text'                  
                            placeholder='TID'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                            value={updateInfoBoleto?.TID ?? ''}                           
                            onChange={(event) =>
                                setUpdateInfoBoleto({
                                    ...updateInfoBoleto,
                                    TID: event.currentTarget.value,
                                })
                            }
                        />
                    </DivRightBoletoEdit>
                </DivMainBoletoContent>
            </div>
            <DivButton>
                {updateInfoBoleto?.Cancelado ? (
                    <Button style={{flex:1}} onClick={() => setPopupRestauraBoleto(true)}>Restaurar fatura</Button>
                ) : (
                    <Button onClick={() => setOpenPopupAvisoParcela(true)} style={{ background: theme.colors.colorDelete,flex:1 }}>
                        Cancelar fatura
                    </Button>
                )}
                <Button style={{ background: theme.colors.colorSave,flex:1 }} onClick={() => salvar(updateInfoBoleto)}>
                    Salvar
                </Button>
            </DivButton>
            <LoadingScreen display={loadScreen} />
            <Popup
                closeOnDocumentClick={false}
                open={popupRestauraBoleto}
                onClose={() => setPopupRestauraBoleto(false)}
                overlayStyle={{
                    width: '100%',
                    height: '100%',
                }}
                contentStyle={{
                    background: '#000000b5',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(4px)',
                }}
            >
                <div style={{ width: 'fit-content', height: 'fit-content' }}>
                    <p style={{ fontSize: '22px' }}>Deseja restaurar essa fatura ?</p>
                    <DivButton style={{ margin: '20px 0 0 0' }}>
                        <Button
                            style={{ background: '#115213' }}
                            onClick={() => {
                                setPopupRestauraBoleto(false);
                                salvar({ ...updateInfoBoleto, Cancelado: 0 });
                            }}
                        >
                            Sim
                        </Button>
                        <Button style={{ background: '#8e0000' }} onClick={() => setPopupRestauraBoleto(false)}>
                            Não
                        </Button>
                    </DivButton>
                </div>
            </Popup>
            <PopupDefault
                close={() => setOpenPopupAvisoParcela(false)}
                children={
                    <DivMainPopupParcelarBoleto>
                        <p>Deseja cancelar a fatura ? O cliente cancelou a compra ?</p>
                        <p>Ou deseja parcelar a fatura ?</p>
                        <div>
                            <Button
                                style={{ background: theme.colors.colorDelete }}
                                onClick={async () => {
                                    await salvar({ ...updateInfoBoleto, Cancelado: 1 });
                                    setOpenPopupAvisoParcela(false);
                                }}
                            >
                                Cancelar fatura
                            </Button>
                            <Button
                                style={{ background: theme.colors.colorSave }}
                                onClick={() => setOpenPopupParcelamentoBoleto(true)}
                            >
                                Parcelar fatura
                            </Button>
                        </div>
                    </DivMainPopupParcelarBoleto>
                }
                open={openPopupAvisoParcela}
            />
            <Dialog open={openPopupParcelamentoBoleto} onClose={() => setOpenPopupParcelamentoBoleto(false)} PaperProps={{sx:{maxWidth:'unset'}}}>
                <DialogContent>
                    <Box display={'flex'}>
                        <FormControl focused sx={{mb:2,width:'200px'}}>
                            <InputLabel id="numeroParcela">Informe o número de parcelas</InputLabel>
                            <SelectMui                                
                                size='small'
                                labelId="numeroParcelas"
                                id="numeroParcelas"           
                                label="Informe o número de parcelas"
                                value={numParcela}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}
                                onChange={(event) => setNumParcela(event.target.value)}                                                                                                                                                                   
                            >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="11">11</MenuItem>                             
                                <MenuItem value="12">12</MenuItem>                             
                            </SelectMui>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ButtonMui
                        size='small'
                        
                        sx={{ background: theme.colors.colocolorSaverDelete,mr:2 }}
                        onClick={() => setOpenPopupParcelamentoBoleto(false)}
                        variant='outlined'                                
                    >
                        Cancelar
                    </ButtonMui>
                    <ButtonMui
                        size='small'
                        
                        sx={{ background: theme.colors.colorSave }}
                        onClick={() => parcelarBoleto(updateInfoBoleto, numParcela)}
                        variant='contained'
                        children={'Parcelar fatura'}
                    >                                
                    </ButtonMui>
                </DialogActions>
            </Dialog>
            
        </DivMainEditBoleto>
    );
}
