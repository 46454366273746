import { Box } from "@mui/material";
import styled from "styled-components";

export const BoxMain = styled(Box)`
    display:flex;
    flex-direction:column;
    width:100%;
    input{
        box-shadow:unset!important;
    }

`