import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { NavLink } from 'react-router-dom';

export const DivMain = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(p) => p.theme.colors.backgroundMenu};
    width: 180px;
    font-size: 18px;

    @media (max-width: 1300px) {
        width: 45px;
        overflow: hidden;
        transition: 0.5s;
        position: absolute;
        z-index: 11;
        :hover {
            width: 180px;
            box-shadow: 20px 0px 10px -12px rgb(0 0 0 / 62%);
        }
    }
`;

export const DivMenu = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    overflow:hidden auto;
    > img {
        margin: 16px 0 16px 12px;
        width: 150px;
    }

    > div {
    }
`;

export const DivPerfilInfo = styled.div`
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors.colorMenu};
    padding: 12px 0px;
    cursor: pointer;
    &:hover {
        background: #fefefe;
        color: #000;
    }

    > img {
        width: 25px;
        border-radius: 50%;
        margin: 0 12px 0 12px;
    }
`;

export const ContainerItemMenu = styled(NavLink)`
    padding: 12px 12px;
    margin: 2px 0px;
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background: ${(p) => (p.selected ? '#fefefe!important' : 'unset')}; */
    /* color: ${(p) => (p.selected ? '#000!important' : 'inherit')}; */

    color: ${(p) => p.theme.colors.colorMenu};
    text-decoration: none;

    :hover {
        background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
        color: ${(p) => p.theme.colors.colorHoverTextMenu};
        cursor: pointer;
        /* transform: scale(1.02); */
        /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    }

    > div {
        margin-right: 20px;

        > svg {
            font-size: 20px;
        }
    }

    > span {
    }
`;
export const ContainerItemSubMenu = styled(NavLink)`
    padding: 12px 12px;
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background: ${(p) => (p.selected ? '#fefefe!important' : 'unset')}; */
    /* color: ${(p) => (p.selected ? '#000!important' : 'inherit')}; */
    background: ${(p) => p.theme.colors.backgroundSubMenu};
    color: ${(p) => p.theme.colors.colorMenu};
    text-decoration: none;

    :hover {
        background: ${(p) => lighten(0.15, p.theme.colors.backgroundMenu)};
        cursor: pointer;
        color: ${(p) => lighten(0.15, p.theme.colors.colorHoverTextMenuSubItem)};
        /* transform: scale(1.02); */
        /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    }

    > div {
        margin-right: 20px;

        > svg {
            font-size: 20px;
        }
    }

    > span {
    }
`;

export const ContainerLogo = styled.div`
    width: 100%;
    padding: 20px;
    @media (max-width: 1300px) {
        padding: 20px 0 20px 10px;
    }
`;

export const LogoComum = styled.img`
    :hover {
        transform: scale(1.1);
    }
    width: 100%;
    transition-duration: 0.3s;
    @media (max-width: 1300px) {
        min-width: 150px;
        max-width: 150px;
        display: none;
        :hover {
            display: block !important;
        }
    }
`;

export const ImgLogoMobile = styled.img`
    display: none;
    @media (max-width: 1300px) {
        display: block !important;
        min-width: 30px !important;
        max-width: 30px !important;
    }
`;