import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import { NavLink } from 'react-router-dom';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import {
    ContainerDivCardGridInfo,
    DivActionCard,
    DivCardGridInfo,
    DivContentInfo,
    DivFilter,
    DivFilterSearch,
    DivFooter,
    DivInfoCard,
    DivInputSearch,
    DivOptionFiltro,
    DivOptionsFilter,
    DivTable,
    DivTitle,
    SpanFiltro,
    TextAreaRps,
    IconBiFilterAlt,
} from '../financas/StyledFinancas';
import { Button, Input } from '~/components/commonComponents/commonComponents';
import { toast } from 'react-toastify';
import BoletoEdit from '~/components/boletoEdit/BoletoEdit';
import NovaFaturaParcelamento from '~/components/novaFaturaPacerlamento/NovaFaturaPacerlamento';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import TabelaBoleto from '~/components/tabelaBoleto/tabelaBoleto';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import FormularioFatura from '~/components/formularioFatura/formularioFatura';
import TabelaModelo from '~/components/tabelaModelo/tabelaModelo';
export default function Financas() {
    //#region declaração dos estados (states)
    const [boletosList, setBoletosList] = useState([]);
    const [listContratos, setListContratos] = useState([]);
    const api = useApi();
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupNovaFaturaParcelamento, setOpenPopupNovaFaturaParcelamento] = useState(false);
    const [openPopupRps, setOpenPopupRps] = useState(false);
    const [openPopupRpsNaoEmitidas, setOpenPopupRpsNaoEmitidas] = useState(false);
    const [openPopupSelectData, setOpenPopupSelectData] = useState(false);
    const [openPopupInfoDataAlterada, setOpenPopupInfoDataAlterada] = useState(false);
    const [openPopupOrganizaDatasRps, setOpenPopupOrganizaDatasRps] = useState(false);
    const [openPopupEditDate, setOpenPopupEditDate] = useState(false);
    const [openPopupFiltros, setOpenPopupFiltros] = useState(false);
    const [dadosRps, setDadosRps] = useState('');
    const [boletoId, setBoletoId] = useState(0);
    const [selected, setSelected] = useState([true, false, false, false]);
    const [loadScreen, setLoadScreen] = useState('none');
    const [boletoDateEdit, setBoletoDateEdit] = useState({});
    const [filtros, setFiltros] = useState({
        de: dayjs(new Date()).format('YYYY-MM-DD'),
        ate: dayjs(new Date()).format('YYYY-MM-DD'),
        ordenado: 'Data Pagamento',
        ordem: 'Crescente',
    });
    //#endregion

    //#region Declaração das referencias (useRef)
    const refDataInicio = useRef();
    const refDataFinal = useRef();
    //#endregion

    async function loadBoletos(id) {
        try {
            setLoadScreen('flex');
            const { data: boletos } = await api.get(`/Boleto/GetBoletoById?Id=${id || ''}`);
            if (Array.isArray(boletos)) {
                setBoletosList(boletos);
                setLoadScreen('none');
            } else {
                toast.info('Não foi possível realizar a busca');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar a busca');
            setLoadScreen('none');
        }
    }

    function openBoletoSelected(boleto) {
        setBoletoId(boleto.Id);
        setOpenPopup(true);
    }
    // eslint-disable-next-line
    function verificaRpsNaoGeradas() {
        if (dadosRps.rpsNaoGeradas) {
            if (dadosRps.rpsNaoGeradas.length > 0) {
                setOpenPopupRpsNaoEmitidas(true);
            } else {
                toast.info('Lista de RPS não geradas está zerada.');
            }
        }
    }

    async function getBoletosCancelados(id) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/boleto/getBoletosCancelados?id=${id}`);
            if (data.status === 200) {
                setBoletosList(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de boletos.');
            setLoadScreen('none');
        }
    }
    // eslint-disable-next-line
    async function getBoletosAtrasados() {
        try {
            setLoadScreen('flex');
            const today = dayjs(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
            const data = await api.get(`/boleto/GetBoletosAtrasados?inicio=${today}`);
            if (data.status === 200) {
                setBoletosList(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de boletos.');
            setLoadScreen('none');
        }
    }

    async function GetContratosRisco() {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/vendas/GetContratosRisco`);
            if (data.status === 200) {
                setListContratos(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar os contatos em risco');
            setLoadScreen('none');
        }
    }
    // eslint-disable-next-line
    async function GetNotasPendentes() {
        try {
            setLoadScreen('flex');
            const data = await api.get(`Boleto/GetBoletosPagosSemRpsOuNfe`);
            //const data = { status: 200, data: notasPendentes };
            if (data.status === 200) {
                setBoletosList(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar os contatos em risco');
            setLoadScreen('none');
        }
    }
    async function GeraNotaFiscal(inicio, final) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`boleto/GeraNotaCPFCorreto?inicio=${inicio}&final=${final}`);
            if (data.status === 200) {
                setOpenPopupSelectData(false);
                setDadosRps(data.data);
                if (data.data.todasRps === 'ErrorDatas') {
                    setOpenPopupOrganizaDatasRps(true);
                    setLoadScreen('none');
                    return;
                }
                if (data.data.todasRps === 'Error') {
                    setOpenPopupRpsNaoEmitidas(true);
                    setLoadScreen('none');
                    toast.warning('Erro ao gerar algumas rps verifique os motivos.');
                    return;
                }
                if (data.data.listaDatasAlteradas.length > 0) {
                    setOpenPopupInfoDataAlterada(true);
                }
                window.open(data.data.todasRps, '_blank');
                setLoadScreen('none');
            } else {
                toast.error('Erro ao gerar notas fiscais.');
                setLoadScreen('none');
            }
        } catch (error) {
            if (error.response.status === 404) {
                toast.error(error.response.data);
                setLoadScreen('none');
                return;
            }
            console.log(error);
            toast.error('Não foi possível gerar as notas fiscais.');
            setLoadScreen('none');
        }
    }

    async function BuscaNotasFiscais() {
        try {
            const inicio = refDataInicio.current.value;
            const final = refDataFinal.current.value;
            if (inicio === '') {
                toast.warning('Informe horario de inicio.');
                return;
            }
            if (final === '') {
                toast.warning('Informe horario de final.');
                return;
            }
            GeraNotaFiscal(inicio, final);
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar busca das notas fiscais.');
        }
    }
    // eslint-disable-next-line
    function baixaArquivo(path) {
        let blob = new Blob([path], { type: 'text/plain;charset=utf-8;' });
        const link = window.document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.txt';
        link.click();
        window.URL.revokeObjectURL(link.href);
        return link;
    };

    function selectBoletoEditDate(boletoDateEdit) {
        setBoletoDateEdit(boletoDateEdit);
        setOpenPopupEditDate(true);
    };

    function organizaRps(rpsEditada) {
        setDadosRps((dadosRps) => {
            let busca = dadosRps.rpsNaoGeradas.findIndex((rps) => rps.idBoleto === rpsEditada.idBoleto);
            dadosRps.rpsNaoGeradas[busca] = { ...dadosRps.rpsNaoGeradas[busca], RPS_Data: rpsEditada.RPS_Data };
            return dadosRps;
        });
        setOpenPopupEditDate(false);
    };

    async function geraRpsBoletosOrganizados() {
        try {
            setLoadScreen('flex');
            const { rpsNaoGeradas } = dadosRps;
            const responsePostGeraRpsBoletosOrganizados = await api.post(`/boleto/PostGeraRpsBoletosOrganizados`, rpsNaoGeradas);
            if (responsePostGeraRpsBoletosOrganizados.status === 200) {
                window.open(responsePostGeraRpsBoletosOrganizados.data.todasRps, '_blank');
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error("Não foi possível gerar as rps's");
            setLoadScreen('none');
        }
    };
    
    async function getBoletosFiltrados() {
        setLoadScreen('flex');
        try {
            const resultGetBoletosFiltrados = await api.get(
                `/boleto/GetBoletosByPeriodo?inicio=${filtros.de}&final=${filtros.ate}`,
            );
            setBoletosList(resultGetBoletosFiltrados.data);
        } catch (error) {
            toast.error(`Erro ao tentar buscar pelos boletos.`);
        }
        setLoadScreen('none');
    };

    const header = ['Vencimento', 'Valor', 'Cliente', 'Descrição', 'Pago', 'Dt Pag.', 'NFE', 'Classificação', 'RPS'];
    
    function retornaValor(key, ObjCompleto) {
        const formataCurrency = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        const classificacaoNome = [
            '',
            'Ativação',
            'Renovação',
            'Consultoria',
            'SMS',
            'MEDX.Social',
            'Prescreva',
            'Eventos/Cursos',
            'Docflix',
        ];
        if (key == 'Vencimento') return dayjs(ObjCompleto.Vencimento)?.format('DD/MM/YYYY');
        if (key == 'Valor') return formataCurrency.format(String(ObjCompleto.Valor).replace(/,/g, '.'));
        if (key == 'Cliente') return ObjCompleto.Nome;
        if (key == 'Descrição') return ObjCompleto.Referente_a + ' ' + ObjCompleto.Referente_a_1;
        if (key == 'Pago') return formataCurrency.format(String(ObjCompleto.Pago).replace(/,/g, '.'));
        if (key == 'Dt Pag.') return ObjCompleto.Data_Pagamento ? dayjs(ObjCompleto.Data_Pagamento).format('DD/MM/YYYY') : '';
        if (key == 'NFE') return ObjCompleto.NFE;
        if (key == 'Classificação') return classificacaoNome[ObjCompleto.Classificacao];
        if (key == 'Classificação') return classificacaoNome[ObjCompleto.Classificacao];
        if (key == 'RPS') return ObjCompleto.RPS;
        return '';
    };
    function ordemCrescenteRps() {
        let arrayOrganizado = boletosList.sort((a, b) => {return (b.RPS - a.RPS)})             
        setBoletosList(arrayOrganizado);
        
    }    

    useEffect(() => {
        loadBoletos('');
        // eslint-disable-next-line
    }, []);

    return (
        <ContainerDivCardGridInfo>
            <DivFilterSearch>
                <DivInputSearch>
                    <Input
                        placeholder="Pesquise pelo nome, id ou key da fatura"
                        onKeyPress={(event) => {
                            return event.key === 'Enter' ? loadBoletos(event.currentTarget.value) : '';
                        }}
                    />
                    <IconBiFilterAlt title="Filtros" onClick={() => setOpenPopupFiltros(!openPopupFiltros)} />
                </DivInputSearch>
                {openPopupFiltros && (
                    <div style={{ display: 'flex', width: '100%', padding: '0 10px', margin: '12px 0 0 0' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 8px' }}>
                                <span>
                                    <nobr>Periodo de:</nobr>
                                </span>
                                <input
                                    type="date"
                                    style={{ margin: '0 8px' }}
                                    value={filtros.de}
                                    onChange={({ target }) => setFiltros({ ...filtros, de: target.value })}
                                    max={'9999-12-31'}
                                    min={'1900-01-01'}
                                />
                                <span>até:</span>
                                <input
                                    type="date"
                                    style={{ margin: '0 0 0 8px' }}
                                    value={filtros.ate}
                                    onChange={({ target }) => setFiltros({ ...filtros, ate: target.value })}
                                    max={'9999-12-31'}
                                    min={'1900-01-01'}
                                />
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 8px' }}>
                                <span>
                                    <nobr>Ordenado por:</nobr>
                                </span>
                                <select
                                    value={filtros.ordenado}
                                    style={{ margin: '0 0 0 8px' }}
                                    onChange={({ target }) => setFiltros({ ...filtros, ordenado: target.value })}
                                >
                                    {[
                                        'Vencimento',
                                        'Valor',
                                        'Cliente',
                                        'Descrição',
                                        'Valor Pago',
                                        'Data Pagamento',
                                        'Nº NFE',
                                        'Classificação',
                                        'Nº RPS',
                                    ].map((option) => {
                                        return (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 8px' }}>
                                <span>Ordem:</span>
                                <select
                                    value={filtros.ordem}
                                    style={{ margin: '0 0 0 8px' }}
                                    onChange={({ target }) => setFiltros({ ...filtros, ordem: target.value })}
                                >
                                    {['Crescente', 'Decrescente'].map((ordem) => (
                                        <option key={ordem} value={ordem}>
                                            {ordem}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                        </div>
                        <Button onClick={() => getBoletosFiltrados()}>Filtrar</Button>
                        {/* <Button onClick={() => ordemCrescenteRps()}>Ordenar RPS</Button> */}
                    </div>
                )}
            </DivFilterSearch>
            {selected[3] ? (
                listContratos.map((item) => {
                    return (
                        <DivCardGridInfo>
                            <DivInfoCard>
                                <DivTitle>
                                    <h1>{item.Nome}</h1>
                                </DivTitle>
                                <DivContentInfo>
                                    <div>
                                        <span>Atividade: {item.Atividade}</span>
                                        <span>Email: {item.Email}</span>
                                        <span>Status: {item.Status}</span>
                                        <span>Data: {dayjs(item.Data).format('DD/MM/YYYY')}</span>
                                    </div>
                                    <div>
                                        <span>
                                            Celular: {item.ddd != null ? '(' + item.ddd + ')' : ''}
                                            {item.Celular}
                                        </span>
                                        <span>
                                            Telefone: {item.ddd != null ? '(' + item.ddd + ')' : ''}
                                            {item.Telefone}
                                        </span>
                                        <span>Vigencia: {dayjs(item.Pago_ate).format('DD/MM/YYYY')}</span>
                                    </div>
                                </DivContentInfo>
                                <DivActionCard>
                                    <Button>
                                        <a
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            href={`https://api.whatsapp.com/send?phone=55${item.Celular}`}
                                        >
                                            {' '}
                                            Entrar em Contato
                                        </a>
                                    </Button>
                                    <Button>
                                        {' '}
                                        <NavLink to={`/app/cliente/${item.Id_do_Cliente}`}>Visualizar perfil</NavLink>
                                    </Button>
                                </DivActionCard>
                            </DivInfoCard>
                        </DivCardGridInfo>
                    );
                })
            ) : (
                <TabelaModelo
                    header={header}
                    callbackHeader={{nome:'RPS',fn:()=>{ordemCrescenteRps()}}}
                    fnRetornaValor={retornaValor}
                    clientes={boletosList}
                    callbackClick={openBoletoSelected}
                />
            )}
            <DivFooter id="footer">
                <Button onClick={() => setOpenPopupNovaFaturaParcelamento(true)}>Nova Fatura</Button>
            </DivFooter>
            <PopupDefault
                open={openPopup}
                close={() => setOpenPopup(false)}
                children={
                    <BoletoEdit
                        clientBoletos={boletosList}
                        close={setOpenPopup}
                        id={boletoId}
                        reload={() => loadBoletos(boletoId)}
                    />
                }
            />
            <LoadingScreen display={loadScreen} />
            {/* POPUP QUE MOSTRA A IMPRESSÃO DO TEXTO XML */}
            <PopupDefault
                children={
                    <div style={{ width: '800px', height: '600px', padding: '12px' }}>
                        <div style={{ height: '40px', textAlign: 'center' }}>
                            <h1 style={{ fontSize: '24px' }}>Texto do XML gerado</h1>
                        </div>
                        <TextAreaRps value={dadosRps.todasRps}></TextAreaRps>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '40px',
                                marginTop: '8px',
                            }}
                        ></div>
                    </div>
                }
                open={openPopupRps}
                close={() => setOpenPopupRps(false)}
            />
            {/* POPUP QUE MOSTRA AS RPS NÃO EMITIDAS*/}
            <PopupDefault
                children={
                    <div style={{ padding: '20px' }}>
                        {dadosRps.rpsNaoGeradas
                            ? dadosRps.rpsNaoGeradas.map((item) => (
                                  <div style={{ paddingBottom: '12px', borderBottom: '1px solid #fefefe', marginBottom: '8px' }}>
                                      <div style={{ display: 'flex' }} onClick={() => openBoletoSelected(item.idBoleto)}>
                                          <p>Id do boleto: </p>
                                          <div style={{ marginLeft: '12px' }}>{item.idBoleto}</div>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                          <p>Motivo: </p>
                                          <div style={{ marginLeft: '12px' }}>{item.motivo}</div>
                                      </div>
                                  </div>
                              ))
                            : ''}
                    </div>
                }
                open={openPopupRpsNaoEmitidas}
                close={() => setOpenPopupRpsNaoEmitidas(false)}
            />
            {/* POPUP QUE MOSTRA A SELEÇÃO DAS DATAS*/}
            <PopupDefault
                children={
                    <div style={{ padding: '20px' }}>
                        <h1>Selecione o período</h1>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '12px' }}>
                            <p>De:</p>
                            <input
                                style={{ marginLeft: '12px' }}
                                type="date"
                                max="9999-12-31"
                                min="1900-01-01"
                                ref={refDataInicio}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p>Até:</p>
                            <input
                                style={{ marginLeft: '12px' }}
                                type="date"
                                max="9999-12-31"
                                min="1900-01-01"
                                ref={refDataFinal}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px' }}>
                            <Button style={{ margin: '' }} onClick={BuscaNotasFiscais}>
                                Buscar
                            </Button>
                        </div>
                    </div>
                }
                open={openPopupSelectData}
                close={() => setOpenPopupSelectData(false)}
            />
            {/* POPUP QUE MOSTRA OS REGISTROS COM DATAS ALTERADAS*/}
            <PopupDefault
                children={
                    <div style={{ padding: '20px' }}>
                        <div style={{ margin: '0 0 12px 0' }}>
                            <h1>Boletos com datas de pagamento maior que 10 dias.</h1>
                        </div>
                        {dadosRps.listaDatasAlteradas
                            ? dadosRps.listaDatasAlteradas.map((info) => {
                                  return (
                                      <div style={{ display: 'flex', margin: '0 0 12px 0' }}>
                                          <span>Id do Boleto: {info.idBoleto}</span>
                                      </div>
                                  );
                              })
                            : ''}
                    </div>
                }
                open={openPopupInfoDataAlterada}
                close={() => setOpenPopupInfoDataAlterada(false)}
            />
            {/* POPUP QUE MOSTRA AS RPS QUE PRECISAM ORGANIZAR AS DATAS RPS */}
            <PopupDefault
                open={openPopupOrganizaDatasRps}
                close={() => setOpenPopupOrganizaDatasRps(false)}
                children={
                    <div style={{ padding: '20px' }}>
                        <div style={{ margin: '0 0 24px 0' }}>
                            <h1>RPS que precisam de organização na data</h1>
                        </div>
                        <div>
                            {dadosRps.rpsNaoGeradas?.map((rps) => {
                                return (
                                    <div
                                        onClick={() => selectBoletoEditDate(rps)}
                                        style={{ padding: '0 0 12px 0', margin: '12px 0 0 0', borderBottom: '1px solid' }}
                                    >
                                        <div style={rps.RPS_Data ? { display: 'flex', color: 'green' } : { display: 'flex' }}>
                                            <p style={{ margin: '0 8px 0 0' }}>Id do Boleto: {rps.idBoleto}</p>
                                            <p style={{ margin: '0 8px 0 0' }}>
                                                Data Pagamento: {dayjs(rps.Data_Pagamento).format('DD/MM/YYYY')}
                                            </p>
                                            <p>RPS Data: {dayjs(rps.RPS_Data).format('DD/MM/YYYY')}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '12px 0 0 0' }}>
                            <Button onClick={() => geraRpsBoletosOrganizados()}>Gerar Novamente RPS</Button>
                        </div>
                    </div>
                }
            />
            {/*POPUP PARA EDITAR AS DATAS DOS PAGAMENTOS QUE POSSUEM DATA DE PAGAMENTO < 10 DIAS.*/}
            <PopupDefault
                open={openPopupEditDate}
                close={() => setOpenPopupEditDate(false)}
                children={
                    <div>
                        <div>
                            <h1>Edite data RPS</h1>
                        </div>
                        <div>
                            <p>Id Boleto: {boletoDateEdit.idBoleto ?? ''}</p>
                            <div>
                                <p>Data Pagamento:</p>
                                <input
                                    type="date"
                                    value={
                                        boletoDateEdit.Data_Pagamento
                                            ? dayjs(boletoDateEdit.Data_Pagamento).format('YYYY-MM-DD')
                                            : ''
                                    }
                                    max="3000-01-01"
                                    min="0000-01-01"
                                    onChange={(event) =>
                                        setBoletoDateEdit({ ...boletoDateEdit, Data_Pagamento: event.target.value })
                                    }
                                />
                            </div>
                            <div>
                                <p>RPS Data:</p>
                                <input
                                    type="date"
                                    value={boletoDateEdit.RPS_Data ? dayjs(boletoDateEdit.RPS_Data).format('YYYY-MM-DD') : ''}
                                    max="3000-01-01"
                                    min="0000-01-01"
                                    onChange={(event) => setBoletoDateEdit({ ...boletoDateEdit, RPS_Data: event.target.value })}
                                />
                            </div>
                            <div>
                                <p>RPS:</p>
                                <input
                                    type="text"
                                    value={boletoDateEdit.RPS ?? ''}
                                    onChange={(event) => setBoletoDateEdit({ ...boletoDateEdit, RPS: event.target.value })}
                                />
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => organizaRps(boletoDateEdit)}>Salvar</Button>
                        </div>
                    </div>
                }
            />
            <PopupDefault
                open={openPopupNovaFaturaParcelamento}
                close={() => setOpenPopupNovaFaturaParcelamento(false)}
                children={
                    <FormularioFatura
                        listaSoftwareId={[]}
                        titulo={'Nova Fatura'}
                        close={() => setOpenPopupNovaFaturaParcelamento(false)}
                    />
                }
            />
        </ContainerDivCardGridInfo>
    );
}
