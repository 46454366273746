import React, { useState, useEffect, useRef } from 'react';
import AgendaComponente from '~/components/agendaComponente/AgendaComponente';
import {
    DivControllViewSchedule,
    IconBiSearch,
    DivSelectDaySchedule,
    IconFaLessThan,
    DivToday,
    IconFaGreaterThan,
    IconFaArrowCircleDown,
    DescricaoCampo,
    DivSelecaoAgenda,
    DivAgendas,
} from './StyledAgenda';
import dayjs from 'dayjs';
import OwnerScheduleSelect from '~/components/selectCustom/selectCustom';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import DatePicker from '~/components/dataPicker/DataPicker';
// import { Container } from './styles';

function Agenda() {
    const [view, setView] = useState('day');
    const [loadScreen, setLoadScreen] = useState('none');
    const [scheduleSelected, setScheduleSelected] = useState('Todos');
    const [openBusca, setOpenBusca] = useState(false);
    const api = useApi();
    const [schedules, setSchedules] = useState([]);
    const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
    const hoje = dayjs(new Date()).format('YYYY-MM-DD');

    const cal = useRef(null);
    const cal2 = useRef(null);
    const cal3 = useRef(null);
    function selectViewMode(mode) {
        setView(mode);
    }

    function getWeekDays() {
        try {
            const dateWeekInitial = cal.current.calendarInst.getDateRangeStart();
            const dateWeekFinal = cal.current.calendarInst.getDateRangeEnd();
            getAgendamentos(
                dayjs(dateWeekInitial._date).format('YYYY-MM-DDTHH:mm'),
                dayjs(dateWeekFinal._date).format('YYYY-MM-DDTHH:mm'),
            );
        } catch (error) {
            toast.error('Error ao carregar os dias.');
            console.log(error);
        }
    }

    function changeDayOrWeek() {
        if (view === 'dia') {
            getAgendamentosDaySelected();
        } else {
            getWeekDays();
        }
    }
    function getAgendamentosDaySelected() {
        const date = cal.current.calendarInst.getDate();
        getAgendamentos(dayjs(date).format('YYYY-MM-DDTHH:mm'), dayjs(date).format('YYYY-MM-DDTHH:mm'));
    }
    function showPicker() {
        let selected = document.getElementById('wrapper');
        selected.firstChild.classList.toggle('tui-hidden');
    }
    async function getAgendamentos(inicio, final) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/agenda/getagenda?inicio=${inicio}&final=${final}`);
            if (data.status === 200 && Array.isArray(data.data)) {
                //status agendamentos = 0:cancelado,1:finalizado,2:agendado
                setSchedules(
                    data.data.map((item) => {
                        return {
                            id: item.Id_do_Agendamento,
                            calendarId: '0',
                            title: item.Descricao,
                            category: 'time',
                            start: item.Inicio,
                            end: item.Final,
                            customStyle: 'padding:8px',
                            raw: {
                                agenda_name: item.Id_do_Usuario,
                                tipoAgendamento: item.Tipo_Agendamento,
                                status: item.Status,
                            },
                        };
                    }),
                );
                setLoadScreen('none');
            } else {
                setLoadScreen('none');
                toast.warning('Não foi possível carregar os agendamentos');
            }
        } catch (error) {
            console.log(error);
            setLoadScreen('none');
            toast.error('Não foi possível carregar os agendamentos.');
        }
        // const today = dayjs(new Date()).format('YYYY-MM-DDTHH:mm');
    }

    useEffect(() => {
        getAgendamentos(hoje, hoje);
    }, [date]);

    useEffect(() => {
        if (schedules.length > 0) {
            setSchedules([]);
            getAgendamentos(hoje, hoje);
        }
    }, [scheduleSelected]);

    return (
        <div style={{ height: '100%' }}>
            <DivControllViewSchedule id="control">
                <DescricaoCampo>Modo de visualização: </DescricaoCampo>
                <div style={{ width: '200px', margin: '0 12px 0 12px' }}>
                    <OwnerScheduleSelect initial_option="dia" array_options={['dia', 'semana']} setValue={selectViewMode} />
                </div>
                <DescricaoCampo>Agenda:</DescricaoCampo>
                <DivSelecaoAgenda>
                    <OwnerScheduleSelect
                        initial_option="Todos"
                        array_options={['Todos', 'Treinamentos', 'Rodrigo', 'Yuri']}
                        setValue={setScheduleSelected}
                    />
                </DivSelecaoAgenda>
                <IconBiSearch title="Click para buscar por um agendamento." onClick={() => setOpenBusca(true)} />
            </DivControllViewSchedule>
            <DivSelectDaySchedule>
                <div
                    onClick={() => {
                        cal.current.calendarInst.prev();
                        cal2.current.calendarInst.prev();
                        cal3.current.calendarInst.prev();
                        changeDayOrWeek();
                    }}
                >
                    <IconFaLessThan />
                </div>
                <DivToday
                    onClick={() => {
                        cal.current.calendarInst.today();
                        cal2.current.calendarInst.today();
                        cal3.current.calendarInst.today();
                        changeDayOrWeek();
                    }}
                >
                    hoje
                </DivToday>
                <div
                    onClick={() => {
                        cal.current.calendarInst.next();
                        cal2.current.calendarInst.next();
                        cal3.current.calendarInst.next();
                        changeDayOrWeek();
                    }}
                >
                    <IconFaGreaterThan />
                </div>
                <div style={{ position: 'relative' }}>
                    <IconFaArrowCircleDown onClick={showPicker} />
                    <DatePicker getAgendamentos={getAgendamentos} refCal={[cal, cal2, cal3]} />
                </div>
            </DivSelectDaySchedule>
            <DivAgendas>
                <div
                    style={{
                        width: scheduleSelected === 'Todos' ? '33%' : '100%',
                        display: scheduleSelected === 'Treinamentos' || scheduleSelected === 'Todos' ? 'unset' : 'none',
                    }}
                >
                    <h2 style={{ margin: '0 0 12px 12px' }}>Treinamentos</h2>
                    <AgendaComponente
                        cal={cal}
                        view={view}
                        inicio={date}
                        final={date}
                        schedules={schedules.filter((el) => el.raw.agenda_name == 42)}
                        setSchedules={setSchedules}
                        getAgendamentos={getAgendamentos}
                        scheduleName={'Treinamentos'}
                    />
                </div>
                <div
                    style={{
                        width: scheduleSelected === 'Todos' ? '33%' : '100%',
                        display: scheduleSelected === 'Rodrigo' || scheduleSelected === 'Todos' ? 'unset' : 'none',
                    }}
                >
                    <h2 style={{ margin: '0 0 12px 12px' }}>Rodrigo</h2>
                    <AgendaComponente
                        cal={cal2}
                        view={view}
                        inicio={date}
                        final={date}
                        schedules={schedules.filter((el) => el.raw.agenda_name == 32)}
                        setSchedules={setSchedules}
                        getAgendamentos={getAgendamentos}
                        scheduleName={'Rodrigo'}
                    />
                </div>
                <div
                    style={{
                        width: scheduleSelected === 'Todos' ? '33%' : '100%',
                        display: scheduleSelected === 'Yuri' || scheduleSelected === 'Todos' ? 'unset' : 'none',
                    }}
                >
                    <h2 style={{ margin: '0 0 12px 12px' }}>Yuri</h2>
                    <AgendaComponente
                        cal={cal3}
                        view={view}
                        inicio={date}
                        final={date}
                        schedules={schedules.filter((el) => el.raw.agenda_name == 54)}
                        setSchedules={setSchedules}
                        getAgendamentos={getAgendamentos}
                        scheduleName={'Yuri'}
                    />
                </div>
            </DivAgendas>
            <LoadingScreen display={loadScreen} />
        </div>
    );
}

export default Agenda;
