import { lighten } from 'polished';
import styled from 'styled-components';

export const DivMain = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 20px;
`;

export const DivMainInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 12px 0;
`;

export const Input = styled.input`
    line-height: 38px;
`;

export const DivTable = styled.div`
    flex: 1;
    overflow: auto;
    margin: 10px 0px 0px 0px;
    padding: 0 0px 10px 0px;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-radius: 6px;

    table {
        width: 100%;
        td {
            font-size: 14px;
            text-align: center;
            min-width: 60px;
        }
        thead {
            background-color: ${(p) => p.theme.colors.background};
            position: sticky;
            z-index: 1;
            top: 0;
            height: 40px;
            border-bottom: 2px solid ${(p) => p.theme.colors.borderColor};
            tr {
                th {
                    vertical-align: middle;
                    text-align: start;
                    padding: 0 20px 0 0;
                }
            }
        }
        tbody {
            tr {
                height: 30px;
                transition: 0.5s;
                td {
                    vertical-align: middle;
                    padding: 5px;
                    margin: auto 0;
                    text-align: start;
                }
                :hover {
                    background: ${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`;

export const TitlePopUp = styled.h1`
    text-align: center;
    font-size: 1.5rem;
    border-bottom: 1px solid ${(p) => (p.theme.title !== 'light' ? '#272727' : '#d2d2d2')};
    padding-bottom: 10px;
`;

export const ContainerPopUp = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    border-radius: 6px;
    overflow: overlay;
`;

export const BodyPopUp = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    > .groupHorizontal {
        display: flex;

        > div {
            flex: 1;
            padding: 5px;
            display: flex;
            flex-direction: column;

            > span {
                margin-bottom: 5px;
                margin-left: 5px;
                font-weight: 500;
            }
            > sub {
                margin-bottom: -5px;
                margin-left: 10px;
                font-weight: 400;
                color: ${(p) => p.theme.colors.textSecundary};
            }
        }
    }
`;

export const FooterPopUp = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-right: 15px;

    > .groupHorizontal {
        display: flex;

        > div {
            flex: 1;
            padding: 5px;
            display: flex;
            flex-direction: column;

            > span {
                margin-bottom: 5px;
                margin-left: 5px;
                font-weight: 500;
            }
            > sub {
                margin-bottom: -5px;
                margin-left: 10px;
                font-weight: 400;
                color: ${(p) => p.theme.colors.textSecundary};
            }
        }
    }
`;
