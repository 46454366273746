import styled from 'styled-components';
import { FaGreaterThan, FaLessThan, FaArrowCircleDown } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';

export const DivMainAgendaComponent = styled.div`
    height: 100%;
    padding: 0 0 0 16px;
`;

export const IconFaLessThan = styled(FaLessThan)`
    cursor:pointer;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    transition:.5s;
    :hover{
        transform:scale(1.08)
    }
`;
export const IconFaGreaterThan = styled(FaGreaterThan)`
    cursor:pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 0 10px;
    transition:.5s;
    :hover{
        transform:scale(1.08)
    }
`;
export const IconFaArrowCircleDown = styled(FaArrowCircleDown)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 10px;
`;
export const IconBiSearch = styled(BiSearch)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 10px;
`;
export const DivControllViewSchedule = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 0 0;
    height: 40px;
`;
export const DivSelectDaySchedule = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 12px 12px;
    height: 30px;
`;
export const DivToday = styled.div`
    cursor:pointer;
    transition:.5s;
    :hover{
        transform:scale(1.08)
    }
`