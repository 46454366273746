import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DivButton,
    DivContentElements,
    DivLeftBoletoEdit,
    DivRightBoletoEdit,
    IconAiOutlineCloseCircle,
} from '../boletoEdit/StyledBoletoEdit';
import { Button, Title } from '../commonComponents/commonComponents';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '../loadScreen/Loadscreen';
import {
    ContainerPopupRevisaoValores,
    DivBotoes,
    DivListaReferenteA,
    DivListClientes,
    DivMainNovaFatura,
    DivTitleBoleto,
    Select,
    TextoConfirmacaoNovaVenda,
} from './styleFormularioFatura';
import { useTheme } from 'styled-components';
import PopupDefault from '../popupDefault/PopupDefault';
import { FormControl, InputLabel, MenuItem, TextField, Select as SelectMui, FormControlLabel, Checkbox, Typography } from '@mui/material';
export default function FormularioFatura({ close, clientInfo, titulo, listaSoftwareId }) {
    const formataCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    const [loadScreen, setLoadScreen] = useState('none');
    const [boletoInfo, setBoletoInfo] = useState({
        Id_do_Cliente: '0',
        Classificacao: 0,
        Emissao: dayjs(new Date()).format('YYYY-MM-DD'),
        Envia_Boleto: -1,
        Vencimento: '1900-01-01',
        Referente_a: '',
        Referente_a_1: '',
        Valor: '0',
        parcelas: '1',
        SoftwareId: null,
        Vigencia: '1900-01-01',
        CanalCadastro: clientInfo?.CanalCadastro || null,
        Obs: '',
    });
    const [valorEntrada, setValorEntrada] = useState(0);
    const [searchCliente, setSearchCliente] = useState([]);
    const [hideDisplay, setHideDisplay] = useState(true);
    const [escondeListaReferenteA, setEscondeListaReferenteA] = useState(true);
    const [escondeListaReferenteA1, setEscondeListaReferenteA1] = useState(true);
    const [isDesconto, setIsDesconto] = useState(false);
    const [listaReferenteA, setListaReferenteA] = useState([]);
    const [filtroReferenteA, setFiltroReferenteA] = useState([]);
    const [listaReferenteA1, setListaReferenteA1] = useState([]);
    const [filtroReferenteA1, setFiltroReferenteA1] = useState([]);
    const [listaCanalCadastro, setListaCanalCadastro] = useState([]);
    const [listaSoftwareIds, setListaSoftwareIds] = useState(listaSoftwareId || []);
    const [openAviso, setOpenAviso] = useState(false);
    const maxWidthInput = { maxWidth: '170px' };
    const api = useApi();
    const theme = useTheme();
    const refParcelas = useRef(0);
    const refInputName = useRef();
    const refSelectPlano = useRef(1);
    const refValor = useRef(0);
    const refDataVigencia = useRef(0);

    function verificaDadosBoleto() {
        try {
            if (boletoInfo.Classificacao == 0) {
                toast.info('Preencha o campo classificação, por favor.');
                throw null;
            }
            if (boletoInfo.Id_do_Cliente == 0) {
                toast.info('Seleciona um cliente, por favor.');
                throw null;
            }
            if (boletoInfo.Vencimento == '1900-01-01') {
                toast.info('Informe a data de vencimento por favor.');
                throw null;
            }
            if(new Date(boletoInfo.Vencimento).getFullYear() < new Date().getFullYear()){
                toast.warning('Data de vencimento não pode ser retroativa.')
                throw null;
            };
            if((new Date(boletoInfo.Vencimento).getFullYear() - new Date().getFullYear()) > 5){
                toast.warning('Data de vencimento maior que 5 anos.')
                throw null;
            };
            if (boletoInfo.Valor == '0') {
                toast.info('Informe o valor por favor.');
                throw null;
            }
            if (boletoInfo.Referente_a == '' || boletoInfo.Referente_a_1 == '') {
                toast.info('Verifique os campos de descrição do boleto, por favor.');
                throw null;
            }
            if (listaSoftwareIds.length > 0 && !boletoInfo.SoftwareId) {
                toast.info('Informe o softwareId');
                throw null;
            }
            if (!boletoInfo.CanalCadastro) {
                toast.info('Informe o canal de cadastro.');
                throw null;
            }
            if (boletoInfo.Vigencia == '1900-01-01') {
                toast.info('Informe a vigência do contrato.');
                throw null;
            }            
            if (refParcelas.current.value == 0) {
                toast.info('Informe a quantidade de parcelas, por favor.');
                throw null;
            }
            if (refParcelas.current.value > 4) {
                toast.warning('Não é possível realizar a criação da fatura.');
                throw null;
            }            
            setOpenAviso(true);
        } catch (error) {
            console.error(error);
            if (!error) return;
            toast.error(error || 'Erro ao verificar campos do boleto.');
        }
    }

    async function criaFatura() {
        setLoadScreen('flex');
        try {
            let dadosBoleto = { ...boletoInfo, Entrada: false, ValorEntrada: 0};
            console.log(dadosBoleto);
            const reponseCriaFatura = await api.post(`boleto/CriaBoleto`, {
                ...dadosBoleto,
                Valor: String(dadosBoleto.Valor).replace(/,/g, '.'),
            });
            if (reponseCriaFatura.status == 200) {
                toast.success('Faturas criadas com sucesso.');
                close(false);
            } else {
                toast.error('Não foi possível criar boleto');
            }
        } catch (error) {
            setLoadScreen('none');
            if (!error) return;
            console.log(error);
            toast.error('Erro ao tentar criar fatura.');
        }
        setLoadScreen('none');
    }

    async function procuraCliente(name) {
        setLoadScreen('flex');
        try {
            const data = await api.get(`/cliente/GetClientById?Id=${name}`);
            if (data.status === 200) {
                setHideDisplay(false);
                setSearchCliente(data.data);
            }
        } catch (error) {
            if (error.response.status == 404) {
                toast.warning('Nenhum paciente foi encontrado.');
            } else {
                console.error(error);
                toast.error('Não foi possível buscar pelo cliente');
            }
        }
        setLoadScreen('none');
    }
    async function getNameFromClientScreen(clientInfo) {
        try {
            const { Nome, Codigo_do_Cliente } = clientInfo;
            refInputName.current.value = Nome;
            setBoletoInfo({ ...boletoInfo, Id_do_Cliente: Codigo_do_Cliente });
        } catch (error) {
            setLoadScreen('none');
            toast.error('Não foi possível carregar o nome dos pacientes.');
        }
    }
    async function carregaReferenteA() {
        setLoadScreen('flex');
        try {
            const responseCarregaReferenteA = await api.get(`/boleto/CarregaReferenteA`);
            if (responseCarregaReferenteA.status == 200) {
                setListaReferenteA(responseCarregaReferenteA.data);
            } else {
                toast.error('Erro ao carregar lista referente_A');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de referente_A');
        }
        setLoadScreen('none');
    }
    async function carregaReferenteA1() {
        setLoadScreen('flex');
        try {
            const responseCarregaReferenteA1 = await api.get(`/boleto/CarregaReferenteA1`);
            if (responseCarregaReferenteA1.status == 200) {
                setListaReferenteA1(responseCarregaReferenteA1.data);
            } else {
                toast.error('Erro ao carregar lista referente_A');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de referente_A');
        }
        setLoadScreen('none');
    }
    async function getListaCanalCadastro() {
        setLoadScreen('flex');
        try {
            const responseGetCanalCadastro = await api.get(`cliente/GetCanalCadastro`);
            if (responseGetCanalCadastro.status == 200) {
                setListaCanalCadastro(responseGetCanalCadastro.data);
            } else {
                toast.error('Erro ao carregar lista de canal de cadastro.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de canal de cadastro.');
        }
        setLoadScreen('none');
    }
    async function carregaListaSoftwareId(Id_do_Cliente) {
        setLoadScreen('flex');
        try {
            const responseCarregaListaSoftewareId = await api.get(`/vendas/GetListVendas?id=${Id_do_Cliente}`);
            if (responseCarregaListaSoftewareId.status == 200) {
                setListaSoftwareIds(responseCarregaListaSoftewareId.data);
            } else {
                toast.error('Erro ao carrega lista de softwaresId');
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carrega lista de softwareid do cliente.');
        }
        setLoadScreen('none');
    }
    async function selectName(item) {
        refInputName.current.value = item.Nome;
        setBoletoInfo({ ...boletoInfo, Id_do_Cliente: item.Codigo_do_Cliente, CanalCadastro: item.CanalCadastro });
        setHideDisplay(true);
        setSearchCliente([]);
        await carregaListaSoftwareId(item.Codigo_do_Cliente);
    }

    function verificaPesquisa() {
        if (searchCliente.length > 0) {
            setHideDisplay(false);
        }
    }

    function carregaValorPlano(value) {
        try {
            if (value == 0) {
                setBoletoInfo({ ...boletoInfo, Valor: 0 });
                refValor.current.value = 0;
                refSelectPlano.current.value = 0;
                return;
            }else if (value == 1) {
                setBoletoInfo({ ...boletoInfo, Valor: 6600 });
                refValor.current.value = 6600;
                refSelectPlano.current.value = 1;
                return;
            } else if (value == 2) {
                setBoletoInfo({ ...boletoInfo, Valor: 4200 });
                refValor.current.value = 4200;
                refSelectPlano.current.value = 2;
                return;
            } else if (value == 3) {
                setBoletoInfo({ ...boletoInfo, Valor: 3000 });
                refValor.current.value = 3000;
                refSelectPlano.current.value = 3;
                return;
            } else if (value == 4) {
                setBoletoInfo({ ...boletoInfo, Valor: 1440 });
                refValor.current.value = 1440;
                refSelectPlano.current.value = 4;
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }

    function carregaValorParcelas(numeroParcelas) {
        try {       
            setBoletoInfo({
                ...boletoInfo,
                parcelas: numeroParcelas,
            });
            refParcelas.current.value = numeroParcelas;
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar valor das parcelas.');
        }
    }

    function DataVigenciaCalc(data) {
        try {
            const novaData = dayjs(data).format('YYYY-MM-DD');
            if (String(novaData).toLowerCase() == 'invalid date') {
                setBoletoInfo({ ...boletoInfo, Vigencia: '1900-01-01' });
                toast.warning('Data inválida');
            } else {
                setBoletoInfo({ ...boletoInfo, Vigencia: novaData });
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar data selecionada.');
        }
    }
    function FiltraOpcoesReferenteA(value) {
        try {
            const textosFiltrados = listaReferenteA.filter((texto) =>
                String(texto.ReferenteA).toLowerCase().includes(String(value).toLowerCase()),
            );
            setFiltroReferenteA(textosFiltrados);
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível filtrar opções de referente a.');
        }
    }
    function FiltraOpcoesReferenteA1(value) {
        try {
            const textosFiltrados = listaReferenteA1.filter((texto) =>
                String(texto.ReferenteA).toLowerCase().includes(String(value).toLowerCase()),
            );
            setFiltroReferenteA1(textosFiltrados);
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível filtrar opções de referente a.');
        }
    }
    const ComponentMostraResumoFatura = () => {
        let descricaoResumoFatura = '';
        const parcelas = boletoInfo.parcelas;
        const boletoValor = boletoInfo.Valor;
        
        descricaoResumoFatura = ` ${parcelas} parcelas de ${formataCurrency.format((boletoValor) / parcelas)}`;
        return (
            <div style={{ margin: '0 0 10px 0' }}>
                <Typography variant='caption'>Valor Total: {formataCurrency.format( boletoInfo.Valor)}</Typography><br/>
                <Typography variant='caption'>{descricaoResumoFatura}</Typography>   
            </div>
        );
    };   
    useEffect(() => {
        carregaReferenteA();
        carregaReferenteA1();
        getListaCanalCadastro();
    }, []);
    useEffect(() => {
        if (clientInfo) {
            getNameFromClientScreen(clientInfo);
        }
    }, [isDesconto]);
    useEffect(() => {
       console.log(boletoInfo)
    }, [boletoInfo]);
    return (
        <DivMainNovaFatura>
            <DivTitleBoleto>
                <Title>{titulo}</Title>
            </DivTitleBoleto>
            <div style={{ width: '100%' }}>
                <DivContentElements style={{ position: 'relative', height: '40px',marginBottom:'8px' }}>
                    <TextField sx={{mb:2}} variant='outlined' label={'Nome'} fullWidth required name='Nome'
                        size='small'
                        focused
                        placeholder='Informe o nome do cliente'
                        inputRef={refInputName}                                          
                        inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}                        
                        onKeyDown={ (event)=> (event.key === 'Enter' ? procuraCliente(event.target.value) : '')}
                        onClick={verificaPesquisa}
                    />              
                    <DivListClientes hide={hideDisplay} onMouseLeave={() => setHideDisplay(true)}>
                        {searchCliente.map((item) => (
                            <div onClick={() => selectName(item)}>{item.Nome}</div>
                        ))}
                    </DivListClientes>
                </DivContentElements>
                <FormControl fullWidth focused sx={{mb:2}}>
                    <InputLabel id="plano">Plano</InputLabel>
                    <SelectMui
                        placeholder='Selecione o plano.'
                        size='small'
                        labelId="plano"
                        id="plano"                            
                        label="plano"
                        onChange={(event) => carregaValorPlano(event.target.value)}                                                                           
                        ref={refSelectPlano}
                        defaultValue={0}
                    >   
                        <MenuItem value={0}>Selecione o plano</MenuItem>
                        <MenuItem value={1}>ENTERPRISE</MenuItem>
                        <MenuItem value={2}>BUSINESS</MenuItem>
                        <MenuItem value={3}>STARTER</MenuItem>
                        <MenuItem value={4}>NUTRICIONISTA</MenuItem>
                    </SelectMui>
                </FormControl>               
                <div style={{ display: 'flex' }}>
                    <DivLeftBoletoEdit>
                        <DivContentElements style={{ position: 'relative' }}>
                            <TextField sx={{mb:2}} variant='outlined' label={'Referente_a'} fullWidth required name='Referente_a'                    
                                size='small'
                                focused
                                placeholder='Referente_a'
                                value={boletoInfo.Referente_a}
                                inputProps={{style:{boxShadow:'none',color:theme.colors.text}}}
                                onClick={() => setEscondeListaReferenteA(false)}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Referente_a: event.target.value })}
                                onKeyUpCapture={() => FiltraOpcoesReferenteA(boletoInfo.Referente_a)}
                            />                            
                            <DivListaReferenteA
                                esconde={escondeListaReferenteA}
                                onMouseLeave={() => setEscondeListaReferenteA(true)}
                            >
                                {boletoInfo.Referente_a.length == 0
                                    ? listaReferenteA.map((item) => (
                                          <div onClick={() => setBoletoInfo({ ...boletoInfo, Referente_a: item.ReferenteA })}>
                                              {item.ReferenteA}
                                          </div>
                                      ))
                                    : filtroReferenteA.map((item) => (
                                          <div onClick={() => setBoletoInfo({ ...boletoInfo, Referente_a: item.ReferenteA })}>
                                              {item.ReferenteA}
                                          </div>
                                      ))}
                            </DivListaReferenteA>
                        </DivContentElements>
                        <TextField variant='outlined' label={'Valor Total'} required name='Valor Total'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='number'                                
                            placeholder='Valor Total'
                            inputRef={refValor}                                                         
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "0.01", min:'0'}}
                            inputMode='decimal'
                            defaultValue={boletoInfo?.Valor}
                            onChange={(event) => setBoletoInfo({ ...boletoInfo, Valor: event.target.value })}
                        />                        
                        <TextField variant='outlined' label={'Emissão'} required name='Emissão'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='RPS'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            defaultValue={dayjs(new Date()).format('YYYY-MM-DD')}
                            onChange={(event) =>
                                setBoletoInfo({
                                    ...boletoInfo,
                                    Emissao: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                })
                            }
                        /> 
                        <TextField variant='outlined' label={'RPS'} required name='RPS'
                            size='small'
                            sx={{mb:2}}
                            focused
                            type='number'                                
                            placeholder='Valor Total'                                                                                
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "1", min:0}}
                            inputMode='decimal'
                            defaultValue={boletoInfo?.Valor}
                            onChange={(event) => setBoletoInfo({ ...boletoInfo, RPS: event.target.value })}
                        />
                        <TextField variant='outlined' label={'NFE'} required name='NFE'
                            size='small'
                            sx={{mb:2}}
                            focused
                            type='number'                                
                            placeholder='Valor Total'                                                                                
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "1", min:0}}                                
                            defaultValue={0}
                            onChange={(event) => setBoletoInfo({ ...boletoInfo, NFE: event.target.value })}
                        />
                        <FormControl fullWidth focused sx={{mb:2}}>
                            <InputLabel id="softwareId">SoftwareId</InputLabel>
                            <SelectMui                                
                                size='small'
                                labelId="softwareId"
                                id="softwareId"                            
                                label="softwareId"
                                value={boletoInfo?.SoftwareId}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, SoftwareId: event.target.value })}                                                                          
                                ref={refSelectPlano}
                                defaultValue={0}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}
                            >   
                                {
                                   listaSoftwareIds.map((softwareId) => (
                                    <MenuItem value={softwareId.SoftwareId}>{softwareId.SoftwareId}</MenuItem>
                                ))
                                }
                            </SelectMui>
                        </FormControl>
                        <FormControl fullWidth focused sx={{mb:2}}>
                            <InputLabel id="classificacao">Classificação</InputLabel>
                            <SelectMui                                
                                size='small'
                                labelId="classificacao"
                                id="classificacao"                            
                                label="Classificação"
                                value={boletoInfo?.Classificacao}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Classificacao: event.target.value })}                                                                        
                                ref={refSelectPlano}
                                defaultValue={0}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}
                            >   
                                <MenuItem></MenuItem>
                                <MenuItem value="1">Ativação</MenuItem>
                                <MenuItem value="2">Renovação</MenuItem>
                                <MenuItem value="3">Consultoria</MenuItem>
                                <MenuItem value="4">SMS</MenuItem>
                                <MenuItem value="5">Medx social</MenuItem>
                                <MenuItem value="6">Prescreva</MenuItem>
                                <MenuItem value="7">Eventos/Cursos</MenuItem>
                                <MenuItem value="8">Docflix</MenuItem>                                
                                <MenuItem value="9">Evolução Inteligente</MenuItem>                                
                                <MenuItem value="10">IA</MenuItem>                                
                                <MenuItem value="11">Whatsapp</MenuItem>                                
                            </SelectMui>
                        </FormControl>                        
                    </DivLeftBoletoEdit>
                    <DivRightBoletoEdit>
                        <DivContentElements style={{ position: 'relative' }}>
                            <TextField variant='outlined' label={'Referente_a_1'} required fullWidth name='Referente_a_1'
                                size='small'
                                sx={{mb:2}}
                                focused                                
                                placeholder='Referente_a_1'                                                                                         
                                inputProps={{style:{boxShadow:'none',color:theme.colors.text},step: "1", min:0}}                                
                                value={boletoInfo?.Referente_a_1}
                                onChange={(event) => setBoletoInfo({ ...boletoInfo, Referente_a_1: event.target.value })}
                                onKeyUpCapture={() => FiltraOpcoesReferenteA1(boletoInfo?.Referente_a_1)}
                                onClick={() => setEscondeListaReferenteA1(false)}
                            />                           
                            <DivListaReferenteA
                                esconde={escondeListaReferenteA1}
                                onMouseLeave={() => setEscondeListaReferenteA1(true)}
                            >
                                {boletoInfo.Referente_a_1.length == 0
                                    ? listaReferenteA1.map((item) => (
                                          <div onClick={() => setBoletoInfo({ ...boletoInfo, Referente_a_1: item.ReferenteA })}>
                                              {item.ReferenteA}
                                          </div>
                                      ))
                                    : filtroReferenteA1.map((item) => (
                                          <div onClick={() => setBoletoInfo({ ...boletoInfo, Referente_a_1: item.ReferenteA })}>
                                              {item.ReferenteA}
                                          </div>
                                      ))}
                            </DivListaReferenteA>
                        </DivContentElements>
                        <FormControl fullWidth focused sx={{mb:2}}>
                            <InputLabel id="classificacao">Nº de Parcelas:</InputLabel>
                            <SelectMui                                
                                size='small'
                                labelId="nparcelas"
                                id="nparcelas"                            
                                label="Nº de Parcelas:"
                                value={boletoInfo?.parcelas}
                                onChange={(event) => carregaValorParcelas(event.target.value)}                                                                        
                                ref={refParcelas}
                                defaultValue={0}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}
                            >   
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>                    
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="11">11</MenuItem>
                                <MenuItem value="12">12</MenuItem>
                            </SelectMui>
                        </FormControl>                        
                        <TextField variant='outlined' label={'Vencimento'} required name='Vencimento'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Vencimento'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            defaultValue={boletoInfo.Vencimento}
                            onChange={(event) =>
                                setBoletoInfo({
                                    ...boletoInfo,
                                    Vencimento: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                })
                            }
                        />
                        <TextField variant='outlined' label={'RPS Data'} required name='RPS Data'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='RPS Data'                            
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1999-01-01', max:'2999-01-01'}}
                            defaultValue={null}
                            onChange={(event) =>
                                setBoletoInfo({
                                    ...boletoInfo,
                                    RPS_Data: dayjs(event.target.value).format('YYYY-MM-DDT') + '00:00',
                                })
                            }
                        />                        
                        <FormControlLabel 
                            label="Enviar Boleto"
                            sx={{marginBottom:'14px'}}
                            defaultChecked={false} 
                            control={
                                <Checkbox  checked={boletoInfo?.Envia_Boleto == -1 ? true : false} />
                            }
                            onClick={(event) => {
                                setBoletoInfo({ ...boletoInfo, Envia_Boleto: event.target.checked ? -1 : 0 });
                            }}
                            onChange={(event) =>
                                setBoletoInfo({ ...boletoInfo, Envia_Boleto: event.target.checked ? -1 : 0 })
                            }
                        />
                        <TextField variant='outlined' label={'Vigência'} required name='Vigência'
                            sx={{mb:2}}
                            size='small'
                            focused
                            type='date'                  
                            placeholder='Vigência'
                            inputRef={refDataVigencia}
                            inputProps={{style:{boxShadow:'none',color:theme.colors.text}, min:'1900-01-01', max:'9999-01-01'}}
                            defaultValue={null}
                            onChange={(event) => {
                                DataVigenciaCalc(event.target.value);
                                return;
                            }}
                        />
                        <FormControl fullWidth focused sx={{mb:2}}>
                            <InputLabel id="Canal Cadastro">Canal Cadastro</InputLabel>
                            <SelectMui
                                placeholder='Canal Cadastro'
                                size='small'
                                labelId="Canal Cadastro"
                                id="Canal Cadastro"                            
                                label="Canal Cadastro"                                                                                                        
                                ref={refSelectPlano}
                                defaultValue={''}
                                onChange={({ target }) => setBoletoInfo({ ...boletoInfo, CanalCadastro: target.value })}
                                SelectDisplayProps={{style:{color:theme.colors.text}}}
                                value={boletoInfo?.CanalCadastro || ''}
                            >   
                                <MenuItem value={''}>Selecione o Canal</MenuItem>
                                {
                                    listaCanalCadastro.map((canalCadastro) => (
                                        <MenuItem value={canalCadastro.Canal}>{canalCadastro.Descricao}</MenuItem>
                                    ))
                                }
                            </SelectMui>
                        </FormControl>                         
                    </DivRightBoletoEdit>
                </div>
            </div>
            <TextField sx={{mb:3}} variant='outlined' label={'Observações'} fullWidth required name='Observações'                    
                size='small'
                focused
                placeholder='Observações'
                inputRef={refInputName}
                multiline
                rows={3}
                inputProps={{style:{boxShadow:'none'}}}                        
                onKeyDown={ (event)=> (event.key === 'Enter' ? procuraCliente(event.target.value) : '')}
                value={boletoInfo.Obs}
                onChange={({ target }) => setBoletoInfo({ ...boletoInfo, Obs: target.value })}
            />            
            <div style={{ cursor: 'default' }}>
                <div style={{ margin: '0 0 10px 0' }}>
                    <span>Resumo da fatura:</span>
                </div>
                <ComponentMostraResumoFatura />
            </div>
            <DivButton>
                <Button style={{ background: theme.colors.colorDelete, flex: '1' }} onClick={() => close(false)}>
                    Cancelar
                </Button>
                <Button style={{ background: theme.colors.colorSave, flex: '1' }} onClick={() => verificaDadosBoleto()}>
                    Salvar
                </Button>
            </DivButton>

            {openAviso ? (
                <PopupDefault
                    zIndex={1000}
                    open={true}
                    close={() => setOpenAviso(false)}
                    children={
                        <ContainerPopupRevisaoValores>
                            <h1>Confirmação novo boleto</h1>
                            <TextoConfirmacaoNovaVenda>
                                Confirma criação de uma nova venda, no valor de{' '}
                                {formataCurrency.format(String(boletoInfo.Valor).replace(/,/g, '.'))}, dividida em{' '}
                                {boletoInfo.parcelas}x parcelas.
                            </TextoConfirmacaoNovaVenda>
                            <DivBotoes>
                                <Button style={{ background: theme.colors.colorDelete }} onClick={() => setOpenAviso(false)}>
                                    Não
                                </Button>
                                <Button style={{ background: theme.colors.colorSave }} onClick={() => criaFatura()}>
                                    Sim
                                </Button>
                            </DivBotoes>
                        </ContainerPopupRevisaoValores>
                    }
                />
            ) : (
                ''
            )}

            <LoadingScreen display={loadScreen} />
        </DivMainNovaFatura>
    );
}
