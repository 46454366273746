import React from 'react';
import { LoaderWrapper, Loader, LoaderInner } from './StyledLoadScreen';
export default function LoadingScreen({ display, position, background }) {
    return (
        <LoaderWrapper display={display} position={position} background={background}>
            <Loader>
                <LoaderInner></LoaderInner>
            </Loader>
        </LoaderWrapper>
    );
}
