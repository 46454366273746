import { lighten } from 'polished';
import styled from 'styled-components';

export const DivMainFinancasNotaFiscal = styled.div`
    height: 100%;
    width: 100%;
`;
export const DivTitulo = styled.div`
    height: 35px;
    text-align: center;
    margin: 8px 0 0 16px;
`;
export const DivButtons = styled.div`
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
`;
export const DivContentNotaFiscal = styled.div`
    height: calc(100% - (35px + 8px + 60px + 40px + 32px));
    overflow: hidden auto;
    margin: 0 0 0 16px;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    margin: 16px;
    padding: 0 10px 10px 10px;
    border-radius: 6px;
`;
export const TableNotasFiscais = styled.table`
    width: 100%;
    thead {
        tr {
            position: sticky;
            top: 0;
            background: ${(p) => p.theme.colors.background}!important;
            border-bottom: 2px solid ${(p) => p.theme.colors.borderColor};
            height: 40px;
            th {
                padding: 10px;
                border-radius: 4px;
                vertical-align: middle;
                text-align: start;
            }
        }
    }
    tbody {
        tr {
            transition: 0.5s;
            cursor: pointer;
            &:hover {
                background: ${(p) => lighten(0.03, p.theme.colors.background)};
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            }
            td {
                width: 200px;
                word-break: break-word;
                text-align: start;
                vertical-align: middle;
                padding: 10px;
            }
        }
    }
`;
export const DivMainPopuEditInfoBoletos = styled.div`
    padding: 20px;
`;
export const DivInfoPopuEditInfoBoletos = styled.div`
    display: flex;
    align-items: center;
    min-width: 500px;
    margin: 0 0 8px 0;
    input {
        margin: 0 0 0 12px;
    }
`;
export const DivMainPopupRpsNaoEmitidas = styled.div`
    display:flex;
    flex-direction:column;
    overflow:hidden auto;
    padding:20px;
    height:100%;
`