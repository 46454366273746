import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    padding:20px;
`;
export const DivSelecaoSubOpcoes = styled.div`
    display:flex;
    flex-wrap :wrap;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: content;
`
export const DivSubOpcoes = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:40px;
    border:1px solid #fff;
    border-radius:8px;
    margin:8px 16px 8px 0px;
    transition:.5s;
    cursor:pointer;
    :hover{
        background:${p => p.theme.colors.colorSelecao};
    }
`