export default {
    reset: (atualState, { payload }) => {
        atualState.status = 'finished';
        atualState.data = payload;
    },
    add: (atualState, { payload }) => {
        atualState.data.push(payload);
        atualState.status = 'finished';
    },
    updateById: (atualState, { payload, comparisonKey }) => {
        const index = atualState.data.findIndex((obj) => obj[comparisonKey] === payload[comparisonKey]);
        if (index !== -1) atualState.data[index] = payload;
        atualState.status = 'finished';
    },
    load: (atualState, { playload }) => {
        atualState.data = playload || atualState.data;
        atualState.status = 'loading';
    },
};
