import React, { useState, useEffect } from 'react';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { toast } from 'react-toastify';
import { DivBody, DivMain, DivSearch, DivTable, DivTitle } from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import ClienteSocialEdit from '~/components/clienteSocialEdit/ClienteSocialEdit';

export default function Social() {
    const [openPopup, setOpenPopup] = useState(false);
    const [clientSelected, setClientSelected] = useState(null);
    const [clientList, setClientList] = useState([]);
    // eslint-disable-next-line 
    const [postTemplateList, setPostTemplateList] = useState([]);
    const [loadScreen, setLoadScreen] = useState('none');
    const [selected, setSelected] = useState([true, false]);
    const [clientSearch, setClientSearch] = useState('');
    const api = useApi();
    async function getClientList(nameOrEmail) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/medxsocial/GetSocialClientes?busca=${nameOrEmail}`);
            if (data.status === 200) {
                if (data.data.length === 0) toast.warning('Nenhum cliente foi encontrado');
                setClientList(data.data);
                setClientSearch(nameOrEmail);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            setLoadScreen('none');
        }
    }
    // eslint-disable-next-line 
    async function getPostsTemplates(idOrTitle) {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/medxsocial/GetSocialPostsTemplates?busca=${idOrTitle}`);
            if (data.status === 200) {
                if (data.data === 0) toast.warning('Nenhum post encontrado');
                setPostTemplateList(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar o template');
            setLoadScreen('none');
        }
    }
    function selectClient(clientInfo) {
        setClientSelected({
            Id: clientInfo.Id,
            nome: clientInfo.Nome,
            Email: clientInfo.Email,
            Celular: clientInfo.Celular ?? '',
            DatadeInscricao: dayjs(clientInfo.DatadeInscricao).format('DD/MM/YYYY'),
            DatadeValidade: dayjs(clientInfo.DatadeValidade).format('DD/MM/YYYY'),
            PrecoPlanoCorrente: clientInfo.PrecoPlanoCorrente,
            PrecoPlano: clientInfo.PrecoPlano,
        });
    }
    // eslint-disable-next-line 
    function selectOption(op) {
        if (op === 1) {
            setSelected([true, false]);
        } else if (op === 2) {
            setSelected([false, true]);
        }
    }

    useEffect(() => {
        getClientList('');
        // eslint-disable-next-line 
    }, []);

    return (
        <DivMain>
            <DivTitle>
                <h1>Medx Social</h1>
            </DivTitle>
            <DivBody style={{ height: 'calc(100% - 60px)' }}>
                <DivSearch>
                    <input
                        onKeyPress={(event) => (event.key === 'Enter' ? getClientList(event.currentTarget.value) : '')}
                        type="text"
                        placeholder="Informe o nome ou email do cliente."
                    />
                </DivSearch>
                <DivTable>
                    <table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Dt Inscrição</th>
                                <th>Dt Validade</th>
                                <th>Plano</th>
                                <th>Email</th>
                                <th>Celular</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selected[0]
                                ? clientList.map((item) => (
                                    <tr
                                        key={item.Id}
                                        onClick={() => {
                                            selectClient(item);
                                            setOpenPopup(true);
                                        }}
                                    >
                                        <td>{item.Nome}</td>
                                        <td>{dayjs(item.DatadeInscricao).format('DD/MM/YYYY')}</td>
                                        <td>{dayjs(item.DatadeValidade).format('DD/MM/YYYY')}</td>
                                        <td>{item.PrecoPlanoCorrente.toFixed(2)}</td>
                                        <td>{item.Email}</td>
                                        <td>{item.Celular}</td>
                                    </tr>
                                ))
                                : ''}
                        </tbody>
                    </table>
                </DivTable>
            </DivBody>
            <LoadingScreen display={loadScreen} />
            <PopupDefault
                open={openPopup}
                close={() => setOpenPopup(false)}
                children={
                    <ClienteSocialEdit
                        dados={clientSelected}
                        close={setOpenPopup}
                        loading={setLoadScreen}
                        reload={() => getClientList(clientSearch)}
                    />
                }
            />
        </DivMain>
    );
}
