import styled from 'styled-components';

export const DivMain = styled.div`
    display:flex;
    align-items:baseline;
`
export const DivMini = styled.div`
    height:6px;
    width:10px;
    background: ${p => p.score >= 5 ? '#009600' : p.score > 2 ? '#e7d817' : '#c11212'};
    margin:0 3px 0 0;
`;
export const DivMedium = styled.div`
    height:10px;
    width:10px;
    background: ${p => p.score >= 5 ? '#009600' : p.score > 2 ? '#e7d817' : '#c11212'};
    margin:0 3px 0 0;
`
export const DivHigh = styled.div`
    height:15px;
    width:10px;
    background: ${p => p.score >= 5 ? '#009600' : p.score > 2 ? '#e7d817' : '#c11212'};
`