import styled from 'styled-components';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { lighten } from 'polished';
import { BiFilterAlt } from 'react-icons/bi';

export const ContainerDivCardGridInfo = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.colors.text};
    span {
        color: #6f6262;
    }
    input {
        width: 100%;
    }
`;
export const DivCardGridInfo = styled.div`
    display: flex;
    background: ${(props) => props.theme.colors.backgroundMenu};
    height: 60px;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    height: fit-content;
    transition: 0.5s;
    cursor: default;
    border: ${(props) => (props.cancelado ? '1px solid #ea5f5f' : '')};
    border-radius: 4px;

    :hover {
        transform: translateY(-2px);

        box-shadow: 0px 0px 15px -2px ${(props) => (props.theme.title === 'dark' ? '#282828' : 'red')};
    }
`;
export const DivInfoCard = styled.div`
    width: 100%;
    margin: 0 24px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin: ${(props) => (props.cancelado ? '0!important' : '')};
    }

    div {
        :nth-child(1) {
            display: ${(props) => (props.cancelado ? 'block!important' : '')};
        }
        display: ${(props) => (props.cancelado ? 'none' : 'flex')};
        div {
            display: flex;
        }
    }
`;
export const DivActionCard = styled.div`
    width: 100%;
    justify-content: center;
    margin: 12px 0 0 0;

    button {
        color: ${(props) => props.theme.colors.text};
        margin: 0 16px;
    }
    div {
        margin: 8px 0;
        :nth-child(3) {
            button {
                background: ${(props) => props.theme.colors.colorDelete};
            }
        }
    }
`;
export const DivContentInfo = styled.div`
    margin: 16px 0;
    justify-content: space-around;
    width: 100%;
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 24px 0 0;
        span {
            margin: 4px 0;
            color: ${(props) => props.theme.colors.textSecundary};
            font-size: 16px;
            :nth-child(2) {
                font-weight: 900;
            }
        }
    }
`;
export const DivTitle = styled.div`
    /* border-bottom: 1px solid #fefefe; */
    width: 100%;
    text-align: center;
    h1 {
        font-weight: 900;
        font-size: 22px;
        color: ${(props) => props.theme.colors.text};
        margin: 0 0 16px 0;
    }
`;
export const DivFilterSearch = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: default;
    z-index: 9;
    background: ${(props) => props.theme.colors.background};
    flex-direction: column;
    div {
        display: flex;
        div {
            margin: 0 16px 0 0;
        }
    }
`;
export const DivFilter = styled.div`
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    margin: 0 0 8px 0;
`;
export const DivOptionsFilter = styled.div`
    display: flex;
    align-items: center;
`;
export const IconIoIosAddCircleOutline = styled(IoIosAddCircleOutline)`
    width: 40px;
    height: 40px;
    color: #115213;
`;
export const DivBotaoNovaFatura = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    margin: 16px 0 !important;
    :hover {
        transform: scale(1.1);
    }
    span {
        color: ${(p) => p.theme.colors.text};
        margin: 0 0 0 12px;
    }
`;
export const DivOptionFiltro = styled.div`
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 50px;
    border-bottom: ${(props) => (props.selected ? '2px solid #fefefe' : '')};
    background: ${(props) => (props.selected ? '#fefefe' : 'inherit')};
    color: ${(props) => (props.selected ? '#000' : props.theme.colors.text)};
    transition: 0.5s;
    :hover {
        transform: scale(1.1);
    }
`;
export const DivBusca = styled.div`
    position: sticky;
    top: 0px;
    z-index: 10;
    box-shadow: 0px 2px 10px -2px #000;
`;
export const DivMainPopupEditClientInfo = styled.div`
    margin: 20px 0 0 0;
    padding: 20px;
    border-radius: 4px;
    position: relative;
`;
export const DivInfoClient = styled.div`
    margin: 12px 0;
    input,
    select {
        margin: 5px 0px 0px 0px;
    }
    option {
        margin: 5px 0;
    }
`;
export const DivButtonsPopupEditClient = styled.div`
    margin: 12px 0;
    display: flex;
    justify-content: space-around;
`;
export const TextAreaRps = styled.textarea`
    width: 100%;
    height: calc(100% - 88px);
    padding: 12px;
    border-radius: 6px;
    resize: none;
`;
export const DivInputSearch = styled.div`
    width: 100%;
    padding: 0 0 0 3px;
    input {
        margin: 5px 5px 5px 0 !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 0px 10px;
`;
export const SpanFiltro = styled.span`
    color: ${(p) => p.theme.colors.text}!important;
    margin: 0 8px 0 0;
`;
export const DivTable = styled.div`
    height: calc(100% - (60px + 80px + 8px + 16px + 8px));
    overflow: auto;
    margin: 0 16px 8px 16px;
    padding: 0 10px 10px 10px;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-radius: 6px;
    table {
        width: 100%;
        td {
            font-size: 14px;
            text-align: center;
            min-width: 60px;
        }
        thead {
            background-color: ${(p) => p.theme.colors.background};
            position: sticky;
            top: 0;
            height: 40px;
            border-bottom: 2px solid ${(p) => p.theme.colors.borderColor};
            tr {
                th {
                    vertical-align: middle;
                    text-align: start;
                    padding: 0 20px 0 0;
                }
            }
        }
        tbody {
            tr {
                height: 30px;
                cursor: pointer;
                transition: 0.5s;
                td {
                    vertical-align: middle;
                    padding: 0 0 0 5px;
                    margin: auto 0;
                    text-align: start;
                }
                :hover {
                    background: ${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`;
export const DivFooter = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.colors.background};
`;
export const IconBiFilterAlt = styled(BiFilterAlt)`
    width: 30px;
    height: 30px;
    transition: 0.5s;
    cursor: pointer;
    :hover {
        transform: scale(1.08);
    }
`;
