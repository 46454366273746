import styled, { css, ThemeConsumer, useTheme } from 'styled-components';

export const classificacoes = [
    { produto: 'Erro', boleto: false, cielo: false, pagarme: false },
    { produto: 'MEDX Ativação', boleto: true, cielo: true, pagarme: false },
    { produto: 'MEDX Renovação', boleto: true, cielo: true, pagarme: false },
    { produto: 'Consultoria', boleto: true, cielo: false, pagarme: false },
    { produto: 'SMS', boleto: true, cielo: false, pagarme: true },
    { produto: 'MEDX SOCIAL', boleto: false, cielo: false, pagarme: true },
    { produto: 'Prescreva', boleto: true, cielo: false, pagarme: false },
    { produto: 'Eventos', boleto: false, cielo: false, pagarme: true },
    { produto: 'DOCFLIX', boleto: false, cielo: false, pagarme: true },
];

export const MyTheme = () => {
    const theme = useTheme();
    return {
        'common.backgroundColor': theme.colors.colorBackGroundSchedule,
        'common.creationGuide.border': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.daygrid.backgroundColor': `1px solid ${theme.colors.colorBorderSchedule}`,
        'month.dayname.borderLeft': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.vpanelSplitter.border': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.daygrid.borderRight': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.daygridLeft.borderRight': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.timegridLeft.borderRight': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.timegridHorizontalLine.borderBottom': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.timegrid.borderRight': `1px solid ${theme.colors.colorBorderSchedule}`,
        'week.timegridLeftAdditionalTimezone.backgroundColor': theme.colors.colorSideSchedule,
        'week.timegridLeft.backgroundColor': theme.colors.colorSideSchedule,
        //cores dos dias da semana;
        'common.today.color': theme.colors.colorTextSchedule,
        'common.dayname.color': theme.colors.colorTextSchedule,
        'common.saturday.color': theme.colors.colorTextSchedule,
        'week.today.color': theme.colors.colortHoursSchedule,
        'week.futureTime.color': theme.colors.colortHoursSchedule,
    };
};
