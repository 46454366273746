import styled from 'styled-components';

export const DivFooter = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    height: 40px;
    margin-top: 10px;
`;
export const DivInfo = styled.div`
    display: flex;
    align-items: center;
    height:50px;
    span{
        width:120px;
        cursor: default;
    }
    input{
        flex:1;
    }
`
export const DivSelect = styled.div`
    height:40px;
    background:${p => p.theme.colors.borderColor};
    display:flex;
    align-items:center;
    border-radius:4px;
    padding:0 10px;
    cursor: pointer;
    transition:.5s;
    flex:1;
    margin:5px;
    position: relative; 
    :hover{
        border:1px solid;
        border-color: rgb(142 179 240);
        box-shadow: 0px 0px 5px -2px;
    }
`
export const DivSelectTitle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`
export const DivContentSelectUser = styled.div`
    display:${p => p.show ? 'block' : 'none'};
    max-height:200px;
    overflow:hidden auto;
    position: absolute;
    top: 40px;
    left: 0;
    background:${p => p.theme.colors.borderColor};
    width:100%;
    z-index:1;
`
export const DivObservacao = styled.div`
    margin:12px 0 0 0;
    textarea{
        resize:none;
    }
`