import { createTheme } from "@mui/material";
// eslint-disable-next-line
const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1463a6',
      },
      secondary: {
        main: '#e3750d',
      },
      background: {
        default: '#071e3f',
        paper: '#071e3f',
      },
      text: {
        primary: '#fafafa',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily:'inherit',
    },
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
    components:{
        MuiTextField:{
            styleOverrides:{
                root:{                    
                    boxShadow:'none'
                }
            }
        }
    }
  })
export default theme;
