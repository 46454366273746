import React, { useState, useEffect, useRef } from 'react';
import OwnerScheduleSelect from '~/components/selectCustom/selectCustom';
import {
    DivClientList,
    DivContentActions,
    DivContentScheduleInfo,
    DivPopupScheduleCustom,
    DivSearchClient,
    TextAreaCustom,
} from './StylePopupSchedule';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import Popup from 'reactjs-popup';
import { DivOptionsSelectAgendaName, DivSelectAgendaName, DivSelectionCustom } from '../selectCustom/StyledSelecCustom';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select, TextField, Typography,Button } from '@mui/material';
import { useTheme } from 'styled-components';

function PopupSchedule({ end, start, close, getAgendamentos, scheduleName }) {
    const theme = useTheme();
    const [scheduleCustom, setScheduleCustom] = useState({
        end,
        start,
        description: '',
        tipoAgendamento: 'Selecione o tipo do agendamento',
        tipoTreinamento: 'Selecione o tipo de treinamento',
        status: 2,
        idCliente: 0,
        idUsuario: getIdUser(scheduleName),
    });
    const [clientList, setClientList] = useState([]);
    const refDuration = useRef();
    const api = useApi({skip:true});    
    const arrayTiposAgendamento = ['Selecione o tipo do agendamento','Treinamento', 'Demonstração', 'Coleta de banco de dados', 'Restrição de horário', 'Outros'];
    const arrayTiposTreinamento = [
        'Selecione o tipo de treinamento',
        'Completo',
        'Secretária',
        'Médico',
        'Marketing',
        'Financeiro',
        'Medx Social',
        'Retirada de dúvidas',
    ];
    const arrayUsuarios = [
        {id:42,nome:'Treinamentos'},
        {id:32,nome:'Rodrigo'},
        {id:54,nome:'Yuri'}
    ];
    const arrayStatus = [
        {id:1,nome:'Cancelado'},
        {id:2,nome:'Agendado'}
    ]
    const [valueDuracao, setValueDuracao] = useState('01:30');
    function CalcEnd(time) {
        let hour = time.split(':')[0];
        let minutes = time.split(':')[1];
        let end = dayjs(scheduleCustom.start).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, end: end });
    }
    function getIdUser(userName) {
        try {
            if (userName === 'Treinamentos') {
                return 42;
            } else if (userName === 'Rodrigo') {
                return 32;
            } else if (userName) {
                return 54;
            } else return -1;
        } catch (error) {
            return -1;
        }
    }

    function removeClientesDuplicados(arrayClientes){
        const arrayLimpo = [];
        arrayClientes.forEach(clienteInfo => {
            const findCliente = arrayLimpo.find(cliente => cliente.Codigo_do_Cliente === clienteInfo.Codigo_do_Cliente);
            if(!findCliente){
                arrayLimpo.push(clienteInfo);
            }
        })
        return arrayLimpo;
    }

    async function loadClientList(client_name) {
        try {
            setClientList([]);
            const data = await api.get(`/cliente/GetListClients?search=${client_name}&filter=todos`);
            if (data.status === 200 && Array.isArray(data.data)) {
                setClientList(removeClientesDuplicados(data.data));
            }
        } catch (error) { 
            console.error(error);            
        }
    }
    function alteraInicio(date, duration) {
        let hour = duration.split(':')[0];
        let minutes = duration.split(':')[1];
        let end = dayjs(scheduleCustom.start).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, start: date, end: end });
        // CalcEnd(refDuration.current.value);
    }

    async function saveSchedule() {
        try {
            if (scheduleCustom.description === '') {
                toast.warning('Preencha o campo de descrição');
                return;
            }
            if (
                !scheduleCustom.tipoAgendamento ||
                scheduleCustom.tipoAgendamento === '' ||
                String(scheduleCustom.tipoAgendamento).toLowerCase() === 'selecione o tipo do agendamento'
            ) {
                toast.warning('Informe o tipo de agendamento, por favor.');
                return;
            }
            if (scheduleCustom.tipoTreinamento === 'Selecione o tipo de treinamento' || scheduleCustom.tipoTreinamento === '') {
                toast.warning('Informe o tipo de treinamento, por favor.');
                return;
            }
            if (dayjs(scheduleCustom.start).format('YYYY-MM-DDTHH:mm') > dayjs(scheduleCustom.end).format('YYYY-MM-DDTHH:mm')) {
                toast.warning('Horário inicial maior que o horário final.')
                return;
            }
            let newScheduleObj = {
                Id_do_Usuario: scheduleCustom.idUsuario,
                Inicio: `${dayjs(scheduleCustom.start).format('YYYY-MM-DDTHH:mm')}`,
                Final: `${dayjs(scheduleCustom.end).format('YYYY-MM-DDTHH:mm')}`,
                Descricao: (
                    scheduleCustom.description +
                    ' ' +
                    scheduleCustom.tipoAgendamento +
                    ' ' +
                    scheduleCustom.tipoTreinamento
                )
                    .replace(/undefined/g, '')
                    .trim(),
                Status: scheduleCustom.status,
                Tipo_Agendamento: (scheduleCustom.tipoAgendamento + '/').replace(/undefined/g, '').trim(),
                Id_Cliente: scheduleCustom.idCliente,
                Historico_Edicao: `${dayjs(new Date()).format('YYYY-MM-DDTHH:mm')}`,
            };
            const data = await api.post(`/agenda/InsertAgendamento`, newScheduleObj);
            // const data = { data: 'Success', status: 200 }
            if (data.data === 'Success' && data.status === 200) {
                toast.success('Agendamento feito com sucesso!');
                getAgendamentos(newScheduleObj.Inicio, newScheduleObj.Final);
                close();
            } else {
                toast.warning('Erro ao salvar o agendamento');
            }
        } catch (error) {
            toast.error('Não fio possível realizar o agendamento');
        }
    }
    useEffect(() => {
        loadClientList('');
        if(scheduleName && scheduleName != ""){
            setScheduleCustom({...scheduleCustom,idUsuario:getIdUser(scheduleName)});
        }
    }, []);

    return (<Dialog open={true} maxWidth={800} PaperProps={{sx:{background: theme.title === 'light' ? '#fafafa' : '#181818'}}}>
        <DialogTitle textAlign={'center'}>Novo Agendamento</DialogTitle>
        <DialogContent>
            <Box component={'form'} display={'flex'} flexDirection={'column'} onSubmit={(event)=>event.preventDefault()} padding={1} gap={2}>
                <FormControl>
                    <Autocomplete 
                        mb={2}               
                        fullWidth                
                        size='small'
                        disablePortal
                        options={clientList.map(clientInfo => ({label: clientInfo.Nome, id: clientInfo.Codigo_do_Cliente,email: clientInfo.Email}))}                
                        renderInput={(params) => (
                            <TextField required 
                                {...params} label="Cliente" 
                                onKeyDown={(event)=> event.key == 'Enter' ? loadClientList(event.target.value) : ''} 
                            />
                        )}
                        renderOption={(props, option,state,ownerState) =>(
                            <Box {...props} p={1} key={option?.id??1} mb={2} 
                                display='flex' flexDirection={'column'} alignItems={'flex-start!important'}
                                sx={{"&:hover":{background:theme.colors.background}}}  
                            >
                                <Typography m={'0 0 1 0'} >{option.label}</Typography>    
                                <Typography m={0} variant='caption'>{option.email}</Typography>    
                            </Box>
                        )}
                        onChange={(_,value)=> setScheduleCustom({...scheduleCustom,idCliente:value?.id?? 0})}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel >Usuário</InputLabel>
                    <Select native size='small' label='Usuário' 
                        value={scheduleCustom.idUsuario}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,idUsuario:target.value})}
                    >   
                        {
                            arrayUsuarios.map(usuario => <option key={usuario.id} value={usuario.id}>{usuario.nome}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel >Status</InputLabel>
                    <Select native size='small' label='Status' 
                        value={scheduleCustom.status}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,status:target.value})}
                    >   
                        {
                            arrayStatus.map(status => <option key={status.id} value={status.id}>{status.nome}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel >Tipo</InputLabel>
                    <Select native size='small' label='Tipo' 
                        value={scheduleCustom.tipoAgendamento}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,tipoAgendamento:target.value})}
                    >   
                        {
                            arrayTiposAgendamento.map(tiposAgendamento => <option key={tiposAgendamento} value={tiposAgendamento}>{tiposAgendamento}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel >Tipo de treinamento</InputLabel>
                    <Select native size='small' label='Tipo de treinamento' 
                        value={scheduleCustom.tipoTreinamento}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,tipoTreinamento:target.value})}
                    >   
                        {
                            arrayTiposTreinamento.map(tiposTreinamento => <option key={tiposTreinamento} value={tiposTreinamento}>{tiposTreinamento}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <Box display={'flex'} gap={2}>
                    <FormControl sx={{flex:1}}>
                        {/* <InputLabel>Inicio</InputLabel> */}
                        <TextField
                            sx={{flex:1}}                        
                            slotProps={{labelProps:{shrink:true}}}
                            label='Inicio'
                            size='small'
                            value={scheduleCustom.start}
                            onChange={(event) => alteraInicio(event.currentTarget.value, valueDuracao)}
                            type='datetime-local'
                        />
                    </FormControl>
                    <FormControl>
                        {/* <InputLabel>Duração</InputLabel> */}
                        <TextField
                            slotProps={{labelProps:{shrink:true}}}
                            label='Duração'
                            size='small'
                            value={valueDuracao}
                            onChange={(event) =>{setValueDuracao(event.target.value); CalcEnd(event.currentTarget.value)}}
                            type='time'
                        />
                    </FormControl>
                </Box>
                <Box>
                    <span>
                    Agendamento será feito das <strong>{dayjs(scheduleCustom?.start).format('DD/MM/YYYY HH:mm')}</strong> às{' '}
                    <strong>
                        <span>{scheduleCustom?.end ? dayjs(scheduleCustom.end).format('DD/MM/YYYY HH:mm') : ''}</span>
                    </strong>
                    </span>
                </Box>
            <FormControl>                
                <TextField
                    label="Descrição"                    
                    InputLabelProps={{
                        shrink: true,
                    }}
                    multiline
                    fullWidth
                    rows={4}
                    value={scheduleCustom.description}
                    onChange={(event) => setScheduleCustom({ ...scheduleCustom, description: event.currentTarget.value })}
                />
            </FormControl>
            <DivContentActions>
                <Button variant='outlined' onClick={close} sx={{flex:1,mr:2 }}>
                    Cancelar
                </Button>
                <Button variant='contained' onClick={saveSchedule} sx={{flex:1 }}>
                    Salvar
                </Button>
            </DivContentActions>
            </Box>
        </DialogContent>        
    </Dialog>
    );
}

export default PopupSchedule;
