import { lighten } from 'polished';
import styled from 'styled-components';
export const DivMain = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    padding: 8px 16px 16px 16px;
    > input {
        width: 100%;
        margin: 16px 0;
    }
    > h1 {
        font-size: 22px;
    }
    @media (max-width: 900px) {
        overflow: hidden auto;
    }
`;
export const DivDadosPessoais = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
export const DivInfo = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;

    > div {
        @media (max-width: 900px) {
            flex-direction: column;
        }
        margin: 8px 0;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
export const DivInfoContent = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        align-items: flex-start;
    }
`;
export const DivInfoIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const DivBotoes = styled.div`
    display: flex;
    margin: 16px 0;

    > button {
        :nth-child(3) {
            @media (max-width: 900px) {
                display: none;
            }
        }
        padding: 8px;
        font-size: 16px;
        cursor: pointer;
        transition: 0.3s;
        :active {
            transform: translateY(2px);
        }
    }
`;
export const TableInfoVendas = styled.table`
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-radius: 6px;
    cursor: default;
    user-select: none;
    color: ${(p) => p.theme.colors.text};
    display: block;

    > thead {
        font-weight: bolder;
        position: sticky;
        top: 0;
        background-color: #181818;
        > tr {
        }
    }

    > tbody,
    thead {
        width: 100%;
        text-align: initial;
        > tr {
            width: 100%;
            > td {
                padding: 12px;
            }
        }
    }

    > tbody {
        > tr {
            width: 100%;
            cursor: pointer;
            :hover {
                background: #71727394;
            }
            td {
            }
        }
    }
    @media (max-width: 900px) {
        display: none;
    }
`;
export const DivMobileContratos = styled.div`
    margin: 0 0 12px 0;
    @media (min-width: 900px) {
        display: none;
    }
`;
export const DivInfoContratoMobile = styled.div`
    margin: 0 0 8px 0;
`;
export const DivFatura = styled.div`
    margin: 24px 0 0 0;
    width: fit-content;
    max-width: 100%;
`;
export const DivHeaderDatabase = styled.div`
    margin: 0 0 20px 0;
    border-radius: 6px;
    font-weight: bolder;
    color: #626263;
    font-size: 17px;
`;
export const DivFaturasMobile = styled.div`
    height: fit-content;
    width: fit-content;
    @media (min-width: 900px) {
        display: none;
    }
`;
export const DivMainInfoFaturasMobile = styled.div`
    margin: 0 0 12px 0;
    display: flex;
    flex-direction: column;
    background: #010305;
    padding: 12px;
    border-radius: 6px;
    div {
        display: flex;
        flex-wrap: wrap;
        margin: 4px 0;
    }
`;
export const DivFaturasContratos = styled.div`
    margin: 16px 0 0 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 900px) {
    }
`;
export const SpanNameField = styled.span`
    font-weight: 600;
`;
export const TdDescription = styled.td`
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const DivMainSearch = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 12px 0;
    height: 40px;
    cursor: default;
    padding: 0 16px;
    top: 0;
    :hover {
        background: #71727394;
    }
`;
export const DivListClientSearch = styled.div`
    display: ${(props) => (props.hide ? 'block' : 'none')};
    position: absolute;
    top: 40px;
    max-height: 400px;
    background: ${(p) => p.theme.colors.backgroundMenuSearch};
    overflow: auto;
    z-index: 10;
    width: calc(100% - 10px);
    border-radius: 6px;
`;
export const DivMainPopupViewDataBase = styled.div`
    padding: 20px;
    border-radius: 6px;
    margin: 20px 0 0 0;
`;
export const DivInfoPopupViewDataBase = styled.div`
    margin: 0 0 12px 0;
    cursor: pointer;
`;
export const DivInfoContrato = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    > div {
        margin: 0 0 0 10px;
        > select {
            height: 38px;
            padding: 0 0 0 8px;
            border: 0px solid #000;
            border-radius: 4px;
        }
    }
`;
export const DivInfoTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    h1 {
        margin: 0 16px 0 0;
        @media screen and (max-width: 767px) {
            margin-bottom: 8px;
        }
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const DivUsers = styled.div`
    margin: 24px 0 0 0;
`;

export const DivTableUsers = styled.div`
    margin: 24px 0 0 0;
    border: 1px solid ${(p) => p.theme.colors.borderColor};
    border-radius: 6px;
    padding: 0 0 10px 0px;
    max-height: 400px;
    overflow: auto;
    width: calc(100% - 16px);

    table {
        cursor: default;
        width: 100%;
        thead {
            position: sticky;
            top: 0;
            background: ${(p) => p.theme.colors.background};
            z-index: 2;                        
            tr {
                &:nth-child(1) {
                    th:nth-child(3) {
                        text-align: center;
                    }
                }
                &:nth-child(2) {
                    th:nth-child(15) {
                        text-align: center;
                    }
                }
                th {          
                    padding-top:8px;       
                    padding-bottom:8px;       
                    vertical-align: middle;
                    text-align: center;                    
                    border: 0;
                }
            }
        }
        tbody {
            tr {
                height: 45px;
                td {
                    text-align: center;
                    vertical-align: middle;
                    input {
                        width: 30px;
                        height: 30px;
                        color: blue;
                    }
                    button {
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                }
                &:hover {
                    background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`;
export const TdFixed = styled.td`
    text-align: start !important;
    position: sticky;
    left: 0;
    background: ${(p) => p.theme.colors.background};    
    padding-left: 10px;
`;
export const ThFixed = styled.th`
    text-align: start !important;
    position: sticky;
    left: 0;    
    background: inherit;
    padding-left: 10px;
`;
export const ThButton = styled.th`
    text-align: start !important;
    position: sticky;
    right: 0;    
    background: inherit;
    padding-left: 10px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
export const TdButton = styled.td`
    position: sticky;
    right: 0;
    background: ${(p) => p.theme.colors.background};    
    padding-right: 10px;
    vertical-align: middle;
    button {
        margin: auto;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
export const SpanInfoDados = styled.span`
    margin-left: 8px;
`;
