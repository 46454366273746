import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import GlobalStyle from '~/config/GlobalStyle';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import {ThemeProvider as ThemeMui} from '@mui/material'
import 'react-toastify/dist/ReactToastify.css';
import dark from '~/styles/themes/dark';
import light from '~/styles/themes/light';
import themeMui from '~/styles/themes/mui';


function App() {
    const [theme, setTheme] = useState(dark);
    function selectTheme() {
        let theme = localStorage.getItem('theme');
        if (theme) {
            if (theme == 'dark') {
                setTheme(dark);
            } else return setTheme(light);
        } else return setTheme(dark);
    }
    return (
        <ThemeMui theme={themeMui}>
            <ThemeProvider theme={theme}>
                <Router setTheme={setTheme} />
                <GlobalStyle />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    className="toastClassCustom"
                />
            </ThemeProvider>
        </ThemeMui>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
