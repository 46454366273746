import React from 'react';
import { Title } from '../commonComponents/commonComponents';
import { DivMainContent, DivMainSmsView, DivViewSmsList } from './styledPopupViewSmsList';
import dayjs from 'dayjs';

export default function PopupViewSmsList({ sms }) {
    return <DivMainSmsView id="DivMainSmsView">
        <Title>Extrato de envio de sms</Title>
        <DivViewSmsList >
            {sms.map((item) => (
                <DivMainContent >
                    <div style={{ margin: '12px 0' }}>
                        <span> <strong>Data: </strong>{dayjs(item.Data).format('DD/MM/YYYY')} </span>
                    </div>
                    <div>
                        <span><strong>Mensagem: </strong>{item.Mensagem}</span>
                    </div>
                </DivMainContent>
            ))}
        </DivViewSmsList>
    </DivMainSmsView>;
}
