import React from 'react';

// import { Container } from './styles';

function Envios() {
    return (
        <div>
            <h1>Envios</h1>
        </div>
    );
}

export default Envios;
