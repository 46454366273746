import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components'
import dayjs from 'dayjs';
import MenuGammyx from '~/components/menuGammyx/menuGammyx';
import { Container, DivCampoPopup, DivContainerPopup, DivTituloPopup } from './styledGammyxCursos';
import useApi from '~/service/api';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { DivBotao, DivBusca, DivTabela } from '../alunos/styledGammyxAlunos';
import { Button, Input, Select, TextArea } from '~/components/commonComponents/commonComponents';
import { formataCurrency } from '~/helpers/functions';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import TabelaModelo from '~/components/tabelaModelo/tabelaModelo';
export default function GammyxCursos() {
    const api = useApi();
    const theme = useTheme();
    const formRef = useRef();
    const history = useHistory();
    const [cursos, setCursos] = useState([]);
    const [autores, setAutores] = useState([]);
    const [mostraLoad, setMostraLoad] = useState(false);
    const [popupCadastroCurso, setPopupCadastroCurso] = useState(false);
    const [dadosNovoCurso, setDadosNovoCurso] = useState({
        nome: "",
        lancamento: "",
        descricao: "",
        urlImagem: "",
        autorId: "",
        valor: "",
        alias: "",
    })
    useEffect(() => {
        carregaCursos("");
        carregaAutores();
    }, []);
    function editaCurso(cursoInfo) {
        history.push({ pathname: `curso/${cursoInfo.id}`, state: cursoInfo });
    }
    async function carregaCursos(nome) {
        setMostraLoad(true)
        try {
            const responseCarregaCursos = await api.get(`/gammyx/GetCursos?Nome=${nome}`);
            if (responseCarregaCursos.status == 200) {
                console.log(responseCarregaCursos.data);
                setCursos(responseCarregaCursos.data);
            }
        } catch (error) {
            toast.error('Não foi possível carregar lista de cursos.')
        }
        setMostraLoad(false)
    }
    async function carregaAutores() {
        setMostraLoad(true);
        try {
            const responseGetAutoresResumo = await api.get(`/gammyx/GetAutoresResumo`);
            if (responseGetAutoresResumo.status == 200) {
                setAutores(responseGetAutoresResumo.data);
            } else {
                toast.error('Erro ao carregar lista de autores.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de autores.')
        }
        setMostraLoad(false);
    }
    async function cadastraCurso(event) {
        setMostraLoad(true)
        try {
            event.preventDefault();
            const responseCadastraCurso = await api.post(`/gammyx/InsertCurso`, dadosNovoCurso);
            if (responseCadastraCurso.status == 200) {
                toast.success('Curso cadastrado com sucesso.')
                await carregaCursos('');
                setPopupCadastroCurso(false);
                setDadosNovoCurso({
                    nome: "",
                    lancamento: "",
                    descricao: "",
                    urlImagem: "",
                    autorId: "",
                    valor: "",
                    alias: "",
                })
            } else {
                toast.error('Erro ao cadastrar curso.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível cadastrar curso.')
        }
        setMostraLoad(false)
    }
    const header = ['Nome', 'Lançamento', 'Valor', ''];
    function retornaValor(key, ObjCompleto) {
        if (key == 'Nome')
            return ObjCompleto?.nome ?? "Sem nome"
        if (key == 'Lançamento')
            return dayjs(ObjCompleto.lancamento).format('DD/MM/YYYY')
        if (key == 'Valor')
            return formataCurrency.format(ObjCompleto.valor)
        if (key == '')
            return <Button onClick={() => editaCurso(ObjCompleto)}>Editar</Button>
        return ""
    }
    return <Container>
        <MenuGammyx />
        <DivBusca>
            <Input placeholder='Busque pelo nome do curso.'
                onKeyDownCapture={({ key, target, currentTarget }) => key == 'Enter' ? carregaCursos(currentTarget.value) : ''}
            />
        </DivBusca>
        <TabelaModelo
            header={header}
            fnRetornaValor={retornaValor}
            clientes={cursos}
            callbackDoubleClick={editaCurso}
        />
        <DivBotao>
            <Button onClick={() => setPopupCadastroCurso(true)}>Cadastrar Curso</Button>
        </DivBotao>
        {mostraLoad && <LoadingScreen display={"flex"} />}
        {popupCadastroCurso &&
            <PopupDefault
                esc={false}
                open={true}
                close={() => setPopupCadastroCurso(false)}
                children={<DivContainerPopup>
                    <DivTituloPopup>
                        <h1>Cadastrar Curso</h1>
                    </DivTituloPopup>
                    <form ref={formRef} onSubmit={(event) => cadastraCurso(event)}>
                        <DivCampoPopup>
                            <span>Nome:</span>
                            <Input value={dadosNovoCurso.nome}
                                placeholder='Informe o nome do curso.'
                                onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, nome: target.value })}
                                required
                            />
                        </DivCampoPopup>
                        <DivCampoPopup>
                            <span><nobr> Link Capa:</nobr></span>
                            <Input value={dadosNovoCurso.urlImagem} type="text" placeholder='Informe o link da capa do curso.' onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, urlImagem: target.value })} />
                        </DivCampoPopup>
                        <div style={{ display: 'flex' }}>
                            <DivCampoPopup style={{ flex: 1, margin: '0 12px 0 0' }}>
                                <span>Lançamento:</span>
                                <Input required
                                    type="date"
                                    placeholder='Informe o nome do curso.'
                                    value={dadosNovoCurso.lancamento}
                                    onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, lancamento: target.value })}
                                />
                            </DivCampoPopup>
                            <DivCampoPopup style={{ flex: 1 }}>
                                <span style={{ width: 'fit-content' }}>Valor:</span>
                                <Input
                                    required
                                    value={dadosNovoCurso.valor}
                                    onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, valor: target.value })}
                                    type="text"
                                    placeholder='Informe o preço do curso.'
                                />
                            </DivCampoPopup>

                        </div>
                        <DivCampoPopup>
                            <span>Autor:</span>
                            <Select onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, autorId: target.value })} value={dadosNovoCurso.autorId}>
                                <option>Selecione um autor</option>
                                {
                                    autores.map(autor => <option key={autor.id} value={autor.id}>{autor.nome}</option>)
                                }
                            </Select>
                        </DivCampoPopup>
                        <DivCampoPopup>
                            <span>Alias:</span>
                            <Input required
                                value={dadosNovoCurso.alias}
                                onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, alias: target.value })}
                                type="text"
                                placeholder='Informe o alias do curso.'
                            />
                        </DivCampoPopup>
                        <DivCampoPopup>
                            <span>Descrição:</span>
                            <TextArea
                                onChange={({ target }) => setDadosNovoCurso({ ...dadosNovoCurso, descricao: target.value })}
                                value={dadosNovoCurso.descricao} placeholder='Informe a descrição do curso.'
                            />
                        </DivCampoPopup>
                    </form>
                    <div style={{ display: 'flex', margin: '16px 0 0 0' }}>
                        <Button style={{ flex: 1, background: theme.colors.colorDelete }} onClick={() => setPopupCadastroCurso(false)}>Cancelar</Button>
                        <Button style={{ flex: 1, background: theme.colors.colorSave }} onClick={() => formRef.current.requestSubmit()}>Salvar</Button>
                    </div>
                </DivContainerPopup>}
            />
        }
    </Container>;
}