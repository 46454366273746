import { lighten } from 'polished';
import styled from 'styled-components';

export const DivMainUsuarios = styled.div`
    height: 100%;
    width: 100%;
    padding: 20px;
`;
export const DivTitulo = styled.div`
    text-align: center;
    height: 35px;
`;
export const DivTabelaUsuarios = styled.div`
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    padding:0 10px 10px 10px;
    @media screen and (max-width:800px) {
        overflow-x: auto;
    }
`;
export const TableUsuarios = styled.table`
    width:100%;
thead {
    height:40px;
        tr {
            position: sticky;
            top: 0;
            background: ${p => p.theme.colors.background};
            th {
                padding: 10px;
                vertical-align: middle;
            }
        }
    }
    tbody {
        tr {
            transition: 0.5s;
            :hover{
                    background:${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
            td {
                width: 200px;
                word-break: break-word;
                text-align: center;
                vertical-align: middle;
                padding: 10px;
            }
        }
    }
`;
export const DivInputUsuario = styled.div`
    input {
        width: 25px;
    }
`;
export const DivButtonsUsuario = styled.div`
    margin: 12px 0 0 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
