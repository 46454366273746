import styled from 'styled-components';

export const DivMainDescription = styled.div`
    display:flex;
    flex-direction:column;
    background:#292828;
    padding:16px;
    
    cursor:default;
    div{
        display:flex;
        margin:8px 0;
        align-items:center;
        span{
            margin:0 8px 0 0;
        }
    }

    input{
        height:20px;
        width:20px;
    }
`;
