import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useApi from '~/service/api';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { DivPrincipalLista, IconAiOutlineMail, IconAiOutlineWhatsApp, TablePaymentLate } from './StyledFinancasBoletosAtrasados';

export default function FinancasBoletosAtrasados() {

    const [boletosBoletosAtrasados, setBoletosBoletosAtrasados] = useState([]);
    // eslint-disable-next-line 
    const [listaBuscaAtrasados, setListaBuscaAtrasados] = useState([]);
    const [cacheBoletos, setCacheBoletos] = useState([]);
    const [loadScreen, setLoadScreen] = useState('none');
    const api = useApi();
    async function getBoletosAtrasados() {
        try {
            setLoadScreen('flex');
            const today = dayjs(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
            const requestGetBoletosAtrasados = await api.get(`/boleto/GetBoletosAtrasados?inicio=${today}`);
            if (requestGetBoletosAtrasados.status === 200) {
                setBoletosBoletosAtrasados(requestGetBoletosAtrasados.data);
                setCacheBoletos(requestGetBoletosAtrasados.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível buscar faturas pendentes.')
        }
    }
    function buscaBoletoAtrasado(evento) {
        try {
            if (evento.key === 'Enter') {
                if (evento.target.value !== "") {
                    let listaFiltrada = cacheBoletos.filter(info => {
                        if (info.Nome)
                            return info.Nome.toLowerCase().includes(evento.target.value.toLowerCase())
                        else {
                            // eslint-disable-next-line 
                            return;
                        }
                    });
                    setBoletosBoletosAtrasados(listaFiltrada);
                } else {
                    setBoletosBoletosAtrasados(cacheBoletos);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar a busca.')
        }
    }
    useEffect(() => {
        getBoletosAtrasados();
        console.log('finanças boleto')
        // eslint-disable-next-line 
    }, [])

    return <div style={{ padding: '20px', height: '100%', width: '100%' }}>
        <div style={{ height: '40px', textAlign: 'center' }}>
            <h1>Boletos Atrasados</h1>
        </div>
        <div style={{ height: '40px' }}>
            <input type="text" placeholder='Informe nome do cliente' onKeyPress={(evento) => buscaBoletoAtrasado(evento)} />
        </div>
        <DivPrincipalLista>
            <TablePaymentLate>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Vencimento</th>
                        <th>Valor</th>
                        <th>Whatsapp</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        boletosBoletosAtrasados.map(payment => {
                            return <tr key={payment.Id}>
                                <td>{payment.Nome}</td>
                                <td>{`${payment.Referente_a} ${payment.Referente_a_1}`}</td>
                                <td>{dayjs(payment.Vencimento).format('DD/MM/YYYY')}</td>
                                <td>R${payment.Valor}</td>
                                <td><a target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://api.whatsapp.com/send?phone=55${payment.Celular}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}><IconAiOutlineWhatsApp /></a></td>
                                <td><a
                                    target="_blank" rel="noopener noreferrer" href={`mailto:${payment.Email}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}><IconAiOutlineMail /></a></td>
                            </tr>
                        })
                    }
                </tbody>
            </TablePaymentLate>
        </DivPrincipalLista>

        <LoadingScreen display={loadScreen} />
    </div>;

}