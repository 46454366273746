import styled from 'styled-components';

export const DivMainPopupBuscaAgendamento = styled.div`
  max-width: 800px;
    max-height: 550px;
    overflow: hidden auto;
`;

export const DivConteudoAgendamento = styled.div`
    margin: 12px 0;
    border-bottom:1px solid #fff;
    padding: 12px 6px;
    cursor:default;
    :hover{
        background:#1463a6;
    }
`
export const DivResultadoBusca = styled.div`
    display:${props => props.mostraResultado ? 'block' : 'none'};
`