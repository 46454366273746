import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';
export const DivMainEditBoleto = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    position: relative;
    overflow:hidden auto;
    @media (max-width: 900px) {
        max-width: 80%;
        max-height: 80%;
    }
     @media (max-height : 724px ) and (min-height: 0px) {        
        height: 550px;
    }
    @media (max-height: 700px) and (min-height: 725px) {        
        height: 650px;
    }
    @media (max-height: 800px) and (min-height: 701px){        
        height: 700px;
    }
`;
export const DivMainBoletoContent = styled.div`
    display: flex;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
export const DivContentElements = styled.div`
    display: flex;
    align-items: center;
    /* margin: 8px 0 8px 0; */
    /* span {
        margin: 0 8px 0 0;
        width: 150px;
    } */
    input {
        /* margin: 0 8px 0 0; */
    }
`;

export const SpanCancelado = styled.span`
    font-size: 1.5rem;
    margin: 0 0 0 12px;
    color: ${(props) => props.theme.colors.secondary};
`;

export const DivLeftBoletoEdit = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 12px 0 0;
`;

export const DivRightBoletoEdit = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const DivButton = styled.div`
    display: flex;
    justify-content: end;

    :nth-child(1) {
        background: ${(props) => props.theme.colors.colorSave};
    }
`;

export const DivIdBoleto = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0 8px 0;
    cursor: default;
    width: fit-content;
    div {
        margin: 0 12px 0 0;
        display: flex;
    }
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
export const IconAiOutlineCloseCircle = styled(AiOutlineClose)`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
    transition: 0.5s;
    color: #fff;
    padding: 5px;
    background: ${(p) => p.theme.colors.background};
    border-radius: 50px;

    :hover {
        transform: scale(1.1);
    }
    @media (max-width: 900px) {
        top: 0px;
        right: 0px;
    }
`;

export const DivListClientes = styled.div`
    display: ${(props) => (props.hide ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    background: #0d0d0d;
    top: 40px;
    border-radius: 6px;
    cursor: default;
    max-height: 200px;
    overflow: auto;
    diV {
        margin: 8px 0;
        padding: 12px;

        :hover {
            background: ${(props) => props.theme.colors.primary};
        }
    }
`;
export const IconMdContentCopy = styled(MdContentCopy)`
    width: 16px;
    height: 16px;
    margin-left: 6px;
    cursor: pointer;
`;
export const DivMainPopupParcelarBoleto = styled.div`
    padding: 32px;
    > p {
        margin: 8px 0;
    }
    > div {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        > button {
            flex: 1;
        }
    }
`;
