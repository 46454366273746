import styled from "styled-components";

export const MensagemPergunta = styled.li`
    white-space: break-spaces;
    border: 1px solid #215179;
    margin: 0 8px 16px 0;
    padding: 8px;
    border-radius: 8px;
    font-size:13px;
    background:${props => props.pergunta ? 'unset' : '#1a364d'};
`