// eslint-disable-next-line
export default {
    title: 'light',

    colors: {
        primary: '#1463a6',
        secondary: '#ea5f5f',
        background: '#f5f5f5',
        text: '#333',
        textSecundary: '#505050',
        textPopup: '#fff',
        borderColor: '#d9d9d9',
        backgroundMenu: '#1463a6',
        backgroundSubMenu: '#104d82',
        colorMenu: '#fffff1',
        colorSave: '#2e7d32',
        colorDelete: '#d32f2f',
        colorHoverTextMenu: '#000',
        colorHoverTextMenuSubItem: '#fff',
        backgroundInput: '#1d1d1d',
        //CORES POPUP
        backgroundPopup: '#000000',
        //CORES AGENDA
        colorBackGroundSchedule: '#f5f5f5',
        colorBorderSchedule: '#202020',
        colorSideSchedule: '#f5f5f5',
        colorTextSchedule: '#000',
        colortHoursSchedule: '#000',
        colorBackGroundScheduleItem: '#aba',

        backgroundMenuSearch: '#ededed',

        //CORES DASHBOARD

        backgroundCardDashboard: '#2e2e2e',
        colorSelecao: '#1463a6'
    },
};
