import React from 'react';
import { Box, Divider, Fade, List, ListItemButton, ListItemIcon, ListItemText, Popper, TextField, Typography } from '@mui/material';
import { DivMain } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {NavLink} from 'react-router-dom'

const ListaWhatsappComponent = ({arrayMensagens,escolheConversa})=>{
    const [numeroConversaSelecionada,setNumeroConversaSelecionada] = React.useState(null);
    return(
        <List component="nav" sx={{height:'100%',overflow:'hidden auto',paddingRight:'8px'}}>
            {
                arrayMensagens.length?arrayMensagens.map(mensagem => (
                        <ListItemButton selected={mensagem?.from == numeroConversaSelecionada} 
                            // onClick={()=>setConversaSelecionada(mensagem)} 
                            onClick={()=>{setNumeroConversaSelecionada(mensagem.from);escolheConversa(mensagem)}}
                            key={mensagem?.from?? ''}
                        >
                            <ListItemText primary={mensagem?.from?? ''} />
                            <ListItemIcon sx={{ml:2}} title='Entrar em contato'>
                            <a target="_blank"
                                title={'Entrar em contato'}
                                rel="noopener noreferrer"
                                href={`https://api.whatsapp.com/send?phone=${mensagem?.from?? ''}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <WhatsAppIcon fontSize='small' sx={{color:'green'}} />
                            </a>                                    
                            </ListItemIcon>
                        </ListItemButton>
                    )
                ): 
                    ''
            }                
        </List>
    ) 
}
const ChatWhatsappComponent = ({arrayMensagens}) =>{
    const styleMensagem = {padding:'8px'};

    return(
        Array.isArray(arrayMensagens)?
        arrayMensagens.map(mensagem =>( 
            <Box display={'grid'} width={'100%'}>
                <Typography sx={styleMensagem}>Data atendimento: {dayjs(mensagem.date).format('DD/MM/YYYY HH:mm')}</Typography>
                <Box maxWidth={'50%'}>
                    <Typography sx={{...styleMensagem}}>Usuário:</Typography>
                    <Typography sx={{...styleMensagem,margin:'0 0 8px 0'}}>{mensagem?.user?? ''}</Typography>
                </Box>
                <Box maxWidth={'50%'} justifySelf={'flex-end'}>
                    <Typography sx={{...styleMensagem,background:'#314a5f',borderRadius:'8px 8px 0 0'}}>Bot atendimento:</Typography>
                    <Typography sx={{...styleMensagem,background:'#314a5f',margin:'0 0 8px 0',borderRadius:'0 0 8px 8px'}}>{mensagem?.agent?? ''}</Typography>
                </Box>
                <Divider sx={{mt:2,mb:2}}></Divider>
            </Box>
            )
        )
        :
        ''
       
    )
}

export default function MensagensSdr() {
    const [conversaSelecionada, setConversaSelecionada] = React.useState({mensagens:[]});
    const [listaMensagens, setListaMensagens] = React.useState([]);
    const [loading, setLoading] = React.useState('none');
    const api = useApi();

    function organizaMensagem(arrayMensagens){
        try {
            if(Array.isArray(arrayMensagens)){
                const arrayOrganizado = [];
                arrayMensagens.forEach(mensagens =>{
                    const findIndexNumero = arrayOrganizado.findIndex( x=> String(x.from).trim() == String(mensagens.from).trim());
                    if(findIndexNumero == -1){
                        let objNumero = {from:String(mensagens.from).trim(),
                            mensagens:[{user:mensagens.user,agent:mensagens.agent,date:mensagens.date}]
                        };
                        arrayOrganizado.push(objNumero);
                    }else{
                        let objMensagens = {user:mensagens.user,agent:mensagens.agent,date:mensagens.date};                        
                        arrayOrganizado[findIndexNumero].mensagens.push(objMensagens);
                    }
                })
                return arrayOrganizado;   
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar organizar lista de mensagens.')
        }
    }

    async function carregaMensagens(busca){
        try {
            setLoading('flex')
            const responseCarregaMensagens = await api.get('/cliente/GetListaMensagensSdr?busca='+busca);
            const {status,data} = responseCarregaMensagens;
            if(status == 200){
                toast.success('Lista de mensagens carregada.')
                setLoading('none')
                return data;
            }            
            throw 'Error'
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar carregar mensagens.');
            setLoading('none')
            throw 'Error'
        }
    }

    function compararDatas(a,b){
        return new Date(a.date) - new Date(b.date)
    }

    function scrollToEnd(){
        
    }

    async function init(busca){
        try {
            let listaMensagens = await carregaMensagens(busca);
            let mensagensOrganizadas = organizaMensagem(listaMensagens);
            mensagensOrganizadas = mensagensOrganizadas.map(mensagemOrganizada =>{
                if(Array.isArray(mensagemOrganizada?.mensagens)){
                    mensagemOrganizada.mensagens = mensagemOrganizada.mensagens.sort( compararDatas )
                }
                return mensagemOrganizada
            })
            setListaMensagens(mensagensOrganizadas);
        } catch (error) {
            console.error(error);
            toast.error('Error na inicialização da paginas.')
        }
    }

    React.useEffect(()=>{
        init('')        
    },[])


  return(
    <DivMain>
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
        <Typography variant='h1' fontSize={'2em'} textAlign={'center'}>Mensagens do SDR</Typography>
        <Divider sx={{mt:2,mb:2}}></Divider>
        <TextField variant='standard' placeholder='Pesquise por palavras na mensagem do usuário' 
            // value={busca}
            // onChange={({target})=>setBusca(target.value)}
            onKeyDown={({key,target}) => key == 'Enter' ?init(target.value) : '' }
            sx={{mb:1,height:60}} inputProps={{sx:{height:'100%',padding:'8px',boxShadow:'none'}}} 
        />
        <Box display={'flex'} height={'calc(100% - 150px)'}>
           <ListaWhatsappComponent arrayMensagens={listaMensagens} escolheConversa={setConversaSelecionada}/>
           <Box display={conversaSelecionada?'flex':'none'} flex={1} flexDirection={'column'} ml={2} pr={1} sx={{height:'100%',overflow:'hidden auto'}}>
                <ChatWhatsappComponent arrayMensagens={conversaSelecionada.mensagens}/>
           </Box>
        </Box>
    </Box>    
    <LoadingScreen display={loading} />
  </DivMain>
  );
};