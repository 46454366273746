import React, { useState, useEffect, useRef } from 'react';
import {DivContentActions} from '../popupSchedule/StylePopupSchedule';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select, TextField, Typography,Button } from '@mui/material';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
const duration2 = require('dayjs/plugin/duration');
dayjs.extend(duration2);
export default function PopupEditSchedule({ id, close, getAgendamentos, scheduleName }) {
    const theme = useTheme();
    const [scheduleCustom, setScheduleCustom] = useState({});
    const [clientList, setClientList] = useState([]);
    const [userNameSchedule, setUserNameSchedule] = useState(scheduleName);
    const refScheduleInfo = useRef({});    
    const arrayTiposAgendamento = ['Treinamento', 'Demonstração', 'Coleta de banco de dados', 'Restrição de horário', 'Outros'];
    const api = useApi();
    const history = useHistory();

    const arrayUsuarios = [
        {id:42,nome:'Treinamentos'},
        {id:32,nome:'Rodrigo'},
        {id:54,nome:'Yuri'}
    ];
    const arrayStatus = [
        {id:1,nome:'Cancelado'},
        {id:2,nome:'Agendado'}
    ]
    const [valueDuracao, setValueDuracao] = useState('01:30');


    function CalcEnd(time) {
        let hour = time.split(':')[0];
        let minutes = time.split(':')[1];
        let end = dayjs(scheduleCustom.Inicio).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, Final: end });
    }

    function setInfoSchedule(string, field) {
        refScheduleInfo.current[field] = string;
    }

    function calcDuration(inicio, final) {
        if (inicio && final) {
            const date1 = dayjs(inicio);
            const date2 = dayjs(final);
            const duration = dayjs.duration(date2.diff(date1, 'milliseconds')).format('HH:mm');

            return duration;
        }
    }

    function alteraInicio(date, duration) {
        let hour = duration.split(':')[0];
        let minutes = duration.split(':')[1];
        let end = dayjs(scheduleCustom.Inicio).add(hour, 'hours').add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm');
        setScheduleCustom({ ...scheduleCustom, Inicio: date, Final: end });
        // CalcEnd(refDuration.current.value);
    }

    async function getSchedule(id) {
        try {
            const data = await api.get(`/agenda/GetAgendamentoById?id=${id}`);
            if (data.status === 200) {
                const duration = calcDuration(data.data[0].Inicio, data.data[0].Final);                            
                setScheduleCustom({ ...data.data[0], Id_do_Agendamento: id, duration: duration, Tipo_Agendamento: data.data[0].Tipo_Agendamento.replace(/\//g,'') });
            }
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar agendamentos');
        }
    }

    async function loadClientList(client_name) {
        try {
            const data = await api.get(`/cliente/GetListClients?search=${client_name}&filter=todos`);
            if (data.status === 200 && Array.isArray(data.data)) {                
                setClientList(removeClientesDuplicados(data.data));
            }
        } catch (error) {}
    }

    function removeClientesDuplicados(arrayClientes){
        const arrayLimpo = [];
        arrayClientes.forEach(clienteInfo => {
            const findCliente = arrayLimpo.find(cliente => cliente.Codigo_do_Cliente === clienteInfo.Codigo_do_Cliente);
            if(!findCliente){
                arrayLimpo.push(clienteInfo);
            }
        })
        return arrayLimpo;
    }

    const Horafinal = ({ date }) => {
        return <span>{dayjs(date).format('DD/MM/YYYY HH:mm')}</span>;
    };

    async function editSchedule(obj_agendamento) {
        try {
            if (
                !obj_agendamento.Tipo_Agendamento ||
                obj_agendamento.Tipo_Agendamento == '' ||
                obj_agendamento.Tipo_Agendamento == '/'
            ) {
                toast.warning('Informe o tipo de agendamento, por favor.');
                return;
            }

            const info_update = {
                Final: dayjs(obj_agendamento.Final).format('YYYY-MM-DDTHH:mm'),
                Inicio: dayjs(obj_agendamento.Inicio).format('YYYY-MM-DDTHH:mm'),
                Descricao: obj_agendamento.Descricao,
                Tipo_Agendamento: obj_agendamento.Tipo_Agendamento,
                Status: obj_agendamento?.Status === 'Agendado' || obj_agendamento?.Status === 2 ? 2 : 1,
                Id_do_Agendamento: obj_agendamento.Id_do_Agendamento,
                Id_do_Usuario: getIdUser(userNameSchedule),
                Id_da_Venda: obj_agendamento.Id_da_Venda || '',
                Id_Cliente: obj_agendamento.Id_Cliente || '',
                Historico_Edicao: dayjs(new Date()).format('YYYY-MM-DDTHH:mm'),
            };
            const data = await api.put(`/agenda/UpdateAgendamento`, info_update);
            // const data = { data: 'Success', status: 200 }
            if (data.data === 'Success' && data.status === 200) {
                toast.success('Editado com sucesso.');
                getAgendamentos(info_update.Inicio, info_update.Final);
                close(false);
            } else {
                toast.warning('Não foi possível salvar o agendamento.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar a edição do agendamento.');
        }
    }

    function getIdUser(userName) {
        try {
            if (userName === 'Treinamentos') {
                return 42;
            } else if (userName === 'Rodrigo') {
                return 32;
            } else if (userName) {
                return 54;
            } else return -1;
        } catch (error) {
            return -1;
        }
    }

    const mostraValorInicial = ()=>{
        const clientFind = clientList.find(clientInfo => clientInfo.Codigo_do_Cliente === scheduleCustom.Id_Cliente);
        return clientFind ? {label: clientFind.Nome, id: clientFind.Codigo_do_Cliente,email: clientFind.Email} :null
    }

    useEffect(() => {
        getSchedule(id);
    }, []);

    return (
        <Dialog open={true} maxWidth={800} PaperProps={{sx:{background: theme.title === 'light' ? '#fafafa' : '#181818'}}}>
        <DialogTitle textAlign={'center'}>Edição de agendamento</DialogTitle>
        <DialogContent>
            <Box component={'div'} display={'flex'} flexDirection={'column'} onSubmit={(event)=>event.preventDefault()} padding={1} gap={2}>
                <FormControl>
                    <Autocomplete 
                        mb={2}               
                        fullWidth                
                        size='small'
                        disablePortal
                        value={{label: scheduleCustom?.Nome??'', id: scheduleCustom?.Id_Cliente??0}}                        
                        options={clientList.map(clientInfo => ({label: clientInfo.Nome, id: clientInfo.Codigo_do_Cliente,email: clientInfo.Email}))}
                        renderInput={(params) => (
                            <TextField required 
                                {...params} label="Cliente"                                
                                onKeyDown={(event)=> event.key == 'Enter' ? loadClientList(event.target.value) : ''} 
                            />
                        )}
                        renderOption={(props, option,state,ownerState) =>(
                            <Box {...props} p={1} key={option?.id??1} mb={2} 
                                display='flex' flexDirection={'column'} alignItems={'flex-start!important'}
                                sx={{"&:hover":{background:theme.colors.background}}}  
                            >
                                <Typography m={'0 0 1 0'} >{option.label}</Typography>    
                                <Typography m={0} variant='caption'>{option.email}</Typography>    
                            </Box>
                        )}
                        onChange={(_,value)=> setScheduleCustom({...scheduleCustom,Id_Cliente:value?.id?? 0,Nome:value?.label??''})}
                    />
                </FormControl>
                <Box width={'100%'} display={'flex'} justifyContent={'end'} sx={{cursor:'pointer'}}>
                    <Typography variant='caption' onClick={()=>history.push(`/app/cliente/${scheduleCustom.Id_Cliente}`)}>Acessar perfil</Typography>
                </Box>
                <FormControl>
                    <InputLabel >Usuário</InputLabel>
                    <Select native size='small' label='Usuário' 
                        value={scheduleCustom.Id_do_Usuario}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,Id_do_Usuario:target.value})}
                    >   
                        {
                            arrayUsuarios.map(usuario => <option key={usuario.id} value={usuario.id}>{usuario.nome}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel >Status</InputLabel>
                    <Select native size='small' label='Status' 
                        value={scheduleCustom.Status}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,Status:target.value})}
                    >   
                        {
                            arrayStatus.map(status => <option key={status.id} value={status.id}>{status.nome}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel >Tipo</InputLabel>
                    <Select native size='small' label='Tipo' 
                        value={scheduleCustom.Tipo_Agendamento}
                        onChange={({target})=>setScheduleCustom({...scheduleCustom,Tipo_Agendamento:target.value})}
                    >   
                        {
                            arrayTiposAgendamento.map(tiposAgendamento => <option key={tiposAgendamento} value={tiposAgendamento}>{tiposAgendamento}</option>)
                        }                                                                                                            
                    </Select>
                </FormControl>                
                <Box display={'flex'} gap={2}>
                    <FormControl sx={{flex:1}}>
                        {/* <InputLabel>Inicio</InputLabel> */}
                        <TextField
                            sx={{flex:1}}                        
                            InputLabelProps={{shrink:true}}
                            label='Inicio'
                            size='small'
                            value={scheduleCustom.Inicio}
                            onChange={(event) => alteraInicio(event.target.value, valueDuracao)}
                            type='datetime-local'
                        />
                    </FormControl>
                    <FormControl>
                        {/* <InputLabel>Duração</InputLabel> */}
                        <TextField
                            slotProps={{labelProps:{shrink:true}}}
                            label='Duração'
                            size='small'
                            value={valueDuracao}
                            onChange={(event) =>{setValueDuracao(event.target.value); CalcEnd(event.currentTarget.value)}}
                            type='time'
                        />
                    </FormControl>
                </Box>
                <Box>
                    <span>
                    Agendamento será feito das <strong>{dayjs(scheduleCustom?.Inicio).format('DD/MM/YYYY HH:mm')}</strong> às{' '}
                    <strong>
                        <span>{scheduleCustom?.Final ? dayjs(scheduleCustom.Final).format('DD/MM/YYYY HH:mm') : ''}</span>
                    </strong>
                    </span>
                </Box>
            <FormControl>
                <TextField
                    label="Descrição"                    
                    InputLabelProps={{
                        shrink: true,
                    }}
                    multiline
                    fullWidth
                    rows={4}
                    value={scheduleCustom.Descricao}
                    onChange={(event) => setScheduleCustom({ ...scheduleCustom, Descricao: event.currentTarget.value })}
                />
            </FormControl>
            <DivContentActions>
                <Button variant='outlined' onClick={() => close(false)} sx={{ flex:1,mr:2 }}>
                    Cancelar
                </Button>
                <Button variant='contained' onClick={() => editSchedule(scheduleCustom)} sx={{flex:1 }}>
                    Salvar Edição
                </Button>
            </DivContentActions>
            </Box>
        </DialogContent>        
    </Dialog>
        
    );
}
