import styled, { keyframes } from 'styled-components';
const loaderKeyframes = keyframes`
    0% { transform: rotate(0deg);}
    25% { transform: rotate(180deg);}
    50% { transform: rotate(180deg);}
    75% { transform: rotate(360deg);}
    100% { transform: rotate(360deg);}
    `;
const loaderInnerKeyFrames = keyframes`
    0% { height: 0%;}
    25% { height: 0%;}
    50% { height: 100%;}
    75% { height: 100%;}
    100% { height: 0%;}
    `;
export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: ${(props) => (props.position ? props.position : 'fixed')};
    top: 0;
    left: 0;
    background-color: ${(props) => (props.background ? props.background : '#333333bd')};
    display: ${(props) => (props.display ? props.display : 'flex')};
    justify-content: center;
    align-items: center;
    z-index: 1000001;
`;
export const Loader = styled.span`
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #fff;
    animation: ${loaderKeyframes} 2s infinite ease;
`;
export const LoaderInner = styled.span`
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: ${loaderInnerKeyFrames} 2s infinite ease-in;
`;
