import styled, { keyframes } from 'styled-components';
import { MdExpandMore, MdExpandLess, MdKeyboardBackspace } from 'react-icons/md'
import { Button } from '~/components/commonComponents/commonComponents';
const deslizaBaixo = keyframes`
  from{
    opacity:0;
    transform:translateY(-40px)
  }to{
    opacity:1;
    transform:translateY(0px)
  }

`
export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding:16px;
  height:100%;
  width:100%;
`;
export const DivVoltar = styled.div`
  display:flex;
  justify-content:start;
  >div{
    display:flex;
    align-items:center;
    cursor:pointer;
    transition:.5s;
    >span{
      margin-left:8px;
    }
    :hover{
      transform:scale(1.08) translateX(10px);
    }
  }
`;
export const DivTitulo = styled.div`
  display:flex;
  margin:16px 0;
  align-items:center;
  >h1{
    font-size:30px;
  }
  >button{
    margin-left:16px;
  }
`
export const DivDetalhesResumidosCurso = styled.div`
  div{
    margin:8px 0;
  }
`
export const JornadaContainer = styled.div`
  display:flex;
  border:2px solid #fff;
  padding:16px;
  border-radius:8px; 
  >div{
    flex:1;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:flex-start;
  }
  div{
    :last-child{            
        text-align:right;
        justify-content:end;
        >span{
          display:flex;
          align-items:center;
          cursor: pointer;
          user-select:none;
        }        
      }
  }
`;
export const IconMdExpandMore = styled(MdExpandMore)`
  width:30px;
  height:30px;
  cursor: pointer;
`;
export const IconMdExpandLess = styled(MdExpandLess)`
  width:30px;
  height:30px;
  cursor: pointer;
`;
export const IconMdKeyboardBackspace = styled(MdKeyboardBackspace)`
  width:30px;
  height:30px;
  cursor: pointer;
`;
export const MaisDetalhes = styled.div`
  display:${p => p.mostrar ? 'flex' : 'none'};
  flex-direction:column;
  border-right:1px solid;
  border-left:1px solid;
  border-bottom:1px solid;
  border-radius:0 0 8px 8px;
  padding:16px;
  animation:${deslizaBaixo} .5s linear;
  animation-fill-mode: forwards;
`;
export const DivConteudoMaisDetalhes = styled.div`
  max-height:300px;
  overflow:hidden auto;  
  margin:0 0 16px 0;
  padding:0 16px 0 0;
`;
export const DivTituloCollapseMaisDetalhes = styled.div`
display	:flex;
align-items:center;
justify-content:space-between;
margin:0 0 24px 0;
  >div{
    >span{
      cursor: pointer;
      display:flex;
      align-items:center;
      justify-content:center;
    }
  }
`;
export const SpanTituloMaisDetalhes = styled.span`
  font-size:22px;
`;
export const DivConteudoMaisDetlhes = styled.div`
  display:${p => p.mostrar ? 'block' : 'none'};
  margin:16px 0;
`;
export const DivAulaInfo = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin:0 0 16px 0;
  border-bottom:1px solid #fff;
  padding:0 0 8px 0;
`;
export const DivAlunoInfoContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin:0 0 16px 0;
  border-bottom:1px solid #fff;
  padding:0 0 8px 0;
  height:40px;
  >div{
    flex:1;
  }
` ;
export const DivJornadasDetalhes = styled.div`
  flex:1;
  overflow:hidden scroll;
  padding: 0 8px 0 0;
`

//#region  POPUP EDIÇÃO DE CURSO.

export const DivPrincipalEdicaoCurso = styled.div`
  padding:0 24px 16px 24px;
  display:flex;
  flex-direction:column;
  min-width:900px;
  max-height:600px;
  overflow:hidden auto;
  >h1{
    margin:0 0 24px 0;
    text-align:center;
  }
`
export const DivPrecoAlias = styled.div`
  display:flex;
`
export const ButtonSave = styled(Button)`
  flex:1;
  background:${p => p.theme.colors.colorSave};
`
export const ButtonDelete = styled(Button)`
  flex:1;
  background:${p => p.theme.colors.colorDelete};
`
//#endregion