import React, { useState, useRef, useMemo } from 'react';
import { Box, Tab, Tabs, Typography, SpeedDial, SpeedDialIcon, SpeedDialAction, Stack, Button, Skeleton, TextField } from '@mui/material';
import { Save as SaveIcon, Print as PrintIcon, Share as ShareIcon, FileCopyOutlined as FileCopyIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { DivMain } from '~/components/commonComponents/commonComponents';
import { useTheme } from 'styled-components';

export default function FinancasIntegracaoPlugNotas() {
    const [value, setValue] = useState(0);
    const [tabConfigValue, setTabConfigValue] = useState(0)
    const inputFileRef = useRef(0);
    const [dadosCertificado, setDadosCertificado] = useState(null);
    const [dadosEmpresa, setDadosEmpresa] = useState({
        cpfCnpj: "08187168000160",
        inscricaoMunicipal: "8214100099",
        inscricaoEstadual: "1234567850",
        razaoSocial: "Tecnospeed S/A",
        nomeFantasia: "Tecnospeed",
        certificado: "5af59d271f6e8f409178fbf3",
        simplesNacional: true,
        regimeTributario: 1,
        incentivoFiscal: true,
        incentivadorCultural: true,
        regimeTributarioEspecial: 5,
        endereco: {
            bairro: "Zona 01",
            cep: "87020025",
            codigoCidade: "4115200",
            estado: "PR",
            logradouro: "Duque de Caxias",
            numero: "882",
            tipoLogradouro: "Avenida",
            codigoPais: "1058",
            complemento: "17 andar",
            descricaoCidade: "Maringá",
            descricaoPais: "Brasil",
            tipoBairro: "Zona"
        },
        telefone: {
            numero: "30379500",
            ddd: "44"
        },
        email: "empresa@plugnotas.com.br",
        nfse: {
            ativo: true,
            tipoContrato: 0,
            config: {
                producao: true,
                rps: {
                    lote: 1,
                    numeracao: [
                        {
                            numero: 1,
                            serie: "RPS"
                        }
                    ],
                    numeracaoAutomatica: true
                },
                prefeitura: {
                    login: "teste",
                    senha: "teste123",
                    receitaBruta: 0,
                    lei: "string",
                    dataInicio: "2021-03-24"
                },
                email: {
                    envio: true
                },
                calculoAutomaticoIbpt: {
                    ativo: true
                }
            }
        },
        nfe: {
            ativo: true,
            tipoContrato: 0,
            config: {
                producao: true,
                impressaoFcp: true,
                impressaoPartilha: true,
                dfe: {
                    ativo: true
                },
                email: {
                    envio: true
                },
                numeracao: [
                    {
                        numero: 1,
                        serie: 1
                    }
                ],
                integracoes: {
                    plugStorage: {
                        ativo: true,
                        email: "string",
                        senha: "string",
                        token: "string"
                    }
                },
                calculoAutomaticoIbpt: {
                    ativo: true
                }
            }
        },
        nfce: {
            ativo: true,
            tipoContrato: 0,
            config: {
                producao: true,
                email: {
                    envio: true
                },
                sefaz: {
                    idCodigoSegurancaContribuinte: "string",
                    codigoSegurancaContribuinte: "string"
                },
                numeracao: [
                    {
                        numero: 1,
                        serie: 1
                    }
                ],
                integracoes: {
                    plugStorage: {
                        ativo: true,
                        email: "string",
                        senha: "string",
                        token: "string"
                    }
                },
                calculoAutomaticoIbpt: {
                    ativo: true
                }
            }
        }
    })
    const theme = useTheme();
    //#region fluxo

    /*
        fazer api que verifica se o cliente possui certificado digital (passando cpf/cnpj cadastrado.)
        
    */

    //#endregion

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const selecionaAbaConfig = (event, newValue) => {
        setTabConfigValue(newValue);
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
    ];

    function openFileInput() {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const updateData = e => {
        setDadosEmpresa({
            ...dadosEmpresa,
            [e.target.name]: e.target.value
        })
    }
    async function salvarDadosEmpresa(event) {
        try {
            event.preventDefault()
            console.log(event.target)
        } catch (error) {
            console.error(error);
        }
    }

    return <DivMain>
        <h1>Integração PLUG NOTAS</h1>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Notas emitidas" {...a11yProps(0)} style={{ color: "#fff" }} />
                    <Tab label="Configurações" {...a11yProps(1)} style={{ color: "#fff" }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                Notas emitidas
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabConfigValue} onChange={selecionaAbaConfig} aria-label="basic tabs example">
                            <Tab label="Certificado" {...a11yProps(0)} style={{ color: "#fff" }} />
                            <Tab label="Dados Empresa" {...a11yProps(1)} style={{ color: "#fff" }} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabConfigValue} index={0}>
                        <Box>
                            {
                                !dadosCertificado?.Certificado ?
                                    <Box>
                                        <Stack spacing={2} direction="row">
                                            <input type="file" accept='.pfx,.p12' ref={inputFileRef} style={{ display: 'none' }} />
                                            <Button variant="contained" type="file" onClick={() => openFileInput()}>Selecionar Certificado</Button>
                                            <TextField id="outlined-basic" focused label="Senha" variant="outlined" inputProps={{ style: { background: theme.colors.backgroundInput, color: theme.colors.text } }} />
                                        </Stack>
                                        <Button variant="contained">Enviar</Button>
                                    </Box>
                                    :
                                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: '6px' }} animation="wave" variant="rectangular" width={210} height={50} />
                            }
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabConfigValue} index={1}>
                        <Box component="form" sx={{ display: 'flex', flexDirection: "column" }} onSubmit={(event) => salvarDadosEmpresa(event)}>
                            <TextField label="CPF/CNPJ" variant="outlined" focused required
                                defaultValue={dadosEmpresa.cpfCnpj}
                                onChange={(event) => updateData(event)}
                                inputProps={{ style: { background: theme.colors.backgroundInput, color: theme.colors.text } }}
                                name='cpfCnpj'
                            />
                            <TextField label="Inscrição Municipal" variant="outlined" focused
                                inputProps={{ style: { background: theme.colors.backgroundInput, color: theme.colors.text } }}
                            />
                            <Button type='submit'>Salvar dados</Button>
                        </Box>
                    </TabPanel>
                    <Box sx={{ position: 'absolute', bottom: 30, right: 30, transform: 'translateZ(0px)', flexGrow: 1 }}>
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: 'absolute', bottom: 0, right: 0 }}
                            icon={<SpeedDialIcon />}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
            </TabPanel>
            <Box sx={{ position: 'absolute', bottom: 30, right: 30, transform: 'translateZ(0px)', flexGrow: 1 }}>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </Box>
        </Box>
    </DivMain>;
}

