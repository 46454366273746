import React, { useState, useEffect } from 'react';
import useApi from '~/service/api';
import { DivCardEditCliente } from './StyledClienteSocialEdit';
import { Button } from '../commonComponents/commonComponents';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

export default function ClienteSocialEdit({ dados, close, loading, reload }) {
    const theme = useTheme();
    const [clientEditInfo, setClientEditInfo] = useState({
        Id: dados.Id,
        Nome: dados.nome,
        Email: dados.Email,
        Celular: dados.Celular ?? '',
        DatadeInscricao: organizeDate(dados.DatadeInscricao),
        DatadeValidade: organizeDate(dados.DatadeValidade),
        PrecoPlanoCorrente: dados.PrecoPlanoCorrente,
        PrecoPlano: dados.PrecoPlano,
    });

    const api = useApi();

    async function saveEditClient() {
        try {
            loading('flex');
            const data = await api.post(`/medxsocial/UpdatePlanoById`, clientEditInfo);
            if ((data.status === 200) & (data.data != 'Error')) {
                toast.success('Salvo com sucesso');
                loading('none');
                close(false);
                reload();
            } else {
                toast.error('Não foi possível salvar os dados.');
                loading('none');
            }
        } catch (error) {
            console.log(error);
            loading('none');
        }
    }
    function organizeDate(date) {
        return `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`;
    }

    function normalizaFloat(number) {
        try {
            return setClientEditInfo({ ...clientEditInfo, PrecoPlanoCorrente: parseFloat(number).toFixed(2) });
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível converter o número');
        }
    }
    function normalizaFloat2(number) {
        try {
            return setClientEditInfo({ ...clientEditInfo, PrecoPlano: parseFloat(number).toFixed(2) });
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível converter o número');
        }
    }
    return (
        <DivCardEditCliente>
            <div>
                <span>Nome:</span>
                <input
                    defaultValue={clientEditInfo.Nome}
                    type="text"
                    onChange={(event) => setClientEditInfo({ ...clientEditInfo, Nome: event.target.value })}
                />
            </div>
            <div>
                <span>Data de Inscrição : </span>{' '}
                <input
                    defaultValue={clientEditInfo.DatadeInscricao}
                    type="date"
                    onChange={(event) => setClientEditInfo({ ...clientEditInfo, DatadeInscricao: event.target.value })}
                />
            </div>
            <div>
                <span>Vigencia : </span>{' '}
                <input
                    defaultValue={clientEditInfo.DatadeValidade}
                    type="date"
                    onChange={(event) => setClientEditInfo({ ...clientEditInfo, DatadeValidade: event.target.value })}
                />
            </div>
            <div>
                <span>Email : </span>{' '}
                <input
                    defaultValue={clientEditInfo.Email}
                    type="text"
                    onChange={(event) => setClientEditInfo({ ...clientEditInfo, Email: event.target.value })}
                />
            </div>
            <div>
                <span>Celular : </span>{' '}
                <input
                    defaultValue={clientEditInfo.Celular}
                    type="text"
                    onChange={(event) => setClientEditInfo({ ...clientEditInfo, Celular: event.target.value })}
                />
            </div>
            <div>
                <span>Valor Plano : </span>{' '}
                <input
                    defaultValue={clientEditInfo.PrecoPlano}
                    type="number"
                    onChange={(event) => normalizaFloat2(event.target.value)}
                />
            </div>
            <div>
                <span>Valor Plano Atual : </span>{' '}
                <input
                    defaultValue={clientEditInfo.PrecoPlanoCorrente}
                    type="number"
                    onChange={(event) => normalizaFloat(event.target.value)}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={() => close(false)} style={{ background: theme.colors.colorDelete }}>Cancelar</Button>
                <Button style={{ background: theme.colors.colorSave }} onClick={() => saveEditClient()}>
                    Salvar
                </Button>
            </div>
        </DivCardEditCliente>
    );
}
