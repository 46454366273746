import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './stylesNotFound';
import img from '~/assets/images/not_found_dark.svg';

function NotFound() {
    return (
        <Container>
            <img src={img} alt="" />
            <h1>Oh, Não, Algo Correu Mal!</h1>
            <span>A página que procura pode ter sido apagada ou nunca existiu.</span>
            <Link to="/app/clientes">Ir para pagina inicial</Link>
        </Container>
    );
}

export default NotFound;
