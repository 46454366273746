import { lighten } from 'polished';
import styled from 'styled-components';

export const DivMainEditParticipante = styled.div`
    min-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 8px;

    h3 {
        text-align: center;
    }
`;
export const TrEventos = styled.tr`
    cursor: pointer !important;
    color: ${(p) => (p.cancelado ? p.theme.colors.secondary : 'inherit')};
`;
export const DivCampo = styled.div`
    display: flex;
    align-items: center;
    span {
        width: 120px;
    }
    input {
        margin-left: 10px;
        margin-bottom: 10px;
        flex: 1;
    }
`;
export const DivMainParticipantesNaoConfirmados = styled.div`
    padding: 12px;
`;
export const DivOpcaoFiltro = styled.div`
    height: 40px;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    padding: 0 5px;
    :hover {
        background-color: ${(p) => lighten(0.03, p.theme.colors.background)};
    }
`;
