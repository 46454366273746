import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, Title } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import { DivMainUsuarios, DivTable } from './StyleUsuarioMedx';

// import { Container } from './styles';

function UsuariosMedx() {
    const api = useApi();
    const [users, setUsers] = useState([]);
    const [openLoadScreen, setOpenLoadScreen] = useState('flex');

    async function getClientesLoginUnificado(email) {
        try {
            setOpenLoadScreen('flex');
            const data = await api.get(`/medx/GetUsuarios?busca=${email || ''}`);
            if (data.status === 200 && Array.isArray(data.data)) {
                if (data.data.length === 0) {
                    const data2 = await api.get(`/cliente/GetListClients?search=${email}`);
                    if (data2.status === 200 && Array.isArray(data2.data)) {
                        setUsers(data2.data);
                        setOpenLoadScreen('none');
                        return;
                    } else {
                        toast.warning('Cliente não cadastrado');
                    }
                }
                setUsers(data.data);
                setOpenLoadScreen('none');
            } else {
                toast.warning('Não foi possível carregar a lista de ');
                setOpenLoadScreen('none');
            }
        } catch (error) {
            setOpenLoadScreen('none');

            console.log('deu ruim');
        }
    }

    useEffect(() => {
        getClientesLoginUnificado();
        // eslint-disable-next-line 
    }, []);

    return (
        <DivMainUsuarios>
            <Title>Lista de usuários/clientes</Title>
            <div style={{ display: 'flex', width: '100%' }}>
                <Input
                    onKeyPress={(event) => (event.key === 'Enter' ? getClientesLoginUnificado(event.currentTarget.value) : '')}
                    placeholder="Pesquise pelo email do cliente, ou parte do email do cliente"
                />
            </div>
            <DivTable>
                <table>
                    <thead>
                        <tr>
                            <td>UserName</td>
                            <td>Email</td>
                            <td>Id Conta</td>
                            <td>Cancelado</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item) => (
                            <tr>
                                <td>{item.UserName}</td>
                                <td>{item.Email}</td>
                                <td>{item.DbId ? item.DbId : item.SoftwareId}</td>
                                <td>{item.Cancelada}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </DivTable>
            <LoadingScreen display={openLoadScreen} />
        </DivMainUsuarios>
    );
}

export default UsuariosMedx;
