import styled from 'styled-components';

export const Container = styled.div`
    background: ${(p) => (p.theme.title === 'light' ? '#fafafa' : '#181818')};
    position: relative;
    padding: 10px 0 0 0;
    border-radius: 6px;
    box-shadow: 0px 8px 15px 5px ${(p) => (p.theme.title === 'light' ? '#acacac' : '#000000')};
    width: ${(props) => props.largura ?? 'unset'};
    height: ${(props) => props.altura ?? 'unset'};
    color: ${(p) => (p.theme.title === 'light' ? '#333' : p.theme.colors.textPopup)}!important;
    p,
    span {
    }
    @media screen and (max-width:767px){
        width:100%;
        height:100%;
    }
`;
