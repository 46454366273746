// eslint-disable-next-line
export default {
    title: 'dark',

    colors: {
        primary: '#1463a6',
        secondary: '#ea5f5f',
        background: '#181818',
        text: '#ffffffeb',
        textSecundary: '#cecece',
        textPopup: '#fff',
        borderColor: '#1d1d1d',
        backgroundMenu: '#1a1a1a',
        backgroundSubMenu: '#101010',
        colorMenu: '#ffffffeb',
        colorSave: '#2e7d32',
        colorDelete: '#d32f2f',
        colorHoverTextMenu: '#ffffffeb',
        colorHoverTextMenuSubItem: '#ffffffeb',
        backgroundInput: '#1d1d1d',
        //CORES POPUP
        backgroundPopup: '#000000',
        //CORES AGENDA
        colorBackGroundSchedule: '#33312b',
        colorBorderSchedule: '#202020',
        colorSideSchedule: '#f5f5f5',
        colorTextSchedule: '#ffffff',
        colortHoursSchedule: '#ffffff',
        colorBackGroundScheduleItem: '#fefefe',

        backgroundMenuSearch: '#000',

        //CORES DASHBOARD

        backgroundCardDashboard: '#2e2e2e',
        colorSelecao: '#1463a6'

    },
};
