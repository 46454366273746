import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding:16px;
  height:100%;
  width:100%;
`;
export const DivListaVendas = styled.div`
    margin: 8px 0px 0px;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    flex: 1;
`
export const RowVenda = styled.div`
  display:flex;
  margin:0 0 16px 0;
  align-items:center;
  justify-content:center;
  transition:.5s;
  padding:0 0 0 8px;
  border-radius:8px;
  cursor:default;
  >div{
    display:flex;
    justify-content:center;
    flex:1;
  }
  :hover{
    /* background:${p => p.theme.colors.secondary}; */
    background: #0f528b!important;
  }
  :nth-child(even){
    background:#242424;
  }
`