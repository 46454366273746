import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Input } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import MenuGammyx from '~/components/menuGammyx/menuGammyx';
import { CardDashboard, Container, DivCardResumo, DivCardsDashboard, DivInfoContentCursoMaisVendido, DivInfoCursoMaisVendido, DivOpcaoGammyx, DivOpcaoGammyxContent, DivSelecaoPeriodo, DivUltimasComprasContent, SpanInfo, SpanInfoContent, SpanInfoMainCursoMaisVendido, SpanNomeCursoMaisVendido, SpanSubtituloInfoCursoMaisVendido, SpanTituloInfo } from './styledGammyxDashboard';

export default function GammyxDashboard() {
    const formataCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })
    const api = useApi();
    const [dadosDashboard, setDadosDashboard] = useState([])
    const [dadosCursoMaisVendido, setDadosCursoMaisVendido] = useState({
        nome: "",
        totalVendas: "",
        faturamento: ""
    })
    const [dadosFaturamentoLeads, setDadosFaturamentosLeads] = useState([])
    const [dadosUltimasVendas, setDadosUltimasVendas] = useState([])
    const [dadosUltimasInscricoes, setDadosUltimasInscricoes] = useState([])

    useEffect(() => {
        init()
    }, [])

    async function getDadosDashBoard(inicio, final) {
        try {
            const { status, data } = await api.get(`/gammyx/GetDadosDashBoard?inicio=${inicio}&final=${final}`)
            if (status == 200) {
                setDadosDashboard([
                    {
                        categoria: "Alunos novos",
                        numero: data?.AlunosNovo ?? 0
                    },
                    {
                        categoria: "Total alunos",
                        numero: data?.TotalAlunos ?? 0
                    },
                    {
                        categoria: "Faturamento",
                        numero: data?.Faturamento ?? 0
                    },
                    {
                        categoria: "Jornadas",
                        numero: data?.QtdJornadas ?? 0
                    },
                    {
                        categoria: "Autores",
                        numero: data?.QtdAutores ?? 0
                    },
                ]
                )
            } else {
                toast.error('Error ao carrega dados resumo')
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao carregar dados resumo.')
        }
    }
    async function getDadosCursoMaisVendido() {
        try {
            const { data, status } = await api.get(`/gammyx/GetDadosCursoMaisVendido`);
            if (status == 200) {
                setDadosCursoMaisVendido({
                    nome: data.Curso,
                    totalVendas: data.TotalVendas,
                    faturamento: data.Faturamento
                })
            } else {
                toast.error('Erro ao carregar curso mais vendido.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao carregar dados co curso mais vendido.')
        }
    }
    async function getDadosLeads() {
        try {

            const { status, data } = await api.get(`/gammyx/GetDadosLeads`);
            if (status == 200) {
                setDadosFaturamentosLeads(data)
            } else {
                toast.error('Erro ao carregar dados de leads.')
            }

        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar dados de leads.')
        }
    }
    async function getUltimasVendas() {
        try {

            const { status, data } = await api.get(`/gammyx/GetUltimasVendas`);
            if (status == 200) {
                setDadosUltimasVendas(data)
            } else {
                toast.error('Erro ao carregar dados de leads.')
            }

        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar dados de leads.')
        }
    }
    async function getUltimosAlunosInscritos() {
        try {

            const { status, data } = await api.get(`/gammyx/GetUltimosAlunosInscritos`);
            if (status == 200) {
                setDadosUltimasInscricoes(data)
            } else {
                toast.error('Erro ao carregar dados de leads.')
            }

        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar dados de leads.')
        }
    }
    async function init() {
        try {
            let ano = new Date().getFullYear();
            let inicio = `${ano}-01-01`;
            let final = `${ano}-12-31`;
            await getDadosDashBoard(inicio, final);
            await getDadosCursoMaisVendido();
            await getDadosLeads();
            await getUltimasVendas();
            await getUltimosAlunosInscritos();
        } catch (error) {
            toast.error('Error na inicialização da página.')
        }
    }


    return <Container>
        <MenuGammyx />
        <DivSelecaoPeriodo>
            <span><nobr>Overview periodo de:</nobr></span>
            <Input type="date" min={"1900-01-01"} max={"9999-12-31"} style={{ maxWidth: '200px' }} />
            <span>até</span>
            <Input type="date" min={"1900-01-01"} max={"9999-12-31"} style={{ maxWidth: '200px' }} />
        </DivSelecaoPeriodo>
        <DivCardsDashboard>
            <DivCardResumo id="Resumo">
                <h4>Resumo</h4>
                {
                    dadosDashboard.length ?
                        dadosDashboard.map(dado => (
                            <CardDashboard key={dado.categoria}>
                                <SpanTituloInfo>{dado.categoria}</SpanTituloInfo>
                                {
                                    dado.categoria.toLowerCase() == 'faturamento' ?
                                        <SpanInfoContent>{formataCurrency.format(dado.numero)}</SpanInfoContent>
                                        :
                                        <SpanInfoContent>{dado.numero}</SpanInfoContent>
                                }
                            </CardDashboard>
                        ))
                        :
                        <LoadingScreen display={'flex'} />
                }
            </DivCardResumo>
            <DivCardResumo id="CursoMaisVendido">
                <h4>Curso mais vendido</h4>
                <DivInfoCursoMaisVendido>
                    <SpanNomeCursoMaisVendido>{dadosCursoMaisVendido.nome}</SpanNomeCursoMaisVendido>
                    <DivInfoContentCursoMaisVendido>
                        <SpanSubtituloInfoCursoMaisVendido>Faturamento</SpanSubtituloInfoCursoMaisVendido>
                        <br />
                        <SpanInfoMainCursoMaisVendido>{formataCurrency.format(dadosCursoMaisVendido.faturamento)}</SpanInfoMainCursoMaisVendido>
                    </DivInfoContentCursoMaisVendido>
                    <DivInfoContentCursoMaisVendido>
                        <SpanSubtituloInfoCursoMaisVendido>Total de vendas</SpanSubtituloInfoCursoMaisVendido>
                        <br />
                        <SpanInfoMainCursoMaisVendido>{dadosCursoMaisVendido.totalVendas}</SpanInfoMainCursoMaisVendido>
                    </DivInfoContentCursoMaisVendido>
                </DivInfoCursoMaisVendido>
            </DivCardResumo>
            <DivCardResumo id="FaturamentoLeads">
                <h4>Faturamento Leads</h4>
                {
                    dadosFaturamentoLeads.length ?
                        dadosFaturamentoLeads.map(dado => (
                            <CardDashboard key={dado.IdLead}>
                                <SpanTituloInfo>{dado.NomeCurso}</SpanTituloInfo>
                                <SpanInfoContent>{formataCurrency.format(dado.FaturamentoLead)}</SpanInfoContent>
                            </CardDashboard>
                        ))
                        :
                        <LoadingScreen display={'flex'} />
                }
            </DivCardResumo>
        </DivCardsDashboard>
        <DivOpcaoGammyxContent>
            <p>Últimas vendas</p>
            <DivOpcaoGammyx >
                {
                    dadosUltimasVendas.length ?
                        dadosUltimasVendas.map(dado => (
                            <DivUltimasComprasContent>
                                <SpanInfo>{dado.NomeAluno}</SpanInfo>
                                <SpanInfo>{dado.NomeCurso}</SpanInfo>
                                <SpanInfo>{formataCurrency.format(dado.Valor)}</SpanInfo>
                            </DivUltimasComprasContent>
                        ))
                        :
                        <LoadingScreen display={"flex"} />
                }
            </DivOpcaoGammyx>
            <p style={{ margin: '16px 0 0 0' }}>Últimos alunos inscritos</p>
            <DivOpcaoGammyx>
                {
                    dadosUltimasInscricoes.length ?
                        dadosUltimasInscricoes.map(dado => (
                            <DivUltimasComprasContent>
                                <SpanInfo>{dado.Nome}</SpanInfo>
                                <SpanInfo>{dado.Email}</SpanInfo>
                                <SpanInfo>{dayjs(dado.DataCriacao).format('DD/MM/YYYY')}</SpanInfo>
                            </DivUltimasComprasContent>
                        ))
                        :
                        <LoadingScreen display={"flex"} />
                }
            </DivOpcaoGammyx>
        </DivOpcaoGammyxContent>
    </Container>

}