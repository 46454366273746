import React, { useState, useEffect, useReducer, useMemo, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import ButtonMui from '@mui/material/Button';
import { Chip, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import produce from 'immer';
import genericActionsImmer from '~/utils/genericActionsImmer';

import { DivMainInfo, DivMain, Input, DivTable, TitlePopUp, ContainerPopUp, BodyPopUp, FooterPopUp } from './StyledPrescreva';
import useApi from '~/service/api';
import { Button } from '~/components/commonComponents/commonComponents';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Prescreva() {
    const api = useApi();
    const [farmaciaSelecionada, setFarmaciaSelecionada] = useState(null);
    const [farmaciaSelecionadaChangeState, setFarmaciaSelecionadaChangeState] = useState(null);
    const [open, setOpen] = useState(false);
    const inputValue = useRef('');

    const [farmacias, farmaciasR] = useReducer(
        (state, action) => {
            const actions = { ...genericActionsImmer };

            const fn = actions[action.type];
            if (fn) {
                return produce(state, (draftState) => fn(draftState, action));
            }

            console.error('[WARNING] Não existe um acção com este nome:', action);
            return state;
        },
        { status: 'finished', data: [] },
    );

    const farmaciasOrganizadas = useMemo(() => {
        const farmaciasAtivas = farmacias.data.filter((farm) => farm.Ativo).sort((a, b) => a.Farmacia.localeCompare(b.Farmacia));
        const farmaciasInativas = farmacias.data
            .filter((farm) => !farm.Ativo)
            .sort((a, b) => a.Farmacia.localeCompare(b.Farmacia));

        return farmaciasAtivas.concat(farmaciasInativas);
    }, [farmacias]);

    useEffect(() => {
        console.log(farmacias);
    }, [farmacias]);

    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadAssinantesPrescreva = useCallback(
        async (busca) => {
            try {
                farmaciasR({ type: 'load' });
                const data = await api.get(`/prescreva/GetPrescrevaAssinantes?busca=${busca}`);
                if (data.status === 200) {
                    if (busca) toast.info(`Sua pesquisa retornou ${data.data.length} farmácias`);
                    farmaciasR({ type: 'reset', payload: data.data });
                }
            } catch (error) {
                farmaciasR({ type: 'reset', payload: farmacias.data });
                toast.warning('Não foi possível carregar lista de assinantes.');
                toast.error('Erro ao carregar lista de assinantes.');
            }
        },
        [farmacias],
    );

    function search(event) {
        try {
            if (event.key === 'Enter') {
                inputValue.current = event.currentTarget.value;
                loadAssinantesPrescreva(event.currentTarget.value);
            }
        } catch (error) {
            toast.error('Erro ao realizar busca.');
        }
    }

    async function postStatusFarmacia(IdAssinatura, Ativo, MEDX) {
        try {
            await api.post(`/prescreva/UpdateStatus`, { IdAssinatura, Ativo, MEDX });
            return true;
        } catch (error) {
            console.log(error);
            throw `Erro ao tentar alterar status da farmácia`;
        }
    }

    async function salvarStatusFarmacia(paramFarmacia) {
        try {
            await postStatusFarmacia(paramFarmacia.IdAssinatura, paramFarmacia.Ativo, paramFarmacia.MEDX);
            handleClose();
            toast.success(`Status da farmacia "${paramFarmacia.Farmacia}", alterado com sucesso!`);
            if (farmaciaSelecionada) {
                setFarmaciaSelecionada(
                    produce((draftState) => {
                        draftState.Ativo = paramFarmacia.Ativo;
                        draftState.MEDX = paramFarmacia.MEDX;
                    }),
                );
            }
            farmaciasR({ type: 'updateById', payload: paramFarmacia, comparisonKey: 'IdAssinatura' });

            return setFarmaciaSelecionadaChangeState(null);
            // achar item dentro do arra e editar ele
        } catch (error) {
            toast.error(`Erro ao tentar alterar status da farmácia "${paramFarmacia.Farmacia}"`);
            handleClose();
            console.log(error);
        }
    }

    function salvarEditFarmacia(paramFarmacia) {
        if (paramFarmacia.IdAssinatura === 0) {
            criaFarmacia(paramFarmacia);
        } else {
            atualizaFarmacia(paramFarmacia);
        }
    }

    async function criaFarmacia(paramFarmacia) {
        try {
            farmaciasR({ type: 'load' });
            const data = await api.post(`/prescreva/InsertAssinantes`, paramFarmacia);
            if (data.status === 200) {
                toast.success(`Farmácia criada com sucesso!`);
                farmaciasR({ type: 'add', payload: paramFarmacia });
                setFarmaciaSelecionada(null);
            }
        } catch (error) {
            farmaciasR({ type: 'reset', payload: farmacias.data });
            if (error?.response?.data?.Message) {
                return toast.error(error?.response?.data?.Message);
            }
            toast.warning('Não foi possivel criar a farmácia.');
            toast.error('Erro ao criar farmácia.');
        }
    }

    async function atualizaFarmacia(paramFarmacia) {
        try {
            farmaciasR({ type: 'load' });
            const data = await api.post(`/prescreva/UpdateAssinantes`, paramFarmacia);
            if (data.status === 200) {
                toast.success(`Farmácia atualizada com sucesso!`);
                farmaciasR({ type: 'updateById', payload: paramFarmacia, comparisonKey: 'IdAssinatura' });
                setFarmaciaSelecionada(null);
            }
        } catch (error) {
            farmaciasR({ type: 'reset', payload: farmacias.data });
            if (error?.response?.data?.Message) {
                return toast.error(error?.response?.data?.Message);
            }
            toast.warning('Não foi possivel atualizar a farmácia.');
            toast.error('Erro ao criar farmácia.');
        }
    }

    useEffect(() => {
        loadAssinantesPrescreva('');
    }, []);

    const MemoForm = useMemo(() => {
        if (!farmaciaSelecionada) return '';
        return (
            <BodyPopUp>
                <div className="groupHorizontal">
                    <div title="Editar Status da farmácia" style={{ flexDirection: 'row', marginBottom: '10px' }}>
                        <div style={{ marginRight: '20px' }}>
                            <span style={{ marginRight: '10px' }}>Ativo:</span>
                            <Chip
                                // icon={item.Ativo ? <CheckCircleOutlinedIcon /> : <DoNotDisturbOnOutlinedIcon />}
                                label={farmaciaSelecionada.Ativo ? 'Sim' : 'Não'}
                                color={farmaciaSelecionada.Ativo ? 'success' : 'error'}
                                onClick={() =>
                                    farmaciaSelecionada.IdAssinatura
                                        ? setFarmaciaSelecionadaChangeState(farmaciaSelecionada)
                                        : toast.info('Os status da farmácia só podem ser editados após a farmácia ser criada')
                                }
                            />
                        </div>
                        <div>
                            <span style={{ marginRight: '10px' }}>Visível no MEDX:</span>
                            <Chip
                                // icon={item.MEDX ? <CheckCircleOutlinedIcon /> : <DoNotDisturbOnOutlinedIcon />}
                                label={farmaciaSelecionada.Ativo && farmaciaSelecionada.MEDX ? 'Sim' : 'Não'}
                                color={farmaciaSelecionada.Ativo && farmaciaSelecionada.MEDX ? 'success' : 'error'}
                                onClick={() =>
                                    farmaciaSelecionada.IdAssinatura
                                        ? setFarmaciaSelecionadaChangeState(farmaciaSelecionada)
                                        : toast.info('Os status da farmácia só podem ser editados após a farmácia ser criada')
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Nome da farmácia">
                        <span>Nome Farmacia*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Farmacia: e.target.value })}
                            value={farmaciaSelecionada.Farmacia}
                            type="text"
                            placeholder="Nome Farmacia*"
                            required
                        />
                    </div>
                    <div title="Campo Usado para criar url da farmácia no prescreva">
                        <span>Alias*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Alias: e.target.value })}
                            value={farmaciaSelecionada.Alias}
                            type="text"
                            placeholder="Alias*"
                            readOnly={farmaciaSelecionada.IdAssinatura ? true : false}
                            required
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Email Farmacia">
                        <span>Email Farmacia*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, EmailFarmacia: e.target.value })}
                            value={farmaciaSelecionada.EmailFarmacia}
                            type="email"
                            placeholder="Email Farmacia*"
                            required
                        />
                    </div>
                    <div title="Nome do Banco">
                        <span>Nome do Banco*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, db: e.target.value })}
                            value={farmaciaSelecionada.db}
                            type="text"
                            placeholder="Alias*"
                            readOnly={farmaciaSelecionada.IdAssinatura ? true : false}
                            required
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Tefone de comunicação com a farmácia">
                        <span>Telefone 1*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Telefone1: e.target.value })}
                            value={farmaciaSelecionada.Telefone1}
                            type="tel"
                            placeholder="Telefone 1*"
                            required
                        />
                    </div>
                    <div title="Tefone de comunicação com a farmácia">
                        <span>Telefone 2</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Telefone2: e.target.value })}
                            value={farmaciaSelecionada.Telefone2}
                            type="tel"
                            placeholder="Telefone 2"
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Nome da pessoa que devemos fazer contato na farmácia">
                        <span>Nome do Responsavel*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Responsavel: e.target.value })}
                            value={farmaciaSelecionada.Responsavel}
                            type="text"
                            placeholder="Nome do Responsavel*"
                            required
                        />
                    </div>
                    <div title="Email da pessoa que devemos fazer contato na farmácia">
                        <span>Email do Responsavel*</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, EmailResponsavel: e.target.value })}
                            value={farmaciaSelecionada.EmailResponsavel}
                            type="email"
                            placeholder="Email do Responsavel*"
                            required
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Tefone de comunicação da pessoa que devemos fazer contato na farmácia">
                        <span>Telefone do Responsavel</span>
                        <input
                            onChange={(e) =>
                                setFarmaciaSelecionada({
                                    ...farmaciaSelecionada,
                                    TelefoneResponsavel: e.target.value,
                                })
                            }
                            value={farmaciaSelecionada.TelefoneResponsavel}
                            type="tel"
                            placeholder="Telefone do Responsavel"
                        />
                    </div>
                    <div title="Celular de comunicação da pessoa que devemos fazer contato na farmácia">
                        <span>Celular do Responsavel</span>
                        <input
                            onChange={(e) =>
                                setFarmaciaSelecionada({ ...farmaciaSelecionada, CelularResponsavel: e.target.value })
                            }
                            value={farmaciaSelecionada.CelularResponsavel}
                            type="tel"
                            placeholder="Celular do Responsavel"
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Logo que vai aparecer para farmácia na tela de login do prescreva">
                        <span>Logo Login Prescreva</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, Logo: e.target.value })}
                            value={farmaciaSelecionada.Logo}
                            type="text"
                            placeholder="Logo Login Prescreva"
                        />
                    </div>
                    <div title="Logo que aparece dentro do modulo de receituario dentro do MEDX">
                        <span>Logo Receituario Medx</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, LogoMedx: e.target.value })}
                            value={farmaciaSelecionada.LogoMedx}
                            type="text"
                            placeholder="Logo Receituario Medx"
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div>
                        <span>Texto de Boas Vindas</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, TextoBoas: e.target.value })}
                            value={farmaciaSelecionada.TextoBoas}
                            type="text"
                            placeholder="Texto de Boas Vindas"
                        />
                    </div>
                    <div>
                        <span>Link de Boas</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, LinkBoas: e.target.value })}
                            value={farmaciaSelecionada.LinkBoas}
                            type="url"
                            placeholder="Link de Boas Vindas"
                        />
                    </div>
                </div>
                <div className="groupHorizontal">
                    <div title="Numero de inscrição estadual da farmácia">
                        <span>Inscrição Estadual</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, InscEstadual: e.target.value })}
                            value={farmaciaSelecionada.InscEstadual}
                            type="text"
                            placeholder="Inscrição Estadual"
                        />
                    </div>
                    <div title="CNPJ da farmácia">
                        <span>CNPJ</span>
                        <input
                            onChange={(e) => setFarmaciaSelecionada({ ...farmaciaSelecionada, CNPJ: e.target.value })}
                            value={farmaciaSelecionada.CNPJ}
                            type="text"
                            placeholder="CNPJ"
                        />
                    </div>
                </div>
            </BodyPopUp>
        );
    }, [farmaciaSelecionada]);

    return (
        <DivMain>
            <div style={{ display: 'flex', justifyContent: 'center', height: '40px' }}>
                <h1>Prescreva</h1>
            </div>
            {farmacias.status === 'loading' ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Input
                        defaultValue={inputValue.current}
                        type="text"
                        placeholder="Informe o nome ou email da farmácia."
                        onKeyPress={(event) => search(event)}
                    />
                    <DivTable>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <span style={{ marginLeft: '5px' }}>Farmácia</span>
                                    </th>
                                    <th>Email</th>
                                    <th style={{ textAlign: 'center' }}>Ativo</th>
                                    <th style={{ textAlign: 'center' }}>Visível no MEDX</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {farmaciasOrganizadas.map((farmacia) => (
                                    <tr key={farmacia.IdAssinatura}>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{ height: '30px', marginRight: '5px' }}
                                                    src={`https://xdem.app/pages_prontuario/images/${
                                                        farmacia.LogoMedx ? farmacia.LogoMedx : 'Logos/receituarioBiformula.png'
                                                    }`}
                                                />
                                                <span>{farmacia.Farmacia}</span>
                                            </div>
                                        </td>
                                        <td>{farmacia.EmailFarmacia}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Chip
                                                label={farmacia.Ativo ? 'Sim' : 'Não'}
                                                color={farmacia.Ativo ? 'success' : 'error'}
                                                onClick={() => setFarmaciaSelecionadaChangeState(farmacia)}
                                            />
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Chip
                                                label={farmacia.Ativo && farmacia.MEDX ? 'Sim' : 'Não'}
                                                color={farmacia.Ativo && farmacia.MEDX ? 'success' : 'error'}
                                                onClick={() => setFarmaciaSelecionadaChangeState(farmacia)}
                                            />
                                        </td>
                                        <td>
                                            <ButtonMui
                                                onClick={() => setFarmaciaSelecionada(farmacia)}
                                                size="small"
                                                variant="outlined"
                                                startIcon={<EditIcon />}
                                            >
                                                Abrir
                                            </ButtonMui>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </DivTable>

                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Button
                            onClick={() =>
                                setFarmaciaSelecionada({
                                    IdAssinatura: 0,
                                    Farmacia: '',
                                    Alias: '',
                                    EmailFarmacia: '',
                                    Telefone1: '',
                                    Telefone2: '',
                                    Responsavel: '',
                                    EmailResponsavel: '',
                                    TelefoneResponsavel: '',
                                    CelularResponsavel: '',
                                    Logo: '',
                                    Ativo: false,
                                    MEDX: false,
                                    LogoMedx: '',
                                    TextoBoas: '',
                                    LinkBoas: '',
                                    CNPJ: '',
                                    InscEstadual: '',
                                    IdFilialPrincipal: 0,
                                    db: '',
                                })
                            }
                        >
                            Adicionar Fármacia
                        </Button>
                    </div>
                </>
            )}

            <PopupDefault open={!!farmaciaSelecionada} close={() => setFarmaciaSelecionada(null)} altura="80vh" largura="80vw">
                {farmaciaSelecionada && (
                    <ContainerPopUp>
                        <TitlePopUp>
                            {!farmaciaSelecionada.IdAssinatura ? 'Nova Farmacia' : `${farmaciaSelecionada.Farmacia}`}
                        </TitlePopUp>
                        {MemoForm}
                        <FooterPopUp>
                            <div className="groupHorizontal">
                                <div style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <div style={{ marginRight: '20px' }}>
                                        <Button
                                            style={{ width: '200px', margin: '0px' }}
                                            className="deletar"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setFarmaciaSelecionada(null);
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            style={{ width: '200px', margin: '0px' }}
                                            className="salvar"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                salvarEditFarmacia(farmaciaSelecionada);
                                            }}
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </FooterPopUp>
                    </ContainerPopUp>
                )}
            </PopupDefault>

            <PopupDefault
                open={!!farmaciaSelecionadaChangeState}
                close={() => setFarmaciaSelecionadaChangeState(null)}
                altura="300px"
                largura="600px"
            >
                {farmaciaSelecionadaChangeState && (
                    <ContainerPopUp>
                        <TitlePopUp>{`Editando Status - ${farmaciaSelecionadaChangeState.Farmacia}`}</TitlePopUp>
                        <BodyPopUp>
                            <div className="groupHorizontal">
                                <div title="Farmacia está ativa">
                                    <span>Ativa</span>
                                    <sub>Quando inativa, não é possivel acessar o prescreva com esta conta.</sub>
                                    <Switch
                                        checked={farmaciaSelecionadaChangeState.Ativo}
                                        onChange={() =>
                                            setFarmaciaSelecionadaChangeState((old) => ({
                                                ...old,
                                                Ativo: !old.Ativo,
                                                MEDX: !old.Ativo ? old.MEDX : false,
                                            }))
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                            </div>
                            <div className="groupHorizontal">
                                <div title="Farmacia está visivel dentro do receituario">
                                    <span>Visível no MEDX</span>
                                    <sub>Quando ativa, a farmacia pode ser vista dentro do receituário do medx.</sub>
                                    <Switch
                                        checked={farmaciaSelecionadaChangeState.MEDX}
                                        onChange={() =>
                                            setFarmaciaSelecionadaChangeState((old) => ({
                                                ...old,
                                                MEDX: !old.MEDX,
                                                Ativo: !old.MEDX ? true : old.Ativo,
                                            }))
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                            </div>
                            <div className="groupHorizontal">
                                <div>
                                    <Button onClick={handleClickOpen}>Salvar</Button>
                                </div>
                            </div>
                        </BodyPopUp>
                    </ContainerPopUp>
                )}
            </PopupDefault>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{'Atenção!'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Está ciente que alterando essas informações, você esta modificando acessos das farmácias e dos clientes do
                        medx? Tem certeza que deseja alterar essas informações?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonMui variant="text" onClick={() => salvarStatusFarmacia(farmaciaSelecionadaChangeState)}>
                        Sim
                    </ButtonMui>
                    <ButtonMui variant="text" onClick={handleClose}>
                        Não, cancelar
                    </ButtonMui>
                </DialogActions>
            </Dialog>
        </DivMain>
    );
}

export default Prescreva;
