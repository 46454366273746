import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
    display: flex;  
    height: 100vh;
    position: relative;
`;
export const LoginContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    @media (max-width:500px) {     
        width: 100%;
        padding: 0px 25px;
    }
    >div{
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        /* gap: 25px; */

        >form{
            margin: 25px 0px;
            width: 100%;
            display: flex;
            flex-direction:column;
            justify-content: center;
            align-items:center;
           

            >div{
                width: 100%;
                margin: 10px 0px;

                >a{
                    color: ${ p => p.theme.colors.text };

                    :hover{
                        color: ${ p => shade( .25, p.theme.colors.text ) };
                    }
                }
            }
        }
    }
`;

export const Img = styled.div`
    background-image: url(${ props => props.bkgUrl });
    background-size: cover;
    background-repeat:no-repeat;
    width: 56.38%;
    @media (max-width:500px) {     
        display:none;
    }
`;
export const BtnVoltar = styled.div`
    position: absolute;
    top:1px;
    left:1px;
    padding:50px;
    display: flex;
    gap:5px;
    font-size: 20px;
    cursor: pointer;
    transition-duration: .3s;
    
    :hover{
        color: ${ p => p.theme.colors.primary }
    }
`;
