import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding:16px;
  height:100%;
  width:100%;
`;

export const DivContainerPopup = styled.div`
  display:flex;
  flex-direction:column;
  padding:0 16px 16px 16px;
  min-width:600px;
`
export const DivTituloPopup = styled.div`
  text-align:center;
  margin:0 0 16px 0;
`
export const DivCampoPopup = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  >span{
    width:130px;
  }
  >input,select,textarea{
    margin:8px 0 8px 16px;
  }
  
`