import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../commonComponents/commonComponents';
import {
    DivMainItensForm,
    InputCampoFormBackup,
    SelectFormBackup,
    TextAreaFormBackup,
} from '../formGerarBackup/StyledFormGerarBackup';
import LoadingScreen from '../loadScreen/Loadscreen';
import useApi from '~/service/api';
import { useTheme } from 'styled-components';
import { TestaCPF, validaCNPJ } from '~/helpers/functions';
import { cnpj,cpf  } from 'cpf-cnpj-validator';
export default function PopupSolicitacaoNovoBackup({ closePopup, update }) {
    const theme = useTheme();
    const [dadosNovaSolicitacao, setDadosNovaSolicitacao] = useState({
        Email: '',
        Softwareid: '',
        Celular: '',
        Motivo: 0,
        Observacoes: '',
        Chave: '',
    });
    const [motivos, setMotivos] = useState([]);
    const [loading, setLoading] = useState('none');
    const api = useApi();

    async function saveSolicitacaoBackup() {
        try {
            //#region Verificar campos obrigatórios
            if (!dadosNovaSolicitacao.Motivo) {
                toast.info('Selecione o motivo, por favor.');
                return null;
            }
            if (!dadosNovaSolicitacao.Softwareid) {
                toast.info('Informe o softwareid, por favor.');
                return null;
            }
            if (!dadosNovaSolicitacao.Celular) {
                toast.info('Informe o celular, por favor.');
                return null;
            }
            if (!dadosNovaSolicitacao.Email) {
                toast.info('Informe o email, por favor.');
                return null;
            }
            if(!dadosNovaSolicitacao.Chave){
                toast.info('Sem chave, verifique o cpf do cliente.');
                return null;
            }            
            //#endregion
            setLoading('flex');
            const saveSolicitacao = await api.post(`/backup/SaveSolicitacao`, dadosNovaSolicitacao);
            if (saveSolicitacao.status == 200) {
                closePopup(false);
                update();
                setLoading('none');
                toast.success('Solicitação salva com sucesso.');
            } else {
                toast.error('Erro ao gerar solicitação.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível solicitar backup.');
            setLoading('none');
        }
    }
    async function getMotivos() {
        try {
            setLoading('flex');
            const listaMotivos = await api.get('/backup/GetMotivos');
            if (listaMotivos.status == 200) {
                setMotivos(listaMotivos.data);
                setLoading('none');
            } else {
                setLoading('none');
                toast.error('Erro ao carregar motivos.');
            }
        } catch (error) {
            console.log(error);
            setLoading('none');
            toast.error('Não foi possível carregar motivos.');
        }
    }
    function gerarChave(cpfParam) {
        try {            
            if(cpf.isValid(cpfParam) || cnpj.isValid(cpfParam)){
                const chave = cpfParam.substring(cpfParam.length - 4, cpfParam.length);
                return chave;
            }
                throw  `cpf ${cpfParam} inválido verifique os dados cadastrais do cliente.`                        
        } catch (error) {
            console.log(error);
            toast.error(error + 'Não foi possível gerar chave');
            setDadosNovaSolicitacao(oldValue =>({...oldValue,Chave:''}))
            return null;
        }
    }
    async function getPacienteInfos(email) {
        try {
            setLoading('flex');
            const responseGetClienteInfoByEmail = await api.get(`/backup/GetClienteInfoByEmail?email=${email}`);
            if (responseGetClienteInfoByEmail.status == 200) {
                const { data } = responseGetClienteInfoByEmail;
                setDadosNovaSolicitacao({
                    ...dadosNovaSolicitacao,
                    Celular: data.Celular,
                    Softwareid: data.Softwareid,
                    Chave: gerarChave(data.CPF),
                });
                setLoading('none');
            } else {
                setLoading('none');
                toast.error('Erro ao buscar dados do cliente.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status == 404) {
                    toast.error(error.response.data);
                    setLoading('none');
                    return;
                }
            }
            setLoading('none');
            console.log(error);
            toast.error('Erro na função getPacienteInfos');
        }
    }
    function buscaPaciente(event) {
        if (event.key == 'Enter') {
            getPacienteInfos(event.target.value);
        }
    }

    

    useEffect(() => {
        getMotivos();
    }, []);

    return (
        <div style={{ padding: '12px', minWidth: '720px' }}>
            <div>
                <h1>Gerar novo backup</h1>
            </div>
            <DivMainItensForm>
                <div>Email de assinante:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o email do assinante"
                    onKeyPress={(event) => buscaPaciente(event)}
                    value={dadosNovaSolicitacao.Email}
                    onChange={(event) => setDadosNovaSolicitacao({ ...dadosNovaSolicitacao, Email: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>SoftwareId:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o softwareId do assinante"
                    value={dadosNovaSolicitacao.Softwareid}
                    onChange={(event) => setDadosNovaSolicitacao({ ...dadosNovaSolicitacao, Softwareid: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>Celular:</div>
                <InputCampoFormBackup
                    type="text"
                    placeholder="Informe o celular do assinante"
                    value={dadosNovaSolicitacao.Celular}
                    onChange={(event) => setDadosNovaSolicitacao({ ...dadosNovaSolicitacao, Celular: event.target.value })}
                />
            </DivMainItensForm>
            <DivMainItensForm>
                <div>Motivo:</div>
                <SelectFormBackup
                    value={dadosNovaSolicitacao.Motivo}
                    onChange={(event) => setDadosNovaSolicitacao({ ...dadosNovaSolicitacao, Motivo: event.target.value })}
                >
                    <option value={0}>Selecione a opção</option>
                    {motivos.map((item) => {
                        return <option value={item.IdMotivo}>{item.Motivo}</option>;
                    })}
                </SelectFormBackup>
            </DivMainItensForm>
            <DivMainItensForm>
                <div>Senha:</div>
                <InputCampoFormBackup
                    type="text"                    
                    value={ dadosNovaSolicitacao.Chave}
                    disabled
                />
            </DivMainItensForm>
            <DivMainItensForm style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <div>Observações:</div>
                <TextAreaFormBackup
                    value={dadosNovaSolicitacao.Observacoes}
                    onChange={(event) => setDadosNovaSolicitacao({ ...dadosNovaSolicitacao, Observacoes: event.target.value })}
                />
            </DivMainItensForm>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={{ background: theme.colors.colorDelete }} onClick={() => closePopup(false)}>Cancelar</Button>
                <Button style={{ background: theme.colors.colorSave }} onClick={() => saveSolicitacaoBackup()}>Gerar</Button>
            </div>
            <LoadingScreen display={loading} />
        </div>
    );
}
