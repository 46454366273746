import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    height:100%;
    width:100%;
    padding:16px;
    overflow:hidden auto;
`;
export const DivCardsDashboard = styled.div`
    display:flex;
    flex-wrap:wrap;
    width:100%;    
    justify-content:space-between;
`
export const DivCardResumo = styled.div`
    display:flex;
    flex-direction:column;
    padding:16px;
    border-radius:8px;
    background:${p => p.theme.colors.backgroundCardDashboard};
    flex:1;
    min-width:300px;
    margin:0 16px 16px 0;
    >h4{
        margin:0 0 16px 0 ;
    }
`
export const CardDashboard = styled.div`
    display:flex;
    align-items:center;    
    justify-content: space-between;
    padding: 12px;
    background:${p => p.theme.colors.background};
    border-radius:8px;
    margin:0 0 8px 0;
`
export const SpanTituloInfo = styled.span`
    font-size:12px;
    color:${p => p.theme.colors.textSecundary};
    
`
export const SpanInfoContent = styled.span`
    font-weight:900;
`
export const DivInfoCursoMaisVendido = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    background:${p => p.theme.colors.background};
    padding:16px;
    margin:0 0 8px 0;
    border-radius:8px;
`
export const DivInfoContentCursoMaisVendido = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`
export const SpanNomeCursoMaisVendido = styled.div`
    font-size:18px;
`
export const SpanSubtituloInfoCursoMaisVendido = styled.span`
    font-size:12px;
    color:${p => p.theme.colors.textSecundary};
`
export const SpanInfoMainCursoMaisVendido = styled.span`
    font-size:20px;
`
export const DivSelecaoPeriodo = styled.div`
    display:flex;
    align-items:center;
    margin:0 0 12px 0;
    flex-wrap:nowrap;
`
export const DivOpcaoGammyxContent = styled.div`
    display:flex;
    flex-direction:column;
`
export const DivOpcaoGammyx = styled.div`
    display:flex;
    overflow:auto hidden;
`
export const DivUltimasComprasContent = styled.div`
    margin:16px 16px 16px 0 ;
    display:flex;
    flex-direction:column;
    padding:24px;
    background:${p => p.theme.colors.backgroundCardDashboard};
    border-radius:8px;
    max-width:300px;
`
export const SpanInfo = styled.span`
    font-size:16px;
    margin:0 0 16px 0;
`