import React,{useState,useEffect, useCallback} from 'react';
import { Box,Button,Dialog,DialogActions,DialogContent,DialogTitle,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import dayjs from 'dayjs';
import FormEditUsuarioHipoGpt from './formEditUsuarioHipoGpt';
import FormNovoUsuarioHipoGpt from './formNovoUsuarioHipoGpt';
function UsuariosHipoGpt() {

    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [listaUsuariosRaw, setListaUsuariosRaw] = useState([]);
    const [assistentes, setAssistentes] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
    const [isNovoUsuario, setIsNovoUsuario] = useState(false);
    const [loading, setLoading] = useState('none');
    const [filtro, setFiltro] = useState('');
    const api = useApi();

    async function filtraLista(key,filtroString){
        try{
            setFiltro(filtroString);
             if(key == 'Enter')
                await carregaListaUsuarios(0,100,filtroString);
        }catch(error){
            toast.error('Erro ao tentar buscar por usuário');
        }
        
        
    }

    function openNovoUsuario(){
        setIsNovoUsuario(true);
    }

    async function salvarEdicao(dadosEdicao){
        try{           
            setLoading('flex');
            const responseSalvaEdicao = await api.post('/HipoGpt/UpdateUsuarioHipoGpt',dadosEdicao);
            if(responseSalvaEdicao.status === 200){
                toast.success('Edição salva com sucesso');
                await init();
                setUsuarioSelecionado(null);
            }
        }catch(error){
            setLoading('none')
            toast.error('Erro ao tentar salvar edição');
            console.error(error);
        }
    }

    const cadastraUsuario = useCallback(async (usuarioInfo)=>{
        try{           
            setLoading('flex');
            const responseSalvaEdicao = await api.post('/HipoGpt/InsertUsuarioHipoGpt',usuarioInfo);
            if(responseSalvaEdicao.status === 200){
                toast.success('Usuário cadastrado com sucesso');
                await init();
                setIsNovoUsuario(false);
            }
        }catch(error){
            setLoading('none')
            toast.error('Erro ao tentar cadastrar usuário');
            console.error(error);
        }
    },[]);
   

    async function carregaListaUsuarios(skip,take,busca){
        try {
            setLoading('flex');
            const responseGetListaUsuarios = await api.get(`/HipoGpt/GetUsuariosHipoGpt?skip=${skip}&take=${take}&busca=${busca}`);
            if(responseGetListaUsuarios.status === 200){
                setListaUsuarios(responseGetListaUsuarios.data);
                setListaUsuariosRaw(responseGetListaUsuarios.data);
                setLoading('none');
            }else{
                toast.error('Erro ao carregar lista de usuários');
                setLoading('none');
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar carregar lista de usuários');
            setLoading('none');
        }
    }

    async function carregaAssistentes(){
        try{
            const responseGetAssistentes = await api.get(`/HipoGpt/GetAssistentesHipoGpt`);
            if(responseGetAssistentes.status === 200){
                setAssistentes(responseGetAssistentes.data);
            }else{
                toast.error('Erro ao tentar carregar lista de assistentes');
            }
        }catch(error){
            console.error(error);
            toast.error('Erro ao tentar carregar lista de assistentes');
        }
    }

    async function init(){
        setLoading('flex')
        await carregaListaUsuarios(0,100,filtro);
        await carregaAssistentes();
        setLoading('none')
    }
    


    useEffect(() => {
        init();
    }, []);

  return <Box height={'100%'} id="BoxMainUsuarios" >
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
        <TextField variant='outlined' size='small' placeholder='Digite o nome ou email do usuário.' sx={{height:'45px'}}
           onKeyDown={({target,key})=> filtraLista(key,target.value)}
        />
        <TableContainer component={Paper}  sx={{overflow:'hidden auto',flex:1,height:'calc(100% - 45px)',mb:2,mt:2,background:'#1d1d1d'}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{position:'sticky',top:'-1px'}}>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Celular</TableCell>
                        <TableCell align="right">Status</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                {listaUsuarios.map((usuarioInfo) => (
                    <TableRow
                    key={usuarioInfo.Id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:'pointer',background:'#1d1d1d' }}
                    onClick={()=>setUsuarioSelecionado(usuarioInfo)}
                    >                       
                    <TableCell component="th" scope="row">
                        {usuarioInfo.Nome}
                    </TableCell>
                    <TableCell align="right">{usuarioInfo.Email}</TableCell>
                    <TableCell align="right">{usuarioInfo.celular}</TableCell>
                    <TableCell align="right">{usuarioInfo.Status}</TableCell>                    
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Button variant='contained' onClick={openNovoUsuario}>Cadastrar novo usuário</Button>
    </Box>
    <Dialog open={usuarioSelecionado} onClose={()=> setUsuarioSelecionado(null)}>
        <DialogTitle textAlign={'center'}>Detalhes do usuário</DialogTitle>
        <DialogContent>
            <FormEditUsuarioHipoGpt dadosUsuarioSelecionado={usuarioSelecionado} salvarEdicao={salvarEdicao} close={setUsuarioSelecionado} listaAssistentes={assistentes} />
        </DialogContent>       
    </Dialog>
    <Dialog open={isNovoUsuario} onClose={()=> setIsNovoUsuario(false)}>
        <DialogTitle textAlign={'center'}>Detalhes do usuário</DialogTitle>
        <DialogContent>
            <FormNovoUsuarioHipoGpt criaNovoUsuario={cadastraUsuario}  close={setIsNovoUsuario} listaAssistentes={assistentes}/>
        </DialogContent>       
    </Dialog>
    <LoadingScreen display={loading} />
  </Box>;
}

export default UsuariosHipoGpt;