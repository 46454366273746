import React, { useState, useEffect } from 'react';
import { ContainerDivCardGridInfo, DivFilterSearch } from '../financas/StyledFinancas';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import { DivTable } from '~/components/commonComponents/commonComponents';
import dayjs from 'dayjs';
import { DivTableContratosRisco, DivTitulo } from './styledContratosEmRisco';
import PopupDefault from '~/components/popupDefault/PopupDefault';
export default function ContratosEmRisco() {
    const api = useApi();
    const [listContratos, setListContratos] = useState([]);
    const [openPopupInfoClient, setOpenPopupInfoClient] = useState(false)
    const [listFull, setListFull] = useState([]);
    const [loadScreen, setLoadScreen] = useState('none');

    function searchContrato(nameOrEmail) {
        if (nameOrEmail == '') {
            GetContratosRisco();
            return;
        };
        const newArrayFiltered = [];
        listFull.forEach(item => {
            if (item.Email.includes(nameOrEmail) || item.Nome.includes(nameOrEmail)) {
                newArrayFiltered.push(item);
            }
        })
        if (newArrayFiltered.length == 0) {
            toast.warning('Nenhum cliente foi encontrado.')
        }
        setListContratos(newArrayFiltered);
    }

    async function GetContratosRisco() {
        try {
            setLoadScreen('flex');
            const data = await api.get(`/vendas/GetContratosRisco`);
            if (data.status === 200) {
                data.data.sort((a, b) => a.Nome.localeCompare(b.Nome))
                setListContratos(data.data);
                setListFull(data.data);
                setLoadScreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar os contatos em risco');
            setLoadScreen('none');
        }
    }
    useEffect(() => {
        GetContratosRisco()
    }, [])
    return <ContainerDivCardGridInfo>
        <DivTitulo>
            <h1>Contratos em Risco</h1>
        </DivTitulo>
        <DivFilterSearch style={{ height: '40px', width: 'calc(100% - 32px)' }}>
            <input
                type="text"
                placeholder="Informe o nome ou o email cadastrado."
                onKeyPress={(event) => (event.key == 'Enter' ? searchContrato(event.currentTarget.value) : '')}
            />
        </DivFilterSearch>
        <DivTableContratosRisco>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>DDD</th>
                        <th>Celular</th>
                        <th>Atividade</th>
                        <th>Plano</th>
                        <th>Vigência</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listContratos.map(clienteInfo => {
                            return <tr onDoubleClick={() => setOpenPopupInfoClient(true)}>
                                <td>{clienteInfo.Nome}</td>
                                <td> <a
                                    target="_blank" rel="noopener" href={`mailto:${clienteInfo.Email}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>{clienteInfo.Email}</a></td>
                                <td>{clienteInfo.DDD}</td>
                                <td><a
                                    target="_blank"
                                    rel="noopener"
                                    href={`https://api.whatsapp.com/send?phone=55${clienteInfo.Celular}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >{clienteInfo.Celular}</a></td>
                                <td>{clienteInfo.Atividade}</td>
                                <td>{clienteInfo.Status}</td>
                                <td>{dayjs(clienteInfo.Pago_ate).format('DD/MM/YYYY')}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </DivTableContratosRisco>
        <LoadingScreen display={loadScreen} />
        <PopupDefault close={() => setOpenPopupInfoClient(false)} open={openPopupInfoClient} children={<div>teste</div>} />
    </ContainerDivCardGridInfo>;
}
