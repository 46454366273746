import React, { useState } from 'react';
import { Box, Button,Checkbox,FormControl,FormControlLabel,InputLabel,Select,TextField, Typography } from '@mui/material';




function FormNovoUsuarioHipoGpt({criaNovoUsuario,close,listaAssistentes}) {
    const [usuarioSelecionado,setUsuarioSelecionado] = useState({
        Nome:'',
        Email:'',
        celular:'',
        Status:'Full',
        Vigencia:'',
        permissoes:[]
    });
  
return(
    <Box component={'form'} p={1} onSubmit={(event)=>{event.preventDefault();criaNovoUsuario(usuarioSelecionado)}}>
    <TextField label='Nome' 
        fullWidth 
        size='small'
        required
        focused
        value={usuarioSelecionado?.Nome} 
        onChange={({target})=>setUsuarioSelecionado({...usuarioSelecionado, Nome: target.value})}
        sx={{mb:2}}
    />
    <TextField label='Email' 
        fullWidth 
        size='small'
        required
        focused
        value={usuarioSelecionado?.Email} 
        onChange={({target})=>setUsuarioSelecionado({...usuarioSelecionado, Email: target.value})}
        sx={{mb:2}}
    />
    <TextField label='Celular' 
        fullWidth 
        size='small'
        required
        focused
        value={usuarioSelecionado?.celular} 
        onChange={({target})=>setUsuarioSelecionado({...usuarioSelecionado, celular: target.value})}
        sx={{mb:2}}
    />
    <FormControl fullWidth required focused>
        <InputLabel>Status</InputLabel>
        <Select size='small' native   value={usuarioSelecionado?.Status}  label="Status"
              onChange={({target})=>setUsuarioSelecionado({...usuarioSelecionado, Status: target.value})}
            sx={{mb:2}}
        >
            <option value={'Full'}>Full</option>
            <option value={'Trial'}>Trial</option>
        </Select>

    </FormControl>
   
    <TextField label='Vigência' 
    size='small'
        fullWidth 
        required
        focused
        value={usuarioSelecionado?.Vigencia}
        type='date'
        slotProps={{ htmlInput:{ min:'1999-01-01',max:'9999-12-31'}}} 
        onChange={({target})=>setUsuarioSelecionado({...usuarioSelecionado, Vigencia:target.value})}
        sx={{mb:2}}
    />
{
        listaAssistentes && listaAssistentes.length > 0 &&(
            <Box mb={2}>
                <Typography>Permissoes</Typography>
                <Box display={'flex'} flexWrap={'wrap'} gap={2}>
                    {
                    listaAssistentes.map(assistenteInfo =>(
                        <Box display={'flex'} flexDirection={'column'}>
                            <FormControlLabel control={
                                        <Checkbox 
                                            checked={usuarioSelecionado.permissoes?.includes(assistenteInfo.Id)}
                                            onClick={()=>setUsuarioSelecionado({...usuarioSelecionado, 
                                                permissoes: usuarioSelecionado.permissoes.includes(assistenteInfo.Id) ? 
                                                    usuarioSelecionado.permissoes.filter(id=>id !== assistenteInfo.Id) 
                                                : 
                                                    [...usuarioSelecionado.permissoes, assistenteInfo.Id]})
                                                }
                                            />
                                    } label= {assistenteInfo.NomeAssistente}
                                    />                
                        </Box>
                    ))
                    }
                </Box>
            </Box>
        ) 
    }
    <Box display={'flex'}>
        <Button variant='outlined' sx={{flex:1,mr:2}} color='primary' onClick={()=>close(false)}>Cancelar</Button>
        <Button variant='contained' sx={{flex:1}} color='primary' type='submit'>Salvar</Button>        
    </Box>                                
</Box>
    )
  
}

export default FormNovoUsuarioHipoGpt;