import React,{useState,useRef} from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { Button } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import useApi from '~/service/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const FormAddImagem = ({close})=>{

    const theme = useTheme();
    const refInputFile = useRef();
    const api = useApi();
    const [loading, setLoading] = useState('none');    
    const [dadosImagem, setDadosImagem] = useState({
         blobName:"",
         filestream:"",
         replaceFile:false
    });
    const [urlImage, setUrlImage] = useState('')
    function handleFile(event) {
        return new Promise((resolve, reject) => {
            // Verifica se um arquivo foi selecionado
        if (event.files.length > 0) {
            const selectedFile = event.files[0];
        
            // Cria um objeto FileReader para ler o arquivo
            const reader = new FileReader();
            const fileName = selectedFile.name;
            
            // Define a função de callback para quando a leitura for concluída
            reader.onload = function(event) {
              // O conteúdo do arquivo em Base64 estará em event.target.result
              const base64Content = event.target.result;
              
              resolve({base64:base64Content.split(',')[1],name:fileName})              
              
              };
        
            // Inicia a leitura do arquivo como um URL de dados (Base64)
              reader.readAsDataURL(selectedFile);
          } else {
            console.error("Nenhum arquivo selecionado.");
            resolve({base64:'',name:''})
          }
        })       
        
    }


    async function getBase64(event){
        let base64File = await handleFile(event)
        if(base64File.name == ''){            
             toast.warning('Nenhum arquivo selecionado.')
        }
        if(urlImage != "")
            setUrlImage("")
        setDadosImagem({...dadosImagem,filestream:base64File.base64,blobName:base64File.name})
    }

    async function salvaImagem(){
        setLoading('flex');
        try {
            if(dadosImagem.blobName == ''){
                toast.warning('Nenhum arquivo selecionado.')
                setLoading('none');
                return
            }
            let responseSalvaImagem = await api.post("/azure/PostStreamAzure",dadosImagem);
            if(responseSalvaImagem.status == 200){
                setUrlImage(responseSalvaImagem.data);
                toast.success('Imagem salva com sucesso.')
            }
        } catch (error) {
            toast.error('Erro ao salvar imagem.')
        }
        setLoading('none');
    }

    function copiarLink(){
        navigator.clipboard.writeText(urlImage)
        toast.success('Link copiado com sucesso.')
    }

    function trocaInfoReplaceArquivo(){

        setDadosImagem({...dadosImagem,replaceFile:!dadosImagem.replaceFile})
    }

  return (
    <div style={{width:'800px',height:'calc(100% - 52px)',padding:'26px',display:'flex',flexDirection:'column'}}>
        <div style={{marginBottom:'16px',textAlign:'center'}}>
            <h1>Cadastrar nova imagem</h1>
        </div>
        <form onSubmit={(event)=>{event.preventDefault();salvaImagem()}} style={{display:'flex',flexDirection:'column'}}>
            <div style={{marginBottom:'16px',flex:1}}>
                <span>Nome da imagem</span>
                <input style={{marginTop:'16px'}} disabled type='text' value={dadosImagem.blobName}/>
            </div>
            {
                urlImage != ''?
                    <div style={{marginBottom:'16px',flex:1}}>
                        <span>Link da imagem</span>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <input style={{marginTop:'16px',marginRight:'8px'}} disabled type='text' value={urlImage}/>
                            <ContentCopyIcon style={{marginTop:'24px',cursor:"pointer"}} onClick={copiarLink}/>
                        </div>
                    </div>
                :
                    ''
            }
            <div style={{marginBottom:'8px',flex:1,display:"flex"}}>
                <input  ref={refInputFile} style={{display:'none'}} type='file' onChange={(event)=>getBase64(event.target)} />
                <Button onClick={()=>refInputFile.current.click()} type='button' style={{width:'100%'}} >Selecionar imagem</Button>                
            </div>
            <div style={{marginBottom:'8px',flex:1,display:"flex",alignItems:"center"}}>
                <Typography variant='caption' sx={{mr:2,cursor:"pointer"}} onClick={trocaInfoReplaceArquivo} >Substituir foto ?</Typography>
                <FormControlLabel 
                        control={
                            <Switch checked={dadosImagem.replaceFile} onChange={trocaInfoReplaceArquivo}/>
                        }
                    />
            </div>
            <div style={{display:'flex',flex:1}}>
                <Button style={{flex:1,background:theme.colors.colorDelete}} type='button' onClick={()=>close()}>Cancelar</Button>
                <Button style={{flex:1,background:theme.colors.colorSave}} type='submit'>Salvar</Button>
            </div>
        </form>
        <LoadingScreen display={loading} />
    </div>)
}

export default FormAddImagem;