import React from 'react';
import { Box, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import dayjs from 'dayjs';
import { DivMain } from '../cliente/ClienteStyled';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import FormularioFatura from '~/components/formularioFatura/formularioFatura';

export default function BoletosPendentesVendas() {
    const [boletos, setBoletos] = React.useState([]);
    const [loadScreen, setLoadScreen] = React.useState('none');
    const [openPopupNovaVenda, setOpenPopupNovaVenda] = React.useState(false);
    const api = useApi();
    async function carregaBoletos(inicio,final){
        try {
            const response = await api.get(`/vendas/GetBoletosNaoPagos?inicio=${inicio}&final=${final}`);
            const {status,data} = response;
            if(status == 200){
                setBoletos(data);

            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de boletos.')
        }
    }

    async function init(){
        setLoadScreen('flex');
        try {
            let dataInicial = dayjs(new Date()).add(-30,'day').format('YYYY-MM-DD');
            let dataFinal = dayjs(new Date()).add(-1,'day').format('YYYY-MM-DD');

            await carregaBoletos(dataInicial, dataFinal);




        } catch (error) {
            console.error(error);
            toast.error('Error ao inicializar a pagina');
        }
        setLoadScreen('none');
    }

    React.useEffect(()=>{
        init()
    },[])

  return(
    <DivMain style={{overflow:'hidden'}}>
        <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
            <Box>
                <Typography variant='h1' fontSize={'2em'} textAlign={'center'}>Vendas pendentes</Typography>
                <Divider sx={{mt:2,mb:2}}></Divider>
            </Box>
            <Box flex={1} overflow={'hidden'}>
                <TableContainer sx={{height:'100%'}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{position:'sticky',top:'0',background:'#1d1d1d!important'}}>
                            <TableCell>Nome</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">DDD</TableCell>
                            <TableCell align="left">Celular</TableCell>
                            <TableCell align="left">Vencimento</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {boletos.map((row) => (
                            <TableRow
                                key={row.IdBoleto}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.NomeCliente}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.EmailCliente}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.DDD2Cliente}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <a target="_blank"
                                    title={'Entrar em contato'}
                                    rel="noopener noreferrer"
                                    href={`https://api.whatsapp.com/send?phone=55${row?.CelularCliente ?? ''}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {row?.CelularCliente ?? ''}
                                    </a>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {dayjs(row.Vencimento).format('DD/MM/YYYY')}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} mt={2}>
                <Button onClick={()=>setOpenPopupNovaVenda(true)} variant='contained'>Nova Venda</Button>
            </Box>
        </Box>
        <PopupDefault
            open={openPopupNovaVenda}
            close={() => setOpenPopupNovaVenda(false)}
            children={
                <FormularioFatura
                    listaSoftwareId={[]}
                    titulo={'Nova Venda'}                    
                    close={() => setOpenPopupNovaVenda(false)}
                />
            }
        />
        <LoadingScreen display={loadScreen} />
    </DivMain>
  );
}