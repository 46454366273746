import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { FaUserFriends, FaCalendarAlt, FaChartBar, FaDatabase } from 'react-icons/fa';
import { GiCoins } from 'react-icons/gi';
import { MdEmail } from 'react-icons/md';
import { HiDatabase } from 'react-icons/hi';
import { FiUsers } from 'react-icons/fi';
import {MonetizationOnOutlined} from '@mui/icons-material';
import {
    DivMain,
    DivMenu,
    ContainerItemMenu,
    DivPerfilInfo,
    ContainerLogo,
    ImgLogoMobile,
    LogoComum,
    ContainerItemSubMenu,
} from './stylesMenu';
import Popup from 'reactjs-popup';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import PopupDefault from '../popupDefault/PopupDefault';
import PopupConfiguracoes from '../popupConfiguracoes/popupConfiguracoes';
import dark from '~/styles/themes/dark';
import light from '~/styles/themes/light';
import logo from '~/assets/images/logo-pequeno-transparente.png'
import imgPerfil from '~/assets/images/blank-profile-picture.png'
import logoMobile from '~/assets/images/logo.png' 
import { Dialog,DialogContent,DialogTitle } from '@mui/material';

export default function Menu({ setTheme }) {
    const [openConfig, setOpenConfig] = useState(false);
    const [permissoes, setPermissoes] = useState({
        Clientes: false,
        Agenda: false,
        Financas: false,
        Social: false,
        Prescreva: false,
        Docflix: false,
        EventoMedx: false,
        Backups: false,
        Usuarios: false,
        UsuariosPermissaoEdit: false,
        ContratosRisco: false,
        R1Med: false,
    });
    const itensMenuGeral = [
        {
            page: '/app/clientes',
            text: 'Clientes',
            icon: <FaUserFriends />,
            permissao: permissoes.Clientes,
            subMenu: {
                permissao: true,
                content: (
                    <>
                        {permissoes.ContratosRisco ? <ContainerItemSubMenu to={'/app/clientes/contratoemrisco'}>Contratos em risco</ContainerItemSubMenu> :''}
                        <ContainerItemSubMenu to={'/app/clientes/cancelamentos'}>Cancelamentos</ContainerItemSubMenu>
                        <ContainerItemSubMenu to={'/app/hipogpt'}>HipoGPT</ContainerItemSubMenu>
                        {/* <ContainerItemSubMenu to={'/app/clientes/loginunificado'}>Login Unificado</ContainerItemSubMenu> */}
                    </>
                ),
            },
        },
        { page: '/app/agenda', text: 'Agenda', icon: <FaCalendarAlt />, permissao: permissoes.Agenda },
        {
            page: '/app/financas',
            text: 'Finanças',
            icon: <GiCoins />,
            permissao: permissoes.Financas,
            subMenu: {
                permissao: permissoes.Financas,
                content: (
                    <>
                        <ContainerItemSubMenu to={'/app/financas/notafiscal'}>Gerenciamento nota fiscal</ContainerItemSubMenu>
                        <ContainerItemSubMenu to={'/app/financas/boletosatrasados'}>Boletos Atrasados</ContainerItemSubMenu>
                        <ContainerItemSubMenu to={'/app/financas/conciliacao'}>Conciliação Bancária</ContainerItemSubMenu>
                        <ContainerItemSubMenu to={'/app/financas/integracaoplugnotas'}>Integração PlugNotas</ContainerItemSubMenu>
                    </>
                ),
            },
        },
        {page: '/app/vendas', text: 'Vendas', icon: <MonetizationOnOutlined />, permissao: true, subMenu:{
            permissao:true,
            content:
            <ContainerItemSubMenu to={'/app/vendas/mensagemsdr'}>Mensagens SDR</ContainerItemSubMenu>
        }},
        { page: '/app/medxsocial', text: 'Medx Social', icon: <FaUserFriends />, permissao: permissoes.Social },
        { page: '/app/prescreva', text: 'Prescreva', icon: <FaUserFriends />, permissao: permissoes.Prescreva },
        { page: '/app/docflix', text: 'Docflix', icon: <FaUserFriends />, permissao: permissoes.Docflix },
        { page: '/app/usuariosmedx', text: 'MEDX', icon: <FaUserFriends />, permissao: permissoes.EventoMedx },
        { page: '/app/backups', text: 'Backups', icon: <HiDatabase />, permissao: permissoes.Backups },
        { page: '/app/usuarios', text: 'Usuarios', icon: <FiUsers />, permissao: permissoes.Usuarios },
        { page: '/app/eventos', text: 'Eventos', icon: <FiUsers />, permissao: true, subMenu:{
            permissao:true,
            content:(
                <>
                        <ContainerItemSubMenu to={'/app/eventos/notificacoes'}>Notificações</ContainerItemSubMenu>
                    </>
            )
        } },
        {
            page: '/app/gammyx', text: 'Gammyx', icon: <FiUsers />, permissao: permissoes.Gammyx, subMenu: {
                permissao: true,
                content: (
                    <>
                        <ContainerItemSubMenu to={'/app/gammyx/alunos'}>Alunos</ContainerItemSubMenu>
                    </>
                ),
            }
        },{
            page: '/app/r1med',
            text: 'R1Med',
            icon: <FaUserFriends />,
            permissao: permissoes.R1Med,            
        },
    ];
    const [name, setName] = useState('Usuário');

    const api = useApi();

    function getUserName() {
        if (localStorage.getItem('@sistemaInterno-user')) {
            setName(localStorage.getItem('@sistemaInterno-user'));
        }
    }
    async function getTypeUser() {
        try {
            const data = await api.get(`/user/getcurrentuser?user=${localStorage.getItem('@sistemaInterno-user')}`);
            if (data.status === 200) {
                const {
                    CurrentUser,
                    Clientes,
                    Agenda,
                    Financas,
                    Social,
                    Backups,
                    Prescreva,
                    Docflix,
                    EventoMedx,
                    Usuarios,
                    ContratosRisco,
                    Gammyx,R1Med
                } = data.data[0];
                sessionStorage.setItem('currentUser', JSON.stringify(data.data[0]));
                setPermissoes({
                    ...permissoes,
                    Agenda,
                    Backups,
                    Clientes,
                    Financas,
                    Social,
                    Prescreva,
                    Docflix,
                    EventoMedx,
                    Usuarios,
                    ContratosRisco,
                    Gammyx,
                    R1Med
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar as informações do usuário');
        }
    }
    function getTheme() {
        let themeSelected = localStorage.getItem('theme');
        if (themeSelected) {
            if (themeSelected == 'dark') {
                setTheme(dark);
            } else {
                setTheme(light);
            }
        } else {
            setTheme(dark);
        }
    }
    
    useEffect(() => {
        getUserName();
        getTypeUser();
        getTheme();
    }, []);

    return (
        <DivMain>
            <ContainerLogo>
                <LogoComum src={logo} />
                <ImgLogoMobile src={logoMobile} />
            </ContainerLogo>
            <DivMenu>
                {itensMenuGeral.map((itemMenu) => {
                    if (itemMenu.permissao) {
                        if (itemMenu.subMenu) {
                            return (
                                <Popup
                                    trigger={
                                        <ContainerItemMenu to={itemMenu.page} activeClassName="selected" key={itemMenu.page}>
                                            <div>{itemMenu.icon}</div>
                                            <span>
                                                <nobr>{itemMenu.text}</nobr>
                                            </span>
                                        </ContainerItemMenu>
                                    }
                                    position="right top"
                                    on="hover"
                                    closeOnDocumentClick
                                    mouseLeaveDelay={100}
                                    mouseEnterDelay={0}
                                    contentStyle={{ padding: '0px', border: 'none' }}
                                    arrow={false}
                                    key={itemMenu.page}
                                >
                                    {itemMenu.subMenu.permissao ? itemMenu.subMenu.content : ''}
                                </Popup>
                            );
                        } else
                            return (
                                <ContainerItemMenu to={itemMenu.page} activeClassName="selected" key={itemMenu.page}>
                                    <div>{itemMenu.icon}</div>
                                    <span>
                                        <nobr>{itemMenu.text}</nobr>
                                    </span>
                                </ContainerItemMenu>
                            );
                    }
                })}
            </DivMenu>
            <DivPerfilInfo onClick={() => setOpenConfig(true)}>
                <img src={imgPerfil} />
                <span>{name}</span>
            </DivPerfilInfo>
            {
                openConfig &&( 
                <Dialog open={openConfig} onClose={() => setOpenConfig(false)}>
                    <DialogTitle textAlign={'center'}>Configurações</DialogTitle>
                    <DialogContent>
                        <PopupConfiguracoes setTheme={setTheme} />
                    </DialogContent>
                </Dialog>)
            }                                  
        </DivMain>
    );
}
