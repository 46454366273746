import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '~/service/api';
import LoadingScreen from '../loadScreen/Loadscreen';
import { DivMainPopupUsuarios, TableInfo } from './styledPopupUsuarios';

export default function PopupUsuarios({ softwareId }) {
    const api = useApi();
    const [usuarios, setUsuarios] = useState([]);
    const [loadscreen, setLoadscreen] = useState('none');
    async function carregaUsuarios(softwareId) {
        try {
            setLoadscreen('flex');
            const data = await api.get(`/cliente/getUsuarios?softwareId=${softwareId}`);
            if (data.status == 200) {
                setUsuarios(data.data);
                setLoadscreen('none');
            } else {
                toast.warning('Não foi possível carregar usuários.');
                setLoadscreen('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar os usuários.');
            setLoadscreen('none');
        }
    }
    function AtivoInput(value) {
        if (value == 'true') {
            return (
                <div style={{ width: '20px' }}>
                    <input type="checkbox" defaultChecked={true} />
                </div>
            );
        } else
            return (
                <div style={{ width: '20px' }}>
                    <input type="checkbox" defaultChecked={false} />
                </div>
            );
    }
    useEffect(() => {
        let softwId = softwareId();
        carregaUsuarios(softwId);
    }, []);
    return (
        <div style={{ overflow: 'auto', padding: '20px', width: '100%', height: '100%' }}>
            <h1 style={{ marginBottom: '20px' }}>Usuários</h1>
            <TableInfo>
                <thead>
                    <tr>
                        <th>Usuário</th>
                        <th>Email</th>
                        <th>Ativo</th>
                        <th>
                            Perm.
                            <br /> Agenda
                        </th>
                        <th>
                            Perm.
                            <br /> Prontuario
                        </th>
                        <th>
                            Perm.
                            <br /> Pront. Exibe
                        </th>
                        <th>
                            Perm.
                            <br /> Pront. Escreve
                        </th>
                        <th>
                            Perm.
                            <br /> Pacientes
                        </th>
                        <th>
                            Perm.
                            <br /> Financeiro
                        </th>
                        <th>
                            Perm.
                            <br /> Editar Fat.
                        </th>
                        <th>
                            Perm.
                            <br /> Marketing
                        </th>
                        <th>
                            Perm.
                            <br /> Configurações
                        </th>
                        <th>
                            Perm.
                            <br /> Usuários
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((item) => {
                        console.log(item.Permissao_Prontuario);
                        return (
                            <tr>
                                <td>{item.Usuario}</td>
                                <td>{item.Email}</td>
                                <td>{AtivoInput(item.Ativo.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Agenda.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Prontuario.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Prontuario_Exibe.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Prontuario_Escreve.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Pacientes.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Financeiro.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Faturamento_Editar.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Marketing.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Configuracao.toString().toLowerCase())}</td>
                                <td>{AtivoInput(item.Permissao_Usuarios.toString().toLowerCase())}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </TableInfo>
            <LoadingScreen display={loadscreen} />
        </div>
    );
}
