import React, { useEffect,useState } from "react";
import { useTheme } from "styled-components";
import { Button, Input, TextArea } from "~/components/commonComponents/commonComponents";
import LoadingScreen from "~/components/loadScreen/Loadscreen";
import { DivCampoFormulario, DivTituloPagina } from "~/pages/eventos/notificacoes/styledNotificacoes";

const FormLoginUnificado = ({dadosLoginUnificado,callbackCancelar})=>{
    const [dadosLogin, setDadosLogin] = useState(null);
    const theme = useTheme();
    const [loading, setLoading] = useState('none');

    useEffect(()=>{
        console.log(dadosLoginUnificado);
        if(dadosLoginUnificado){
            setDadosLogin(dadosLoginUnificado);
        }
    },[])

    return(
        <div style={{width:'calc(100% - 52px)',height:'calc(100% - 52px)',padding:'26px',display:'flex',flexDirection:'column',minWidth:'800px'}}>
                <DivTituloPagina>
                    <h1>Dados Login Unificado</h1>
                </DivTituloPagina>
                <form style={{flex:1,display:'flex',flexDirection:'column '}} onSubmit={(event)=>{event.preventDefault()}}>
                    <div style={{flex:1,marginBottom:'16px'}}>
                        <DivCampoFormulario>
                            <span>Email:</span>
                            <Input  
                                value={dadosLogin?.Email??''}
                                onChange={(event)=>setDadosLogin(oldValue => ({...oldValue,Email:event.target.value}))}                         
                                required style={{resize:'none'}} 
                                name='notificacao'                                
                            />
                        </DivCampoFormulario>                        
                    </div>
                    <DivCampoFormulario style={{width:'100%'}}>
                        <Button style={{flex:1,background: theme.colors.colorDelete}} type='button' onClick={callbackCancelar}>Cancelar</Button>
                        <Button style={{flex:1,background: theme.colors.colorSave}} type='submit'>Salvar</Button>
                    </DivCampoFormulario>
                </form>
                <LoadingScreen display={loading} />
        </div> 
    )
}
export default FormLoginUnificado;