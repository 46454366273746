import { lighten } from 'polished';
import styled from 'styled-components';
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';
export const DivMainBoletosAtrasados = styled.div`    
    margin:0 12px 8px 12px;
    padding-bottom:8px;
    border-bottom: 1px solid #fefefe;
    width:350px;
`;
export const DivConteudoBoleto = styled.div`
  display:flex;
  margin:8px 0;
  p:nth-child(2){
      margin-left:8px;
  }
`;
export const DivConteudoBoletoDescricao = styled.div`
  display:flex;
  margin:8px 0;
  height:40px;
  p:nth-child(2){
      margin-left:8px;
  }
`;
export const DivPrincipalLista = styled.div`
  margin-top: 12px;
  height: calc(100% - 80px);
  overflow: hidden auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const TablePaymentLate = styled.table`
width:100%;
thead{
  tr{
    position:sticky;
    background:${p => p.theme.colors.background};
    height:30px;
    top:0;
    th{
    text-align:start;

    }
  }
}
  tbody{
    tr{
      cursor:default;
      :hover{
                    background:${(p) => lighten(0.03, p.theme.colors.background)};
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                }
      td{
        text-align:start;
      }
    }
  }
  td{
    min-width:60px;
    height:40px;
    font-size:14px;
    vertical-align:middle;
  }
`
export const IconAiOutlineWhatsApp = styled(AiOutlineWhatsApp)`
  width:30px;
  height:30px;
`
export const IconAiOutlineMail = styled(AiOutlineMail)`
  width:30px;
  height:30px;
`