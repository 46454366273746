import styled from 'styled-components';

export const DivMainNovaFatura = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    position: relative;
    height:100%;
    overflow:hidden auto;

`

export const DivListClientes = styled.div`
    display: ${(props) => (props.hide ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    background: #0d0d0d;
    top: 40px;
    border-radius: 6px;
    cursor: default;
    max-height: 200px;
    overflow: auto;
    diV {
        margin: 8px 0;
        padding: 12px;

        :hover {
            background: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const Select = styled.select`
    :hover{
        border-color: rgba(176,206,255,1);
        box-shadow: 0px 0px 2px 1px;
    }
`
export const DivTitleBoleto = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 16px 0;
    align-items: center;
`