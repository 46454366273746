import React,{useState,useEffect} from 'react';
import { DivCampoFormulario, DivTituloPagina } from "./styledNotificacoes";
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import { Button, Input, TextArea } from '~/components/commonComponents/commonComponents';
import { useTheme } from 'styled-components';
import { useRef } from 'react';
import dayjs from 'dayjs';
import LoadingScreen from '~/components/loadScreen/Loadscreen';

export const FormNotificacoes = ({dadosNotificacao,callbackCancelar,isEdit,atualizaTela,close})=>{

    const api = useApi();
    const theme = useTheme();
    const formRef = useRef();
    const notificacaoRef = useRef();
    const validadeRef = useRef();
    const permanenteRef = useRef();
    const statusRef = useRef();
    const urlRef = useRef();
    const [loading, setLoading] = useState('none');
    const [listaStatusNotificacaoes,setListaStatusNotificacaoes] = useState([]);

    
    
    useEffect(()=>{
        init();        
    },[])


    async function salvar(event){
        try {
            event.preventDefault();
            const objNotificacao={
                Notificacao:event.target.notificacao.value,
                Validade:event.target.validade.value,
                Permanente:event.target.permanente.checked,
                Status:event.target.status.value,
                IddoEvento:0,
                Url:event.target.url.value,
            }
            if(isEdit){
                await updateNotificacao(objNotificacao);
                await atualizaTela()
            }else{
                await insertNotificacao(objNotificacao);
                await atualizaTela()
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao salvar informações.')
        }
    }

    async function insertNotificacao(objNotificacao){
        setLoading('flex')
        try {
            const {status,data} = await api.post('/eventos/InsertNotificacao',objNotificacao);
            if(status == 200){
                toast.success('Notificação criada com sucesso.');
                close();
            }
        } catch (error) {
            // console.log(error);
            toast.error('Erro ao tentar cadastrar notificação.')
        }
        setLoading('none')
    };
    async function getListaStatus(){
        setLoading('flex')
        try {
            const {status,data}= await api.get('/eventos/GetStatus')
            if(status != 200)
                throw 'Erro ao carregar lista de notificações.'
            setListaStatusNotificacaoes(data)
        } catch (error) {
            console.error(error);
        }
        setLoading('none')
    };
    async function updateNotificacao(objNotificacao){
        setLoading('flex')
        try {
            const {status} = await api.put('/eventos/updateNotificacao',{...objNotificacao,id:dadosNotificacao.Id});
            if(status == 200){
                toast.success('Dados atualizados com sucesso.');
                close()
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível atualizar notificação.')
        }
        setLoading('none')
    }
   
    async function init(){    
        await getListaStatus();
        if(dadosNotificacao){            
            // console.log(dadosNotificacao.Permanente)
            notificacaoRef.current.value = dadosNotificacao.Notificacao;
            validadeRef.current.value = dayjs(dadosNotificacao.Validade).format('YYYY-MM-DD');
            permanenteRef.current.checked = dadosNotificacao.Permanente == 0 ? false : true;
            statusRef.current.value = dadosNotificacao.Status;
            urlRef.current.value = dadosNotificacao.Url;            
        }
    }

    return(
        <div style={{width:'calc(100% - 52px)',height:'calc(100% - 52px)',padding:'26px',display:'flex',flexDirection:'column'}}>
                <DivTituloPagina >
                    <h1>Nova notificação</h1>
                </DivTituloPagina>
                <form style={{flex:1,display:'flex',flexDirection:'column '}} onSubmit={(event)=>{salvar(event)}} ref={formRef}>
                    <div style={{flex:1}}>
                        <DivCampoFormulario>
                            <span>Notificação:</span>
                            <TextArea 
                                ref={notificacaoRef} 
                                required style={{resize:'none'}} 
                                name='notificacao'                                
                            />
                        </DivCampoFormulario>
                        <DivCampoFormulario>
                            <span>Validade:</span>
                            <Input type='date' name='validade' required ref={validadeRef}/>
                        </DivCampoFormulario>
                        <DivCampoFormulario>
                            <span>Permanente:</span>                            
                            <Input type='checkbox' style={{height:'20px',width:'20px'}} name='permanente' ref={permanenteRef}/>
                        </DivCampoFormulario>
                         <DivCampoFormulario>
                            <span>Status:</span>
                            <select name='status' ref={statusRef}>
                                {
                                    listaStatusNotificacaoes.map(status => <option value={status}>{status}</option>)
                                }
                            </select>
                        </DivCampoFormulario>                        
                        <DivCampoFormulario>
                            <span>Url:</span>
                            <TextArea required style={{resize:'none'}} name='url' ref={urlRef}/>
                        </DivCampoFormulario>
                    </div>
                    <DivCampoFormulario style={{width:'100%'}}>
                        <Button style={{flex:1,background: theme.colors.colorDelete}} type='button' onClick={callbackCancelar}>Cancelar</Button>
                        <Button style={{flex:1,background: theme.colors.colorSave}} type='submit'>Salvar</Button>
                    </DivCampoFormulario>
                </form>
                <LoadingScreen display={loading} />
        </div> 
    )
}