import React, { useState, useEffect } from 'react';
import light from '~/styles/themes/light';
import dark from '~/styles/themes/dark';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from 'react-router-dom';
import { removeToken } from '~/service/auth';

export default function PopupConfiguracoes({ setTheme }) {
    const [themeSelectedLocal, setThemeSelectedLocal] = useState('');
    const history = useHistory();


    function verifyThemeSelected() {
        const themeSelected = localStorage.getItem('theme');
        if (themeSelected) {
            setThemeSelectedLocal(themeSelected);
        } else {
            setThemeSelectedLocal('dark');
        }
    }

    function selectTheme(theme) {
        localStorage.setItem('theme', theme);
        setThemeSelectedLocal(theme);
        if (theme == 'dark') {
            setTheme(dark);
        } else {
            setTheme(light);
        }
    }

    function logout() {
        removeToken();
        history.push('/');
    }

    useEffect(() => {
        verifyThemeSelected();
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'} minWidth={400}>
            <Box display={'flex'} flexDirection={'column'} mb={2} >
                <FormControl>
                    <FormLabel sx={{textAlign:'center',color:'#ffffff'}} id="demo-radio-buttons-group-label">Tema</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={themeSelectedLocal}
                        onChange={({target})=>selectTheme(target.value)}
                        sx={{display:'flex', justifyContent:'center',flexDirection:'row'}}
                    >
                        <FormControlLabel value="dark" control={<Radio />} label="Escuro" />
                        <FormControlLabel value="light" control={<Radio />} label="Claro" />                    
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box width={'100%'}>
                <Button fullWidth  variant='outlined' endIcon={<LogoutIcon/>} onClick={logout}>Logout</Button>
            </Box>
        </Box>
    );
}
