import React, { useState, useEffect } from 'react';
import {
    DivBuscaBackups,
    DivMainBackups,
    DivRodape,
    DivTabelaBackups,
    DivTitulo,
    Observacao,
    TableBackups,
} from './styledBackups';
import useApi from '~/service/api';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import dayjs from 'dayjs';
import { Button } from '~/components/commonComponents/commonComponents';
import PopupEditSolicitacaoBackup from '~/components/popupEditSolicitacaoBackup/popupEditSolicitacaoBackup';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import PopupSolicitacaoNovoBackup from '~/components/popupSolicitacaoNovoBackup/popupSolicitacaoNovoBackup';
import PopupListaMotivos from '~/components/popupListaMotivos/popupListaMotivos';
export default function Backups() {
    const [openPopupEditSolicitacao, setOpenPopupEditSolicitacao] = useState(false);
    const [openPopupNovaSolicitacaoBackup, setOpenPopupNovaSolicitacaoBackup] = useState(false);
    const [openPopupListaMotivo, setOpenPopupListaMotivo] = useState(false);
    const [dadosSelecionados, setDadosSelecionados] = useState();
    const [listaBackups, setListaBackups] = useState([]);
    const [loading, setLoading] = useState('none');
    const [motivos, setMotivos] = useState([]);
    const api = useApi();

    const styledInlineButton = { width: '200px', margin: '0 0 0 12px', background: '#115213' };

    async function getListaBackups() {
        try {
            setLoading('flex');
            await getMotivos();
            const listaDeBackups = await api.get('/backup/GetListaBackups?idBackup=');
            if (listaDeBackups.status == 200) {
                setListaBackups(listaDeBackups.data);
                setLoading('none');
            } else {
                toast.error('Erro ao carregar a lista de backups.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar lista de backup');
            setLoading('none');
        }
    }
    async function buscaBackup(event) {
        try {
            if (event.key != 'Enter') return null;
            setLoading('flex');
            const emailOuSoftwareId = event.target.value;
            const buscaRequest = await api.get(`/backup/GetListaBackups?idBackup=${emailOuSoftwareId}`);
            if (buscaRequest.status == 200) {
                setListaBackups(buscaRequest.data);
                setLoading('none');
                return;
            }
            toast.error('Erro ao procurar pelo backup.');
            setLoading('none');
        } catch (error) {
            console.log(error);
            if (error.request.status == 404) {
                toast.error('Nenhum backup encontrado.');
                setLoading('none');
                return;
            }
            setLoading('none');
            toast.error('Não foi possível buscar backup.');
        }
    }
    async function getMotivos() {
        try {
            const listaMotivos = await api.get('/backup/GetMotivos');
            if (listaMotivos.status == 200) {
                setMotivos(listaMotivos.data);
            } else {
                toast.error('Erro ao carregar motivos.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar motivos.');
        }
    }
    function retornaMotivo(idMotivo) {
        try {
            const motivoLabel = motivos.filter((item) => item.IdMotivo == idMotivo);
            if (motivoLabel.length > 0) {
                return motivoLabel[0].Motivo;
            } else {
                return 'Motivo não encontrado';
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível encontrar um motivo.');
            return null;
        }
    }
    function copyInfo(info) {
        try {
            navigator.clipboard.writeText(info);
            toast.success('Copiado com sucesso');
        } catch (error) {
            toast.error('error ao copiar');
        }
    }
    function selecionaIndoAbrePopup(item) {
        setDadosSelecionados(item);
        setOpenPopupEditSolicitacao(true);
    }
    useEffect(() => {
        getListaBackups();
    }, []);
    return (
        <DivMainBackups>
            <DivTitulo>
                <h1>Lista de backups</h1>
            </DivTitulo>
            <DivBuscaBackups>
                <input type="text" placeholder="Busque pelo backup." onKeyPress={(event) => buscaBackup(event)} />
                {/* <Button style={{ width: '200px', margin: '0 0 0 12px', background: '#115213' }}>Inserir novo backup</Button> */}
            </DivBuscaBackups>
            <DivTabelaBackups>
                <TableBackups>
                    <thead>
                        <tr>
                            <th>SoftwareId</th>
                            <th>Email</th>
                            <th>Celular</th>
                            <th>Motivo</th>
                            <th>Data Solicitação</th>
                            <th>Concluido</th>
                            <th>Link Banco</th>
                            <th>Link MedxData</th>
                            <th>Senha</th>
                            <th>Observações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaBackups.map((item) => {
                            return (
                                <tr onClick={() => selecionaIndoAbrePopup(item)}>
                                    <td>{item.SoftwareId}</td>
                                    <td>{item.EmailAssinante}</td>
                                    <td>{item.Celular}</td>
                                    <td>{retornaMotivo(item.IdMotivo)}</td>
                                    <td>{dayjs(item.CreationDate).format('DD/MM/YYYY')}</td>
                                    <td>{item.Concluido ? dayjs(item.Concluido).format('DD/MM/YYYY') : ''}</td>
                                    <td title={item.Link} onClick={() => copyInfo(item.Link ?? '')}>
                                        {item.Link ? 'Link' : ''}
                                    </td>
                                    <td title={item.LinkMedxData} onClick={() => copyInfo(item.LinkMedxData ?? '')}>
                                        {item.LinkMedxData
                                            ? item.LinkMedxData.split('<br>').map((link, index) => {
                                                return <p>Link{index + 1}</p>;
                                            })
                                            : ''}
                                    </td>
                                    <td>{item.Chave}</td>
                                    <td>
                                        <Observacao title={item.Observacoes}>{item.Observacoes}</Observacao>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableBackups>
            </DivTabelaBackups>
            <DivRodape>
                <div style={{ display: 'flex' }}>
                    <Button onClick={() => setOpenPopupNovaSolicitacaoBackup(true)}>
                        Inserir novo backup
                    </Button>
                    <Button onClick={() => setOpenPopupListaMotivo(true)}>
                        <nobr>Cadastrar novo motivo</nobr>
                    </Button>
                </div>
            </DivRodape>
            <LoadingScreen display={loading} />
            <PopupDefault
                children={
                    <PopupEditSolicitacaoBackup
                        solicitacaoInfo={dadosSelecionados}
                        closePopup={() => setOpenPopupEditSolicitacao(false)}
                        update={() => getListaBackups()}
                    />
                }
                open={openPopupEditSolicitacao}
                close={() => setOpenPopupEditSolicitacao(false)}
            />
            <PopupDefault
                open={openPopupNovaSolicitacaoBackup}
                close={() => setOpenPopupNovaSolicitacaoBackup(false)}
                children={
                    <PopupSolicitacaoNovoBackup closePopup={setOpenPopupNovaSolicitacaoBackup} update={() => getListaBackups()} />
                }
            />
            <PopupDefault
                open={openPopupListaMotivo}
                close={() => setOpenPopupListaMotivo(false)}
                children={<PopupListaMotivos close={() => setOpenPopupListaMotivo(false)} />}
            />
        </DivMainBackups>
    );
}
