import React, { useState, useEffect } from 'react';
import {
    DivMainPopupEditSolicitacaoBackup,
    DivButtons,
    DivInfosPopupEditSolicitacaoBackup,
    DivConteudoInfo,
} from './styledPopupEditSolicitacaoBackup';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useApi from '~/service/api';
import { SelectFormBackup, TextAreaFormBackup } from '../formGerarBackup/StyledFormGerarBackup';
import { Button } from '../commonComponents/commonComponents';
import PopupDefault from '../popupDefault/PopupDefault';
import LoadingScreen from '../loadScreen/Loadscreen';
import { useTheme } from 'styled-components';
export default function PopupEditSolicitacaoBackup({ solicitacaoInfo, closePopup, update }) {
    const theme = useTheme();
    const [motivos, setMotivos] = useState([]);
    const [loading, setLoading] = useState('none');
    const [openPopupConfirmacao, setOpenPopupConfirmacao] = useState(false);
    const api = useApi();

    const [dadosBackup, setDadosBackup] = useState({
        Celular: solicitacaoInfo.Celular,
        Concluido: solicitacaoInfo.Concluido,
        CreationDate: solicitacaoInfo.CreationDate,
        EmailAssinante: solicitacaoInfo.EmailAssinante,
        IdBackup: solicitacaoInfo.IdBackup,
        IdMotivo: solicitacaoInfo.IdMotivo,
        Link: solicitacaoInfo.Link,
        LinkMedxData: solicitacaoInfo.LinkMedxData,
        Observacoes: solicitacaoInfo.Observacoes,
        SoftwareId: solicitacaoInfo.SoftwareId,
    });
    async function getMotivosBackup() {
        try {
            setLoading('flex');
            const data = await api.get('/backup/GetMotivos');
            if (data.status == 200) {
                setMotivos(data.data);
                setLoading('none');
            } else {
                toast.error('Não carregou motivos backups.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar motivos do backup.');
            setLoading('none');
        }
    }
    async function saveSolicitacaoBackup() {
        try {
            //#region Verificar campos obrigatórios
            if (dadosBackup.Motivo == 0) {
                toast.info('Selecione o motivo, por favor.');
                return null;
            }
            if (dadosBackup.SoftwareId == '') {
                toast.info('Informe o softwareid, por favor.');
                return null;
            }
            if (dadosBackup.Celular == '') {
                toast.info('Informe o celular, por favor.');
                return null;
            }
            if (dadosBackup.Email == '') {
                toast.info('Informe o email, por favor.');
                return null;
            }
            //#endregion
            setLoading('flex');
            const saveSolicitacao = await api.post(`/backup/UpdateSolicitacao`, dadosBackup);
            if (saveSolicitacao.status == 200) {
                update();
                toast.success('Solicitação salva com sucesso.');
                closePopup();
                setLoading('none');
            } else {
                toast.error('Erro ao gerar solicitação.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível solicitar backup.');
            setLoading('none');
        }
    }
    async function ExcluiSolicitacao() {
        try {
            setLoading('flex');
            const responseDeleteSolicitacao = await api.post(`/backup/DeleteSolicitacao?idBackup=${dadosBackup.IdBackup}`);
            if (responseDeleteSolicitacao.status == 200) {
                update();
                closePopup();
                toast.success('Solicitação excluída com sucesso.');
                setLoading('none');
            } else {
                toast.error('Erro ao excluir solicitação.');
                setLoading('none');
            }
        } catch (error) {
            toast.error('Não foi possível excluir solicitação.');
            setLoading('none');
        }
    }
    useEffect(() => {
        getMotivosBackup();
    }, []);

    return (
        <DivMainPopupEditSolicitacaoBackup>
            <DivConteudoInfo>
                <h1>Editar</h1>
            </DivConteudoInfo>
            <DivInfosPopupEditSolicitacaoBackup>
                <DivConteudoInfo>
                    <span>IdBackup: {dadosBackup.IdBackup}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>EmailAssinante: {dadosBackup.EmailAssinante}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>Motivo: </span>
                    <SelectFormBackup
                        value={dadosBackup.IdMotivo}
                        onChange={(event) => setDadosBackup({ ...dadosBackup, IdMotivo: event.target.value })}
                    >
                        <option value={0}>Selecione a opção</option>
                        {motivos.map((item) => {
                            return <option value={item.IdMotivo}>{item.Motivo}</option>;
                        })}
                    </SelectFormBackup>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>Link: {dadosBackup.Link}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>LinkMedxData:</span>
                    <div style={{ margin: '0 0 0 12px' }}>
                        {dadosBackup.LinkMedxData ? dadosBackup.LinkMedxData.split(/<br>/).map((link) => <p>{link}</p>) : ''}
                    </div>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>SoftwareId: {dadosBackup.SoftwareId}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>Concluido em: {dayjs(dadosBackup.Concluido).format('DD/MM/YYYY')}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>Celular: {dadosBackup.Celular}</span>
                </DivConteudoInfo>
                <DivConteudoInfo>
                    <span>Solicitado em: {dayjs(dadosBackup.CreationDate).format('DD/MM/YYYY')}</span>
                </DivConteudoInfo>
                <DivConteudoInfo style={{ flexDirection: 'column', alignItems: 'start' }}>
                    <span>Observacoes: </span>
                    <TextAreaFormBackup
                        value={dadosBackup.Observacoes}
                        onChange={(event) => setDadosBackup({ ...dadosBackup, Observacoes: event.target.value })}
                    />
                </DivConteudoInfo>
            </DivInfosPopupEditSolicitacaoBackup>
            <DivButtons>
                <Button style={{ background: theme.colors.colorDelete }} onClick={() => setOpenPopupConfirmacao(true)}>
                    Excluir Solicitação
                </Button>
                <Button style={{ background: theme.colors.colorSave }} onClick={saveSolicitacaoBackup}>
                    Salvar
                </Button>
            </DivButtons>
            <PopupDefault
                open={openPopupConfirmacao}
                close={() => setOpenPopupConfirmacao(false)}
                children={
                    <div style={{ padding: '30px 18px 18px 18px' }}>
                        <p>Confirma exclusão da solicitação ?</p>
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '18px 0 0 0' }}>
                            <Button onClick={ExcluiSolicitacao} style={{ background: '#115213' }}>
                                Sim
                            </Button>
                            <Button onClick={() => setOpenPopupConfirmacao(false)} style={{ background: '#8e0000' }}>
                                Não
                            </Button>
                        </div>
                    </div>
                }
            />
            <LoadingScreen display={loading} />
        </DivMainPopupEditSolicitacaoBackup>
    );
}
