import React, { useState, useEffect } from 'react';
import { DivInfoContrato, DivMainSmsView } from '~/pages/cliente/ClienteStyled';
import { DivMainPopupEditContrato } from './styledPopupEditContrato';
import useApi from '~/service/api';
import { Button, Title } from '../commonComponents/commonComponents';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import LoadingScreen from '../loadScreen/Loadscreen';
export default function PopupEditContrato({ dadosContrato, closePopup }) {
    const [dados, setDados] = useState(dadosContrato);
    const api = useApi();
    const [options, setOptions] = useState([]);
    const [loadScreen, setLoadScreen] = useState('none');
    const Status = [
        { value: '', nome: '' },
        { value: 'Trial', nome: 'Trial' },
        { value: 'FULL', nome: 'Enterprise' },
        { value: 'Small', nome: 'Business' },
        { value: 'Individual', nome: 'Starter' },
        { value: 'Nutrição', nome: 'Nutrição' },
        { value: 'Corporativo', nome: 'Corporativo' },
        { value: 'Suspenso', nome: 'Suspenso' },
        { value: 'CERTIFIED MED (OBSOLETO)', nome: 'CERTIFIED MED (OBSOLETO)' },
    ];
    function verificaSelecao(item) {
        if (item.Id_do_Produto === dados.Id_do_produto)
            return (
                <option key={item.Id_do_Produto} selected value={item.Id_do_Produto}>
                    {item.Produto}
                </option>
            );
        else
            return (
                <option
                    key={item.Id_do_Produto}
                    selected={() => {
                        return verificaSelecao(item.Id_do_Produto);
                    }}
                    value={item.Id_do_Produto}
                >
                    {item.Produto}
                </option>
            );
    }

    function verificaStatus(item) {
        if (item.value === dados.Status)
            return (
                <option selected value={item.value}>
                    {item.nome}
                </option>
            );
        else return <option value={item.value}>{item.nome}</option>;
    }

    function verificaCancelado(cancelado) {
        if (cancelado) {
            return <input onClick={() => alteraCancelado(cancelado)} type="checkbox" checked />;
        } else {
            return <input onClick={() => alteraCancelado(cancelado)} type="checkbox" />;
        }
    }

    function alteraCancelado(cancelado) {
        if (cancelado === 1) {
            setDados({ ...dados, Cancelado: 0 });
        } else if (cancelado === 0) {
            setDados({ ...dados, Cancelado: 1 });
        } else {
            toast.error('Valor inválido.');
        }
    }

    function salvaEdicao() {
        updateDadosContratos(dados);
    }

    async function getOptions() {
        try {
            const data = await api.get(`/cliente/getprodutos`);
            if (data.status === 200) {
                setOptions(data.data);
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar as options.');
        }
    }

    async function updateDadosContratos(objContrato) {
        try {
            setLoadScreen('flex');
            const data = await api.post(`/cliente/UpdateDadosContrato`, {
                Id_do_produto: objContrato.Id_do_produto,
                data: objContrato.data,
                Cancelado: objContrato.Cancelado,
                Status: objContrato.Status,
                Condicao_Pagamento: objContrato.Condicao_Pagamento,
                Vigencia: objContrato.Vigencia,
                Valor: objContrato.Valor,
                Data_Contrato: objContrato.Data_Contrato,
                Periodicidade: objContrato.Periodicidade,
                SoftwId: objContrato.SoftwId,
            });
            if (data.status === 200) {
                toast.success('Salvo com sucesso.');
                setLoadScreen('none');
                closePopup(false);
            } else {
                toast.warning('Erro ao atualizar.');
                setLoadScreen('none');
                closePopup(false);
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível realizar atualização.');
            setLoadScreen('none');
            closePopup(false);
        }
    }

    useEffect(() => {
        getOptions();
    }, []);

    return (
        <DivMainPopupEditContrato>
            <Title style={{ textAlign: 'center', fontSize: '32px' }}>{dados.Cliente}</Title>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '0 24px 0 0' }}>
                    <DivInfoContrato>
                        <span>Data:</span>
                        <div>
                            <input
                                type="date"
                                defaultValue={dayjs(dados.data).format('YYYY-MM-DD')}
                                onChange={(event) => setDados({ ...dados, data: event.target.value })}
                            />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>SoftwId:</span>
                        <div>
                            <input disabled type="text" defaultValue={dados.SoftwId} />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Atividade Cliente:</span>
                        <div>
                            <input disabled type="text" defaultValue={dados.Atividade_Cliente} />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Produto:</span>
                        <div>
                            <select onChange={(event) => setDados({ ...dados, Id_do_produto: event.target.value })}>
                                {options.map((item) => verificaSelecao(item))}
                            </select>
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Cancelado:</span>
                        <div style={{ width: '20px' }}>{verificaCancelado(dados.Cancelado)}</div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Status:</span>
                        <div>
                            <select onChange={(event) => setDados({ ...dados, Status: event.target.value })}>
                                {Status.map((item) => verificaStatus(item))}
                            </select>
                        </div>
                    </DivInfoContrato>
                </div>
                <div>
                    <DivInfoContrato>
                        <span>Condição Pagamento:</span>
                        <div>
                            <input
                                onChange={(event) => setDados({ ...dados, Condicao_Pagamento: event.target.value })}
                                type="text"
                                defaultValue={dados.Condicao_Pagamento}
                            />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Vigência:</span>
                        <div>
                            <input
                                onChange={(event) => setDados({ ...dados, Vigencia: event.target.value })}
                                type="date"
                                defaultValue={dayjs(dados.Vigencia).format('YYYY-MM-DD')}
                            />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Valor Contrato:</span>
                        <div>
                            <input
                                onChange={(event) => setDados({ ...dados, Valor: event.target.value })}
                                type="number"
                                min={0}
                                step={0.01}
                                defaultValue={dados.Valor}
                            />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Data Contrato</span>
                        <div>
                            <input
                                onChange={(event) => setDados({ ...dados, Data_Contrato: event.target.value })}
                                type="date"
                                defaultValue={dayjs(dados.Data_Contrato).format('YYYY-MM-DD')}
                            />
                        </div>
                    </DivInfoContrato>
                    <DivInfoContrato>
                        <span>Periodicidade</span>
                        <div>
                            <input
                                onChange={(event) => setDados({ ...dados, Periodicidade: event.target.value })}
                                type="number"
                                min={0}
                                step={1}
                                defaultValue={dados.Periodicidade}
                            />
                        </div>
                    </DivInfoContrato>
                </div>
            </div>
            <DivInfoContrato style={{ alignItems: 'center', justifyContent: 'space-around', margin: '15px 0 0 0' }}>
                <Button onClick={salvaEdicao} style={{ background: '#115213' }}>
                    Salvar
                </Button>
                <Button onClick={() => closePopup(false)} style={{ background: '#8e0000' }}>
                    Cancelar
                </Button>
            </DivInfoContrato>
            <LoadingScreen display={loadScreen} />
        </DivMainPopupEditContrato>
    );
}
