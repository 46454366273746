import styled from 'styled-components';

export const TableInfo = styled.table`
    height: 100px;
    overflow: auto;
    thead {
        th {
            position: sticky;
            top: -20px;
            background: #1a1a1a;
            padding: 10px;
            text-align: start;
        }
    }
    tbody {
        td {
            padding: 0 10px;
        }
    }
`;
