import React, { useState, useEffect } from 'react';
import useApi from '~/service/api';
import LoadingScreen from '../loadScreen/Loadscreen';
import { toast } from 'react-toastify';
import { Button } from '../commonComponents/commonComponents';
import { DivInfos, DivMainPopupListaMotivos, DivTitulo, PMotivos } from './styledPopupListaMotivos';
import { useTheme } from 'styled-components';
export default function PopupListaMotivos({ close }) {
    const theme = useTheme()
    const api = useApi();
    const [motivos, setMotivos] = useState([]);
    const [loading, setLoading] = useState('none');
    const [novoMotivo, setNovoMotivo] = useState('');
    async function getMotivos() {
        try {
            setLoading('flex');
            const listaMotivos = await api.get('/backup/GetMotivos');
            if (listaMotivos.status == 200) {
                setMotivos(listaMotivos.data);
                setLoading('none');
            } else {
                toast.error('Erro ao carregar motivos.');
                setLoading('none');
            }
        } catch (error) {
            console.log(error);
            toast.error('Não foi possível carregar motivos.');
            setLoading('none');
        }
    }
    async function salvarNovoMotivo() {
        try {
            const reponseInsertMotivo = await api.post(`/backup/InsertMotivo?novoMotivo=${novoMotivo}`);
            if (reponseInsertMotivo.status == 200) {
                toast.success('Novo motivo criado com sucesso.');
                await getMotivos();
                close();
            } else {
                toast.error('Erro ao salvar novo motivo');
            }
        } catch (error) {
            toast.error('Não foi possível salvar novo motivo.');
        }
    }
    useEffect(() => {
        getMotivos();
    }, []);

    return (
        <DivMainPopupListaMotivos>
            <DivTitulo>
                <h1>Motivos</h1>
            </DivTitulo>
            <DivInfos>
                {motivos.map((motivo) => (
                    <PMotivos>{motivo.Motivo}</PMotivos>
                ))}
            </DivInfos>
            <DivInfos>
                <input type="text" placeholder="Insira o novo motivo." onChange={(event) => setNovoMotivo(event.target.value)} />
            </DivInfos>
            <DivInfos style={{ display: 'flex' }}>
                <Button style={{ background: theme.colors.colorDelete }} onClick={() => close()}>Fechar</Button>
                <Button style={{ background: theme.colors.colorSave }} onClick={salvarNovoMotivo}>Salvar</Button>
            </DivInfos>
            <LoadingScreen display={loading} />
        </DivMainPopupListaMotivos>
    );
}
