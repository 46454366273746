import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DivButton } from '~/components/boletoEdit/StyledBoletoEdit';
import { Button } from '~/components/commonComponents/commonComponents';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import MenuGammyx from '~/components/menuGammyx/menuGammyx'
import PopupDefault from '~/components/popupDefault/PopupDefault';
import useApi from '~/service/api';
import { ButtonDelete, ButtonSave, DivPrincipalEdicaoCurso } from '../curso/styledGammyxCurso';
import { DivCampoPopup } from '../cursos/styledGammyxCursos';
import { DivListaVendas, RowVenda } from '../vendas/styledGammyxVendas';
import { Container } from './styledGammyxAutores';


export default function GammyxAutores() {
    const api = useApi();
    const [mostraLoad, setMostraLoad] = useState(false);
    const [listaAutores, setListaAutores] = useState([]);
    const [openPopupEditaAutor, setOpenPopupEditaAutor] = useState(false);
    const [autorInfo, setAutorInfo] = useState({
        descricaoAcademica: "",
        dataCriacao: "",
        foto: "",
        nascimento: "",
        sexo: "M",
        celular: "",
        email: "",
        bairro: "",
        cep: "",
        cidade: "",
        complemento: "",
        cpf: "",
        endereco: "",
        numero: "",
        keyStone: "",
        estado: "",
        id: "",
        nome: "",
    })
    const [tituloPopup, setTituloPopup] = useState('Novo Autor')
    useEffect(() => {
        init();
    }, [])

    async function carregaAutores() {
        setMostraLoad(true)
        try {
            const responseCarregaAutores = await api.get(`/gammyx/GetAutores`);
            if (responseCarregaAutores.status == 200) {
                setListaAutores(responseCarregaAutores.data);
            } else {
                toast.error('Erro ao carregar lista de autores')
            }
        } catch (error) {
            console.log(error);
            toast.error()
        }
        setMostraLoad(false)
    }
    async function atualizaCadastraAutor() {
        setMostraLoad(true)
        try {
            if (autorInfo.id == "") {
                const responseCadastraAutor = await api.post(`/gammyx/InsertAutor`, autorInfo);
                if (responseCadastraAutor.status == 200) {
                    toast.success('Autor cadastrado com sucesso.');
                    await carregaAutores();
                    fechaEdicaoAutor();
                } else {
                    toast.error(`Erro ao cadastrar autor.`)
                }
            } else {
                const responseCadastraAutor = await api.put(`/gammyx/UpdateAutor`, autorInfo);
                if (responseCadastraAutor.status == 200) {
                    toast.success('Autor editado com sucesso.');
                    await carregaAutores();
                    fechaEdicaoAutor();
                } else {
                    toast.error(`Erro ao editar autor.`)
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível cadastrar/atualizar autor.')
        }
        setMostraLoad(false)
    }
    async function init() {
        await carregaAutores();
    }
    function fechaEdicaoAutor() {
        setAutorInfo({
            descricaoAcademica: "",
            dataCriacao: "",
            foto: "",
            nascimento: "",
            sexo: "M",
            celular: "",
            email: "",
            bairro: "",
            cep: "",
            cidade: "",
            complemento: "",
            cpf: "",
            endereco: "",
            numero: "",
            keyStone: "",
            estado: "",
            id: "",
            nome: "",
        });
        setOpenPopupEditaAutor(false);
    }
    function editarAutor(autor) {
        setAutorInfo({ ...autor, nascimento: dayjs(autor.nascimento).format('YYYY-MM-DD') });
        setOpenPopupEditaAutor(true)
    }
    return <Container >
        <MenuGammyx />
        <div style={{ flex: 1 }}>
            <RowVenda style={{ height: '40px' }}>
                <div style={{ justifyContent: 'flex-start' }}>Nome</div>
                <div>Email</div>
                <div>Celular</div>
                <div></div>
            </RowVenda>
            <DivListaVendas >
                {
                    listaAutores.length ? listaAutores.map(autor => (<RowVenda>
                        <div style={{ justifyContent: 'flex-start' }}>{autor.nome}</div>
                        <div>{autor.email}</div>
                        <div>{autor.celular}</div>
                        <div>
                            <Button
                                style={{ flex: 1, maxWidth: '150px' }}
                                onClick={() => editarAutor(autor)}
                            >
                                Editar
                            </Button>
                        </div>
                    </RowVenda>))
                        :
                        ""
                }
            </DivListaVendas>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => { setOpenPopupEditaAutor(true); setTituloPopup('Cadastrar Autor') }}>Novo Autor</Button>
        </div>
        {mostraLoad && <LoadingScreen display={'flex'} />}
        {openPopupEditaAutor && <PopupDefault
            open={true}
            close={() => fechaEdicaoAutor()}
            children={<DivPrincipalEdicaoCurso>
                <h1>{tituloPopup}</h1>
                <div>
                    <DivCampoPopup>
                        <span>Nome:</span>
                        <input
                            type="text"
                            value={autorInfo.nome}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, nome: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>CPF:</span>
                        <input
                            type="text"
                            value={autorInfo.cpf}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, cpf: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Nascimento:</span>
                        <input
                            type="date"
                            value={autorInfo.nascimento}
                            min={"1900-01-01"}
                            max={"9999-01-01"}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, nascimento: target.value })}
                        />
                    </DivCampoPopup>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ marginRight: '16px', flex: 1 }}>
                            <span>Celular:</span>
                            <input
                                style={{ margin: '8px 0 8px 24px' }}
                                type="text"
                                value={autorInfo.celular}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, celular: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup>
                            <span>Sexo:</span>
                            <select
                                value={autorInfo.sexo}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, sexo: target.value })}
                            >
                                {
                                    [{ opcao: "Masculino", valor: "M" }, { opcao: "Feminino", valor: 'F' }].map(opcao => (<option value={opcao.valor}>{opcao.opcao}</option>))
                                }
                            </select>
                        </DivCampoPopup>
                    </div>
                    <DivCampoPopup>
                        <span>Email:</span>
                        <input
                            type="text"
                            value={autorInfo.email}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, email: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Cep:</span>
                        <input
                            type="text"
                            value={autorInfo.cep}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, cep: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Cep:</span>
                        <input
                            type="text"
                            value={autorInfo.cep}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, cep: target.value })}
                        />
                    </DivCampoPopup>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ flex: 1, margin: '0 16px 0 0' }}>
                            <span>Endereço:</span>
                            <input
                                style={{ margin: '8px 0 8px 24px' }}
                                type="text"
                                value={autorInfo.endereco}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, endereco: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup style={{ maxWidth: '200px' }}>
                            <span>Nº:</span>
                            <input
                                type="text"
                                value={autorInfo.numero}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, numero: target.value })}
                            />
                        </DivCampoPopup>
                    </div>
                    <DivCampoPopup>
                        <span>Complemento:</span>
                        <input
                            type="text"
                            value={autorInfo.complemento}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, complemento: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Cidade:</span>
                        <input
                            type="text"
                            value={autorInfo.cidade}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, cidade: target.value })}
                        />
                    </DivCampoPopup>
                    <div style={{ display: 'flex' }}>
                        <DivCampoPopup style={{ margin: '0 16px 0 0', flex: 1 }}>
                            <span>Bairro:</span>
                            <input
                                style={{ margin: '8px 0 8px 30px' }}
                                type="text"
                                value={autorInfo.bairro}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, bairro: target.value })}
                            />
                        </DivCampoPopup>
                        <DivCampoPopup>
                            <span>Estado:</span>
                            <select
                                value={autorInfo.estado}
                                onChange={({ target }) => setAutorInfo({ ...autorInfo, estado: target.value })}
                            >
                                <option value=""> </option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                            </select>
                        </DivCampoPopup>

                    </div>
                    <DivCampoPopup>
                        <span>Url foto:</span>
                        <input
                            type="text"
                            value={autorInfo.foto}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, foto: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Chave da Stone:</span>
                        <input
                            type="text"
                            value={autorInfo.keyStone}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, keyStone: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup style={{ margin: '8px 0 0 0' }}>
                        <span>Descrição Academica:</span>
                        <textarea
                            rows={5}
                            value={autorInfo.descricaoAcademica}
                            onChange={({ target }) => setAutorInfo({ ...autorInfo, descricaoAcademica: target.value })}
                            style={{ flex: 1, margin: '0 0 0 -4px', padding: '16px' }}
                        />
                    </DivCampoPopup>
                </div>
                <DivButton style={{ margin: '16px 0 0 0' }}>
                    <ButtonDelete className='deletar' onClick={fechaEdicaoAutor}>Cancelar</ButtonDelete>
                    <ButtonSave style={{ margin: 0 }} className='salvar' onClick={() => atualizaCadastraAutor()} >Salvar</ButtonSave>
                </DivButton>
            </DivPrincipalEdicaoCurso>}
        />}
    </Container>;
}