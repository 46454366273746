import styled from 'styled-components';
import { DivTable } from '~/components/commonComponents/commonComponents';

export const DivTitulo = styled.div`
    display: flex;
    justify-content: center;
    height: 40px;
    margin-top: 20px;
    width: calc(100% - 32px);
    margin-left: 16px;
`;
export const DivTableContratosRisco = styled(DivTable)`
    width: calc(100% - 32px);
    margin-left: 16px;
    height: calc(100% - 150px);
    table{
        thead{
            tr{
                th{
                    padding-left:10px;
                }
            }
        }
        tbody{
            tr{
                height:35px;
                td{
                    padding-left:10px;
                }
            }
        }
    }
`