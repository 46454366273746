import styled from 'styled-components';
import { Link } from 'react-router-dom'
export const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin:0 0 16px 0;
`;
export const DivOpcoes = styled.div`
    display:flex;    
    flex-wrap:wrap;
    >a{
        flex:1;
    }
`
export const DivOpcao = styled.div`
   
`
export const LinkCustom = styled(Link)`
 border:1px solid #fff;
    padding:16px;
    border-radius:6px;
    margin:16px 16px 0 0;
    flex:1;
    transition:.5s;
    text-align:center;
    background:${p => p.selecionado ? 'red' : 'unset'};
    :hover{
        background:red;
        cursor:pointer;        
    }
    :nth-child(5){        
        margin:16px 0 0 0;
    }
`