import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import LoadingScreen from '~/components/loadScreen/Loadscreen';
import MenuGammyx from '~/components/menuGammyx/menuGammyx';
import useApi from '~/service/api';
import { Container } from './styledGammyxVendas';
import { formataCurrency } from '~/helpers/functions'
import { Button } from '~/components/commonComponents/commonComponents';
import PopupDefault from '~/components/popupDefault/PopupDefault';
import { ButtonDelete, ButtonSave, DivPrincipalEdicaoCurso } from '../curso/styledGammyxCurso';
import { DivCampoPopup } from '../cursos/styledGammyxCursos';
import { DivButton } from '~/components/boletoEdit/StyledBoletoEdit';
import TabelaModelo from '~/components/tabelaModelo/tabelaModelo';
export default function GammyxVendas() {
    const [opcaoBusca, setOpcaoBusca] = useState("")
    const arrayOpcoes = ["", "tid", "idCurso", "id", "idAluno", "curso", "aluno"];
    const header = ['Nome', 'Valor', 'Curso', 'Status', 'Dt.Pagamento', ''];
    const [mostraLoad, setMostraLoad] = useState(false)
    const [editaVenda, setEditaVenda] = useState(false);
    const [listaVendas, setListaVendas] = useState([]);
    const [vendaInfo, setVendaInfo] = useState({
        id: "",
        valor: "",
        stutus: "",
        tid: "",
        idFaturaMedx: "",
        idCurso: "",
        idAluno: "",
        dataCriacao: "",
        idCupom: "",
        idLead: "",
        dataPagamento: "",
        nomeCurso: "",
        nomeAluno: ""
    })
    const api = useApi();

    useEffect(() => {
        carregaVendas("");
    }, []);

    async function carregaVendas(busca) {
        setMostraLoad(true)
        try {
            const responseGetVendas = await api.get(`/gammyx/GetVendas?valor=${busca}&campo=${opcaoBusca}`);
            if (responseGetVendas.status == 200) {
                setListaVendas(responseGetVendas.data);
            } else {
                toast.error('Erro ao carregar lista de vendas.')
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar lista de vendas')
        }
        setMostraLoad(false)
    };
    async function updateVenda() {
        setMostraLoad(true);
        try {
            const objUpdateVenda = {
                id: vendaInfo.id,
                tid: vendaInfo.tid,
                dataPagamento: vendaInfo.dataPagamento,
                stutus: vendaInfo.stutus,
            }
            const responseUpdateVenda = await api.put(`/gammyx/UpdateVenda`, objUpdateVenda);
            if (responseUpdateVenda.status == 200) {
                toast.success('Venda editada com sucesso.');
                fecharPopupEditaVenda()
            } else {
                toast.error('Error ao editar venda.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível atualizar venda.')
        }
        setMostraLoad(false);
    }
    function fnEditaVenda(venda) {
        setVendaInfo(venda);
        setEditaVenda(true);
    }
    function fecharPopupEditaVenda() {
        setVendaInfo({
            id: "",
            valor: "",
            stutus: "",
            tid: "",
            idFaturaMedx: "",
            idCurso: "",
            idAluno: "",
            dataCriacao: "",
            idCupom: "",
            idLead: "",
            dataPagamento: "",
            nomeCurso: "",
            nomeAluno: ""
        });
        setEditaVenda(false)
    }
    function retornaValor(key, ObjCompleto) {
        if (key == 'Nome')
            return ObjCompleto.nomeAluno
        if (key == 'Valor')
            return formataCurrency.format(ObjCompleto.valor)
        if (key == 'Curso')
            return ObjCompleto.nomeCurso
        if (key == 'Status')
            return ObjCompleto.stutus
        if (key == 'Dt.Pagamento')
            return dayjs(ObjCompleto.dataPagamento).format('DD/MM/YYYY')
        if (key == '') {
            return <Button
                style={{ width: '100%' }}
                onClick={() => fnEditaVenda(ObjCompleto)}
            >
                Editar
            </Button>
        }
        return ""
    }
    return <Container>
        <MenuGammyx />
        <div>
            <div style={{ display: 'flex' }}>
                <input
                    placeholder='Busque por uma venda'
                    style={{ flex: 1, margin: '0 16px 0 0' }}
                    onKeyDown={({ key, target }) => key == 'Enter' ? carregaVendas(target.value) : ""}
                />
                <select style={{ width: 'fit-content' }}
                    value={opcaoBusca}
                    onChange={({ target }) => setOpcaoBusca(target.value)}
                >
                    {
                        arrayOpcoes.map(opcao => <option value={opcao}>{opcao != "" ? opcao : "Selecione a opção de busca"}</option>)
                    }
                </select>
            </div>
        </div>
        <TabelaModelo
            header={header}
            clientes={listaVendas}
            callbackDoubleClick={editaVenda}
            fnRetornaValor={retornaValor}
        />
        {mostraLoad && <LoadingScreen display={'flex'} />}
        {editaVenda && <PopupDefault
            open={true}
            close={() => setEditaVenda(false)}
            children={<DivPrincipalEdicaoCurso>
                <h1>Edição venda</h1>
                <div>
                    <DivCampoPopup>
                        <span>Aluno:</span>
                        <input
                            type="text"
                            value={vendaInfo.nomeAluno}
                            disabled
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Curso:</span>
                        <input
                            type="text"
                            value={vendaInfo.nomeCurso}
                            disabled
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Valor:</span>
                        <input
                            type="text"
                            value={formataCurrency.format(vendaInfo.valor)}
                            disabled
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Tid:</span>
                        <input
                            type="text"
                            value={vendaInfo.tid}
                            onChange={({ target }) => setVendaInfo({ ...vendaInfo, tid: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Data de Pagamento:</span>
                        <input
                            type="date"
                            max={'9999-01-01'}
                            min={'1900-01-01'}
                            value={dayjs(vendaInfo.dataPagamento).format('YYYY-MM-DD')}
                            onChange={({ target }) => setVendaInfo({ ...vendaInfo, dataPagamento: target.value })}
                        />
                    </DivCampoPopup>
                    <DivCampoPopup>
                        <span>Status:</span>
                        <select
                            alue={vendaInfo.stutus}
                            onChange={({ target }) => setVendaInfo({ ...vendaInfo, stutus: target.value })}
                        >
                            {
                                ["paid", "pendente", "cancelado"].map(status => <option value={status}>{status}</option>)
                            }
                        </select>
                    </DivCampoPopup>
                </div>
                <DivButton style={{ margin: '16px 0 0 0' }}>
                    <ButtonDelete className='deletar' onClick={fecharPopupEditaVenda}>Cancelar</ButtonDelete>
                    <ButtonSave className='salvar' onClick={() => updateVenda()} >Salvar</ButtonSave>
                </DivButton>
            </DivPrincipalEdicaoCurso>}
        />}
    </Container>;
}