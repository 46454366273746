import styled from 'styled-components';

export const DivMainItensForm = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0;
`;

export const TextAreaFormBackup = styled.textarea`
    flex: 1;
    resize: none;
    padding: 12px;
    border-radius: 4px;
    margin: 5px 0 0 0;
    font-family: inherit;
    min-width: 720px;
    width: 100%;
    min-height: 120px;
`;

export const InputCampoFormBackup = styled.input`
    margin: 0 0 0 12px;
`;

export const SelectFormBackup = styled.select`
    margin: 0 0 0 12px;
    :hover {
        border-color: rgba(176, 206, 255, 1);
        box-shadow: 0px 0px 2px 1px;
    }
`;
